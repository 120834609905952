import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

const BlockIcon = ({
  ref,
  ...props
}: SVGProps<SVGSVGElement>): ReactElement<SVGProps<SVGSVGElement>> => (
  <IconBase version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M4,12 C4,7.58 7.58,4 12,4 C13.85,4 15.55,4.63 16.9,5.69 L5.69,16.9 C4.63,15.55 4,13.85 4,12 Z M12,20 C10.15,20 8.45,19.37 7.1,18.31 L18.31,7.1 C19.37,8.45 20,10.15 20,12 C20,16.42 16.42,20 12,20 Z"
        fill="#333333"
        id="Shape"
      />
    </g>
  </IconBase>
);

export default BlockIcon;
