import { PubNubProvider } from 'pubnub-react';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import { useTracking } from '~/lib/analytics';
import { setPageTitle } from '~/lib/dom';
import { refreshAccess as refreshAccessFor, RoomContext } from '~/lib/rooms';
import { AudienceProvider } from '~/lib/rooms/audience';
import { useHeartbeat } from '~/lib/rooms/heartbeat';
import { ModerationProvider } from '~/lib/rooms/moderation';
import { QuestionsProvider } from '~/lib/rooms/questions';
import {
  CalendarEvent,
  ChatConfig,
  MediaSettings,
  Occupant,
  OpentokConfig,
  Participant,
  PubnubClientConfig,
  PubnubFeatureConfig,
  ReactionsConfig,
  Room,
  RoomBroadcast,
  Sponsor,
  Stream,
  User,
} from '~/types/rooms';

import usePubnubSubscriber from '../realtime/usePubnubSubscriber';
import PublisherProvider from './PublisherProvider';

export type RoomProviderProps = {
  broadcast?: RoomBroadcast;
  calendarEvent?: CalendarEvent;
  chat?: PubnubFeatureConfig;
  chatConfig: ChatConfig;
  children: ReactNode;
  debug?: Record<string, unknown>;
  grantAccessUrl?: string | null;
  heartbeatInterval?: number;
  initialMediaSettings?: MediaSettings;
  moderation: PubnubFeatureConfig;
  occupant: Occupant;
  opentok?: OpentokConfig;
  participants: Participant[];
  pubnub: PubnubClientConfig;
  questions?: PubnubFeatureConfig;
  reactions?: ReactionsConfig;
  room: Room;
  sponsor?: Sponsor;
  stream?: Stream;
  user: User;
};

declare global {
  interface Window {
    roomDebug?: unknown;
  }
}

const RoomProvider = ({
  calendarEvent,
  chat,
  children,
  debug,
  grantAccessUrl,
  moderation,
  occupant,
  opentok,
  participants = [],
  pubnub: pubnubConfig,
  reactions,
  room,
  questions,
  stream,
  user,
  sponsor,
  chatConfig,
  initialMediaSettings,
  heartbeatInterval,
  broadcast,
}: RoomProviderProps) => {
  const refreshAccess = useCallback(() => refreshAccessFor(grantAccessUrl || ''), [grantAccessUrl]);
  const channels = useMemo(() => {
    return [
      moderation.channel,
      `${moderation.channel}-pnpres`,
      reactions?.channel,
      questions?.channel,
      questions?.channel && `${questions?.channel}-current`,
      chat?.channel,
    ].filter(Boolean) as string[];
  }, [moderation, reactions, questions, chat]);

  const withState = useMemo(() => {
    return {
      channels: [moderation.channel],
      state: user,
    };
  }, [user, moderation]);

  const { pubnub } = usePubnubSubscriber({
    channels,
    config: {
      ...pubnubConfig,
      heartbeatInterval: 60,
      presenceTimeout: 125,
    },
    withState,
  });

  useEffect(() => {
    setPageTitle(room.name);
  }, [room]);

  useHeartbeat({
    interval: heartbeatInterval || 0,
    roomId: room.id,
  });

  const track = useTracking();
  useEffect(() => track('Joined room'), [track]);

  const roomContextValue = useMemo(
    () => ({
      broadcast,
      calendarEvent,
      chat,
      chatConfig,
      debug,
      moderation,
      occupant,
      opentok,
      participants,
      pubnub,
      pubnubConfig,
      questions,
      reactions,
      refreshAccess,
      room,
      sponsor,
      stream,
      user,
    }),
    [
      broadcast,
      calendarEvent,
      chat,
      chatConfig,
      debug,
      moderation,
      occupant,
      opentok,
      participants,
      pubnub,
      pubnubConfig,
      questions,
      reactions,
      refreshAccess,
      room,
      sponsor,
      stream,
      user
    ],
  );

  if (!pubnub) {
    return null;
  }

  return (
    <RoomContext.Provider value={roomContextValue}>
      <PubNubProvider client={pubnub}>
        <PublisherProvider
          initialMediaSettings={initialMediaSettings}
          opentok={opentok}
          participants={participants}
          refreshAccess={refreshAccess}
        >
          <ModerationProvider>
            <AudienceProvider>
              <QuestionsProvider>{children}</QuestionsProvider>
            </AudienceProvider>
          </ModerationProvider>
        </PublisherProvider>
      </PubNubProvider>
    </RoomContext.Provider>
  );
};

export default RoomProvider;
