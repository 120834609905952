import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTracking } from '~/lib/analytics';
import BlockAttendeeButton from '~/components/attendees/BlockAttendeeButton';
import ButtonBase from '~/components/button/ButtonBase';
import AlertIcon from '~/components/icons/AlertIcon';

type SafetyControlsProps = {
  attendeeId: string;
  firstName: string;
  incidentReportUrl: string;
  isBlocked: boolean;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const Message = styled.div`
  text-align: center;
  font-size: var(--fs-sm);
`;

const ReportIcon = styled(AlertIcon)`
  color: var(--c-red);
`;

const SafetyControls = ({
  isBlocked,
  firstName,
  attendeeId,
  incidentReportUrl,
}: SafetyControlsProps): ReactElement<SafetyControlsProps> => {
  const { t } = useTranslation();
  const track = useTracking();

  return (
    <Container>
      {isBlocked && <Message>{t('safety.blocked', { name: firstName })}</Message>}
      <BlockAttendeeButton attendeeId={attendeeId} />
      <ButtonBase
        as="a"
        href={incidentReportUrl}
        rel="noopener noreferrer"
        size="small"
        target="_blank"
        variant="secondary"
        onClick={(): void => track('Reported attendee')}
      >
        <ReportIcon />
        {t('safety.report', { name: firstName })}
      </ButtonBase>
    </Container>
  );
};

export default SafetyControls;
