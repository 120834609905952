import {
  ReactElement,
  UIEvent as ReactUIEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import { useModeration } from '~/lib/rooms/moderation';
import { NewMessagesCounterContext } from '~/components/chat/NewMessagesCounter';
import { Message } from '~/types/chat';

import RoomChatMessage from './RoomChatMessage';
import RoomNewMessages from './RoomNewMessages';

const deleted = (m: Message) => !!m.actions?.hidden;

const StyledChatStream = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

export type RoomChatStreamProps = {
  historyLoaded?: boolean;
  isFetching?: boolean;
  loadMore?: () => void;
  messages?: Message[];
  sendMessageAction: (action: string, value: string, timetoken: string) => void;
};

const RoomChatStream = ({
  messages = [],
  loadMore,
  historyLoaded,
  sendMessageAction,
}: RoomChatStreamProps): ReactElement<RoomChatStreamProps> => {
  const chatStream = useRef<HTMLDivElement>(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const { newMessagesCounter, setNewMessagesCounter } = useContext(NewMessagesCounterContext);

  const scrollToBottom = useCallback(() => {
    if (chatStream.current) {
      chatStream.current.scrollTop = chatStream.current.scrollHeight;
    }

    if (newMessagesCounter) {
      setNewMessagesCounter(0);
    }
  }, [newMessagesCounter, setNewMessagesCounter]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      scrollToBottom();
    }
  }, [messages.length, shouldScrollToBottom, scrollToBottom, newMessagesCounter]);

  const handleScroll = (e: ReactUIEvent<HTMLDivElement>) => {
    const elem = e.currentTarget;

    if (elem.scrollHeight - elem.scrollTop === elem.clientHeight) {
      setShouldScrollToBottom(true);
      return;
    }

    if (shouldScrollToBottom) {
      setShouldScrollToBottom(false);
    }
  };

  const { pinMessage, pinnedMessage } = useModeration();
  const showNewMessagesButton = !!newMessagesCounter && !shouldScrollToBottom;

  return (
    <StyledChatStream ref={chatStream} onScroll={handleScroll}>
      {pinnedMessage && (
        <RoomChatMessage
          isPinned
          author={pinnedMessage.author}
          body={pinnedMessage.body}
          onPin={() => pinMessage(null)}
        />
      )}
      <InfiniteScroll
        initialLoad
        isReverse
        hasMore={!historyLoaded}
        loadMore={() => loadMore?.()}
        threshold={35}
        useWindow={false}
      >
        {messages.map((m) => {
          if (m.type !== 'text') {
            return null;
          }

          // At this point messages are raw pubnub messages and their content has not been validated.
          // Ensure we gracefully handle unexpected message contents by skipping the message.
          try {
            return (
              <RoomChatMessage
                key={m.id}
                author={m.publisher}
                body={m.data.body}
                isDeleted={deleted(m)}
                onDelete={() => sendMessageAction('hidden', 'yes', m.timetoken)}
                onPin={() => pinMessage({ author: m.publisher, body: m.data.body })}
              />
            );
          } catch (err) {
            if (err instanceof Error) {
              window.newrelic?.noticeError(err);
            }
            return null;
          }
        })}
      </InfiniteScroll>
      {showNewMessagesButton && (
        <RoomNewMessages counter={newMessagesCounter} readNewMessages={scrollToBottom} />
      )}
    </StyledChatStream>
  );
};

export default RoomChatStream;
