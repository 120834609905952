import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

type CountdownLabelProps = {
  children: ReactNode;
  className?: string;
};

const CountdownLabel = ({
  className,
  children,
}: CountdownLabelProps): ReactElement<CountdownLabelProps> => {
  return <span className={clsx(className, 'countdown-label')}>{children}</span>;
};

export default CountdownLabel;
