import { ReactNode } from 'react';

import AudienceIcon from '../../icons/AudienceIcon';
import ChatIcon from '../../icons/ChatIcon';
import InfoIcon from '../../icons/InfoIcon';
import QuestionIcon from '../../icons/QuestionIcon';

export enum Tabs {
  Audience = 'AUDIENCE',
  Chat = 'CHAT',
  Info = 'INFO',
  QA = 'QA'
}

export type Tab = {
  icon: ReactNode;
  id: Tabs;
  label: string;
};

type TabsSettings = {
  withChat?: boolean;
  withInfo?: boolean;
  withQA?: boolean;
};

export const generateTabs = ({
  withQA = false,
  withChat = false,
  withInfo = false,
}: TabsSettings): Tab[] => [
  ...(withInfo
    ? [
        {
          icon: <InfoIcon />,
          id: Tabs.Info,
          label: 'Info',
        },
      ]
    : []),
  ...(withChat
    ? [
        {
          icon: <ChatIcon />,
          id: Tabs.Chat,
          label: 'Chat',
        },
      ]
    : []),
  {
    icon: <AudienceIcon />,
    id: Tabs.Audience,
    label: 'Audience',
  },
  ...(withQA
    ? [
        {
          icon: <QuestionIcon />,
          id: Tabs.QA,
          label: 'Questions',
        },
      ]
    : []),
];
