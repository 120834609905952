import { ReactElement, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import TimeIcon from '../icons/TimeIcon';
import LoginContext from './LoginContext';
import LoginMessage from './LoginMessage';
import LoginPanel from './LoginPanel';

const LoginClosedPanel = (): ReactElement => {
  const { t } = useTranslation();
  const { appUrl } = useContext(LoginContext);

  return (
    <LoginPanel>
      <LoginMessage icon={<TimeIcon />} title={t('login.closed.title')}>
        <Trans i18nKey="login.closed.message">
          <a href={appUrl}>appUrl</a>
        </Trans>
      </LoginMessage>
    </LoginPanel>
  );
};

export default LoginClosedPanel;
