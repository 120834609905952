import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

import { useRoom } from '~/lib/rooms';

import SponsorOverlay from './room_elements/SponsorOverlay';

type RoomLayoutProps = {
  children: ReactNode;
};

const RoomLayout = ({ children }: RoomLayoutProps): ReactElement<RoomLayoutProps> => {
  const {
    sponsor,
    room: { featuresBackstage },
  } = useRoom();
  return (
    <div className={clsx('stage', (sponsor || featuresBackstage) && '-spaced')}>
      {sponsor?.logoUrl && <SponsorOverlay backgroundUrl={sponsor.logoUrl} />}
      {children}
    </div>
  );
};

export default RoomLayout;
