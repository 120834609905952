/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import every from 'lodash/every';
import PubNub from 'pubnub';
import { useMemo } from 'react';

export const extractIdentity = (uuid = '') => uuid?.split(':')[0];

type UsePubnubArgs = {
  authKey: string;
  heartbeatInterval?: number;
  presenceTimeout?: number;
  publishKey: string;
  subscribeKey: string;
  suppressLeaveEvents?: boolean;
  uuid: string;
};

const usePubnub = ({
  authKey,
  publishKey,
  subscribeKey,
  uuid,
  presenceTimeout = 0,
  heartbeatInterval = undefined,
  suppressLeaveEvents = true,
}: UsePubnubArgs) => {
  const pubnub = useMemo(() => {
    const isConfigured = every([authKey, uuid, publishKey, subscribeKey], Boolean);
    if (!isConfigured) {
      return null;
    }

    return new PubNub({
      authKey,
      heartbeatInterval,
      presenceTimeout,
      publishKey,
      restore: true,
      ssl: true,
      subscribeKey,
      suppressLeaveEvents,
      uuid,
    });
  }, [
    authKey,
    presenceTimeout,
    publishKey,
    subscribeKey,
    uuid,
    heartbeatInterval,
    suppressLeaveEvents,
  ]);

  return {
    authKey,
    identity: extractIdentity(uuid),
    pubnub,
    uuid,
  };
};

export default usePubnub;
