export const BLACKLISTED_CHARACTERS = /^[^@]+$/;
const MIN_INTEREST_INPUT_LENGTH = 3;
const MAX_INTEREST_INPUT_LENGTH = 10;
const INTEREST_INPUT_MIN_ERROR_MESSAGE = `Choose at least ${MIN_INTEREST_INPUT_LENGTH} topics`;
const INTEREST_INPUT_MAX_ERROR_MESSAGE = `Choose at maximum ${MAX_INTEREST_INPUT_LENGTH} topics`;

export const REQUIRED_ERROR_MESSAGE = 'This field is required.';
export const SPECIAL_CHARS_MESSAGE = 'Please avoid using @ special character';

const ACCEPTED_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
// MB
export const MAX_AVATAR_SIZE = 10;

const acceptedExtensions = ACCEPTED_TYPES.map((type) => type.replace('image/', '')).join(', ');
export const acceptedTypes = ACCEPTED_TYPES.join(', ');

type ValidationResult = boolean | string;

export const isBlacklisted = (value: string): ValidationResult =>
  BLACKLISTED_CHARACTERS.test(value) || 'Please avoid using @ special character';

export const getLimitMessage = (min: number, max: number): string =>
  `Please enter text between ${min}-${max} characters.`;

export const charactersLimit = (value: string, min: number, max: number): ValidationResult =>
  (value.length >= min && value.length <= max) || getLimitMessage(min, max);

export const interestInputLength = {
  maxLength: (value: string[]): ValidationResult =>
    value.length <= MAX_INTEREST_INPUT_LENGTH || INTEREST_INPUT_MAX_ERROR_MESSAGE,
  minLength: (value: string[]): ValidationResult =>
    value.length >= MIN_INTEREST_INPUT_LENGTH || INTEREST_INPUT_MIN_ERROR_MESSAGE,
};

export const maxLengthValidator = (
  max: number,
): {
  message: string;
  value: number;
} => ({
  message: `This field can not be longer than ${max} characters.`,
  value: max,
});

export const maxLength = (value: string, max: number): ValidationResult =>
  value.length <= max || `This field can not be longer than ${max} characters.`;

export const checkFilesInvalidExtension = (files?: FileList): string | true => {
  if (files && files?.length) {
    const file = files[0];
    const hasAcceptedTypes = ACCEPTED_TYPES.includes(file.type);
    if (!hasAcceptedTypes) {
      return `Supported image extensions: ${acceptedExtensions}`;
    }
  }

  return true;
};

export const checkFilesMaxFileSize = (files?: FileList): string | true => {
  if (files && files?.length) {
    const file = files[0];
    const sizeMB = file.size / (1024 * 1024);

    if (sizeMB > MAX_AVATAR_SIZE) {
      return 'Maximum image size is 10MB';
    }
  }

  return true;
};
