import { ReactElement } from 'react';

import BitmovinPlayer from './BitmovinPlayer';
import GatepassPlayer from './GatepassPlayer';
import IframePlayer from './IframePlayer';
import StaticPlayer from './StaticPlayer';
import VimeoPlayer from './VimeoPlayer';

export type PlayerProps = {
  muted?: boolean;
  src: Source;
  subtitles?: boolean;
  videoId?: string;
  videoTitle?: string;
  poster?: string;
};

export type Source = {
  gatepass?: string;
  hls?: string;
  iframe?: string;
  static?: string;
  vimeo?: string;
};

const Player = ({
  src,
  muted,
  subtitles,
  ...rest
}: PlayerProps): ReactElement<PlayerProps> | null => {
  if (src.hls) {
    return <BitmovinPlayer muted={muted} url={src.hls} {...rest} />;
  }
  if (src.vimeo) {
    return <VimeoPlayer url={src.vimeo} />;
  }
  if (src.static) {
    return <StaticPlayer mp4Url={src.static} subtitles={subtitles} />;
  }
  if (src.gatepass) {
    return <GatepassPlayer muted={muted} url={src.gatepass} {...rest} />;
  }
  if (src.iframe) {
    return <IframePlayer src={src.iframe} />;
  }
  return null;
};

export default Player;
