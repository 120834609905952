import clsx from 'clsx';
import { CSSProperties,ReactElement } from 'react';

import { Headcount } from '~/types/rooms';
import { NOTIFICATIONS_PER_PAGE } from '~/components/notifications/NotificationStream';

import { Tab, Tabs } from './discussionPanelTabsConfig';

const getHeadcountString = (headcount = 0): string => {
  if (headcount > 1000) {
    const thousands = Math.round(headcount / 1000);

    return `${thousands}k`;
  }

  return `${headcount}`;
};

type DiscussionPanelTabsMaximisedProps = {
  activeTab: Tabs;
  headcount: Headcount;
  newMessagesCounter: number;
  openTab: (id: Tabs) => void;
  showAudienceHeadcount: boolean;
  showNewMessagesCounter: boolean;
  tabs: Tab[];
};

const DiscussionPanelTabsMaximised = ({
  tabs,
  showNewMessagesCounter,
  activeTab,
  openTab,
  headcount,
  showAudienceHeadcount,
  newMessagesCounter,
}: DiscussionPanelTabsMaximisedProps): ReactElement<DiscussionPanelTabsMaximisedProps> => {
  const style = {
    '--tabs-count': tabs.length,
  } as CSSProperties;

  return (
    <header className="discussion-panel__header">
      <div className="discussion-panel__switcher" style={style}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={clsx(
              'discussion-panel__button',
              tab.id === activeTab ? '-on' : '-off',
              `-${tab.label.toLowerCase()}`,
            )}
            type="button"
            onClick={() => openTab(tab.id)}
          >
            <span className={clsx('discussion-panel__icon', tab.id === activeTab ? '-on' : '-off')}>
              {tab.icon}
            </span>

            <span className="discussion-panel__label">{tab.id === activeTab && tab.label}</span>
            {tab.id === Tabs.Chat && showNewMessagesCounter && (
              <div className="attention-indicator -count">
                <span className="count">
                  {newMessagesCounter < NOTIFICATIONS_PER_PAGE
                    ? newMessagesCounter
                    : `${NOTIFICATIONS_PER_PAGE - 1}+`}
                </span>
              </div>
            )}
            {tab.id === Tabs.Audience && showAudienceHeadcount && (
              <div className="inline-indicator -count">
                <span className="count">{getHeadcountString(headcount || 0)}</span>
              </div>
            )}
          </button>
        ))}
      </div>
    </header>
  );
};

export default DiscussionPanelTabsMaximised;
