import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import ConnectedRefinementList from './ConnectedRefinementList';

const ScheduleSearchFilters = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="search-filters">
      <ConnectedRefinementList
        attribute="channel.name"
        label={t('schedule.filterLabel.channel')}
        limit={100}
      />
      <ConnectedRefinementList
        attribute="schedule_tracks.name"
        label={t('schedule.filterLabel.tracks')}
        limit={100}
      />
      <ConnectedRefinementList
        attribute="conference_topics.name"
        label={t('schedule.filterLabel.topics')}
        limit={100}
      />
      <ConnectedRefinementList
        attribute="format.label"
        label={t('schedule.filterLabel.format')}
        limit={100}
      />
      <ConnectedRefinementList
        attribute="location.venue"
        label={t('schedule.filterLabel.venue')}
        limit={100}
      />
    </div>
  );
};

export default ScheduleSearchFilters;
