import { ReactElement } from 'react';

import AttendeeCard from '~/components/attendees/AttendeeCard';
import Carousel from '~/components/shared/molecules/Carousel';

type AttendeeType = {
  avatarUrl?: string | null | undefined;
  companyName?: string | null | undefined;
  id: string;
  jobTitle?: string | null | undefined;
  name: string;
  role?: string | null | undefined;
};

export type AttendeeCarouselProps = {
  attendees?: AttendeeType[];
  title?: string;
};

const AttendeeCarousel = ({
  title,
  attendees,
}: AttendeeCarouselProps): ReactElement<AttendeeCarouselProps> => (
  <section className="profile-page__pane pane">
    <Carousel className="-auto-height" slideCount={3} title={title}>
      {attendees ? (
        attendees.map((attendee, index) => (
          <Carousel.Slide key={attendee.id} virtualIndex={index}>
            <Carousel.SlideContent>
              <AttendeeCard
                avatarUrl={attendee.avatarUrl ?? undefined}
                cardRole={attendee?.role ?? undefined}
                companyName={attendee.companyName ?? undefined}
                firstName={attendee.name}
                id={attendee.id}
                jobTitle={attendee.jobTitle ?? undefined}
                theme="dense"
              />
            </Carousel.SlideContent>
          </Carousel.Slide>
        ))
      ) : (
        <>
          <Carousel.Slide>
            <Carousel.SlideContent>
              <AttendeeCard />
            </Carousel.SlideContent>
          </Carousel.Slide>
          <Carousel.Slide>
            <Carousel.SlideContent>
              <AttendeeCard />
            </Carousel.SlideContent>
          </Carousel.Slide>
          <Carousel.Slide>
            <Carousel.SlideContent>
              <AttendeeCard />
            </Carousel.SlideContent>
          </Carousel.Slide>
        </>
      )}
    </Carousel>
  </section>
);

export default AttendeeCarousel;
