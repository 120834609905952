import { ReactNode, useContext, useState } from 'react';

import { useAudience } from '~/lib/rooms/audience';

import { NewMessagesCounterContext } from '../../chat/NewMessagesCounter';
import { Tab, Tabs } from './discussionPanelTabsConfig';
import DiscussionPanelTabsMaximised from './DiscussionPanelTabsMaximised';
import DiscussionPanelTabsMinimised from './DiscussionPanelTabsMinimised';

type DiscussionPanelTabsProps = {
  children: ({ activeTab }: { activeTab: Tabs }) => ReactNode;
  isSidebarOpen: boolean;
  onTabOpen?: () => void;
  tabs: Tab[];
};

const DiscussionPanelTabs = ({
  children,
  tabs,
  isSidebarOpen,
  onTabOpen,
}: DiscussionPanelTabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const { headcount } = useAudience();
  const { newMessagesCounter } = useContext(NewMessagesCounterContext);
  const showNewMessagesCounter = !!newMessagesCounter && activeTab !== Tabs.Chat;
  const showAudienceHeadcount = headcount !== null && headcount > 0;

  const openTab = (tabId: Tabs) => {
    setActiveTab(tabId);
    onTabOpen?.();
  };

  return (
    <>
      {isSidebarOpen ? (
        <DiscussionPanelTabsMaximised
          activeTab={activeTab}
          headcount={headcount}
          newMessagesCounter={newMessagesCounter}
          openTab={openTab}
          showAudienceHeadcount={showAudienceHeadcount}
          showNewMessagesCounter={showNewMessagesCounter}
          tabs={tabs}
        />
      ) : (
        <DiscussionPanelTabsMinimised
          openTab={openTab}
          showNewMessagesCounter={showNewMessagesCounter}
          tabs={tabs}
        />
      )}
      {children({ activeTab })}
    </>
  );
};

export default DiscussionPanelTabs;
