import { ReactElement } from 'react';
import { ClipLoader } from 'react-spinners';

export type SpinnerProps = {
  size?: string | number;
  color?: string;
};

const Spinner = ({
  size = '1rem',
  color = 'var(--c-brand-primary)',
}: SpinnerProps): ReactElement => {
  return <ClipLoader color={color} size={size} />;
};

export default Spinner;
