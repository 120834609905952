import { ReactElement } from 'react';
import styled from 'styled-components';

import ButtonBase from '~/components/button/ButtonBase';

const NavBase = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 2rem;

  ${ButtonBase} + ${ButtonBase} {
    margin-left: 0.75rem;
  }
`;

type TimeslotNavigationProps = {
  indexUrl: string;
};

const TimeslotNavigation = ({
  indexUrl,
}: TimeslotNavigationProps): ReactElement<TimeslotNavigationProps> => {
  return (
    <NavBase>
      <ButtonBase as="a" href={indexUrl} variant="secondary">
        View full schedule
      </ButtonBase>
    </NavBase>
  );
};

export default TimeslotNavigation;
