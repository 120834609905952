import clsx from 'clsx';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { cloneElement, ReactElement, RefObject } from 'react';
import {
  AutocompleteExposed,
  AutocompleteProvided,
  connectAutoComplete,
  ConnectedComponentClass,
  Hit,
} from 'react-instantsearch-core';

import ChevronIcon from '~/components/icons/ChevronIcon';

// Downshift does not provide correct type for it
type GetItemProps = Record<string, string | (() => void)>;

export type ListItemComponentProps<TItem> = {
  index?: number;
  isHighlighted?: boolean;
  item?: Hit<TItem>;
} & GetItemProps;

type GenericHitObject = unknown;
export type AgoliaDropdownProps<THitObject = GenericHitObject> =
  AutocompleteProvided<THitObject> & {
    className?: string;
    inputRef?: RefObject<HTMLInputElement>;
    itemToString: (item: Hit<THitObject> | null) => string;
    label?: string;
    listItemComponent?: ReactElement<ListItemComponentProps<THitObject>>;
    name: string;
    onBlur?: () => void;
    onChange?: (selectedItem: Hit<THitObject> | null | undefined) => void;
    onIsOpenChange?: (changes: UseComboboxStateChange<Hit<THitObject>>) => void;
    placeholder?: string;
  };

const AgoliaDropdown = <THitObject extends GenericHitObject>({
  name,
  className,
  onBlur,
  onIsOpenChange,
  placeholder,
  label,
  onChange,
  listItemComponent,
  itemToString,
  refine,
  hits,
  inputRef,
}: AgoliaDropdownProps<THitObject>): ReactElement<AgoliaDropdownProps<THitObject>> => {
  const {
    getInputProps,
    getComboboxProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    openMenu,
  } = useCombobox<Hit<THitObject>>({
    itemToString,
    items: hits,
    onInputValueChange: ({ inputValue }) => refine(inputValue),
    onIsOpenChange,
    onSelectedItemChange: (changes) => {
      onChange?.(changes.selectedItem);
    },
  });

  return (
    <div className={clsx('dropdown', className)}>
      <div {...getComboboxProps()} className="dropdown__main">
        <input
          {...getInputProps({
            name,
            onBlur,
            onFocus: () => {
              if (!isOpen) {
                openMenu();
              }
            },
            ref: inputRef,
          })}
          aria-label={label}
          className="dropdown__input"
          placeholder={placeholder}
        />
        <button
          className="dropdown__button"
          type="button"
          {...getToggleButtonProps()}
          aria-label="toggle menu"
        >
          <ChevronIcon />
        </button>
      </div>
      <ul {...getMenuProps()} className={clsx('dropdown__list', isOpen && '-open')}>
        {isOpen &&
          hits.map((item, index) => {
            if (listItemComponent) {
              return cloneElement(listItemComponent, {
                ...getItemProps({
                  index,
                  item,
                }),
                index,
                isHighlighted: highlightedIndex === index,
                item,
                key: item.objectID,
              });
            }
            return (
              <li
                key={item.objectID}
                className={clsx(
                  'dropdown__list-item',
                  highlightedIndex === index && '-highlighted',
                )}
                {...getItemProps({
                  index,
                  item,
                })}
              >
                {itemToString(item)}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default connectAutoComplete<AgoliaDropdownProps>(AgoliaDropdown);

export const createAgoliaDropdown = <
  THitObject extends GenericHitObject,
>(): ConnectedComponentClass<
  AgoliaDropdownProps<THitObject>,
  AutocompleteProvided<THitObject>,
  AutocompleteExposed
> => connectAutoComplete<AgoliaDropdownProps<THitObject>, THitObject>(AgoliaDropdown);
