import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import config from '~/lib/config';
import LOADER from '~/../assets/images/loading.gif';
import setupWebSocketConnection from '~/lib/setupWebSocketConnection';
import urlFor from '~/lib/urlFor';

type QRLoginProps = {
  appUrl?: string;
  baseUrl?: string;
};

const Wrapper = styled.div`
  border-radius: 0.5rem;
  border: 1px solid var(--c-silver);
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const QRLogin = ({
  baseUrl = config.wsloginUrl,
  appUrl = '',
}: QRLoginProps): ReactElement<QRLoginProps> | null => {
  const onLogin = (accessToken: string): void => {
    window.location.href = urlFor(`/login/qr/${accessToken}`);
  };

  const [qrCode, setQrCode] = useState<string>(LOADER);

  useEffect(() => setupWebSocketConnection(baseUrl, appUrl, setQrCode, onLogin), [baseUrl, appUrl]);

  return (
    <Wrapper>
      <img alt="login qr code" src={qrCode} />
    </Wrapper>
  );
};

export default QRLogin;
