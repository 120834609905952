import axios from 'axios';
import { FormEventHandler, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';

import urlFor from '~/lib/urlFor';

import Button from '../button/Button';
import FormField from '../forms/FormField';
import LoadingIcon from '../icons/LoadingIcon';
import StandaloneFlashNotification from '../notifications/StandaloneFlashNotification';
import LoginForm from './LoginForm';
import LoginPanel from './LoginPanel';
import SuccessMessage from './SuccessMessage';

type LoginResponse = {
  message?: string;
  status: 'ok' | 'pending' | 'error';
};

const TicketLoginPanel = (): ReactElement => {
  const { t } = useTranslation();
  const [ticketRef, setTicketRef] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<LoginResponse['status']>();
  const [message, setMessage] = useState<string>();

  // Login link bypassed
  useEffect(() => {
    if (status === 'ok') {
      window.location.href = urlFor('/login/redirect');
    }
  }, [status]);

  const login = async (): Promise<void> => {
    setLoading(true);

    try {
      const res = await axios.post<LoginResponse>(urlFor('/sessions.json'), {
        ticket_reference: ticketRef,
      });

      setStatus(res.data.status);
      setMessage(res.data.message);
    } catch (err) {
      if (axios.isAxiosError(err) || err instanceof Error) {
        window.newrelic?.noticeError(err);
      }
      setStatus('error');
      setMessage('Login failed');
    }

    setLoading(false);
  };

  if (status === 'pending') {
    return (
      <LoginPanel size="small">
        <SuccessMessage>{t('login.confirmLogin')}</SuccessMessage>
      </LoginPanel>
    );
  }

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    void login();
  }

  return (
    <LoginPanel size="small">
      <LoginForm
        onSubmit={handleSubmit}
      >
        {message && (
          <StandaloneFlashNotification
            fadeOutTimeout={30_000}
            message={message}
            onDismiss={(): void => setMessage(undefined)}
          />
        )}
        <FormField isRequired label={t('login.bookingReferenceLabel')} name="ticket_reference">
          <input
            required
            id="ticket_reference"
            name="ticket_reference"
            placeholder={t('login.bookingReferencePlaceholder')}
            type="text"
            value={ticketRef}
            onChange={(e): void => setTicketRef(e.target.value)}
          />
        </FormField>
        <Button disabled={loading} icon={loading && <LoadingIcon />} size="large">
          {t('login.logIn')}
        </Button>
        <Link href="/forgot">{t('login.forgotBookingReference')}</Link>
      </LoginForm>
    </LoginPanel>
  );
};

export default TicketLoginPanel;
