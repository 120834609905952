import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { API_ENDPOINTS } from '~/endpoints';
import { mingleLog } from '~/lib/loggers';
import replaceArrayItem from '~/lib/replaceArrayItem';

export type MingleHistory = {
  downvote_url: string;
  endedAt: string;
  id: string;
  partner: {
    accreditation: string;
    avatar_url: string;
    company_name: string;
    id: string;
    name: string;
  };
  scheduled_for: string;
  started_at: string;
  upvote_url: string;
  vote?: unknown;
};

const fetchMingles = async () => {
  const { data } = await axios.get(API_ENDPOINTS.mingleHistory());
  return data['mingle/mingles'];
};

const useMingleHistory = (): {
  history: MingleHistory[];
  isLoading: boolean;
  voteOnMingle: (voteUrl: string) => void;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mingleHistory, setMingleHistory] = useState<MingleHistory[]>([]);

  useEffect(() => {
    const fetchMinglesData = async () => {
      try {
        const data = await fetchMingles();
        setMingleHistory(data);
      } catch (e) {
         
        console.log('failed to fetch mingle history');
      } finally {
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    void fetchMinglesData();
  }, []);

  const voteOnMingle = useCallback(
    async (voteUrl: string) => {
      const { data } = await axios.post(voteUrl);
      const existingMingle = mingleHistory.find((mingle) => mingle.id === data.mingle_id);
      const updatedMingle = { ...existingMingle, vote: data };
      setMingleHistory((prevHistory) =>
        replaceArrayItem(
          prevHistory,
          (mingle) => mingle.id === data.mingle_id,
          updatedMingle as MingleHistory,
        ),
      );
      return data;
    },
    [mingleHistory],
  );

  useEffect(() => {
    mingleLog('useMingleHistory', {
      mingleHistory,
    });
  }, [mingleHistory]);

  return {
    history: mingleHistory,
    isLoading,
    voteOnMingle,
  };
};

export default useMingleHistory;
