import clsx from 'clsx';
import type { ReactElement } from 'react';

import { useCurrentUser } from '~/lib/user';
import type { Attendee } from '~/operations/catalyst';
import LazyTooltip from '~/components/others/LazyTooltip';
import urlFor from '~/lib/urlFor';
import OldAttendeeConnectionButton from '../connections/OldAttendeeConnectionButton';

import AttendeeRoleTag from './AttendeeRoleTag';
import Portrait, { PortraitProps } from './Portrait';
import ChatIcon from '../icons/ChatIcon';

export type AttendeeInfo = Pick<
  Attendee,
  'role' | 'avatarUrl' | 'name' | 'jobTitle' | 'companyName' | 'id'
>;

type AttendeeTooltipProps = {
  className?: string;
  info: AttendeeInfo;
  portraitSize?: PortraitProps['size'];
};

type AttendeeTooltipContentProps = Omit<AttendeeTooltipProps, 'className' | 'portraitSize'>;

const AttendeeTooltipContent = ({
  info: { role, avatarUrl, name, jobTitle, companyName, id },
}: AttendeeTooltipContentProps): ReactElement<AttendeeTooltipContentProps> => {
  const { id: currentUserId } = useCurrentUser();
  const isCurrentUser = currentUserId === id;

  return (
    <div className="attendee-tooltip-content">
      <div
        className={clsx(
          'attendee-tooltip-content__accreditation-banner accreditation',
          `-${(role || '').toLowerCase()}`,
        )}
      />
      <div className="attendee-tooltip-content__bio">
        <Portrait
          avatarUrl={avatarUrl}
          className="attendee-tooltip-content__portrait"
          isLink={false}
          name={name}
          size="md"
        />
        <p className="attendee-tooltip-content__name">
          {name}
          {role && <AttendeeRoleTag accreditation={role} visibleAccreditations={['Staff']} />}
        </p>
        {jobTitle && <p className="attendee-tooltip-content__role">{jobTitle}</p>}
        {companyName && (
          <p className="attendee-tooltip-content__company">
            {'at '}
            {companyName}
          </p>
        )}
      </div>
      {!isCurrentUser && (
        <div className="attendee-tooltip-content__actions">
          <div className="attendee-tooltip-content__connection-buttons">
            <OldAttendeeConnectionButton attendee={{ id, name }} />
          </div>
          <a className="interact-btn -chat" href={`${urlFor(`/my/conversations/?attendee=${id}`)}`}>
            <ChatIcon />
            Chat
          </a>
        </div>
      )}
    </div>
  );
};

const AttendeeTooltip = ({
  info,
  className,
  portraitSize = 'list-item',
}: AttendeeTooltipProps): ReactElement<AttendeeTooltipProps> => (
  <LazyTooltip
    interactive
    appendTo={(): HTMLElement => document.body}
    arrow={false}
    content={<AttendeeTooltipContent info={info} />}
    delay={[400, 0]}
    interactiveBorder={10}
    interactiveDebounce={25}
    moveTransition="transform 0.2s ease-out"
    offset={({ reference }): [number, number] => [reference.width + 5, -reference.height / 2 + 5]}
    placement="bottom-start"
  >
    <div className={clsx('attendee-tooltip', className)}>
      <Portrait
        isLink
        avatarUrl={info.avatarUrl}
        id={info.id}
        name={info.name}
        size={portraitSize}
      />
    </div>
  </LazyTooltip>
);

export default AttendeeTooltip;
