import { ReactElement, SVGProps } from 'react';

type InfoIconProps = SVGProps<SVGSVGElement>;

const InfoIcon = (props: InfoIconProps): ReactElement<InfoIconProps> => {
  return (
    <svg fill="none" viewBox="0 0 21 20" {...props}>
      <path
        clipRule="evenodd"
        d="M10.8026 0C5.28261 0 0.802612 4.48 0.802612 10C0.802612 15.52 5.28261 20 10.8026 20C16.3226 20 20.8026 15.52 20.8026 10C20.8026 4.48 16.3226 0 10.8026 0ZM10.8026 2C6.38661 2 2.80261 5.584 2.80261 10C2.80261 14.416 6.38661 18 10.8026 18C15.2186 18 18.8026 14.416 18.8026 10C18.8026 5.584 15.2186 2 10.8026 2ZM11.8026 15H9.80261V9H11.8026V15ZM11.8026 7H9.80261V5H11.8026V7Z"
        fill="#333333"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default InfoIcon;
