import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import MapIcon from '~/components/icons/MapIcon';
import MingleConnectionIcon from '~/components/icons/MingleConnectionIcon';
import BreakoutIcon from '~/components/icons/BreakoutIcon';
import CloseIcon from '~/components/icons/CloseIcon';
import ExploreIcon from '~/components/icons/ExploreIcon';
import HamburgerIcon from '~/components/icons/HamburgerIcon';
import LiveIcon from '~/components/icons/LiveIcon';
import ScheduleIcon from '~/components/icons/ScheduleIcon';
import WatchIcon from '~/components/icons/WatchIcon';
import urlFor from '~/lib/urlFor';

interface HamburgerMenuProps {
  disabled: {
    breakout: boolean;
    explore: boolean;
    map: boolean;
    mingle: boolean;
    schedule: boolean;
    watch: boolean;
  };
  live: {
    mingle: boolean;
  };
  show: {
    breakout: boolean;
    explore: boolean;
    map: boolean;
    mingle: boolean;
    schedule: boolean;
    watch: boolean;
  };
}

const HamburgerMenu = ({ show, disabled, live }: HamburgerMenuProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = (): void => {
    setIsOpen((lastOpen) => !lastOpen);
  };

  const closeMenu = (): void => {
    setIsOpen(false);
  };

  return (
    <div className="hamburger-container">
      <div className="hamburger-toggler">
        {isOpen ? (
          <button className="btn -invisible -svg" type="button" onClick={toggleMenu}>
            <CloseIcon />
            <span>Close menu</span>
          </button>
        ) : (
          <button className="btn -invisible -svg" type="button" onClick={toggleMenu}>
            <HamburgerIcon />
            <span>Menu</span>
          </button>
        )}
      </div>

      <CSSTransition
        mountOnEnter
        unmountOnExit
        classNames="hamburger-menu-"
        in={isOpen}
        timeout={250}
      >
        <nav className="hamburger-menu">
          {show.watch && (
            <a className="hamburger-menu__item" href={urlFor('/channels')}>
              <div className="icon">
                <WatchIcon />
              </div>
              <h4 className="title">{t('labels.watch')}</h4>
              <p className="subtitle">{t('menu.watch')}</p>
            </a>
          )}
          {show.schedule && (
            <a
              className={clsx(
                'hamburger-menu__item',
                disabled.schedule && '-disabled -disabled-link',
              )}
              href={urlFor('/schedule')}
            >
              <div className="icon">
                <ScheduleIcon />
              </div>
              <h4 className="title">{t('labels.schedule')}</h4>
              <p className="subtitle">{t('menu.schedule')}</p>
            </a>
          )}
          {show.breakout && (
            <a
              className={clsx(
                'hamburger-menu__item',
                disabled.breakout && '-disabled -disabled-link',
              )}
              href={urlFor('/breakout')}
            >
              <div className="icon">
                <BreakoutIcon />
              </div>
              <h4 className="title">{t('labels.breakout')}</h4>
              <p className="subtitle">{t('menu.breakout')}</p>
            </a>
          )}
          {show.mingle && (
            <a
              className={clsx(
                'hamburger-menu__item',
                disabled.mingle && '-disabled -disabled-link',
              )}
              href={urlFor('/mingle')}
            >
              <div className="icon">
                <MingleConnectionIcon />
              </div>
              <h4 className="title">
                {t('labels.mingle')}
                {live.mingle && (
                  <span className="mingle-live">
                    <LiveIcon />
                  </span>
                )}
              </h4>
              <p className="subtitle">{t('menu.mingle')}</p>
            </a>
          )}
          {show.explore && (
            <a
              className={clsx(
                'hamburger-menu__item',
                disabled.explore && '-disabled -disabled-link',
              )}
              href={urlFor('/partners')}
            >
              <div className="icon">
                <ExploreIcon />
              </div>
              <h4 className="title">{t('labels.explore')}</h4>
              <p className="subtitle">{t('menu.explore')}</p>
            </a>
          )}
          {show.map && (
            <a
              className={clsx('hamburger-menu__item', disabled.map && '-disabled -disabled-link')}
              href={urlFor('/map')}
            >
              <div className="icon">
                <MapIcon />
              </div>
              <h4 className="title">{t('labels.map')}</h4>
              <p className="subtitle">{t('menu.map')}</p>
            </a>
          )}
        </nav>
      </CSSTransition>

      {isOpen ? (
        <div className="hamburger-overlay" role="presentation" onClick={closeMenu}>
          &nbsp;
        </div>
      ) : null}
    </div>
  );
};

export default withErrorBoundary(HamburgerMenu, {
  FallbackComponent: () => null,
});
