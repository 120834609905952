import { ReactElement, useEffect, useMemo } from 'react';
import Countdown from 'react-countdown';

import DingSound from '~/../assets/audio/ding.mp3';
import CountdownLabel from '~/components/shared/atoms/CountdownLabel';
import { mingleLog } from '~/lib/loggers';

import timeWithOffset from './timeWithOffset';

type MingleCountdownProps = {
  initTime: string;
  onEnd: () => void;
  serverClockOffset: number;
};

const DEFAULT_COUNTDOWN = 5 * 1000; // milliseconds

const MingleCountdown = ({
  initTime,
  onEnd,
  serverClockOffset,
}: MingleCountdownProps): ReactElement => {
  const countdownTo = useMemo(() => {
    const startsAtOnServer = new Date(initTime);
    const nowOnServer = timeWithOffset({ offset: serverClockOffset });
    const alreadyStartedOrStartsTooSoon =
      startsAtOnServer.getTime() - nowOnServer.getTime() < DEFAULT_COUNTDOWN;

    if (alreadyStartedOrStartsTooSoon) {
      return timeWithOffset({ offset: DEFAULT_COUNTDOWN });
    }

    return timeWithOffset({ offset: -serverClockOffset, time: startsAtOnServer.toISOString() });
  }, [initTime, serverClockOffset]);

  useEffect(() => {
    mingleLog('Countdown started');
    const ding = new Audio(DingSound);
    void ding.play();
  }, []);

  return (
    <Countdown
      date={countdownTo}
      renderer={({ seconds }): ReactElement => {
        return (
          <div className="mingle-room__countdown">
            <span>Done! Your mingle starts in:</span>
            <CountdownLabel>{seconds}</CountdownLabel>
          </div>
        );
      }}
      onComplete={onEnd}
    />
  );
};

export default MingleCountdown;
