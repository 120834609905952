import { useMemo } from 'react';

type FilteredInterlocutors<TParticipant> = {
  currentUser: TParticipant | undefined;
  interlocutors: TParticipant[];
};

export const filterCurrentUserAndInterlocutors = <TParticipant extends { identity: string }>(
  currentUserIdentity: string,
  participants: TParticipant[],
): FilteredInterlocutors<TParticipant> => {
  const interlocutors: TParticipant[] = [];
  let currentUser;
  participants.forEach((user) => {
    if (user.identity === currentUserIdentity) {
      currentUser = user;
    } else {
      interlocutors.push(user);
    }
  });
  return { currentUser, interlocutors };
};

export const useFilteredInterlocutors = <TParticipant extends { identity: string }>(
  currentUserIdentity: string,
  interlocutors: TParticipant[],
): FilteredInterlocutors<TParticipant> => {
  return useMemo(() => {
    return filterCurrentUserAndInterlocutors(currentUserIdentity, interlocutors);
  }, [currentUserIdentity, interlocutors]);
};
