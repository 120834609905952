import { ReactElement } from 'react';

export type IframePlayerProps = {
  src: string;
};

const IframePlayer = ({ src }: IframePlayerProps): ReactElement<IframePlayerProps> | null => {
  return (
    <div className="video-player">
      <div className="video-content-wrapper">
        <iframe
          allow="autoplay; fullscreen"
          className="video-content"
          frameBorder="0"
          scrolling="no"
          src={src}
          title="Livestream"
        />
      </div>
    </div>
  );
};

export default IframePlayer;
