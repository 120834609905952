import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { desktop } from '~/style-mixins/theme';
import bodyMixin from '~/style-mixins/typography/bodyMixin';
import displayTitleMixin from '~/style-mixins/typography/displayTitleMixin';
import defaultCoverImage from '~/../assets/images/illustrations/onboarding.png';

import Button from '~/components/button/Button';
import { useOnboarding } from './OnboardingContext';

type WelcomeScreenProps = {
  onContinueButtonClick?: () => void;
};

const CoverPhoto = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;

const CoverPhotoWrapper = styled.div`
  ${desktop(css`
    min-height: 220px;
  `)}
`;

const Content = styled.div`
  text-align: center;
  padding: 2rem;
`;

const Title = styled.h1`
  ${displayTitleMixin}
  margin: 0 0 2rem 0;
`;

const InterationButtonWrapper = styled.div`
  display: inline-flex;
  min-width: 220px;
  width: auto;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0 auto;
`;

const Description = styled.p`
  ${bodyMixin}
  margin: 2rem 0;
`;

const WelcomeScreenRoot = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 1px solid var(--c-light-silver);
  border-radius: 0.5rem;
  overflow: hidden;
`;

const WelcomeScreen = ({
  onContinueButtonClick,
}: WelcomeScreenProps): ReactElement<WelcomeScreenProps> => {
  const { t } = useTranslation();
  const { conference, user } = useOnboarding();

  return (
    <WelcomeScreenRoot>
      <CoverPhotoWrapper>
        <CoverPhoto
          alt={`Welcome to ${conference.name}`}
          src={conference?.coverPhotoUrl || defaultCoverImage}
        />
      </CoverPhotoWrapper>
      <Content>
        <Title>
          {t('onboarding.hi', { name: user.person.first_name })}
          <br />
          {t('onboarding.welcome', { conference: conference.name })}
        </Title>
        <Description>{t('onboarding.intro')}</Description>
        <InterationButtonWrapper>
          <Button size="large" variant="brand" onClick={onContinueButtonClick}>
            <span>{t('onboarding.start')}</span>
          </Button>
        </InterationButtonWrapper>
      </Content>
    </WelcomeScreenRoot>
  );
};

export default WelcomeScreen;
