import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

type PlayIconProps = SVGProps<SVGSVGElement>;

const PlayIcon = ({ ref, ...props }: PlayIconProps): ReactElement<PlayIconProps> => {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" id="2.-Rooms" stroke="none" strokeWidth="1">
        <g id="Backstage-MC---ModeratorSpeaker0" transform="translate(-1168.000000, -673.000000)">
          <g id="Button/Medium/Primary/Enabled-Copy" transform="translate(1144.000000, 665.000000)">
            <g id="Icon-/-Accept" transform="translate(24.000000, 8.000000)">
              <rect height="24" id="Bounds" width="24" x="0" y="0" />
              <polygon fill="#FFFFFF" id="Path" points="8 5 8 19 19 12" />
            </g>
          </g>
        </g>
      </g>
    </IconBase>
  );
};

export default PlayIcon;
