import { ReactElement } from 'react';

import { useRoom } from '~/lib/rooms';
import { useAudience } from '~/lib/rooms/audience';

import AttendeeList from '../AttendeeList';
import DiscussionPanelTab from './DiscussionPanelTab';

const AudienceTab = (): ReactElement => {
  const { audience } = useAudience();
  const { opentok } = useRoom();

  // Only show the attendee controls if this is a video call room.
  const withSettings = Boolean(opentok);

  return (
    <DiscussionPanelTab>
      <AttendeeList audience={audience} withSettings={withSettings} />
    </DiscussionPanelTab>
  );
};

export default AudienceTab;
