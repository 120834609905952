import { ReactElement, SVGProps } from 'react';

const CameraBlockedIcon = (
  props: SVGProps<SVGSVGElement>,
): ReactElement<SVGProps<SVGSVGElement>> => (
  <svg height={24} width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <path d="M0 0h24v24H0z" />
      <path
        d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
        fill="#333"
      />
      <rect fill="#D94437" height={9} rx={1} stroke="#F1F3F4" width={9} x={14.5} y={13} />
      <g>
        <path d="M15 13.5h8v8h-8z" />
        <path
          d="M21 19.03l-.47.47L19 17.97l-1.53 1.53-.47-.47 1.53-1.53L17 15.97l.47-.47L19 17.03l1.53-1.53.47.47-1.53 1.53z"
          fill="#FFF"
        />
      </g>
    </g>
  </svg>
);

export default CameraBlockedIcon;
