import { OTPublisher } from 'opentok-react';
import { ReactElement, ReactNode, useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import AudioLevelIndicator, {
  useAudioLevel,
} from '~/components/room/room_elements/AudioLevelIndicator';
import { useMediaDevices } from '~/lib/opentok/mediadevices';
import { publisherOptions } from '~/configs/opentokConfig';
import AudioIcon from '~/components/icons/AudioIcon';
import VideoIcon from '~/components/icons/VideoIcon';
import { useCurrentUser } from '~/lib/user';

import { OpenTokContext } from '../room/OpenTokSession';
import VideoCover from '../room/room_elements/VideoCover';
import VideoInputSelect from './VideoInputSelect';

export type LobbyVideoProps = {
  audio: boolean;
  onAudioChanged: (enabled: boolean) => void;
  onVideoChanged: (enabled: boolean) => void;
  onVideoSourceChanged: (value: string | undefined) => void;
  publisherActionsDisabled?: boolean;
  video: boolean;
  videoOverlay?: ReactNode;
  videoSource?: string;
};

export const LobbyVideoSkeleton = (): ReactElement => {
  return (
    <div className="lobby__video">
      <Skeleton className="stage__video" />
    </div>
  );
};

const LobbyVideo = ({
  audio,
  video,
  videoSource,
  publisherActionsDisabled,
  videoOverlay,
  onAudioChanged,
  onVideoChanged,
  onVideoSourceChanged,
}: LobbyVideoProps): ReactElement<LobbyVideoProps> => {
  const { name, avatarUrl } = useCurrentUser();
  const { session, publishError, setPublishError } = useContext(OpenTokContext);
  const { audioLevelUpdated, volumeLevel } = useAudioLevel();
  const { cameraAvailable, microphoneAvailable, refresh } = useMediaDevices();

  const audioEnabled = microphoneAvailable && !publishError;
  const videoEnabled = cameraAvailable && !publishError;

  useEffect(() => {
    onAudioChanged(audioEnabled);
    onVideoChanged(videoEnabled);
  }, [videoEnabled, audioEnabled, onAudioChanged, onVideoChanged]);

  return (
    <div className="lobby__video">
      <div className="stage__video">
        {videoOverlay && <div className="lobby__video__overlay">{videoOverlay}</div>}
        <VideoCover avatarUrl={avatarUrl ?? undefined} isCoverVisible={!video} name={name} />
        <OTPublisher
          eventHandlers={{ accessDialogClosed: refresh, audioLevelUpdated }}
          properties={{
            ...publisherOptions,
            publishAudio: audio,
            publishVideo: video,
            videoSource,
          }}
          session={session}
          onError={setPublishError}
        />
      </div>
      <nav className="toolbar">
        <section className="lobby__volume-indicator">
          <AudioLevelIndicator volumeLevel={volumeLevel} />
        </section>
        <section>
          <VideoInputSelect
            isDisabled={!video}
            value={videoSource}
            onChange={onVideoSourceChanged}
          />
        </section>
        {!publisherActionsDisabled && (
          <section className="toolbar__actions -participant">
            <button
              className={video ? 'btn -toolbar -on' : 'btn -toolbar -off'}
              type="button"
              onClick={(): void | false => videoEnabled && onVideoChanged(!video)}
            >
              <VideoIcon isOn={video} />
              Video
            </button>
            <button
              className={audio ? 'btn -toolbar -on' : 'btn -toolbar -off'}
              type="button"
              onClick={(): void | false => audioEnabled && onAudioChanged(!audio)}
            >
              <AudioIcon isOn={audio} />
              Audio
            </button>
          </section>
        )}
      </nav>
    </div>
  );
};

export default LobbyVideo;
