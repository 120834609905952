import { ReactElement, SVGProps } from 'react';

type KeynoteIconProps = SVGProps<SVGSVGElement>;

const KeynoteIcon = (props: KeynoteIconProps): ReactElement<KeynoteIconProps> => {
  return (
    <svg version="1.1" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-269.000000, -3317.000000)">
          <g transform="translate(269.500000, 3317.000000)">
            <path
              d="M21.142,3 L7.428,3 L7.428,5.429 L4,5.429 C3.44771525,5.429 3,5.87671525 3,6.429 L3,15.571 C3,16.1232847 3.44771525,16.571 4,16.571 L7.3,16.571 L5.578,18.293 C5.31804342,18.5440745 5.2137874,18.9158779 5.30530284,19.2655073 C5.39681828,19.6151368 5.66986324,19.8881817 6.01949268,19.9796972 C6.36912211,20.0712126 6.74092553,19.9669566 6.992,19.707 L10.128,16.571 L11.586,16.571 L14.721,19.707 C15.1133789,20.0859722 15.7370848,20.0805524 16.1228186,19.6948186 C16.5085524,19.3090848 16.5139722,18.6853789 16.135,18.293 L14.414,16.571 L17.714,16.571 C18.2662847,16.571 18.714,16.1232847 18.714,15.571 L18.714,12.143 L21.142,12.143 L21.142,3 Z M16.714,14.571 L5,14.571 L5,7.429 L16.714,7.429 L16.714,14.571 Z"
              fill="#333333"
              id="Combined-Colour"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KeynoteIcon;
