import { ReactElement, SVGProps } from 'react';

type AlertEmptyIconProps = SVGProps<SVGSVGElement>;

const AlertEmptyIcon = (props: AlertEmptyIconProps): ReactElement<AlertEmptyIconProps> => (
  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" id="Icon-/-Alert" stroke="none" strokeWidth="1">
      <path
        d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 Z M13,13 L11,13 L11,7 L13,7 L13,13 Z M13,17 L11,17 L11,15 L13,15 L13,17 Z"
        fill="#333333"
        id="Shape"
      />
    </g>
  </svg>
);

export default AlertEmptyIcon;
