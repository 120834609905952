import { FormEventHandler, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { createOtpChallenge } from '~/lib/avenger/api';

import Button from '../button/Button';
import FormField from '../forms/FormField';
import LoadingIcon from '../icons/LoadingIcon';
import LoginForm from './LoginForm';
import LoginPanel from './LoginPanel';

const saveOtpFlowStarted = (): void => {
  try {
    localStorage.setItem('otp', '1');
  } catch {
    // no-op
  }
};

const OtpLoginPanel = (): ReactElement => {
  const { t } = useTranslation();
  const [login, setLogin] = useState('');
  const [loading, setLoading] = useState(false);
  const [_location, setLocation] = useLocation();

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    const res = await createOtpChallenge(login);
    const params = new URLSearchParams({
      id: res.id,
      email: login,
    });

    // Record the fact that the otp flow has been started in this browser.
    saveOtpFlowStarted();

    setLoading(false);
    setLocation(`/verify?${params.toString()}`);
  };

  return (
    <LoginPanel size="small">
      <LoginForm onSubmit={handleSubmit}>
        <FormField isRequired label={t('login.otp.label')} name="email">
          <input
            required
            autoComplete="email"
            id="email"
            name="email"
            placeholder={t('login.otp.placeholder')}
            type="text"
            value={login}
            onChange={(e): void => setLogin(e.target.value)}
          />
        </FormField>
        <Button disabled={loading} icon={loading && <LoadingIcon />} size="large">
          {t('login.logIn')}
        </Button>
      </LoginForm>
    </LoginPanel>
  );
};

export default OtpLoginPanel;
