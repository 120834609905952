import { createContext, useContext } from 'react';

import { RoomInfoFragment } from '~/operations/catalyst';

export const RoomInfoContext = createContext<RoomInfoFragment | undefined>(undefined);

export const useRoomInfo = (): RoomInfoFragment => {
  const config = useContext(RoomInfoContext);
  if (!config) {
    throw new Error('useRoomInfo called outside of RoomInfoContext');
  }
  return config;
};
