import { ReactElement, SVGProps } from 'react';

type ForwardIconProps = SVGProps<SVGSVGElement>;

const ForwardIcon = (props: ForwardIconProps): ReactElement<ForwardIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M10,9 L10,5 L3,12 L10,19 L10,14.9 C15,14.9 18.5,16.5 21,20 C20,15 17,10 10,9 Z"
        transform="translate(12.000000, 12.500000) scale(-1, 1) translate(-12.000000, -12.500000) "
      />
    </g>
  </svg>
);

export default ForwardIcon;
