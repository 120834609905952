/* eslint-disable react/no-danger */
import { ReactElement, useEffect, useState } from 'react';

import { Asset } from '~/lib/assets';
import DefaultReactionsSprite from '~/../assets/images/reactions-sprite.svg';

const spriteUrl = Asset.roomReactionsSprite || DefaultReactionsSprite;

// Loads the SVG file containing the reactions sprites and returns it as a react element.
// This is necessary because browsers do not allow us to reference external urls (such as CNDs)
// in the `use` svg directive. And so we have to fetch the data and insert it into our own DOM.
const ReactionsSprite = (): ReactElement => {
  const [data, setData] = useState('');

  const fetchSvg = async () => {
    const res = await fetch(spriteUrl);
    const text = await res.text();
    setData(text);
  };

  useEffect(() => {
    void fetchSvg();
  });

  return <div dangerouslySetInnerHTML={{ __html: data }} style={{ display: 'none' }} />;
};

export default ReactionsSprite;
