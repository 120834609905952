import { ReactElement, ReactNode } from 'react';

import StarIcon from '~/components/icons/StarIcon';
import urlFor from '~/lib/urlFor';
import { Sponsor } from '~/types/mingle';

export type MingleHeaderTextProps = {
  defaultNode?: ReactNode;
  isDisabled?: boolean;
  sessionTitle?: string;
  sponsor?: Sponsor;
};

export const MingleHeaderText = ({
  sessionTitle,
  defaultNode,
  isDisabled,
  sponsor,
}: MingleHeaderTextProps): ReactElement<MingleHeaderTextProps> => {
  if (isDisabled) {
    return <div className="mingle-header__title">Mingle will be back soon!</div>;
  }

  const hasTitle = !!sessionTitle;

  if (sponsor) {
    return (
      <>
        <div className="mingle-header__indication">
          {sponsor.company.logoUrl && (
            <img
              alt={sponsor.company.name}
              className="mingle-header__sponsor"
              src={sponsor.company.logoUrl}
            />
          )}
        </div>
        <div className="mingle-header__title">
          {hasTitle ? sessionTitle : 'Mingle'}
          <p className="mingle-header__lead">
            Brought to you by{' '}
            <a className="mingle-header__sponsor-url" href={urlFor(`/exhibitors/${sponsor.id}`)}>
              {sponsor.company.name}
            </a>
          </p>
        </div>
      </>
    );
  }

  if (sessionTitle) {
    return (
      <>
        <div className="mingle-header__indication">
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </div>
        <div className="mingle-header__title">{sessionTitle}</div>
      </>
    );
  }

  return <>{defaultNode}</>;
};

const MingleHeader = (props: MingleHeaderTextProps): ReactElement<MingleHeaderTextProps> => {
  return (
    <header className="mingle-header">
      { }
      <MingleHeaderText {...props} />
    </header>
  );
};

export default MingleHeader;
