import { ReactElement, SVGProps } from 'react';

type NewChatIconProps = SVGProps<SVGSVGElement>;

const NewChatIcon = (props: NewChatIconProps): ReactElement<NewChatIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <path
      clipRule="evenodd"
      d="M19.2 4C20.19 4 21 4.81 21 5.8V16.6C21 17.59 20.19 18.4 19.2 18.4H6.6L3 22V5.8C3 4.81 3.81 4 4.8 4H19.2ZM13 7H11V10H8V12H11V15H13V12H16V10H13V7Z"
      fill="#333333"
      fillRule="evenodd"
    />
  </svg>
);

export default NewChatIcon;
