import { ReactElement } from 'react';

import { usePermissions, useRoom } from '~/lib/rooms';
import { RoomReaction } from '~/operations/catalyst';

import usePubnubMessages from '../realtime/usePubnubMessages';
import ReactionStream, { IncomingMessageType } from './ReactionStream';

type ReactionsContentProps = {
  isRoom?: boolean;
  reactionsLayout?: RoomReaction[];
};

const ReactionsContent = ({
  isRoom = false,
  reactionsLayout,
}: ReactionsContentProps): ReactElement<ReactionsContentProps> => {
  const { reactions } = usePermissions();
  const channel = reactions?.channelId;
  const canReact = !!reactions;
  const { reactions: reactionsConfig } = useRoom();

  const { messages, sendMessage } = usePubnubMessages<IncomingMessageType>({
    channel,
  });

  return (
    <ReactionStream
      isDisabled={!canReact}
      isRoom={isRoom}
      messages={messages}
      reactionsLayout={reactionsLayout}
      reactionsPerSecond={reactionsConfig?.hypeFactor}
      sendMessage={sendMessage}
    />
  );
};

export default ReactionsContent;
