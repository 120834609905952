import Linkify from 'linkify-react';
import { ReactElement } from 'react';
import styled from 'styled-components';

import Timestamp from '~/components/timeslot/Timestamp';
import { usePermissions, useRoom } from '~/lib/rooms';

import AttendeeList from '../AttendeeList';
import ExhibitorLink from '../ExhibitorLink';
import RoomModerationActions from '../room_elements/RoomModerationActions';
import DiscussionPanelTab from './DiscussionPanelTab';

const Info = styled.div`
  overflow-y: auto;
  flex: 1;
`;

const Details = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--c-offwhite);
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: var(--fs-lg);
  font-weight: 600;
`;

const Description = styled.div`
  padding-bottom: 2rem;
  font-size: var(--fs-sm);
`;

const InfoTab = (): ReactElement => {
  const {
    participants,
    sponsor,
    room: { name, description, featuresBackstage },
    calendarEvent,
  } = useRoom();
  const { isModerator } = usePermissions();
  const actions = isModerator && featuresBackstage ? <RoomModerationActions /> : undefined;

  return (
    <DiscussionPanelTab actions={actions}>
      <Info>
        <Details>
          {calendarEvent && (
            <Timestamp
              showLiveIcon
              endDateIso={calendarEvent.endsAt}
              startDateIso={calendarEvent.startsAt}
            />
          )}
          <Title>{name}</Title>
          {description && (
            <Description>
              <Linkify>{description}</Linkify>
            </Description>
          )}
          {sponsor && (
            <ExhibitorLink
              country={sponsor.country}
              logoUrl={sponsor.logoUrl}
              name={sponsor.name}
              profileUrl={sponsor.profileUrl}
            />
          )}
        </Details>
        <AttendeeList audience={participants} />
      </Info>
    </DiscussionPanelTab>
  );
};

export default InfoTab;
