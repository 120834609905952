export const TEXT_TYPE = 'text';
export const VIDEO_CALL_TYPE = 'video_call_v2';
export const ABSTRACT_TYPING_INDICATOR_TYPE = '___typing';

export type FormatMessage = {
  data: {
    body: string;
  };
  type: string;
};

export const formatMessage = (givenMessage: string): FormatMessage => ({
  data: { body: givenMessage },
  type: TEXT_TYPE,
});

export const formatVideoCall = (
  roomId: string,
): {
  data: { roomId: string };
  type: typeof VIDEO_CALL_TYPE;
} => ({
  data: { roomId },
  type: VIDEO_CALL_TYPE,
});

export const allowedMessagesTypes = [TEXT_TYPE, VIDEO_CALL_TYPE, ABSTRACT_TYPING_INDICATOR_TYPE];
