import { ReactElement, SVGProps } from 'react';

type GalleryIconProps = SVGProps<SVGSVGElement>;

const GalleryIcon = (props: GalleryIconProps): ReactElement<GalleryIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-486.000000, -373.000000)">
        <g transform="translate(472.000000, 365.000000)">
          <g transform="translate(14.000000, 8.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <path
              d="M19,5 L19,19 L5,19 L5,5 L19,5 M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M14.14,11.86 L11.14,15.73 L9,13.14 L6,17 L18,17 L14.14,11.86 Z"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GalleryIcon;
