import { ReactElement, SVGProps } from 'react';

const BreakoutIcon = ({ ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M10.09 3H1.91a.896.896 0 00-.91.889v6.222c0 .493.405.889.91.889h8.18c.505 0 .91-.396.91-.889V3.89A.896.896 0 0010.09 3zm0 10H1.91a.896.896 0 00-.91.889v6.222c0 .493.405.889.91.889h8.18c.505 0 .91-.396.91-.889V13.89a.896.896 0 00-.91-.889zm12-10h-8.18a.896.896 0 00-.91.889v6.222c0 .493.405.889.91.889h8.18c.505 0 .91-.396.91-.889V3.89A.896.896 0 0022.09 3zm0 10h-8.18a.896.896 0 00-.91.889v6.222c0 .493.405.889.91.889h8.18c.505 0 .91-.396.91-.889V13.89a.896.896 0 00-.91-.889z"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default BreakoutIcon;
