import { ReactElement, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';

import MingleHeader from '~/components/mingle/MingleHeader';
import { subscriberOptions } from '~/configs/opentokConfig';
import { Sponsor } from '~/types/mingle';
import { Participant, User } from '~/types/rooms';

import DevDebug from '../DevDebug';
import { OpenTokContext } from '../room/OpenTokSession';
import WebcamVideoPublisher from '../room/WebcamVideoPublisher';
import WebcamVideoSubscriber from '../room/WebcamVideoSubscriber';
import MinglePartner from './MinglePartner';

type MingleStreamsProps = {
  partner: Participant;
  user: User;
};

const MingleStreams = ({ partner, user }: MingleStreamsProps): ReactElement<MingleStreamsProps> => {
  const { streams } = useContext(OpenTokContext);

  const partnerStream = streams[0];

  return (
    <div className="mingle-participants">
      {user && (
        <WebcamVideoPublisher avatarUrl={user.avatarUrl ?? undefined} userName={user.name} />
      )}
      {!user && <Skeleton height="100%" />}
      {partnerStream && (
        <WebcamVideoSubscriber
          key={partnerStream.streamId}
          participant={partner}
          stream={partnerStream}
          subscriberOptions={subscriberOptions}
        />
      )}
      {!partnerStream && <Skeleton height="100%" />}
    </div>
  );
};

type MingleBoothProps = {
  debug?: Record<string, unknown>;
  onMingleEnded: () => void;
  partner: Participant;
  reportPartner: () => void;
  sessionName?: string;
  sponsor?: Sponsor;
  user: User;
};

const MingleBooth = ({
  debug,
  partner,
  user,
  reportPartner,
  onMingleEnded,
  sponsor,
  sessionName,
}: MingleBoothProps): ReactElement<MingleBoothProps> => {
  return (
    <>
      {debug && <DevDebug info={debug} />}
      <MingleHeader sessionTitle={sessionName} sponsor={sponsor} />
      <MingleStreams partner={partner} user={user} />
      <MinglePartner
        attendee={partner}
        reportPartner={reportPartner}
        onMingleEnded={onMingleEnded}
      />
    </>
  );
};

export default MingleBooth;
