import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { connectToggleRefinement } from 'react-instantsearch-core';

import { useTracking } from '~/lib/analytics';
import { SearchPageVariant } from '~/types/search';
import Checkbox from '~/components/shared/atoms/Checkbox';
import CheckboxCollection, {
  CheckboxCollectionBody,
} from '~/components/shared/molecules/CheckboxCollection';
import CheckboxControl from '~/components/shared/molecules/CheckboxControl';

import { COUNTRY_FILTER_LIMIT } from '../constants';
import { getEndorsedByFilter } from '../utils/filterFlags';
import ConnectedRefinementList from './ConnectedRefinementList';
import ConnectedToggleRefinementList from './ConnectedToggleRefinementList';

type CompanySearchFiltersProps = {
  variant: SearchPageVariant;
};

const ConnectedToggleRefinementItem = connectToggleRefinement(
  ({ canRefine, label, currentRefinement, refine }) => {
    const track = useTracking();

    if (!canRefine) {
      return null;
    }

    return (
      <CheckboxControl>
        <CheckboxControl.Label>
          <Checkbox
            checked={currentRefinement}
            setChecked={(checked) => {
              track('Applied filter', label);
              refine(checked);
            }}
          />
          {label}
        </CheckboxControl.Label>
      </CheckboxControl>
    );
  },
);

const CompanySearchFilters = ({
  variant,
}: CompanySearchFiltersProps): ReactElement<CompanySearchFiltersProps> => {
  const { t } = useTranslation();
  const showEndorsedByFilter = getEndorsedByFilter();

  return (
    <div className="search-filters">
      {variant === SearchPageVariant.Partners && (
        <CheckboxCollection>
          <CheckboxCollectionBody>
            <ConnectedToggleRefinementItem
              value
              attribute="currently_recruiting"
              label={t('explore.filters.hiring.label')}
            />
            {showEndorsedByFilter && (
              <ConnectedToggleRefinementItem
                attribute="endorsed_by"
                label="CTA member"
                value="CTA"
              />
            )}
          </CheckboxCollectionBody>
        </CheckboxCollection>
      )}
      {variant === SearchPageVariant.Startups && (
        <CheckboxCollection>
          <CheckboxCollectionBody>
            <ConnectedToggleRefinementItem
              value
              attribute="has_pitch_video"
              label={t('explore.filters.pitch.label')}
            />
            <ConnectedToggleRefinementItem
              value
              attribute="currently_recruiting"
              label={t('explore.filters.hiring.label')}
            />
            <ConnectedToggleRefinementItem
              value
              attribute="fundraising"
              label={t('explore.filters.fundraising.label')}
            />
          </CheckboxCollectionBody>
        </CheckboxCollection>
      )}
      <ConnectedToggleRefinementList attribute="track" label={t('explore.filters.track.label')} />
      <ConnectedRefinementList
        attribute="industry"
        label={t('explore.filters.industry.label')}
        limit={100}
        placeholderText={t('explore.filters.industry.placeholder')}
      />
      <ConnectedRefinementList
        attribute="country"
        label={t('explore.filters.location.label')}
        limit={COUNTRY_FILTER_LIMIT}
        placeholderText={t('explore.filters.location.placeholder')}
      />
      <ConnectedRefinementList
        attribute="venue"
        label={t('explore.filters.venue.label')}
        limit={COUNTRY_FILTER_LIMIT}
        placeholderText={t('explore.filters.venue.placeholder')}
      />
    </div>
  );
};

export default CompanySearchFilters;
