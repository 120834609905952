import { ReactElement, SVGProps } from 'react';

const WatchIcon = ({ ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M21 3c1.1 0 2 .9 2 2l-.01 12c0 1.1-.89 2-1.99 2h-6.062l2.416 2.42-1.41 1.41L12.115 19h-.878L7.41 22.83 6 21.42 8.416 19H3c-1.05 0-1.918-.82-1.994-1.851L1 17V5c0-1.1.9-2 2-2zm0 2H3v12h18V5z"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default WatchIcon;
