import { ReactElement, SVGProps } from 'react';

type QuestionIconProps = SVGProps<SVGSVGElement>;

const QuestionIcon = (props: QuestionIconProps): ReactElement<QuestionIconProps> => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" {...props}>
    <path
      clipRule="evenodd"
      d="M21.2 4C22.19 4 23 4.81 23 5.8V16.6C23 17.59 22.19 18.4 21.2 18.4H4.6L1 22V5.8C1 4.81 1.81 4 2.8 4H21.2ZM6.12784 8.10228C4.5767 8.10228 3.40341 9.1875 3.40341 11.0909C3.40341 12.9858 4.5767 14.0795 6.12784 14.0795C6.49147 14.0795 6.83522 14.0199 7.14772 13.9006L7.61647 14.4773H8.7642L7.93181 13.4318C8.49999 12.9233 8.85227 12.1278 8.85227 11.0909C8.85227 9.1875 7.67045 8.10228 6.12784 8.10228ZM11.6761 8.10512C10.7216 8.10512 10.0881 8.75569 10.0881 9.59944C10.0881 10.1165 10.3381 10.5483 10.642 10.9347C10.0085 11.3693 9.58239 11.7813 9.58239 12.4545C9.58239 13.3722 10.3182 14.0852 11.5284 14.0852C12.0369 14.0852 12.5881 13.9432 13.017 13.608L13.3693 14H14.6307L13.7557 12.9972C14.0795 12.5682 14.2841 11.9517 14.2841 11.1619H13.2812C13.2812 11.5426 13.1875 11.8807 13.0341 12.1676L12.0739 11.0653L12.4148 10.821C12.9034 10.4688 13.1875 10.0256 13.1875 9.47728C13.1875 8.73296 12.6108 8.10512 11.6761 8.10512ZM18.4375 8.18182H16.8523L14.8438 14H16.1619L16.5937 12.6733H18.6932L19.125 14H20.4432L18.4375 8.18182ZM11.2614 11.6534L12.3636 12.8807C12.1193 13.0313 11.8523 13.1136 11.5881 13.1136C11.0824 13.1136 10.7557 12.8011 10.7557 12.3864C10.7557 12.1648 10.9062 11.9091 11.2273 11.6761L11.2614 11.6534ZM6.12784 9.19035C7.01988 9.19035 7.60511 9.85796 7.60511 11.0909C7.60511 11.7244 7.44886 12.2102 7.17897 12.5313L6.74716 11.9773H5.7017L6.44602 12.9602C6.34659 12.9801 6.23863 12.9915 6.12784 12.9915C5.23295 12.9915 4.65057 12.3239 4.65057 11.0909C4.65057 9.85796 5.23295 9.19035 6.12784 9.19035ZM17.6676 9.51137L18.3835 11.7131H16.9062L17.6222 9.51137H17.6676ZM11.6847 9.07955C12.0256 9.07671 12.2187 9.31535 12.2187 9.54546C12.2187 9.78978 12.0852 9.99148 11.7955 10.1761L11.4915 10.375C11.2756 10.1023 11.125 9.8608 11.125 9.61932C11.125 9.32671 11.3466 9.08239 11.6847 9.07955Z"
      fill="#333333"
      fillRule="evenodd"
    />
  </svg>
);

export default QuestionIcon;
