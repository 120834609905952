import uniqBy from 'lodash/uniqBy';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { RoomConfig } from '~/operations/catalyst';
import usePubnubMessages from '~/components/realtime/usePubnubMessages';
import { usePublisher } from '~/lib/rooms/publisher';
import { MinimalOccupant } from '~/types/rooms';
import {
  ChatMessage,
  EvictOccupantAction,
  IncomingModerationAction,
  Mode,
  ModerationAction,
  ModeratorAction,
} from '~/types/rooms-moderation';

import { useRoomConfig } from './config';
import {
  formatModeratorActionMessage,
  isEvictOccupantAction,
  isPinMessageAction,
  isSetModeAction,
} from './messageHelpers';
import useRoom from './useRoom';

export type ModerationProviderProps = {
  children: ReactNode;
};

export type Moderation = {
  changeMode: (m: Mode) => void;
  changeModeWithDelay: (ms: number, mode: Mode) => void;
  evictOccupant: (p: MinimalOccupant) => void;
  evictedOccupantIds: string[];
  evictedOccupants: MinimalOccupant[];
  mode: Mode;
  pinMessage: (m: ChatMessage | null) => void;
  pinnedMessage: ChatMessage | null;
};

const resolveOldRoomMode = (mode: string): Mode | null => {
  if (mode === 'questions') {
    return Mode.live;
  }
  if (mode === 'streaming') {
    return Mode.content;
  }
  return null;
};

const defaultMode = (config: RoomConfig) => {
  if (config.backstageEnabled) {
    return Mode.offline;
  }
  if (config.contentLiveStream) {
    return Mode.content;
  }
  return Mode.live;
};

export const ModerationContext = createContext<Moderation | undefined>(undefined);

export const ModerationProvider = ({
  children,
}: ModerationProviderProps): ReactElement<ModerationProviderProps> => {
  const { moderation, pubnub } = useRoom();
  const config = useRoomConfig();
  const [mode, setMode] = useState(defaultMode(config));

  const [evictedOccupants, setEvictedOccupants] = useState<MinimalOccupant[]>([]);
  const [pinnedMessage, setPinnedMessage] = useState<ChatMessage | null>(null);

  const {
    messages: moderationMessages,
    sendMessage,
    fetchMessages,
  } = usePubnubMessages<IncomingModerationAction>({
    channel: moderation?.channel,
    fetchAllHistory: true,
    isReverse: true,
    messagePerPage: 25,
    sortingOrder: 'desc',
    user: undefined,
  });

  useEffect(() => {
    if (!pubnub || moderationMessages.length) return;

    fetchMessages();
  }, [pubnub, fetchMessages, moderationMessages]);

  const sendModeratorAction = useCallback(
    (moderatorAction: ModeratorAction) =>
      sendMessage(formatModeratorActionMessage(moderatorAction), undefined, {
        storeInHistory: !isEvictOccupantAction(moderatorAction),
      }),
    [sendMessage],
  );

  const changeMode = useCallback(
    (m: Mode) => {
      sendModeratorAction({ setMode: m });
    },
    [sendModeratorAction],
  );

  const evictOccupant = useCallback(
    (occupant: MinimalOccupant) => {
      sendModeratorAction({ evictOccupant: occupant });
    },
    [sendModeratorAction],
  );

  const pinMessage = useCallback(
    (m: ChatMessage | null) => sendModeratorAction({ pinMessage: m }),
    [sendModeratorAction],
  );

  useEffect(() => {
    const setModeMessage = moderationMessages.find((message) => isSetModeAction(message.data));

    const action = setModeMessage?.data;

    if (action && isSetModeAction(action)) {
      setMode(resolveOldRoomMode(action.setMode) || action.setMode);
    }
  }, [moderationMessages]);

  useEffect(() => {
    const occupantMessages = (moderationMessages as ModerationAction[])
      .filter((message) => isEvictOccupantAction(message.data))
      .map((message) => message.data as EvictOccupantAction);

    const toEvict = uniqBy(occupantMessages, (action) => action.evictOccupant.id).map(
      (action) => action.evictOccupant,
    );

    setEvictedOccupants(toEvict);
  }, [moderationMessages]);

  const evictedOccupantIds = useMemo(
    () => evictedOccupants.map(({ id }) => id),
    [evictedOccupants],
  );

  useEffect(() => {
    const message = (moderationMessages as ModerationAction[]).find((m) =>
      isPinMessageAction(m.data),
    );

    const action = message?.data;

    if (action && isPinMessageAction(action)) {
      setPinnedMessage(action.pinMessage);
    }
  }, [moderationMessages]);

  const { startCountdown, clearCountdown, countdown } = usePublisher();
  const [delayedMode, setDelayedMode] = useState<Mode>();
  const delayedActionBag = useRef<{
    changeMode: typeof changeMode;
    clearCountdown: typeof clearCountdown;
    newMode: Mode | undefined;
  }>();

  useEffect(() => {
    delayedActionBag.current = {
      changeMode,
      clearCountdown,
      newMode: delayedMode,
    };
  }, [changeMode, delayedMode, clearCountdown]);

  useEffect(() => {
    const bag = delayedActionBag.current;
    if (countdown === null && bag?.newMode) {
      bag?.changeMode?.(bag.newMode);
    }
  }, [countdown]);

  useEffect(() => {
    // In case of some external change or desync we should reset the countdown.
    // For example when a user is not allowed to see the countdown, and mode change
    // came from a moderator
    delayedActionBag.current?.clearCountdown?.();
  }, [mode]);

  const changeModeWithDelay: Moderation['changeModeWithDelay'] = useCallback(
    (time, newMode) => {
      startCountdown(time);
      setDelayedMode(newMode);
    },
    [startCountdown],
  );

  const moderationContextValue = useMemo(
    () => ({
      changeMode,
      changeModeWithDelay,
      evictOccupant,
      evictedOccupantIds,
      evictedOccupants,
      mode,
      pinMessage,
      pinnedMessage,
    }),
    [
      changeMode,
      changeModeWithDelay,
      evictOccupant,
      evictedOccupantIds,
      evictedOccupants,
      mode,
      pinMessage,
      pinnedMessage,
    ],
  );

  return (
    <ModerationContext.Provider value={moderationContextValue}>
      {children}
    </ModerationContext.Provider>
  );
};

export const useModeration = (): Moderation => {
  const moderation = useContext(ModerationContext);
  if (moderation === undefined) {
    throw new Error('useModeration called with no ModerationProvider');
  }
  return moderation;
};
