import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import Button from '~/components/button/Button';
import ButtonBase from '~/components/button/ButtonBase';
import { ConnectionState, useAttendeeConnection } from '~/lib/connections';
import ConfirmAttendeeConnectionModal from '~/components/connections/ConfirmAttendeeConnectionModal';
import AcceptIcon from '~/components/icons/AcceptIcon';
import ConnectIcon from '~/components/icons/ConnectIcon';

type AttendeeConnectionButtonProps = { id: string; name: string };

type ConnectButtonProps = { connect: () => void };

const ConnectedButton = (): ReactElement => (
  <ButtonBase as="span" size="medium" variant="brand">
    <AcceptIcon />
    Connected
  </ButtonBase>
);

const NotConnectedButton = ({ name, connect }: ConnectButtonProps & { name: string }): ReactElement => (
  <ConfirmAttendeeConnectionModal
    modalTrigger={({ openModal }): ReactElement => (
      <Button
        icon={<ConnectIcon />}
        size="medium"
        type="button"
        variant="primary"
        onClick={openModal}
      >
        Connect
      </Button>
    )}
    name={name}
    onConfirm={connect}
  />
);

const RequestReceived = ({ connect }: ConnectButtonProps): ReactElement => (
  <Button icon={<AcceptIcon />} size="medium" type="button" variant="primary" onClick={connect}>
    Accept connection
  </Button>
);

const RequestSent = (): ReactElement => (
  <ButtonBase as="span" size="medium" variant="disabled">
    <AcceptIcon />
    Pending
  </ButtonBase>
);

const getButtonsForState = {
  [ConnectionState.Connected]: ConnectedButton,
  [ConnectionState.NotConnected]: NotConnectedButton,
  [ConnectionState.RequestReceived]: RequestReceived,
  [ConnectionState.RequestSent]: RequestSent,
};

const AttendeeConnectionButton = ({
  id,
  name,
}: AttendeeConnectionButtonProps): ReactElement | null => {
  const { loading, state, connect } = useAttendeeConnection(id);

  if (loading) return <Skeleton />;
  if (state === undefined) return null;

  const ReturnButton = getButtonsForState[state];

  return <ReturnButton connect={connect} name={name} />;
};

export default AttendeeConnectionButton;
