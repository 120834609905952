import clsx from 'clsx';
import { ReactElement } from 'react';

import AcceptIcon from '../icons/AcceptIcon';
import InteractButton from '../shared/atoms/InteractButton';

export type RequestReceivedButtonProps = {
  className?: string;
  onClick?: () => void;
};

const RequestReceivedButton = ({
  className,
  onClick,
}: RequestReceivedButtonProps): ReactElement<RequestReceivedButtonProps> => {
  return (
    <InteractButton className={clsx('-connection', className)} onClick={onClick}>
      <AcceptIcon />
      <span>Accept Connection</span>
    </InteractButton>
  );
};

export default RequestReceivedButton;
