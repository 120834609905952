import clsx from 'clsx';
import { ReactElement } from 'react';
import type { MenuProvided } from 'react-instantsearch-core';

import { useTracking } from '~/lib/analytics';
import isoDateToDayOfMonth from '~/lib/isoDateToDayOfMonth';

const getDisplayItems = (items: MenuProvided['items']) => {
  return items
    .sort((a, b) => {
      const adate = new Date(a.label);
      const bdate = new Date(b.label);

      if (adate === bdate) {
        return 0;
      }

      return adate > bdate ? 1 : -1;
    })
    .map((i) => ({
      ...i,
      label: isoDateToDayOfMonth(i.label),
    }));
};

const DateFilterMenu = ({ items, refine }: MenuProvided): ReactElement<MenuProvided> => {
  const track = useTracking();
  const isDateFilterAvailable = items?.length > 0;

  const displayItems = getDisplayItems(items);

  return (
    <ul className="search-navigation__switcher">
      {isDateFilterAvailable &&
        displayItems.map((item) => (
          <li key={`search-navigation__item_${item.label}`} className="search-navigation__item">
            <button
              className={clsx('search-navigation__selectable', item.isRefined && '-selected')}
              type="submit"
              onClick={() => {
                track('Applied filter', 'Date');
                refine(item.value);
              }}
            >
              {item.label}
            </button>
          </li>
        ))}
    </ul>
  );
};

export default DateFilterMenu;
