import clsx from 'clsx';
import { DateTime } from 'luxon';
import { PropsWithChildren, ReactElement, useMemo } from 'react';

export type MessageProps = PropsWithChildren<{
  from: 'currentUser' | 'participant';
  publisher?: { name: string };
  showTimestamp?: boolean;
  timestamp?: string;
}>;

const Message = ({
  from,
  timestamp,
  publisher,
  children,
  showTimestamp = false,
}: MessageProps): ReactElement<MessageProps> => {
  const formattedTimestamp = useMemo(() => {
    if (timestamp) {
      return DateTime.fromISO(timestamp).toLocaleString(DateTime.TIME_SIMPLE);
    }
    return '';
  }, [timestamp]);
  return (
    <div
      className={clsx('chat-stream__message', {
        '-current': from === 'currentUser',
      })}
    >
      <div className={clsx('chat-stream__message-content')} title={formattedTimestamp}>
        <div className={clsx('chat-stream__message-bubble')}>{children}</div>
      </div>
      {showTimestamp && (
        <div className="chat-stream__user-timestamp">
          {from === 'currentUser'
            ? formattedTimestamp
            : `${publisher?.name} · ${formattedTimestamp}`}
        </div>
      )}
    </div>
  );
}

export default Message;
