import { ReactElement, SVGProps } from 'react';

type CameraIconFrontProps = SVGProps<SVGSVGElement>;

const CameraIconFront = (props: CameraIconFrontProps): ReactElement<CameraIconFrontProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <polygon id="Path" points="0 0 24 0 24 24 0 24" />
      <path
        d="M14.12,5 L15.95,7 L20,7 L20,19 L4,19 L4,7 L8.05,7 L9.88,5 L14.12,5 M15,3 L9,3 L7.17,5 L4,5 C2.9,5 2,5.9 2,7 L2,19 C2,20.1 2.9,21 4,21 L20,21 C21.1,21 22,20.1 22,19 L22,7 C22,5.9 21.1,5 20,5 L16.83,5 L15,3 Z M12,10 C13.65,10 15,11.35 15,13 C15,14.65 13.65,16 12,16 C10.35,16 9,14.65 9,13 C9,11.35 10.35,10 12,10 M12,8 C9.24,8 7,10.24 7,13 C7,15.76 9.24,18 12,18 C14.76,18 17,15.76 17,13 C17,10.24 14.76,8 12,8 Z"
        fill="#333333"
        id="Shape"
      />
    </g>
  </svg>
);

export default CameraIconFront;
