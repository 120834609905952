import { ReactElement } from 'react';

import urlFor from '~/lib/urlFor';
import { ExhibitorConnectionDetailsFragment } from '~/operations/catalyst';
import ExhibitorConnectionButton from '~/components/exhibitors/ExhibitorConnectionButton';
import ExhibitorLogo from '~/components/exhibitors/ExhibitorLogo';

type ExhibitorConnectionPaneProps = {
  appearance: ExhibitorConnectionDetailsFragment;
};

const ExhibitorConnectionPane = ({ appearance }: ExhibitorConnectionPaneProps): ReactElement => {
  const { id } = appearance;
  const { name, logoUrl, countryName } = appearance.company;

  return (
    <div className="profile-page__pane exhibitor-connection-pane">
      <a
        className="exhibitor-connection-pane__exhibitor-anchor"
        href={urlFor(`/exhibitors/${id}`) ?? '#'}
      >
        {name && logoUrl ? <ExhibitorLogo alt={name} logoUrl={logoUrl} /> : null}
        <div className="exhibitor-connection-pane__details">
          <p className="exhibitor-connection-pane__details__name">{name}</p>
          <p className="exhibitor-connection-pane__details__location">{countryName}</p>
        </div>
      </a>
      <ExhibitorConnectionButton attributionSource="Partner attendee page" id={id} name={name} />
    </div>
  );
};

export default ExhibitorConnectionPane;
