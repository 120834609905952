 

export const mingleLog = (...msg: unknown[]): void => {
  console.log('%c[Mingle]', 'color: white; background-color: magenta', ...msg);
};

export const pubnubLog = (...msg: unknown[]): void =>
  console.log('%c[PUBNUB]', 'color: white; background-color: black', ...msg);

export const pubnubLogArgsHandler =
  (name: string) =>
  (...args: unknown[]): void =>
    pubnubLog(name, args);

export const opentokLog = (e: { type: string } & unknown): void =>
  console.log('%c[OpenTok]', 'color: white; background-color: brown', e.type, e);
