import { useMutation, useQuery } from '@apollo/client';

import {
  AttendeeConnectDocument,
  AttendeeConnectionStateDocument,
  ConnectionState,
} from '~/operations/catalyst';

import { useTracking } from './analytics';

export { ConnectionState };

export const useAttendeeConnection = (
  id: string,
): {
  connect: () => void;
  loading: boolean;
  state?: ConnectionState;
} => {
  const track = useTracking();
  const { data, loading } = useQuery(AttendeeConnectionStateDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
  });

  const [attendeeConnect] = useMutation(AttendeeConnectDocument, {
    variables: {
      id,
    },
  });

  const connect = async () => {
    const state = data?.attendance?.connectionState;
    if (state === ConnectionState.NotConnected) {
      track('Requested connection');
    }
    if (state === ConnectionState.RequestReceived) {
      track('Accepted connection');
    }
    return attendeeConnect();
  };

  return {
    connect,
    loading,
    state: data?.attendance?.connectionState,
  };
};
