import { ApolloProvider, useMutation } from '@apollo/client';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import ButtonBase from '~/components/button/ButtonBase';
import Spinner from '~/components/loading/Spinner';
import Lobby from '~/components/lobby/Lobby';
import LobbyPreviewAccess from '~/components/lobby/LobbyPreviewAccess';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { TrackingContext } from '~/lib/analytics';
import { convertJoinRoomMutationDataToRoomOptions } from '~/lib/rooms';
import { RoomConfigContext } from '~/lib/rooms/config';
import { RoomInfoContext } from '~/lib/rooms/info';
import { useCurrentUser, UserContext } from '~/lib/user';
import { JoinRoomDocument, RoomRole } from '~/operations/catalyst';
import RoomView from '~/components/room/RoomView';
import NoResults from '~/components/icons/NoResults';
import { init } from '~/lib/apollo';
import { MediaDevicesProvider } from '~/lib/opentok/mediadevices';
import urlFor from '~/lib/urlFor';
import { RoomOptions, User } from '~/types/rooms';

const Room = (): ReactElement => {
  const user = useCurrentUser();
  const [mutate, { data, loading }] = useMutation(JoinRoomDocument);
  const [roomOptions, setRoomOptions] = useState<RoomOptions>();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const calendarEventId = searchParams.get('cid');
    const roomId = searchParams.get('rid');
    const timeslotId = searchParams.get('tid');

    if (calendarEventId) {
      mutate({
        variables: {
          input: {
            calendarEventId,
          },
        },
      }).catch(window.newrelic?.noticeError);
      return;
    }

    if (roomId) {
      mutate({
        variables: {
          input: { roomId },
        },
      }).catch(window.newrelic?.noticeError);
      return;
    }

    if (timeslotId) {
      mutate({
        variables: {
          input: { timeslotId },
        },
      }).catch(window.newrelic?.noticeError);
      return;
    }

    mutate()
      .then((response) => {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('rid', response.data?.roomJoin?.room.id ?? '');
        return window.history.replaceState(null, '', newUrl.href);
      })
      .catch(window.newrelic?.noticeError);
  }, [mutate]);

  const { roomJoin: roomJoinMutation } = data ?? {};

  const lobbyOptions = useMemo(
    () => convertJoinRoomMutationDataToRoomOptions(roomJoinMutation, user),
    [roomJoinMutation, user],
  );

  const join = useCallback(
    ({
      videoSource,
      videoEnabled,
      audioEnabled,
      isParticipating,
    }: {
      videoSource?: string;
      audioEnabled?: boolean;
      videoEnabled?: boolean;
      isParticipating?: boolean;
    }) => {
      if (!lobbyOptions) {
        return;
      }
      const initialMediaSettings = {
        isParticipating,
        isPublishingAudio: audioEnabled,
        isPublishingVideo: videoEnabled,
        videoSource,
      };
      setRoomOptions({ ...lobbyOptions, initialMediaSettings });
    },
    [lobbyOptions],
  );

  const skipLobby =
    roomJoinMutation?.role === RoomRole.Spectator || !roomJoinMutation?.config.videoCall;

  useEffect(() => {
    if (skipLobby) {
      join({ isParticipating: false });
    }
  }, [join, skipLobby]);

  const trackingContextValue = useMemo(
    () => ({
      category: 'Room',
      name: `${data?.roomJoin?.room.title} (${data?.roomJoin?.room.id})`,
    }),
    [data?.roomJoin?.room.title, data?.roomJoin?.room.id],
  );

  // Since joining the room is an effect `loading` will be false before `roomOptions` is populated.
  // Make sure the lobby is not rendered if we're going to skip the lobby.
  if (loading || (skipLobby && lobbyOptions && !roomOptions)) {
    return (
      <div className="lobby-holding-screen">
        <Spinner size="4rem" />
      </div>
    );
  }

  if (roomOptions) {
    return (
      <RoomInfoContext.Provider value={data?.roomJoin?.room}>
        <RoomConfigContext.Provider value={data?.roomJoin?.config}>
          <TrackingContext.Provider value={trackingContextValue}>
            <RoomView options={roomOptions} />
          </TrackingContext.Provider>
        </RoomConfigContext.Provider>
      </RoomInfoContext.Provider>
    );
  }

  if (lobbyOptions) {
    const isStaff = user.accreditation?.toLocaleLowerCase() === 'staff';

    return (
      <RoomConfigContext.Provider value={data?.roomJoin?.config}>
        <Lobby room={lobbyOptions.room}>
          {({ videoSource, videoEnabled, audioEnabled }): ReactElement => (
            <LobbyPreviewAccess
              participateGrant
              spectateGrant
              canSpectate={isStaff}
              isAdminOrStaff={isStaff}
              moderationChannel={lobbyOptions.moderation.channel}
              participate={(): void => {
                join({ audioEnabled, isParticipating: true, videoEnabled, videoSource });
              }}
              pubnub={lobbyOptions.pubnub}
              spectate={(): void => {
                join({ audioEnabled, isParticipating: false, videoEnabled, videoSource });
              }}
            />
          )}
        </Lobby>
      </RoomConfigContext.Provider>
    );
  }

  return (
    <div className="lobby-holding-screen">
      <NoResults />
      <h2 className="lobby-holding-screen__header -b">This room does not exist</h2>
      <ButtonBase as="a" href={urlFor('/')} variant="secondary">
        Return to homepage
      </ButtonBase>
    </div>
  );
};

type RoomV2Props = {
  apiUrl: string;
  user: User;
};
const RoomV2 = ({ apiUrl, user }: RoomV2Props): ReactElement<RoomV2Props> => {
  const client = useMemo(() => init({ uri: apiUrl }), [apiUrl]);

  return (
    <UserContext.Provider value={user}>
      <MediaDevicesProvider>
        <ApolloProvider client={client}>
          <Room />
        </ApolloProvider>
      </MediaDevicesProvider>
    </UserContext.Provider>
  );
};

export default withErrorBoundary(RoomV2, {
  fallback: <ErrorScreen />,
});
