import { ReactElement, SVGProps } from 'react';

type VideoIconProps = SVGProps<SVGSVGElement> & {
  isOn?: boolean;
};

const VideoIcon = ({ isOn = true, ...rest }: VideoIconProps): ReactElement<VideoIconProps> => (
  <svg viewBox="0 0 24 24" {...rest}>
    {isOn ? (
      <path d="M17,10.5 L17,7 C17,6.45 16.55,6 16,6 L4,6 C3.45,6 3,6.45 3,7 L3,17 C3,17.55 3.45,18 4,18 L16,18 C16.55,18 17,17.55 17,17 L17,13.5 L21,17.5 L21,6.5 L17,10.5 Z" />
    ) : (
      <path d="M21,6.5 L17,10.5 L17,7 C17,6.45 16.55,6 16,6 L9.82,6 L21,17.18 L21,6.5 Z M3.27,2 L2,3.27 L4.73,6 L4,6 C3.45,6 3,6.45 3,7 L3,17 C3,17.55 3.45,18 4,18 L16,18 C16.21,18 16.39,17.92 16.54,17.82 L19.73,21 L21,19.73 L3.27,2 Z" />
    )}
  </svg>
);

export default VideoIcon;
