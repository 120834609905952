import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

const TimeIcon = ({ ref, ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <IconBase viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd">
        <path
          d="M12 3a9.01 9.01 0 019 9 9 9 0 11-9-9zm0 2a7 7 0 100 14 7.009 7.009 0 007-7 7 7 0 00-7-7zm-1.3 2.829a1 1 0 011 1v3.476h3.476a1 1 0 010 2h-4.418A1.122 1.122 0 019.7 13.247V8.829a1 1 0 011-1z"
          fill="#333"
        />
      </g>
    </IconBase>
  );
};

export default TimeIcon;
