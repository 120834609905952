import { useMutation } from '@apollo/client';
import { ReactElement, useEffect } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { RoomPreviewDocument } from '~/operations/catalyst';

import OpenTokSession from '../room/OpenTokSession';
import LobbyVideo, { LobbyVideoProps, LobbyVideoSkeleton } from './LobbyVideo';

const LobbyVideoWrapper = (props: LobbyVideoProps): ReactElement<LobbyVideoProps> => {
  const [createPreview, { data }] = useMutation(RoomPreviewDocument);

  useEffect(() => {
    void createPreview();
  }, [createPreview]);

  if (!data?.roomPreview) {
    return <LobbyVideoSkeleton />;
  }

  const { apiKey, sessionId, token } = data.roomPreview;

  return (
    <OpenTokSession apiKey={apiKey} initialToken={token} sessionId={sessionId}>
      <LobbyVideo
         
        {...props}
      />
    </OpenTokSession>
  );
};

export default withErrorBoundary(LobbyVideoWrapper, {
  fallback: <LobbyVideoSkeleton />,
});
