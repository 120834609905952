import { ReactElement } from 'react';

import { Asset } from '~/lib/assets';
import HoldingScreen from '~/components/room/HoldingScreen';
import defaultMasterclassImage from '~/../assets/images/illustrations/masterclass-starting-soon.svg';
import defaultQaImage from '~/../assets/images/illustrations/qa-starting-soon.svg';

// Custom generic image takes precedence over default variant asset.
const genericImage = Asset.roomHoldingScreenGeneric;
const masterclassImage =
  Asset.roomHoldingScreenMasterclass || Asset.roomHoldingScreenGeneric || defaultMasterclassImage;
const qaImage = Asset.roomHoldingScreenQa || Asset.roomHoldingScreenGeneric || defaultQaImage;

const getOfflineImageUrl = (eventFormat?: string): string | undefined => {
  if (eventFormat === 'Masterclass') {
    return masterclassImage;
  }
  if (eventFormat === 'Q&A') {
    return qaImage;
  }

  return genericImage;
};

type OfflineScreenProps = {
  eventFormat?: string;
};

const OfflineScreen = ({ eventFormat }: OfflineScreenProps): ReactElement<OfflineScreenProps> => {
  const image = getOfflineImageUrl(eventFormat);
  const text = image ? undefined : 'Streaming will resume shortly. Please hold.';

  return (
    <HoldingScreen
      holdingImage={image && <img alt="Streaming will resume shortly. Please hold." src={image} />}
      text={text}
    />
  );
};

export default OfflineScreen;
