import { ReactElement } from 'react';
import styled from 'styled-components';

import defaultEmptyChatImageUrl from '~/../assets/images/illustrations/empty-chat.svg';
import { Asset } from '~/lib/assets';

const emptyChatImageUrl = Asset.emptyChatImage || defaultEmptyChatImageUrl;

const StyledEmptyChat = styled.div`
  background-color: var(--c-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 0;

  svg {
    height: 20rem;
  }
  img {
    max-width: 80%;
    object-fit: contain;
    margin-bottom: 1rem;
  }
`;

const EmptyChat = (): ReactElement => (
  <StyledEmptyChat>
    <img alt="No chat messages yet" src={emptyChatImageUrl} />
    <p>
      Start chatting with people,
      <br />
      all your messages will be here
    </p>
  </StyledEmptyChat>
);

export default EmptyChat;
