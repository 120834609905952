import { useQuery } from '@apollo/client';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Asset } from '~/lib/assets';
import { Question, useQuestions } from '~/lib/rooms/questions';
import { RoomPresentationViewDocument } from '~/operations/catalyst';
import { RoomOptions } from '~/types/rooms';

import Portrait from '../attendees/Portrait';
import Spinner from '../loading/Spinner';

type QuestionCardProps = {
  question: Question;
};

const QuestionCard = ({ question }: QuestionCardProps): ReactElement => {
  return (
    <div>
      <Portrait
        avatarUrl={question.author.avatarUrl}
        id={question.author.id}
        name={question.author.name}
      />
      <div className="presentation-view__question_header">
        <div className="presentation-view__question_header_name">{question.author.name}</div>
        <div className="presentation-view__question_header_title">
          {question.author.jobTitle} at {question.author.companyName}
        </div>
      </div>
      <div className="presentation-view__question_body">{question.body}</div>
    </div>
  );
};

type PresentationViewProps = {
  options: RoomOptions;
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 4rem;
`;

const AppLogo = styled.img`
  height: 6rem;
`;

const Title = styled.h1`
  font-size: var(--fs-64);
  font-weight: 600;
  color: var(--c-white);
  margin-bottom: 6rem;
`;

const SubmitQuestions = styled.h2`
  font-size: var(--fs-48);
  line-height: var(--fs-56);
  font-weight: 600;
  color: var(--c-white);
  flex: 1;
`;

const Placeholder = styled.div`
  font-size: var(--fs-32);
  line-height: var(--fs-40);
  color: rgba(255, 255, 255, 0.65);
  margin-left: 8rem;
`;

const PresentationView = ({
  options,
}: PresentationViewProps): ReactElement<PresentationViewProps> => {
  const { room } = options;

  const { t } = useTranslation();
  const { currentQuestion } = useQuestions();
  const { loading, data } = useQuery(RoomPresentationViewDocument);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <div className="presentation-view">
        {!currentQuestion && (
          <Title>
            {t('presentationView.title', {
              title: room.name,
            })}
          </Title>
        )}
        <Header>
          {Asset.appLogo ? <AppLogo src={Asset.appLogo} /> : null}
          <SubmitQuestions>
            {t('presentationView.sendQuestion', {
              brand: data?.event?.brandName,
              title: room.name,
            })}
          </SubmitQuestions>
        </Header>
        <div className="presentation-view__details presentation-view__column">
          {currentQuestion ? (
            <div className="presentation-view__question">
              <QuestionCard question={currentQuestion} />
            </div>
          ) : (
            <Placeholder>{t('presentationView.placeholder')}</Placeholder>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default PresentationView;
