import clsx from 'clsx';
import { ReactElement } from 'react';

import { Tab,Tabs } from './discussionPanelTabsConfig';

type DiscussionPanelTabsMinimisedProps = {
  openTab: (id: Tabs) => void;
  showNewMessagesCounter: boolean;
  tabs: Tab[];
};

const DiscussionPanelTabsMinimised = ({
  tabs,
  openTab,
  showNewMessagesCounter,
}: DiscussionPanelTabsMinimisedProps): ReactElement<DiscussionPanelTabsMinimisedProps> => (
  <nav className="discussion-panel__minimised">
    {tabs.map((tab) => (
      <button
        key={tab.id}
        className={clsx('btn', '-icon', '-white', `-${tab.label.toLowerCase()}`)}
        type="button"
        onClick={() => openTab(tab.id)}
      >
        {tab.icon}
        {tab.id === Tabs.Chat && showNewMessagesCounter && <div className="attention-indicator" />}
      </button>
    ))}
  </nav>
);

export default DiscussionPanelTabsMinimised;
