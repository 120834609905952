import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactElement } from 'react';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;
type NativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export type BaseButtonProps = { isDisabled?: boolean } & (
  | ({ as: 'a' } & AnchorProps)
  | ({ as?: 'button' } & Omit<NativeButtonProps, 'disabled'>)
);

/**
 * @deprecated use `components/button/Button`
 */
const BaseButton = ({
  children,
  className,
  isDisabled,
  ...restProps
}: BaseButtonProps): ReactElement => {
  if (restProps.as === 'a') {
    const { as, href, ...anchorProps } = restProps;
    return (
      <a className={className} href={isDisabled ? undefined : href} {...anchorProps}>
        {children}
      </a>
    );
  }

  const { as, ...buttonProps } = restProps;
  return (
    <button className={className} disabled={isDisabled} type="button" {...buttonProps}>
      {children}
    </button>
  );
};

export default BaseButton;
