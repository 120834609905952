import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

type ExhibitorProfileEndorsementTextProps = {
  name: string;
  url: string;
};

export const ExhibitorProfileEndorsementText = ({
  url,
  name,
}: ExhibitorProfileEndorsementTextProps): ReactElement<ExhibitorProfileEndorsementTextProps> | null => {
  return (
    <Trans i18nKey="exhibitors.endorsedBy" name={name}>
      Supported by{' '}
      <a href={url} rel="noreferrer" target="_blank">
        {name}
      </a>
    </Trans>
  );
};

export default ExhibitorProfileEndorsementText;
