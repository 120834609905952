import clsx from 'clsx';
import { Fragment,ReactElement } from 'react';

import { useBreakpoint } from '~/components/util/BreakpointProvider';
import AcceptIcon from '~/components/icons/AcceptIcon';

type StepperProps = {
  activeStepIndex: number;
  className?: string;
  steps: string[];
};

const Stepper = ({
  steps,
  activeStepIndex,
  className,
}: StepperProps): ReactElement<StepperProps> => {
  const { desktop: isDesktop } = useBreakpoint();
  return (
    <div className={clsx('stepper', className)}>
      {steps.map((step, index) => {
        const counted = index < activeStepIndex;
        const active = index === activeStepIndex;

        const key = step.toLocaleLowerCase().replace(' ', '-');

        return (
          <Fragment key={`stepper-step::${key}`}>
            {index === 0 && <div className="stepper__end-spacer" />}
            <div
              className={clsx(
                'stepper__step-node',
                counted && 'stepper__step-node--counted',
                active && 'stepper__step-node--active',
              )}
            >
              {counted && <AcceptIcon className="stepper__tick" />}
              {active && index + 1}
            </div>
            {index + 1 < steps.length && <div className="stepper__step-divider" />}
            {index + 1 === steps.length && <div className="stepper__end-spacer" />}
            {isDesktop && (
              <div className={clsx('stepper__step-name', active && 'stepper__step-name--active')}>
                {step}
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
