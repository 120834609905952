import { forwardRef, PropsWithChildren, ReactNode } from 'react';

import InteractiveButton, {
  InteractiveButtonProps,
} from '~/components/shared/atoms/InteractiveButton';

export type ActionButtonProps = PropsWithChildren<
  {
    icon: ReactNode;
    onClick?: () => void;
    styling: 'safe' | 'danger' | 'secondary';
    text: ReactNode;
  } & Pick<InteractiveButtonProps, 'disabled' | 'size'>
>;

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ styling, onClick, icon, disabled, size, text }: ActionButtonProps, ref) => {
    return (
      <InteractiveButton
        ref={ref}
        disabled={disabled}
        size={size}
        styling={styling}
        onClick={onClick}
      >
        {icon}
        {text}
      </InteractiveButton>
    );
  },
);
