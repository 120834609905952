// opentok presence actions
export const JOIN = 'join';
export const LEAVE = 'leave';
export const STATE_CHANGE = 'state-change';
export const TIMEOUT = 'timeout';

// pubnub signals
export const IS_TYPING = 'typing_on';

// ClampLines options
export const CLAMP_LINES_OPTIONS = {
  ellipsis: '...',
  lines: 3,
};
