import styled from 'styled-components';

const LinkButton = styled.button`
  display: inline-flex;
  padding: 0;
  background: transparent;
  cursor: pointer;
  color: var(--c-black);
  font-weight: 400;

  &:hover {
    text-decoration: underline;
    color: var(--c-brand-primary);
  }
`;

export default LinkButton;
