import { ReactElement, ReactNode } from 'react';

import SvgWomanWaiting from '../icons/WomanWaiting';

type HoldingScreenProps = {
  holdingImage?: ReactNode;
  text?: string;
};

const HoldingScreen = ({
  text,
  holdingImage,
}: HoldingScreenProps): ReactElement<HoldingScreenProps> => {
  return (
    <div className="stage__stream -holding-screen">
      {holdingImage ?? <SvgWomanWaiting />}
      {text && <span className="stage__holding-text">{text}</span>}
    </div>
  );
};

export default HoldingScreen;
