import { ReactElement, SVGProps } from 'react';

type CameraIconProps = SVGProps<SVGSVGElement>;

const CameraIcon = (props: CameraIconProps): ReactElement<CameraIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <rect height="24" id="Bounds" width="24" x="0" y="0" />
      <path
        className="camera-icon"
        d="M16.4444444,6 C17.0555556,6 17.5555556,6.4875 17.5555556,7.08333333 L17.5555556,7.08333333 L17.5555556,10.875 L22,6.54166667 L22,18.4583333 L17.5555556,14.125 L17.5555556,17.9166667 C17.5555556,18.5125 17.0555556,19 16.4444444,19 L16.4444444,19 L3.11111111,19 C2.5,19 2,18.5125 2,17.9166667 L2,17.9166667 L2,7.08333333 C2,6.4875 2.5,6 3.11111111,6 L3.11111111,6 Z M9.5,13.8235294 C7.998125,13.8235294 5,14.5329412 5,15.9411765 L5,17 L14,17 L14,15.9411765 C14,14.5329412 11.001875,13.8235294 9.5,13.8235294 Z M9.5,8 C8.18375,8 7.11764706,9.06610294 7.11764706,10.3823529 C7.11764706,11.6986029 8.18375,12.7647059 9.5,12.7647059 C10.81625,12.7647059 11.8823529,11.6986029 11.8823529,10.3823529 C11.8823529,9.06610294 10.81625,8 9.5,8 Z"
        fill="#333333"
      />
    </g>
  </svg>
);

export default CameraIcon;
