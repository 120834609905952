import { css } from 'styled-components';

const bodyMixin = css`
  font-weight: 400;
  font-size: var(--fs-body);
  line-height: var(--lh-body);
  letter-spacing: normal;
`;

export default bodyMixin;
