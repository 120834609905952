import { ReactElement, useEffect, useRef, useState } from 'react';
import initBitmovinMux from '@mux/mux-data-bitmovin';

import { useTracking } from '~/lib/analytics';

import Loader from './Loader';
import LiveStreamOfflineOverlay from './LiveStreamOfflineOverlay';

declare const bitmovin: any;

const loadBitmovin = (onLoad?: () => void) => {
  const existingScript = document.getElementById('bitmovin-script');

  if (existingScript) {
    onLoad?.();
    return;
  }

  const script = document.createElement('script');
  script.src = 'https://cdn.bitmovin.com/player/web/8/bitmovinplayer.js';
  script.id = 'bitmovin-script';

  document.body.appendChild(script);

  script.onload = () => {
    onLoad?.();
  };
};

export type BitmovinPlayerProps = {
  muted?: boolean;
  url: string;
  videoId?: string;
  videoTitle?: string;
  poster?: string;
};

const BitmovinPlayer = ({
  url,
  muted = false,
  videoId,
  videoTitle,
  poster,
}: BitmovinPlayerProps): ReactElement<BitmovinPlayerProps> => {
  const container = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [offline, setOffline] = useState(false);
  const track = useTracking();

  useEffect(() => {
    loadBitmovin(() => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    const el = container.current;

    if (!el || !loaded) {
      return () => {
        // No cleanup needed
      };
    }

    const player = new bitmovin.player.Player(el, {
      adaptation: {
        preload: false,
      },
      cast: {
        enable: true,
      },
      key: 'e16a184b-ff78-4217-b0e3-e64c2edc3d68',
      live: {
        lowLatency: {
          catchup: {
            playbackRate: 1.2,
            playbackRateThreshold: 0.075,
            seekThreshold: 5,
          },
          fallback: {
            playbackRate: 0.95,
            playbackRateThreshold: 0.075,
            seekThreshold: 5,
          },
          targetLatency: 10,
        },
        synchronization: [
          {
            method: 'httphead',
            serverUrl: 'http://time.akamai.com',
          },
        ],
      },
      playback: {
        muted,
        timeShift: false,
        seeking: false,
      },
      style: {
        aspectratio: '16:9',
      },
      ui: {
        errorMessages: {
          1210: 'Oops! Please fully close and restart your browser (Chrome, Firefox, etc) to access our content. If you have further issues, please contact our support!',
          1301: 'We’re sorry, Safari has experienced an issue. Our content is experienced best over Google Chrome, which we recommend you switch to. If you have further issues, please contact our support!',
          1400: 'Oops! Please fully close and restart your browser (Chrome, Firefox, etc) to access our content. If you have further issues, please contact our support!',
        },
        playbackSpeedSelectionEnabled: false,
      },
    });

    initBitmovinMux(player, {
      data: {
        env_key: 'seiftfqe6bf270m5scuevdko7',
        player_init_time: initBitmovinMux.utils.now(),
        video_id: videoId,
        video_title: videoTitle,
        video_stream_type: 'live',
      },
    });

    const play = async () => {
      try {
        await player.load({
          hls: url,
          poster,
        });
      } catch (err) {
        // Media is unavailable
      }
    };

    let timer: number;

    // Mark the stream as offline and poll the manifest waiting for it to come online.
    const showOfflineOverlay = () => {
      setOffline(true);
      clearInterval(timer);
      timer = window.setInterval(() => play(), 30_000);
    };

    player.on(bitmovin.player.PlayerEvent.Error, (error: Error): void => {
      // Failed to load manifest, stream is offline.
      if (error.name === 'SOURCE_COULD_NOT_LOAD_MANIFEST') {
        showOfflineOverlay();
      }
    });

    player.on(bitmovin.player.PlayerEvent.PlaybackFinished, (): void => {
      // Stream ended.
      showOfflineOverlay();
    });

    player.on(bitmovin.player.PlayerEvent.SourceLoaded, (): void => {
      // Mux tends to keep the HLS manifest available in a finished state for a few minutes after the stream has
      // concluded. Don't attempt to playback a non-live stream.
      if (!player.isLive()) {
        showOfflineOverlay();
        player.unload();
        return;
      }

      // Stream came online.
      clearInterval(timer);
      setOffline(false);
      player.play();
    });

    void play();

    return () => player.destroy();
  }, [loaded, url, muted, videoId, videoTitle, track, poster]);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <div ref={container} className="container">
      {offline && <LiveStreamOfflineOverlay poster={poster} />}
    </div>
  );
};

export default BitmovinPlayer;
