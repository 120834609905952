import { DateTime } from 'luxon';

const timeWithOffset = ({ offset, time }: { offset: number; time?: string }): Date => {
  const date = time ? DateTime.fromISO(time).toJSDate() : DateTime.local().toJSDate();

  date.setTime(date.getTime() + offset);
  return date;
};

export default timeWithOffset;
