declare global {
  interface Window {
    templateVariables?: Record<string, string>;
  }
}

const nargs = /\{([0-9a-zA-Z_]+)\}/g;

export const format = (s: string, args: Record<string, string> = {}): string => {
  const allArgs = {
    ...window.templateVariables,
    ...args,
  };

  return s.replace(nargs, (match: string, i: string, index: number) => {
    if (s[index - 1] === '{' && s[index + match.length] === '}') {
      return i;
    }
    const result = allArgs[i];
    if (result === null || result === undefined) {
      return '';
    }

    return result;
  });
};
