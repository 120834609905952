import { cloneElement, ReactElement } from 'react';

import { useTracking } from '~/lib/analytics';
import DownloadIcon from '~/components/icons/DownloadIcon';
import CrunchbaseIcon from '~/components/icons/social/CrunchbaseIcon';
import FacebookIcon from '~/components/icons/social/FacebookIcon';
import GithubIcon from '~/components/icons/social/GithubIcon';
import InstagramIcon from '~/components/icons/social/InstagramIcon';
import LinkedinIcon from '~/components/icons/social/LinkedinIcon';
import LinkIcon from '~/components/icons/social/LinkIcon';
import TwitterIcon from '~/components/icons/social/TwitterIcon';
import YoutubeIcon from '~/components/icons/social/YoutubeIcon';

const capitalize = (value: string): string => {
  return value[0].toUpperCase() + value.substring(1);
};

const LINK_ICONS_MAP: Record<string, ReactElement> = {
  crunchbase: <CrunchbaseIcon />,
  default: <LinkIcon />,
  facebook: <FacebookIcon />,
  github: <GithubIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedinIcon />,
  pitchdeck: <DownloadIcon />,
  twitter: <TwitterIcon />,
  youtube: <YoutubeIcon />,
};

type ExternalUrlsProps = {
  externalUrls: Record<string, string | null | undefined>;
};

const ExternalUrls = ({ externalUrls }: ExternalUrlsProps): ReactElement<ExternalUrlsProps> => {
  const entries = Array.from<[string, string | null | undefined]>(Object.entries(externalUrls));
  const track = useTracking();

  return (
    <ul className="external-urls">
      {entries.map(
        ([provider, url]) =>
          url && (
            <li key={provider}>
              <a
                href={url}
                rel="noreferrer noopener"
                target="_blank"
                title={provider}
                onClick={(): void => track(`Clicked ${provider} link`)}
              >
                {cloneElement(LINK_ICONS_MAP[provider] ?? LINK_ICONS_MAP.default, {
                  className: `social-icon -${provider}`,
                })}
                {capitalize(provider)}
              </a>
            </li>
          ),
      )}
    </ul>
  );
};

export default ExternalUrls;
