import { useMutation, useQuery } from '@apollo/client';
import { MouseEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTracking } from '~/lib/analytics';
import {
  AttendeeBlockedDocument,
  BlockAttendeeDocument,
  UnblockAttendeeDocument,
} from '~/operations/catalyst';
import Button from '~/components/button/Button';
import Modal from '~/components/others/Modal';
import BlockIcon from '~/components/icons/BlockIcon';

import LockIcon from '../icons/LockIcon';

const UnblockIcon = styled(LockIcon)`
  color: var(--c-red);
`;

const StyledBlockIcon = styled(BlockIcon)`
  color: var(--c-red);
`;

type BlockAttendeeButtonProps = {
  attendeeId: string;
  onBlock?: () => void;
};

const BlockAttendeeButton = ({ attendeeId, onBlock }: BlockAttendeeButtonProps): ReactElement => {
  const { t } = useTranslation();
  const track = useTracking();

  const { data } = useQuery(AttendeeBlockedDocument, {
    variables: { id: attendeeId },
  });

  const [block] = useMutation(BlockAttendeeDocument, {
    variables: { id: attendeeId },
  });

  const [unblock] = useMutation(UnblockAttendeeDocument, {
    variables: { id: attendeeId },
  });

  const renderModalContent = ({ closeModal }: { closeModal: () => void }): ReactElement => {
    const handleUnblock: MouseEventHandler = () => {
      track('Unblocked attendee');
      void unblock();
      closeModal();
    };
    const handleBlock: MouseEventHandler = () => {
      track('Blocked attendee');
      void block();
      onBlock?.();
      closeModal();
    };

    return (
      <>
        <div className="preview__generic">
          <h2 className="preview__generic__heading">
            {data?.attendance?.blocked
              ? t('safety.confirmUnblockModalTitle', { name: data.attendance?.firstName })
              : t('safety.confirmBlockModalTitle', { name: data?.attendance?.firstName })}
          </h2>
          <div className="preview__generic__text">
            <p>
              {data?.attendance?.blocked
                ? t('safety.confirmUnblockModalText')
                : t('safety.confirmBlockModalText')}
            </p>
          </div>
        </div>
        <nav className="preview__nav">
          <Button type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          {data?.attendance?.blocked ? (
            <Button type="button" onClick={handleUnblock}>
              {t('safety.unblock', { name: data.attendance.firstName })}
            </Button>
          ) : (
            <Button type="button" onClick={handleBlock}>
              {t('safety.block', { name: data?.attendance?.firstName })}
            </Button>
          )}
        </nav>
      </>
    );
  };

  return (
    <Modal
      modalTrigger={({ openModal }): ReactElement => (
        <Button
          icon={data?.attendance?.blocked ? <UnblockIcon /> : <StyledBlockIcon />}
          size="small"
          type="button"
          variant="secondary"
          onClick={openModal}
        >
          {data?.attendance?.blocked
            ? t('safety.unblock', { name: data.attendance.firstName })
            : t('safety.block', { name: data?.attendance?.firstName })}
        </Button>
      )}
    >
      {renderModalContent}
    </Modal>
  );
};

export default BlockAttendeeButton;
