import clsx from 'clsx';
import { ReactElement,SVGProps } from 'react';

const MingleIcon = ({
  className,
  ...props
}: SVGProps<SVGSVGElement>): ReactElement<SVGProps<SVGSVGElement>> => (
  <svg
    className={clsx('mingle-icon', className)}
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="mingle-icon__book"
      d="M18.631 3.097a1 1 0 011.43.9v6.63l1.046.581a1 1 0 01.515.874v8.561a1 1 0 01-1.486.875l-8.075-4.487-8.075 4.487a1 1 0 01-1.486-.875v-8.56a1 1 0 01.515-.875l1.046-.58v-6.63a1 1 0 011.43-.9l6.57 3.12zm-5.57 5.928v6.272l.977.55A3.479 3.479 0 0119 18.604l.622.345v-6.278l-6.561-3.646zm-2 0L4.5 12.671v6.271l.6-.333a3.476 3.476 0 014.973-2.763l.988-.549V9.025zm-3.41 2.652a1.743 1.743 0 111.336 3.22 1.743 1.743 0 01-1.336-3.22zm7.464 0a1.743 1.743 0 111.336 3.22 1.743 1.743 0 01-1.336-3.22zm-6.796.868a.743.743 0 100 1.486.743.743 0 000-1.486zm7.464 0a.743.743 0 100 1.486.743.743 0 000-1.486zM6.061 5.58v3.936l3.817-2.119L6.061 5.58zm12 .005l-3.817 1.812 3.817 2.12V5.584z"
      fill="#333"
    />
  </svg>
);

export default MingleIcon;
