import { ApolloProvider, useQuery } from '@apollo/client';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AttendeeCarousel from '~/components/attendees/AttendeeCarousel';
import AttendeeInterests from '~/components/attendees/AttendeeInterests';
import AttendeeProfileHead from '~/components/attendees/AttendeeProfileHead';
import AttendeeProfileSidebar from '~/components/attendees/AttendeeProfileSidebar';
import ExhibitorConnectionPane from '~/components/attendees/ExhibitorConnectionPane';
import SimilarPeople from '~/components/attendees/SimilarPeople';
import TimeslotSection from '~/components/breakout/TimeslotSection';
import PageNotFound from '~/components/errors/PageNotFound';
import ExhibitorPerks from '~/components/exhibitors/ExhibitorPerks';
import AccreditationBanner from '~/components/shared/atoms/AccreditationBanner';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { TrackingContext } from '~/lib/analytics';
import { init } from '~/lib/apollo';
import paramaterize from '~/lib/parametize';
import { Attendee, AttendeeProfileDocument } from '~/operations/catalyst';

const ATTENDEE_TALKS_LIMIT = 10;
const SIMILAR_PEOPLE_LIMIT = 10;

export type AttendeeProfilePageProps = {
  id: string;
  incidentReportUrl: string;
};

const createExternalUrls = (
  attendance: Pick<Attendee, 'facebookUrl' | 'githubUrl' | 'twitterUrl'>,
): {
  facebook?: string | undefined;
  github?: string | undefined;
  twitter?: string | undefined;
} => {
  const result: {
    facebook?: string;
    github?: string;
    twitter?: string;
  } = {};

  if (attendance.facebookUrl) {
    result.facebook = attendance.facebookUrl;
  }

  if (attendance.twitterUrl) {
    result.twitter = attendance.twitterUrl;
  }

  if (attendance.githubUrl) {
    result.github = attendance.githubUrl;
  }

  return { ...result };
};

export const AttendeeProfilePage = ({
  id,
  incidentReportUrl,
}: AttendeeProfilePageProps): ReactElement<AttendeeProfilePageProps> => {
  const client = init();

  const { t } = useTranslation();
  const { loading, data, error } = useQuery(AttendeeProfileDocument, {
    client,
    fetchPolicy: 'cache-and-network',
    variables: {
      attendeeId: id,
      attendeeTalksLimit: ATTENDEE_TALKS_LIMIT,
      similarPeopleLimit: SIMILAR_PEOPLE_LIMIT,
    },
  });

  const attendance = data?.attendance;
  const name = attendance?.name;

  const notFound = !loading && !attendance;

  const trackingContextValue = useMemo(
    () => ({
      category: 'Attendee',
      name: `${name} (${id})`,
    }),
    [name, id],
  );

  if (error) {
    return <ErrorScreen />;
  }

  if (notFound) {
    return <PageNotFound />;
  }

  const externalUrls = createExternalUrls(attendance ?? {});

  const role = attendance?.role;
  const exhibitor = attendance?.exhibitor;

  const offeringInterests = attendance?.offeringTopics.edges.map((e) => e.node.name);
  const seekingInterests = attendance?.seekingTopics.edges.map((e) => e.node.name);
  const similarAttendees = attendance?.similarAttendees.edges.map((e) => e.node);
  const talks = attendance?.talks.edges.map((e) => e.node) ?? [];

  const timeslots = exhibitor?.sponsoredSessions.edges.map((e) => e.node);
  const exhibitorTeam = exhibitor?.team.edges
    .map((e) => e.node)
    .filter((e) => e.id !== attendance?.id);

  return (
    <ApolloProvider client={client}>
      <TrackingContext.Provider value={trackingContextValue}>
        <div className="container -md -omega">
          <div className="profile-page">
            <AccreditationBanner className={role ? `-${paramaterize(role)}` : undefined} />
            <div className="profile-page__main profile-page__grid">
              <div className="profile-sidebar-container">
                <AttendeeProfileSidebar
                  attendance={data?.attendance ?? undefined}
                  externalUrls={externalUrls}
                  incidentReportUrl={incidentReportUrl}
                  loading={loading}
                />
              </div>
              <main className="profile-page__content">
                <AttendeeProfileHead attendance={attendance ?? undefined} loading={loading} />

                {exhibitor ? <ExhibitorConnectionPane appearance={exhibitor} /> : null}

                {!loading ? (
                  <section className="profile-page__pane pane">
                    <header className="pane-header">
                      <h3 className="pane-header__heading">{t('profile.interests')}</h3>
                    </header>
                    <AttendeeInterests
                      attendeeName={name ?? ''}
                      offeringInterests={offeringInterests ?? []}
                      seekingInterests={seekingInterests ?? []}
                    />
                  </section>
                ) : null}

                {talks.length > 0 && (
                  <TimeslotSection slideCount={2} timeslots={talks} title="Talks" />
                )}

                {exhibitor && timeslots && timeslots.length > 0 ? (
                  <TimeslotSection
                    slideCount={2}
                    timeslots={timeslots}
                    title={t('profile.sessions', { exhibitorName: exhibitor.company.name })}
                  />
                ) : null}

                <SimilarPeople
                  attendees={similarAttendees ?? []}
                  firstName={attendance?.firstName ?? ''}
                  loading={loading}
                />

                {exhibitor && (
                  <>
                    <AttendeeCarousel
                      attendees={exhibitorTeam}
                      title={t('profile.otherAttendees', { exhibitorName: exhibitor.company.name })}
                    />
                    {/* TODO do we still have perks? */}
                    <ExhibitorPerks
                      id={exhibitor.id}
                      logoUrl={exhibitor?.company?.logoUrl || undefined}
                      title={`${exhibitor?.company?.name} perks`}
                    />
                  </>
                )}
              </main>
            </div>
          </div>
        </div>
      </TrackingContext.Provider>
    </ApolloProvider>
  );
};

export default AttendeeProfilePage;
