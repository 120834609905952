import { ReactElement, SVGProps } from 'react';
import IconBase from './IconBase';

const CalendarIcon = ({ ref, ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M19,3 L18,3 L18,1 L16,1 L16,3 L8,3 L8,1 L6,1 L6,3 L5,3 C3.89,3 3.01,3.9 3.01,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,7.85714286 L19,7.85714286 L19,19 Z"
          fill="#333333"
        />
      </g>
    </IconBase>
  );
};

export default CalendarIcon;
