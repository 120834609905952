import clsx from 'clsx';
import { forwardRef, ReactElement } from 'react';
import { Hit } from 'react-instantsearch-core';

import { AttendeeHitType } from '~/types/search';
import AttendeeRoleTag from '~/components/attendees/AttendeeRoleTag';
import Portrait from '~/components/attendees/Portrait';
import {
  AgoliaDropdownProps,
  createAgoliaDropdown,
  ListItemComponentProps,
} from '~/components/forms/AgoliaDropdown';

export type AttendeeHit = Hit<AttendeeHitType>;
export type AttendeeSearchDropdownOnChangeValueType = AttendeeHit | null | undefined;

const Dropdown = createAgoliaDropdown<AttendeeHit>();

type DropdownItemProps = ListItemComponentProps<AttendeeHit>;

const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>(
  // FIXME: These props are validated
   
  ({ item, isHighlighted, ...props }, ref) => {
    const {
      role,
      company_name: companyName,
      job_title: jobTitle,
      avatar_urls: avatarUrls,
      last_name: lastName,
      first_name: firstName,
    } = item ?? {};
    return (
      <li
        ref={ref}
        className={clsx('dropdown__list-item', isHighlighted && '-highlighted')}
        {...props}
      >
        <Portrait avatarUrl={avatarUrls?.thumb} size="dropdown-item" />
        <p className="attendee-details">
          <span className="-b">
            {firstName} {lastName}{' '}
            {role && <AttendeeRoleTag accreditation={role} visibleAccreditations={['Staff']} />}
          </span>
          <span>
            {jobTitle} {companyName && <>at {companyName}</>}
          </span>
        </p>
      </li>
    );
  },
);

type AttendeeSearchDropdownProps = {
  className?: string;
  name: string;
} & Pick<AgoliaDropdownProps<AttendeeHit>, 'inputRef' | 'onIsOpenChange' | 'onChange'>;

const itemToString = (item: Hit<AttendeeHit> | null) =>
  item ? [item.first_name, item.last_name].join(' ') : '';

const AttendeeSearchDropdown = ({
  onChange,
  onIsOpenChange,
  name,
  inputRef,
  className,
}: AttendeeSearchDropdownProps): ReactElement<AttendeeSearchDropdownProps> => {
  return (
    <Dropdown
      className={clsx('attendee-select', className)}
      inputRef={inputRef}
      itemToString={itemToString}
      listItemComponent={<DropdownItem />}
      name={name}
      onChange={onChange}
      onIsOpenChange={onIsOpenChange}
    />
  );
};

export default AttendeeSearchDropdown;
