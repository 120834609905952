import { useQuery } from '@apollo/client';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { components } from '~/lib/avenger/schema';

import { EventSponsorInfoDocument } from '~/operations/catalyst';
import { SerializedAttendeeProfile, UpdateAttendeeProfileFormData } from '~/types/attendees';
import { Sponsor } from '~/types/event';
import { EntityType } from '~/types/shared';

export type OnboardingContextType = {
  conference: {
    coverPhotoUrl?: string;
    facebookAppKey?: string;
    githubAppKey?: string;
    name: string;
    slug: string;
    sponsor?: Sponsor | null;
    twitterAppKey?: string;
  };
  copy: {
    introMessage: string;
  };
  countries: EntityType[];
  currentAttendeeId: string;
  disableAvatarUploads?: boolean;
  industries: EntityType[];
  profileData: Partial<SerializedAttendeeProfile & UpdateAttendeeProfileFormData>;
  setProfileData: Dispatch<SetStateAction<Partial<UpdateAttendeeProfileFormData>>>;
  topics: EntityType[];
  user: components['schemas']['Me'];
};

export const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export type OnboardingContextProviderProps = PropsWithChildren<
  Omit<OnboardingContextType, 'profileData' | 'setProfileData'>
>;
export const OnboardingContextProvider = ({
  children,
  conference,
  copy,
  user,
  disableAvatarUploads,
  ...props
}: OnboardingContextProviderProps): ReactElement<OnboardingContextProviderProps> => {
  const { data: eventInfo } = useQuery(EventSponsorInfoDocument);

  const [profileData, setProfileData] = useState<Partial<SerializedAttendeeProfile>>({
    avatarUrl: user.person.avatar_url,
    bio: user.person.bio,
    city: user.person.city,
    companyName: user.person.company_name,
    countryId: user.person.country_id,
    firstName: user.person.first_name,
    industryId: user.conference_industry_id,
    jobTitle: user.person.job_title,
    lastName: user.person.last_name,
    offeringTopicIds: user.offering_topic_ids,
    role: user.ticket.public_role,
    seekingTopicIds: user.seeking_topic_ids,
  });

  const data = useMemo(
    () => ({
      ...props,
      conference: { ...conference, sponsor: eventInfo?.event?.sponsor ?? null },
      copy,
      disableAvatarUploads,
      profileData,
      setProfileData,
      user,
    }),
    [
      profileData,
      setProfileData,
      conference,
      user,
      copy,
      eventInfo?.event?.sponsor,
      disableAvatarUploads,
      props,
    ],
  );

  return <OnboardingContext.Provider value={data}>{children}</OnboardingContext.Provider>;
};

export const useOnboarding = (): OnboardingContextType => {
  const res = useContext(OnboardingContext);
  if (!res) {
    throw new Error('useOnboarding must be called inside OnboardingContextProvider');
  }
  return res;
};
