import { ReactElement } from 'react';

import { TrackingContext } from '~/lib/analytics';
import { CalendarPinsProvider } from '~/lib/calendar/pins';
import TimeslotCard from '~/components/timeslot/TimeslotCard';

const trackingContextValue = { category: 'Profile' }

type CalendarEventInvitee = {
  avatarUrl?: string;
  id: string;
  name: string;
};

type CalendarEvent = {
  ended: boolean;
  endsAt: string;
  id: string;
  invitees: CalendarEventInvitee[];
  path: string;
  startsAt: string;
  title: string;
};

type CalendarEventsListProps = {
  events: CalendarEvent[];
};

const CalendarEventsList = ({
  events,
}: CalendarEventsListProps): ReactElement<CalendarEventsListProps> => (
  <TrackingContext.Provider value={trackingContextValue}>
    <CalendarPinsProvider>
      <div className="directory-list calendar-list">
        {events.map((event) => (
          <TimeslotCard
            key={event.id}
            calendarEventId={event.id}
            endDateIso={event.endsAt}
            ended={event.ended}
            participants={event.invitees}
            startDateIso={event.startsAt}
            title={event.title}
          />
        ))}
      </div>
    </CalendarPinsProvider>
  </TrackingContext.Provider>
);

export default CalendarEventsList;
