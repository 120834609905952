import { PublisherProperties, SubscriberProperties } from 'opentok-react/types/opentok';

export const publisherOptions: PublisherProperties = {
  facingMode: 'user',
  fitMode: 'cover',
  height: '100%',
  publishAudio: false,
  publishVideo: false,
  showControls: false,
  width: '100%',
};

export const subscriberOptions: SubscriberProperties = {
  audioVolume: 50,
  fitMode: 'cover',
  height: '100%',
  preferredResolution: {
    height: 240,
    width: 320,
  },
  showControls: false,
  subscribeToAudio: true,
  subscribeToVideo: true,
  testNetwork: false,
  width: '100%',
};

export const moderatorSubscriberOptions = {
  audioBitrate: 50000,
  disableAudioProcessing: true,
};
