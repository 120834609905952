import { useMutation } from '@apollo/client';
import { ReactElement, useEffect, useMemo, MouseEventHandler } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import ChannelView from '~/components/channel/ChannelView';
import Button from '~/components/shared/atoms/Button';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { TrackingContext } from '~/lib/analytics';
import { convertJoinRoomMutationDataToRoomOptions } from '~/lib/rooms';
import { RoomConfigContext } from '~/lib/rooms/config';
import { useCurrentUser } from '~/lib/user';
import { JoinRoomDocument } from '~/operations/catalyst';

type ContentChannelProps = {
  channelId: string;
  liveStreamPoster?: string;
  locationId: string;
  trackId: string;
};

const ContentChannel = ({
  channelId,
  locationId,
  trackId,
  liveStreamPoster,
}: ContentChannelProps): ReactElement<ContentChannelProps> => {
  const user = useCurrentUser();

  const input = locationId ? { locationId } : { trackId };

  const [mutate, { data, loading, error }] = useMutation(JoinRoomDocument, {
    variables: {
      input,
    },
  });

  useEffect(() => {
    void mutate();
  }, [mutate]);

  const { roomJoin } = data || {};

  const roomOptions = useMemo(
    () => convertJoinRoomMutationDataToRoomOptions(roomJoin, user),
    [roomJoin, user],
  );

  const trackingValue = useMemo(
    () => ({
      category: 'Channel',
      name: `${roomJoin?.room.title} (${roomJoin?.room.id})`,
    }),
    [roomJoin?.room.title, roomJoin?.room.id],
  );

  const mutateClickHandler: MouseEventHandler = () => {
    void mutate();
  };

  if (loading) {
    return <ChannelView />;
  }

  if (error || !roomOptions) {
    return (
      <ErrorScreen>
        <Button theme="secondary" onClick={mutateClickHandler}>
          Try again
        </Button>
      </ErrorScreen>
    );
  }

  return (
    <RoomConfigContext.Provider value={roomJoin?.config}>
      <TrackingContext.Provider value={trackingValue}>
        <ChannelView
          id={channelId}
          liveStreamPoster={liveStreamPoster}
          reactionsLayout={roomJoin?.config.reactions?.enabled}
          room={roomOptions}
        />
      </TrackingContext.Provider>
    </RoomConfigContext.Provider>
  );
};

export default withErrorBoundary(ContentChannel, {
  fallback: <ErrorScreen />,
});
