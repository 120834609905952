import { ReactElement } from 'react';

import { BreakoutTimeslot } from '~/components/breakout/types';

import TimeslotSection from './TimeslotSection';

type Track = {
  id: string;
  name: string;
  timeslots: BreakoutTimeslot[];
};

export type TimeslotSectionListProps = {
  tracks: Track[];
};

const TimeslotSectionList = ({
  tracks,
}: TimeslotSectionListProps): ReactElement<TimeslotSectionListProps> => {
  const displayTracks = tracks.filter((t) => t.timeslots.length > 0);

  return (
    <>
      {displayTracks.map((track) => (
        <TimeslotSection key={track.id} join timeslots={track.timeslots} title={track.name} />
      ))}
    </>
  );
};

export default TimeslotSectionList;
