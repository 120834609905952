import { ReactElement, SVGProps } from 'react';

type ScreenShareIconProps = SVGProps<SVGSVGElement> & {
  isOn: boolean;
};

const ScreenShareIcon = ({
  isOn = false,
  ...rest
}: ScreenShareIconProps): ReactElement<ScreenShareIconProps> => (
  <svg viewBox="0 0 24 24" {...rest}>
    {isOn ? (
      <path d="M21,3 L3,3 C1.89,3 1,3.89 1,5 L1,19 C1,20.11 1.89,21 3,21 L21,21 C22.11,21 23,20.11 23,19 L23,5 C23,3.89 22.11,3 21,3 Z M21,19.02 L3,19.02 L3,4.98 L21,4.98 L21,19.02 Z M10,12 L8,12 L12,8 L16,12 L14,12 L14,16 L10,16 L10,12 Z" />
    ) : (
      <path d="M21,3 L3,3 C1.89,3 1,3.89 1,5 L1,19 C1,20.11 1.89,21 3,21 L21,21 C22.11,21 23,20.11 23,19 L23,5 C23,3.89 22.11,3 21,3 Z M21,19.02 L3,19.02 L3,4.98 L21,4.98 L21,19.02 Z M10,12 L8,12 L12,8 L16,12 L14,12 L14,16 L10,16 L10,12 Z" />
    )}
  </svg>
);

export default ScreenShareIcon;
