import { css } from 'styled-components';

const captionOneMixin = css`
  font-weight: 400;
  font-size: var(--fs-caption-one);
  line-height: var(--lh-caption-one);
  letter-spacing: normal;
`;

export default captionOneMixin;
