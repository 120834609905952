import { ReactElement, SVGProps } from 'react';

type ConnectionIssuesIconProps = SVGProps<SVGSVGElement>;

const ConnectionIssuesIcon = (
  props: ConnectionIssuesIconProps,
): ReactElement<ConnectionIssuesIconProps> => (
  <svg version="1.1" viewBox="0 0 31 32" {...props}>
    <g fill="none" fillRule="evenodd" id="X-Events" stroke="none" strokeWidth="1">
      <g id="Room_2" transform="translate(-733.000000, -534.000000)">
        <g transform="translate(579.000000, 446.275829)">
          <g transform="translate(154.458333, 88.068592)">
            <rect height="29.6028881" id="Bounds" width="29.5614035" x="0" y="0" />
            <path
              d="M28.3173611,12.593562 C23.5875365,7.85709988 16.997807,6.13026474 10.8884503,7.38838748 L13.9923977,10.4966907 C18.2664839,10.2870036 22.6021564,11.7918171 25.8539108,15.0604693 L28.3173611,12.593562 L28.3173611,12.593562 Z M23.3904605,17.5273767 C21.8015351,15.9362214 19.8923611,14.9001203 17.8600146,14.3697353 L22.2080044,18.7238267 L23.3904605,17.5273767 L23.3904605,17.5273767 Z M2.46345029,5.25451264 L6.24484649,9.01654633 C4.43421053,9.90463297 2.73442982,11.0887485 1.23172515,12.593562 L3.68285819,15.0604693 C5.21019737,13.5309868 6.97156433,12.3962094 8.8561038,11.6438026 L11.6151681,14.4067389 C9.61977339,14.9247894 7.72291667,15.9608905 6.15862573,17.5273767 L6.15862573,17.5397112 L8.60975877,19.994284 C10.284905,18.316787 12.4773757,17.4780385 14.6698465,17.4533694 L23.3781433,26.1615523 L24.9424342,24.6074007 L4.05237573,3.70036101 L2.46345029,5.25451264 Z M11.0855263,22.4611913 L14.7807018,26.1615523 L18.4758772,22.4611913 C16.4435307,20.4136582 13.1301901,20.4136582 11.0855263,22.4611913 Z"
              fill="#ED1846"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ConnectionIssuesIcon;
