 
import clsx from 'clsx';
import { ReactElement } from 'react';

import { useRoomConfig } from '~/lib/rooms/config';
import LobbyPreview from '~/components/lobby/LobbyPreview';
import Modal from '~/components/others/Modal';
import CameraIcon from '~/components/icons/CameraIcon';
import { RoomOptions } from '~/types/rooms';

const JoinButton = ({ onClick }: { onClick: () => void }): ReactElement => (
  <div className="join-option -primary">
    <button className="btn -svg -md" type="button" onClick={onClick}>
      <CameraIcon />
      <span>Join the conversation</span>
    </button>
  </div>
);

type LobbyAccessAdminWarningModalProps = {
  participate: () => void;
  spectate: () => void;
};

const LobbyAccessAdminWarningModal = ({
  participate,
  spectate,
}: LobbyAccessAdminWarningModalProps): ReactElement<LobbyAccessAdminWarningModalProps> => {
  return (
    <Modal modalTrigger={({ openModal }): ReactElement => <JoinButton onClick={openModal} />}>
      {(): ReactElement => (
        <>
          <div className="preview__generic">
            <h2 className="preview__generic__heading">
              Are you sure you want to join the room and be on camera?
            </h2>
          </div>
          <nav className="preview__nav">
            <button className={clsx('btn', '-secondary')} type="button" onClick={participate}>
              Yes, I want to participate
            </button>
            <button className="btn" type="button" onClick={spectate}>
              No, I want to watch
            </button>
          </nav>
        </>
      )}
    </Modal>
  );
};

type LobbyPreviewAccessProps = {
  canSpectate: boolean;
  isAdminOrStaff?: boolean;
  moderationChannel: string;
  participate: () => void;
  participateGrant: boolean;
  pubnub: RoomOptions['pubnub'];
  spectate: () => void;
  spectateGrant: boolean;
};

const LobbyPreviewAccess = ({
  spectate,
  participate,
  moderationChannel,
  pubnub,
  canSpectate,
}: LobbyPreviewAccessProps): ReactElement<LobbyPreviewAccessProps> => {
  const { backstageEnabled } = useRoomConfig();
  const showSpectateOption = canSpectate && backstageEnabled;

  return (
    <div className="lobby__access">
      <LobbyPreview channel={moderationChannel} config={pubnub} />
      <div className="lobby__join">
        {showSpectateOption ? (
          <>
            <LobbyAccessAdminWarningModal participate={participate} spectate={spectate} />
            <div className="join-option">
              If you just want to watch,{' '}
              <button className="lobby__spectate" type="button" onClick={spectate}>
                click here
              </button>
            </div>
          </>
        ) : (
          <JoinButton onClick={participate} />
        )}
      </div>
    </div>
  );
};

export default LobbyPreviewAccess;
