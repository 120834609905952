import { ReactElement, SVGProps } from 'react';

type ExitIconProps = SVGProps<SVGSVGElement>;

const ExitIcon = (props: ExitIconProps): ReactElement<ExitIconProps> => {
  return (
    <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M20.2857143,4 C21.1836735,4 21.9261419,4.80725624 21.994817,5.84299752 L22,6 L21.9914286,18 C21.9914286,19.047619 21.2994947,19.9138322 20.4191207,19.9939531 L20.2857143,20 L11.7142857,20 C10.8081633,20 10.0730807,19.1836735 10.0051275,18.1557067 L10,18 L10,16 L12,16 L12,18 L20,18 L20,6 L12,6 L12,8 L10,8 L10,6 C10,4.94285714 10.6919339,4.08526077 11.5797122,4.00598208 L11.7142857,4 L20.2857143,4 Z M7,7 L8.4,8.4 L5.8,11 L16,11 L16,13 L5.8,13 L8.4,15.6 L7,17 L2,12 L7,7 Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
};

export default ExitIcon;
