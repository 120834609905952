declare global {
  interface Window {
    _flags?: Partial<Flags>;
  }
}

export type Flags = {
  exhibitorProductsDisabled: boolean;
  exhibitorVideoDisabled: boolean;
};

const flags: Flags = {
  exhibitorProductsDisabled: window._flags?.exhibitorProductsDisabled ?? false,
  exhibitorVideoDisabled: window._flags?.exhibitorVideoDisabled ?? false,
};

export default flags;
