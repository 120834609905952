import clsx from 'clsx';
import { ReactElement } from 'react';

import DefaultAvatar from '~/components/icons/DefaultAvatar';
import urlFor from '~/lib/urlFor';

export type ExhibitorCardProps = {
  accreditation?: string | null;
  heading: string;
  id: string;
  info?: string | null;
  logoUrl?: string | null;
  theme?: 'dense' | null;
  url?: string | null;
};

const ExhibitorCard = ({
  id,
  logoUrl,
  heading,
  info,
  theme,
  accreditation,
  url,
}: ExhibitorCardProps): ReactElement<ExhibitorCardProps> => (
  <a
    className={clsx('exhibitor-item directory-item card -link', theme && `-${theme}`)}
    href={url ?? urlFor(`/exhibitors/${id}`)}
  >
    <div className={clsx('card__banner accreditation', accreditation && `-${accreditation}`)}>
      <div className="card__logo">
        {logoUrl ? (
          <img alt={heading} className="exhibitor-logo" src={logoUrl} />
        ) : (
          <DefaultAvatar className="exhibitor-logo" />
        )}
      </div>
    </div>
    <div className="card__content">
      <div className="card__heading">{heading}</div>
      <div className="info">{info}</div>
    </div>
  </a>
);

export default ExhibitorCard;
