import Linkify from 'linkify-react';
import { ReactElement } from 'react';
import { Optional } from 'utility-types';

import urlFor from '~/lib/urlFor';
import type { ChatParticipant, Message as MessageType } from '~/types/chat';
import Typing from '../loading/Typing';

import { extractIdentity } from '../realtime/usePubnub';
import { VIDEO_CALL_TYPE } from './ChatUtils';
import JoinCallMessage from './JoinCallMessage';
import Message from './Message';

type PublisherWithOptionalUUID = Pick<MessageType, 'sent_at' | 'id'> & {
  publisher: Optional<MessageType['publisher'], 'uuid'>;
};

type MessagesGroupProps = {
  group: (PublisherWithOptionalUUID & (MessageType | { data: null, type: '___typing'; }))[];
  participantsByIdentity: Record<string, ChatParticipant>;
  userIdentity: string;
};

const MessagesGroup = ({
  group,
  participantsByIdentity,
  userIdentity,
}: MessagesGroupProps): ReactElement<MessagesGroupProps> => (
  <div className="chat-stream__messages-group">
    {group.map((message, i) => {
      const { id, publisher, sent_at: sentAt } = message;
      const publisherIdentity = publisher.identity || extractIdentity(publisher.uuid);
      const participant = participantsByIdentity[publisherIdentity];
      const wasSentByCurrentUser = userIdentity === publisherIdentity;

      if (message.type === VIDEO_CALL_TYPE) {
        const link = urlFor(`/rooms?rid=${message.data.roomId}`);
        return <JoinCallMessage key={id} link={link} name={participant.name} />;
      }

      if (message.type === 'text' || message.type === '___typing') {
        const isLast = group.length > 1 && group.length === i + 1;

        return (
          <Message
            key={id}
            from={wasSentByCurrentUser ? 'currentUser' : 'participant'}
            publisher={participant}
            showTimestamp={(isLast || group.length === 1) && participant !== undefined}
            timestamp={sentAt}
          >
            {message.type === 'text' && (
              <Linkify options={{ target: '_blank' }}>{message.data.body}</Linkify>
            )}
            {message.type === '___typing' && <Typing />}
          </Message>
        );
      }

      return null;
    })}
  </div>
);

export default MessagesGroup;
