import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

export type StageStreamsProps = {
  children: ReactNode;
  isFullSize?: boolean;
  isQuestion?: boolean;
  isScreensharing?: boolean;
};

const StageStreams = ({
  children,
  isScreensharing,
  isFullSize,
  isQuestion,
}: StageStreamsProps): ReactElement<StageStreamsProps> => {
  return (
    <div className="stage__streams-container">
      <div
        className={clsx(
          'stage__streams',
          isFullSize && '-full-size',
          isScreensharing ? '-screensharing' : '-no-screensharing',
          isQuestion && '-question',
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default StageStreams;
