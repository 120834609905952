import { ReactElement } from 'react';

import { useTitle } from '~/lib/dom';
import { AttendeeProfileHeadDetailsFragment } from '~/operations/catalyst';
import ProfileHead from '~/components/shared/molecules/ProfileHead';

import SkeletonProfileHead from '../shared/molecules/SkeletonProfileHead';

type AttendeeProfileHeadProps = {
  attendance?: AttendeeProfileHeadDetailsFragment;
  loading?: boolean;
};

const AttendeeProfileHead = ({
  attendance,
  loading,
}: AttendeeProfileHeadProps): ReactElement | null => {
  useTitle(attendance?.name);

  if (loading) return <SkeletonProfileHead />;

  if (!attendance) return null;

  const { role, name, jobTitle, companyName, country, industry, email, city, bio } = attendance;

  const info =
    jobTitle && companyName
      ? `${jobTitle} at ${companyName}`
      : jobTitle ?? companyName ?? undefined;

  const location =
    country && city ? `${city}, ${country.name}` : country?.name ?? city ?? undefined;

  return (
    <ProfileHead
      description={bio ?? undefined}
      email={email ?? undefined}
      industry={industry?.name}
      info={info}
      location={location}
      name={name}
      role={role ?? undefined}
    />
  );
};

export default AttendeeProfileHead;
