import { ReactElement } from 'react';
import { ErrorBoundary, withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/loading/Spinner';

import HeroBanner from '~/components/shared/atoms/HeroBanner';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import TimeslotListCard from '~/components/timeslot/TimeslotListCard';
import { TrackingContext } from '~/lib/analytics';
import { useAlgoliaSettings } from '~/lib/avenger/api';
import { CalendarPinsProvider } from '~/lib/calendar/pins';
import { useTitle } from '~/lib/dom';
import { SearchConfig, timeslotHitListSchema } from '~/types/search';
import ScheduleList from '~/components/search/atoms/ScheduleList';
import ScheduleSearchFilters from '~/components/search/organisms/ScheduleSearchFilters';
import TimeslotNavBar from '~/components/search/organisms/TimeslotNavBar';
import AlgolaSearchProvider from '~/components/search/providers/AlgoliaSearchProvider';
import SearchTemplate, {
  connectHitsWithPlaceholder,
} from '~/components/search/templates/SearchTemplate';

type SchedulePageProps = {
  bannerEnabled?: boolean;
  bannerHref?: string;
  bannerImgSrc?: string;
  searchConfig: SearchConfig;
  searchText?: string;
  showFilters: boolean;
  title: string;
};

const trackingContextValue = {
  category: 'Schedule',
};

const ConnectedTimeslotHits = connectHitsWithPlaceholder(({ hits }) => {
  // Zod does runtime typechecking and schema validation that's compatiable with Typescript control flow analysis,
  // so we don't have to cast the unknown hit type to something else and hope it all goes well
  //
  // This validation should only fail if we mismatch the schema between the frontend code
  // and the avenger models, so it really shouldn't fail in a live confrence setting.
  const parsedHits = timeslotHitListSchema.parse(hits);

  return (
    <ScheduleList>
      {parsedHits.map((hit) => (
        <TimeslotListCard key={`schedule-list-item::${hit.id}`} hit={hit} />
      ))}
    </ScheduleList>
  );
});

const SchedulePage = ({ bannerImgSrc, bannerHref }: SchedulePageProps): ReactElement => {
  useTitle('Schedule');
  const { t } = useTranslation();
  const { data, error } = useAlgoliaSettings();

  if (error) {
    return <ErrorScreen />;
  }

  if (!data) {
    return <Spinner />;
  }

  return (
    <ErrorBoundary fallback={<ErrorScreen />}>
      <TrackingContext.Provider value={trackingContextValue}>
        <div className="container -md -omega">
          <AlgolaSearchProvider
            appId={data.app_id}
            hitsPerPage={24}
            replicaName={data.indexes.schedule_timeslot}
            searchAPIKey={data.key}
          >
            {bannerImgSrc ? (
              <HeroBanner
                altText="Schedule banner"
                bannerHref={bannerHref}
                bannerImgSrc={bannerImgSrc}
              />
            ) : (
              <h2 className="search-navigation__text -spacing">{t('labels.schedule')}</h2>
            )}
            <SearchTemplate
              isSearchWithNavigation
              filters={(): ReactElement => <ScheduleSearchFilters />}
              navBar={(): ReactElement => <TimeslotNavBar />}
              searchBoxPlaceholder={t('schedule.search')}
            >
              <CalendarPinsProvider>
                <ConnectedTimeslotHits />
              </CalendarPinsProvider>
            </SearchTemplate>
          </AlgolaSearchProvider>
        </div>
      </TrackingContext.Provider>
    </ErrorBoundary>
  );
};

export default SchedulePage;
