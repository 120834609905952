import { ReactElement } from 'react';

import ConfirmationButton, {
  ConfirmationButtonProps,
} from '~/components/shared/molecules/ConfirmationButton';
import LoadingIcon from '~/components/icons/LoadingIcon';

export type CountdownAwareConfirmationButtonProps = {
  isCountdownActive: boolean;
} & ConfirmationButtonProps;

const CountdownAwareConfirmationButton = ({
  isCountdownActive,
  onClick,
  styling,
  text,
  confirmation,
  cancel,
  confirm,
  icon,
}: CountdownAwareConfirmationButtonProps): ReactElement<CountdownAwareConfirmationButtonProps> => {
  return (
    <ConfirmationButton
      cancel={cancel}
      confirm={confirm}
      confirmation={confirmation}
      disabled={isCountdownActive}
      icon={isCountdownActive ? <LoadingIcon /> : icon}
      styling={styling}
      text={text}
      onClick={onClick}
    />
  );
};

export default CountdownAwareConfirmationButton;
