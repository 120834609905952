import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

type ScheduleListProps = { children: ReactNode };

const ScheduleListBase = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-flow: row;
  grid-gap: 1rem;

  &.-two {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ScheduleList = ({ children }: ScheduleListProps): ReactElement<ScheduleListProps> => (
  <ScheduleListBase>{children}</ScheduleListBase>
);

export default ScheduleList;
