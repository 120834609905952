import clsx from 'clsx';
import { CSSProperties, ReactElement, useEffect, useMemo } from 'react';
import Countdown from 'react-countdown';

import timeWithOffset from './timeWithOffset';

type MingleTimerProps = {
  endsAt: string;
  handleMingleFinish: () => void;
  serverClockOffset: number;
  setMingleAboutToEnd: (isLast15SecondsOfMingle: boolean) => void;
};

const MINGLE_ABOUT_TO_END_TIME = 15000; // 15 seconds

const MingleTimer = ({
  endsAt,
  serverClockOffset,
  handleMingleFinish,
  setMingleAboutToEnd,
}: MingleTimerProps): ReactElement => {
  const countdownTo = useMemo(() => {
    return timeWithOffset({ offset: -serverClockOffset, time: endsAt });
  }, [endsAt, serverClockOffset]);

  const duration = useMemo(
    () =>
      Math.abs(
        new Date(endsAt).getTime() - timeWithOffset({ offset: serverClockOffset }).getTime(),
      ) / 1000,
    [endsAt, serverClockOffset],
  );

  useEffect(() => {
    const countdown = setInterval(() => {
      const now = timeWithOffset({ offset: serverClockOffset }).getTime();

      const timeDiff = new Date(endsAt).getTime() - now;

      if (timeDiff <= MINGLE_ABOUT_TO_END_TIME) {
        setMingleAboutToEnd(true);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [endsAt, serverClockOffset, setMingleAboutToEnd]);

  return (
    <Countdown
      date={countdownTo}
      renderer={({ total: milliSecondsPassed }): ReactElement => {
        const percentage = Math.min(Math.max((milliSecondsPassed / 1000 / duration) * 100, 0), 100);
        return (
          <section className="toolbar__clock">
            <div className="toolbar__clock-time">
              <span aria-hidden="true" className="toolbar__clock-label -bottom">
                Time remaining
              </span>
              <div
                className={clsx('toolbar__clock-progress', {
                  '-about-to-end': milliSecondsPassed < MINGLE_ABOUT_TO_END_TIME,
                })}
                style={
                  {
                    '--percentage': `${percentage}%`,
                  } as CSSProperties
                }
              >
                <span className="toolbar__clock-label -top">Time remaining</span>
              </div>
            </div>
          </section>
        );
      }}
      onComplete={handleMingleFinish}
    />
  );
};

export default MingleTimer;
