import { usePubNub } from 'pubnub-react';
import { SetStateAction,useContext, useEffect } from 'react';

import { NewMessagesCounterContext } from '../chat/NewMessagesCounter';

const usePubnubMessages = ({
  channel,
}: {
  channel: string;
}): {
  newMessagesCounter: number;
  setNewMessagesCounter: (n: SetStateAction<number>) => void;
} => {
  const pubnub = usePubNub();
  const { newMessagesCounter, setNewMessagesCounter } = useContext(NewMessagesCounterContext);

  useEffect(() => {
    const listener = {
      message: (e: { channel?: string }) => {
        if (e.channel === channel) {
          setNewMessagesCounter((prevState: number) => prevState + 1);
        }
      },
    };
    pubnub.addListener(listener);

    return () => {
      pubnub.removeListener(listener);
    };
  }, [pubnub, channel, setNewMessagesCounter]);

  return {
    newMessagesCounter,
    setNewMessagesCounter,
  };
};

export default usePubnubMessages;
