import clsx from 'clsx';
import { ReactElement } from 'react';

import { Mode } from '~/types/rooms-moderation';

type RoomModeBadgeProps = {
  mode: Mode;
};

const BADGE_TEXT_MAP: Record<Mode, string> = {
  [Mode.live]: 'Live',
  [Mode.content]: 'Live',
  [Mode.offline]: 'Backstage mode',
};

const RoomModeBadge = ({ mode }: RoomModeBadgeProps): ReactElement<RoomModeBadgeProps> | null => {
  return (
    <div
      className={clsx('room-mode-badge', {
        '-backstage': mode === Mode.offline,
        '-live': [Mode.live, Mode.content].includes(mode),
      })}
    >
      {BADGE_TEXT_MAP[mode]}
    </div>
  );
};

export default RoomModeBadge;
