export type SentenceOptions = {
  lastSeparator?: string;
  separator?: string;
};

/**
 * Joins the given words to form a sentence.
 *
 * https://raw.githubusercontent.com/shinnn/array-to-sentence/master/index.js
 */
export const sentence = (
  words: string[],
  { separator = ', ', lastSeparator = ' and ' }: SentenceOptions = {},
): string => {
  if (words.length === 0) {
    return '';
  }
  if (words.length === 1) {
    return words[0];
  }
  return words.slice(0, -1).join(separator) + lastSeparator + words[words.length - 1];
};

export const plural = (n: number): string => {
  return n > 1 ? 'are' : 'is';
};

export const firstName = (name: string): string => {
  return name.split(' ')[0];
};
