import { ReactElement } from 'react';
import styled from 'styled-components';

import textClampMixin from '~/style-mixins/textClampMixin';
import bodyMixin from '~/style-mixins/typography/bodyMixin';
import headlineMixin from '~/style-mixins/typography/headlineMixin';
import { Participation } from '~/types/search';
import { formatCompanyInfo } from '~/lib/utils';

const Thumb = styled.img`
  border-radius: 50%;
  width: 2.75rem;
  height: 2.75rem;
`;

const Name = styled.h3`
  ${headlineMixin}
`;

const Details = styled.div`
  display: flex;
  flex-flow: column wrap;
  grid-area: details;
`;

const ParticipantBase = styled.div`
  display: grid;
  gap: 0.1rem 0.5rem;
  grid-template-columns: 3rem auto;
  grid-template-rows: auto;
  grid-template-areas: 'thumb details';
`;

const CompanyInfo = styled.p`
  ${bodyMixin}
  ${textClampMixin('2', 'ellipis')}
  color: var(--c-grey);
`;

type ParticipantProps = {
  className?: string;
  participation: Participation;
};

const Participant = ({
  className,
  participation,
}: ParticipantProps): ReactElement<ParticipantProps> => (
  <ParticipantBase className={className}>
    {participation.avatar_urls.tiny && (
      <Thumb alt="particpant avatar" src={participation.avatar_urls.tiny} />
    )}
    <Details>
      <Name>{participation.name}</Name>
      <CompanyInfo>
        {formatCompanyInfo(participation.job_title, participation.company_name)}
      </CompanyInfo>
    </Details>
  </ParticipantBase>
);

export default Participant;
