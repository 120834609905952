import last from 'lodash/last';
import { PubNubProvider } from 'pubnub-react';
import { ReactElement, useEffect, useMemo } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { useTracking } from '~/lib/analytics';
import ChatHeader from '~/components/conversations/ChatHeader';
import usePubnubSubscriber from '~/components/realtime/usePubnubSubscriber';
import { PRIVATE_CHAT_CONFIG } from '~/configs/chatConfig';
import { ChatChannel, Message } from '~/types/chat';

import ChatForm from '../chat/ChatForm';
import { useChat } from '../chat/ChatProvider';
import ChatStream from '../chat/ChatStream';
import { extractIdentity } from '../realtime/usePubnub';
import usePubnubMessages from '../realtime/usePubnubMessages';
import ErrorScreen from '../shared/molecules/ErrorScreen';

type ConversationProps = {
  channel: ChatChannel;
};

const Conversation = ({
  channel: { pubnubId: channelId, participants, name },
}: ConversationProps): ReactElement | null => {
  const track = useTracking();
  const channels = useMemo(() => [channelId], [channelId]);
  const { pubnubConfig, markAsRead } = useChat();

  const { pubnub, userIdentity } = usePubnubSubscriber({
    channels,
    config: pubnubConfig,
  });

  const {
    messages,
    historyLoaded,
    sendMessage,
    addMessageAction,
    fetchMessages,
    isFetching,
    isErrored,
    isSending
  } = usePubnubMessages<Message>({
    channel: channelId,
    includeMessageActions: true,
    messagePerPage: 20,
  });

  useEffect(() => {
    if (messages.length && !isFetching) {
      const lastMessage: {
        actions?: { receipt?: { message_mark_as_read?: { uuid: string }[] } };
        publisher?: { uuid?: string };
        timetoken?: string;
      } = last(messages) || {};

      const messageReadAction = lastMessage?.actions?.receipt?.message_mark_as_read;

      const isAlreadyReadByCurrentUser = messageReadAction?.find(
        ({ uuid: userUuid }) => extractIdentity(userUuid) === userIdentity,
      );

      if (
        !isAlreadyReadByCurrentUser &&
        extractIdentity(lastMessage?.publisher?.uuid) !== userIdentity
      ) {
        addMessageAction({
          action: {
            type: 'receipt',
            value: 'message_mark_as_read',
          },
          messageTimetoken: lastMessage?.timetoken || '0',
        });
        markAsRead(channelId);
      }
    }
  }, [addMessageAction, messages, channelId, isFetching, userIdentity, markAsRead]);

  if (!pubnub) return null;

  return (
    <PubNubProvider client={pubnub}>
      <>
        <ChatHeader
          participants={participants}
          userIdentity={userIdentity}
        />
        <ChatStream
          historyLoaded={historyLoaded}
          isErrored={isErrored}
          isFetching={isFetching}
          loadMore={fetchMessages}
          messages={messages}
          participants={participants}
          pubnub={pubnub}
          userIdentity={userIdentity}
        />

        <ChatForm
          withTypingSignal
          channel={channelId}
          isDisabled={isFetching || isErrored}
          isSending={isSending}
          isSingleLineInput={false}
          messageMaxLength={PRIVATE_CHAT_CONFIG.messageMaxLength}
          pubnub={pubnub}
          sendMessage={(payload, callback): void => {
            track('Sent private message', name);
            sendMessage(payload, callback);
          }}
        />
      </>
    </PubNubProvider>
  );
};

export default withErrorBoundary(Conversation, {
  fallback: <ErrorScreen />,
});
