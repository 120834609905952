import { createContext, useContext } from 'react';

export type Publisher = {
  clearCountdown: () => void;
  countdown: number | null;
  isParticipating: boolean;
  isPublishingAudio: boolean;
  isPublishingVideo: boolean;
  isRaisingHand: boolean;
  isScreenSharing: boolean;
  setIsScreenSharing: (value: boolean) => void;
  setVideoConsent: (value: boolean) => void;
  setVideoSource: (value: string) => void;
  startCountdown: (delayInMs: number) => void;
  toggleAudio: () => void;
  toggleParticipating: () => void;
  toggleRaisingHand: () => void;
  toggleScreenSharing: () => void;
  toggleVideo: () => void;
  videoConsent: boolean;
  videoSource?: string;
};

export const PublisherContext = createContext<Publisher | undefined>(undefined);

export const usePublisher = (): Publisher => {
  const publisher = useContext(PublisherContext);
  if (publisher === undefined) {
    throw new Error('usePublisher called with no PublisherProvider');
  }
  return publisher;
};
