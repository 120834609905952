import { ReactElement, useCallback, useState } from 'react';

import MingleTimer from './MingleTimer';

type MingleNavigationProps = {
  children: ({ isMingleAboutToEnd }: { isMingleAboutToEnd?: boolean }) => ReactElement;
  endsAt: string;
  onEnd: (continueMingling: boolean) => void;
  serverClockOffset: number;
};

const MingleNavigation = ({
  endsAt,
  onEnd,
  serverClockOffset,
  children,
}: MingleNavigationProps): ReactElement => {
  const [isMingleAboutToEnd, setMingleAboutToEnd] = useState(false);

  const handleMingleFinish = useCallback(() => {
    onEnd(true);
  }, [onEnd]);

  return (
    <>
      {children({ isMingleAboutToEnd })}
      <nav className="mingle-toolbar">
        <MingleTimer
          endsAt={endsAt}
          handleMingleFinish={handleMingleFinish}
          serverClockOffset={serverClockOffset}
          setMingleAboutToEnd={setMingleAboutToEnd}
        />
      </nav>
    </>
  );
};

export default MingleNavigation;
