import { ApolloProvider, useMutation } from '@apollo/client';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import Spinner from '~/components/loading/Spinner';

import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { convertJoinRoomMutationDataToRoomOptions } from '~/lib/rooms';
import { RoomConfigContext } from '~/lib/rooms/config';
import { RoomInfoContext } from '~/lib/rooms/info';
import { useCurrentUser, UserContext } from '~/lib/user';
import { JoinRoomDocument } from '~/operations/catalyst';
import PresentationViewProvider from '~/components/room/PresentationViewProvider';
import Button from '~/components/shared/atoms/Button';
import NoResults from '~/components/icons/NoResults';
import { init } from '~/lib/apollo';
import urlFor from '~/lib/urlFor';
import { RoomOptions, User } from '~/types/rooms';

type PresentationProps = {
  roomId: string;
};

const Presentation = ({ roomId }: PresentationProps): ReactElement<PresentationProps> => {
  const user = useCurrentUser();
  const [mutate, { data, loading, error }] = useMutation(JoinRoomDocument);
  const [roomOptions, setRoomOptions] = useState<RoomOptions>();

  useEffect(() => {
    mutate({
      variables: {
        input: { roomId },
      },
    }).catch(window.newrelic?.noticeError);
  }, [mutate, roomId]);

  const { roomJoin: roomJoinMutation } = data ?? {};

  const joinRoomOptions = useMemo(
    () => convertJoinRoomMutationDataToRoomOptions(roomJoinMutation, user),
    [roomJoinMutation, user],
  );

  useEffect(() => {
    if (!joinRoomOptions) {
      return;
    }
    setRoomOptions({ ...joinRoomOptions });
  }, [joinRoomOptions]);

  if (error) {
    return (
      <div className="presentation-view__holding-screen">
        <NoResults />
        <h2 className="presentation-view__holding-screen__header -b">This room does not exist</h2>
        <Button as="a" href={urlFor('/')} theme="secondary">
          Return to homepage
        </Button>
      </div>
    );
  }

  if (loading || !roomOptions) {
    return (
      <div className="presentation-view__holding-screen">
        <Spinner size="4rem" />
      </div>
    );
  }

  return (
    <RoomInfoContext.Provider value={roomJoinMutation?.room}>
      <RoomConfigContext.Provider value={roomJoinMutation?.config}>
        <PresentationViewProvider options={roomOptions} />
      </RoomConfigContext.Provider>
    </RoomInfoContext.Provider>
  );
};

type RoomPresentationViewProps = {
  apiUrl: string;
  roomId: string;
  user: User;
};
const RoomPresentationView = ({
  apiUrl,
  roomId,
  user,
}: RoomPresentationViewProps): ReactElement<RoomPresentationViewProps> => {
  const client = useMemo(() => init({ uri: apiUrl }), [apiUrl]);

  return (
    <UserContext.Provider value={user}>
      <ApolloProvider client={client}>
        <Presentation roomId={roomId} />
      </ApolloProvider>
    </UserContext.Provider>
  );
};

export default withErrorBoundary(RoomPresentationView, {
  fallback: <ErrorScreen />,
});
