import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

const LockIcon = ({
  ref,
  ...props
}: SVGProps<SVGSVGElement>): ReactElement<SVGProps<SVGSVGElement>> => (
  <IconBase version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M18,8 L17,8 L17,6 C17,3.24 14.76,1 12,1 C9.24,1 7,3.24 7,6 L7,8 L6,8 C4.9,8 4,8.9 4,10 L4,20 C4,21.1 4.9,22 6,22 L18,22 C19.1,22 20,21.1 20,20 L20,10 C20,8.9 19.1,8 18,8 Z M16.7180574,10 C17.1638168,10 17.3254599,10.0464128 17.4884229,10.1335664 C17.6513858,10.2207199 17.7792801,10.3486142 17.8664336,10.5115771 C17.9535872,10.6745401 18,10.8361832 18,11.2819426 L18,18.7180574 C18,19.1638168 17.9535872,19.3254599 17.8664336,19.4884229 C17.7792801,19.6513858 17.6513858,19.7792801 17.4884229,19.8664336 C17.3254599,19.9535872 17.1638168,20 16.7180574,20 L7.2819426,20 C6.83618323,20 6.67454011,19.9535872 6.51157715,19.8664336 C6.34861419,19.7792801 6.2207199,19.6513858 6.13356635,19.4884229 C6.04641281,19.3254599 6,19.1638168 6,18.7180574 L6,11.2819426 C6,10.8361832 6.04641281,10.6745401 6.13356635,10.5115771 C6.2207199,10.3486142 6.34861419,10.2207199 6.51157715,10.1335664 C6.67454011,10.0464128 6.83618323,10 7.2819426,10 L16.7180574,10 Z M12,13 C10.9,13 10,13.9 10,15 C10,16.1 10.9,17 12,17 C13.1,17 14,16.1 14,15 C14,13.9 13.1,13 12,13 Z M12,2.9 C13.71,2.9 15.1,4.29 15.1,6 L15.1,8 L8.9,8 L8.9,6 C8.9,4.29 10.29,2.9 12,2.9 Z"
        fill="#333333"
      />
    </g>
  </IconBase>
);

export default LockIcon;
