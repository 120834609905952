import clsx from 'clsx';
import { ReactElement } from 'react';

type AccreditationBannerProps = {
  className?: string;
};

const AccreditationBanner = ({ className }: AccreditationBannerProps): ReactElement => (
  <div className={clsx('profile-page__banner', 'accreditation', className)} />
);

export default AccreditationBanner;
