import { ReactElement } from 'react';
import styled from 'styled-components';

export type VimeoPlayerProps = {
  url: string;
};

const VimeoIFrameContainer = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`;

const VimeoIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const VimeoPlayer = ({ url }: VimeoPlayerProps): ReactElement<VimeoPlayerProps> | null => {
  // Support both video urls (https://vimeo.com/ID) and embed urls (https://player.vimeo.com/video/ID)
  const matches = /\d+/.exec(url);
  const videoId = matches?.[0];

  if (!videoId) {
    return null;
  }

  return (
    <VimeoIFrameContainer>
      <VimeoIFrame
        allowFullScreen
        allow="autoplay; fullscreen"
        src={`https://player.vimeo.com/video/${videoId}`}
        title="Pitch video on Vimeo"
      />
    </VimeoIFrameContainer>
  );
};

export default VimeoPlayer;
