import { ApolloProvider, useQuery } from '@apollo/client';
import Linkify from 'linkify-react';
import { DateTime } from 'luxon';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import AttendeeCard from '~/components/attendees/AttendeeCard';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import getLiveState from '~/components/timeslot/getLiveState';
import JoinSessionButton from '~/components/timeslot/JoinSessionButton';
import LiveLabel from '~/components/timeslot/LiveLabel';
import Timestamp from '~/components/timeslot/Timestamp';
import TrackLabel from '~/components/timeslot/TrackLabel';
import Player from '~/components/video/Player';
import { TrackingContext } from '~/lib/analytics';
import { init } from '~/lib/apollo';
import { CalendarPinsProvider } from '~/lib/calendar/pins';
import { useTitle } from '~/lib/dom';
import urlFor from '~/lib/urlFor';
import { UserContext } from '~/lib/user';
import { SchedulePageDocument } from '~/operations/catalyst';
import displayTitleMixin from '~/style-mixins/typography/displayTitleMixin';
import { User } from '~/types/rooms';

import AddToScheduleButton from '../timeslot/AddToScheduleButton';
import TimeslotNavigation from '../timeslot/TimeslotNavigation';

export type ScheduleTimeslotPageProps = {
  id: string;
};

const TimeAndLocation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Heading = styled.h1`
  ${displayTitleMixin}
  grid-row: 1;
  grid-column: 1;
`;

const Description = styled.div`
  grid-row: 2;
  grid-column: 1;
  margin-top: 2rem;
  margin-right: 2rem;
`;

const Buttons = styled.div`
  grid-row: span 2;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Content = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 75% 1fr;
`;

export const ScheduleTimeslotPage = ({
  id,
}: ScheduleTimeslotPageProps): ReactElement<ScheduleTimeslotPageProps> => {
  const { loading, data, error } = useQuery(SchedulePageDocument, {
    variables: { id },
  });

  useTitle(data?.scheduleTimeslot ? `${data.scheduleTimeslot.title} | Schedule` : undefined);

  const timeslot = data?.scheduleTimeslot;
  const hasArchivedVideo = Boolean(timeslot?.vimeoUrl || timeslot?.videoFileUrl);
  const startTime = DateTime.fromISO(timeslot?.startsAt ?? '');
  const endTime = DateTime.fromISO(timeslot?.endsAt ?? '');
  const liveTime = DateTime.now();

  const liveState = getLiveState({
    endTime,
    liveTime,
    startTime,
  });

  const trackingContextValue = useMemo(
    () => ({
      category: 'Schedule',
      name: `${timeslot?.title} (${timeslot?.id})`,
    }),
    [timeslot?.id, timeslot?.title],
  );

  if (error || !(loading || timeslot)) {
    return (
      <div className="container -md -omega">
        <div className="timeslot-show">
          <div className="timeslot-show__info">
            <ErrorScreen />
          </div>
        </div>
      </div>
    );
  }

  return (
    <TrackingContext.Provider value={trackingContextValue}>
      <div className="container -md -omega">
        <div className="timeslot-show">
          {hasArchivedVideo && (
            <div className="timeslot-show__player">
              <Player
                subtitles
                src={{
                  static: timeslot?.videoFileUrl || undefined,
                  vimeo: timeslot?.vimeoUrl || undefined,
                }}
              />
            </div>
          )}
          <div className="timeslot-show__info">
            <div className="timeslot-show__header">
              <TimeAndLocation>
                {loading && (
                  <>
                    <Skeleton width={200} />
                    <Skeleton width={300} />
                  </>
                )}
                {!loading && (
                  <>
                    <LiveLabel
                      hasArchivedVideo={hasArchivedVideo}
                      liveState={liveState}
                      onlineSession={timeslot?.onlineSession}
                      premium={timeslot?.premium}
                    />
                    <Timestamp
                      endDateIso={timeslot?.endsAt || ''}
                      startDateIso={timeslot?.startsAt || ''}
                    />
                    <TrackLabel
                      format={timeslot?.format?.label}
                      location={timeslot?.location?.name}
                      track={timeslot?.track?.name}
                    />
                  </>
                )}
              </TimeAndLocation>
            </div>
            <Content>
              <Heading>{loading ? <Skeleton /> : timeslot?.title}</Heading>
              <Description>
                {loading && <Skeleton count={5} />}
                {!loading && timeslot?.description && <Linkify>{timeslot.description}</Linkify>}
              </Description>
              <Buttons>
                {loading && <Skeleton />}
                {!loading && timeslot?.calendarEventId && (
                  <CalendarPinsProvider>
                    <AddToScheduleButton calendarEventId={timeslot.calendarEventId} />
                  </CalendarPinsProvider>
                )}
              </Buttons>
            </Content>
            {(loading || Boolean(timeslot?.participants.edges.length)) && (
              <div className="timeslot-show__speakers pane">
                <header className="pane-header">
                  <h3 className="pane-header__heading">{loading ? <Skeleton /> : 'Speakers'}</h3>
                </header>
                <div className="pane-content">
                  <div className="directory-list attendee-list">
                    {loading ? (
                      <>
                        <AttendeeCard />
                        <AttendeeCard />
                        <AttendeeCard />
                      </>
                    ) : (
                      <>
                        {timeslot?.participants.edges.map((edge) => (
                          <AttendeeCard
                            key={edge.node.id}
                            avatarUrl={edge.node.avatarUrl || undefined}
                            cardRole={edge.node.role || undefined}
                            companyName={edge.node.companyName || undefined}
                            firstName={edge.node.firstName}
                            id={edge.node.id}
                            jobTitle={edge.node.jobTitle || undefined}
                            lastName={edge.node.lastName || undefined}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {timeslot && <TimeslotNavigation indexUrl={urlFor('/schedule')} />}
          </div>
        </div>
      </div>
    </TrackingContext.Provider>
  );
};

export type ScheduleTimeslotPageWrapperProps = {
  id: string;
  user: User;
};

const ScheduleTimeslotPageWrapper = ({
  id,
  user,
}: ScheduleTimeslotPageWrapperProps): ReactElement<ScheduleTimeslotPageWrapperProps> => {
  const client = init();

  return (
    <UserContext.Provider value={user}>
      <ApolloProvider client={client}>
        <ScheduleTimeslotPage id={id} />
      </ApolloProvider>
    </UserContext.Provider>
  );
};

export default ScheduleTimeslotPageWrapper;
