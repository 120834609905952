import clsx from 'clsx';
import { ReactElement, useEffect, useState } from 'react';

import DiscussionPanel from './discussion_panel/DiscussionPanel';
import RoomLayout from './RoomLayout';
import Toolbar from './Toolbar';
import VideoStreams from './VideoStreams';

const LOBBY_PATH_MATCHER = /\/lobby$/;
const promoteFromLobby = () => {
  const currentPath = window.location.pathname;
  if (LOBBY_PATH_MATCHER.test(currentPath))
    window.history.replaceState({}, '', currentPath.replace(LOBBY_PATH_MATCHER, ''));
};

const RoomSpace = (): ReactElement => {
  const [collapsed, setCollapsed] = useState(false);
  useEffect(promoteFromLobby, []);

  return (
    <div className={clsx('room', !collapsed ? '-sidebar-open' : '-sidebar-closed')}>
      <RoomLayout>
        <VideoStreams />
        <Toolbar />
      </RoomLayout>
      {!collapsed && (
        <button className="btn -sidebar-toggle" type="button" onClick={() => setCollapsed(true)}>
          Close sidebar
        </button>
      )}
      <DiscussionPanel collapsed={collapsed} onTabOpen={() => setCollapsed(false)} />
    </div>
  );
};

export default RoomSpace;
