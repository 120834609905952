import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import MingleIcon from '~/components/icons/MingleIcon';

const InnerContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const Base = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Icon = styled(MingleIcon)`
  width: var(--is-2xl);
  height: var(--is-2xl);

  &.mingle-icon .mingle-icon__book {
    fill: var(--c-light-gray);
  }
`;

const Info = styled.div`
  color: var(--c-light-gray);
  font-size: var(--fs-sm);
`;

const NoMingles = ({ children }: { children?: ReactNode }): ReactElement => (
  <Base>
    <InnerContainer>
      <Icon />
      <Info>{children}</Info>
    </InnerContainer>
  </Base>
);

export default NoMingles;
