import { ReactElement, SVGProps } from 'react';

type ArrowDownIconProps = SVGProps<SVGSVGElement>;

const ArrowDownIcon = (props: ArrowDownIconProps): ReactElement<ArrowDownIconProps> => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      clipRule="evenodd"
      d="M4 12.0548L5.41966 10.6351L11.0479 16.2534V4L13.0616 4V16.2534L18.6899 10.6351L20.1096 12.0548L12.0548 20.1096L4 12.0548Z"
      fill="#333333"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowDownIcon;
