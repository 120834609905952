import { useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { Trans } from 'react-i18next';

import { useTracking } from '~/lib/analytics';
import { CheckExhibitorConnectionDocument, CreateConnectionDocument } from '~/operations/catalyst';
import AcceptIcon from '~/components/icons/AcceptIcon';
import CloseIcon from '~/components/icons/CloseIcon';
import { Sponsor } from '~/types/rooms';

type PopupProps = {
  attributionSource?: string;
  sponsor: Sponsor;
};

const Popup = ({ sponsor, attributionSource }: PopupProps) => {
  const track = useTracking();
  const { loading, data } = useQuery(CheckExhibitorConnectionDocument, {
    variables: {
      appearanceId: sponsor.avengerUuid,
    },
  });

  const [connect] = useMutation(CreateConnectionDocument, {
    variables: {
      entityId: sponsor.avengerUuid,
      entityType: 'Appearance',
      source: attributionSource || window.location.href,
    },
  });

  const [wasClosed, setWasClosed] = useState(false);

  if (loading) return null;

  const connections = data?.me?.connections;

  const isConnected = connections && connections.edges.length > 0;

  const requestConnection = () => {
    track('Accepted GDPR agreement');
    void connect();
    setWasClosed(true);
  };

  const close = () => {
    track('Declined GDPR agreement');
    setWasClosed(true);
  };

  const isOpened = !isConnected && !wasClosed;

  return (
    <div className={clsx('modal', 'exhibitor-connection', isOpened && '-open')}>
      <div className="modal__overlay" />

      <div className="modal__content -lg">
        <div className="preview">
          <div className="preview__generic">
            <h2 className="preview__generic__heading">
              <div className="card__logo">
                <img alt={sponsor.name} className="exhibitor-logo -md" src={sponsor.logoUrl} />
              </div>
            </h2>
            <div className="preview__generic__text">
              <Trans i18nKey="connections.exhibitorDisclaimerWithName">
                <p>
                  <b>{sponsor.name}</b> would like your name, company, title, email and country so
                  they may contact you with their latest news and offers by email.
                </p>
                <p>
                  You may unsubscribe at any time via the unsubscribe button provided in emails.
                  Providing the Partner with your information may involve transferring it to another
                  country. Personal Data will be processed in accordance with the Partner&apos;s
                  Privacy Policy.
                </p>
              </Trans>
            </div>
          </div>
          <nav className="preview__nav">
            <button className={clsx('btn', '-svg', '-secondary')} type="button" onClick={close}>
              <CloseIcon />
              <span>No, I don&apos;t agree</span>
            </button>
            <button
              className={clsx('btn', '-svg', '-primary')}
              type="button"
              onClick={requestConnection}
            >
              <AcceptIcon />
              <span>Yes, I agree</span>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

type ExhibitorConnectionPopupProps = {
  attributionSource?: string;
  sponsor: Sponsor;
};

const ExhibitorConnectionPopup = ({
  sponsor,
  attributionSource,
}: ExhibitorConnectionPopupProps): ReactElement<ExhibitorConnectionPopupProps> => {
  return <Popup attributionSource={attributionSource} sponsor={sponsor} />;
};

export default ExhibitorConnectionPopup;
