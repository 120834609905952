import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

import Portrait from '~/components/attendees/Portrait';
import Stack from '~/components/attendees/Stack';
import urlFor from '~/lib/urlFor';

import { BreakoutRoom } from './types';

export type RoomCardProps = {
  className?: string;
  room: BreakoutRoom;
  settings?: ReactNode;
  setup?: ReactNode;
  withCreator?: boolean;
  withOccupants?: boolean;
};

const RoomCard = ({
  room,
  setup,
  settings,
  className,
  withOccupants = false,
  withCreator = true,
}: RoomCardProps): ReactElement<RoomCardProps> => {
  const { id, name, description, sponsor, capacity, occupants, creator, isClosed } = room;

  return (
    <div className={clsx('room-card', className, isClosed && '-closed')}>
      <a className="room-card__link" href={urlFor(`/rooms/${id}/lobby`)}>
        Join room
      </a>
      <div>
        {(sponsor || withCreator) && (
          <div className="room-card__logo">
            {sponsor && <img key={sponsor.name} alt={sponsor.name} src={sponsor.logoUrl} />}
            {!sponsor && withCreator && (
              <Portrait avatarUrl={creator?.avatarUrl} isLink={false} size="lg" />
            )}
          </div>
        )}
        <div className="room-card__content">
          <div className="room-card__heading">{name}</div>
          {description && <div className="room-card__description">{description}</div>}
        </div>
      </div>
      {withOccupants && occupants && (
        <div className="room-card__state-item -occupants">
          <Stack participants={occupants} size="xs" />
          {occupants.length}/{capacity} occupants
        </div>
      )}
      {setup}
      {settings}
    </div>
  );
};

export default RoomCard;
