import { ReactElement } from 'react';

import { QuestionsProvider } from '~/lib/rooms/questions';
import PresentationView from '~/components/room/PresentationView';
import RoomProvider from '~/components/room/RoomProvider';
import { PUBLIC_CHAT_CONFIG } from '~/configs/chatConfig';
import { RoomOptions } from '~/types/rooms';

type PresentationViewProviderProps = {
  options: RoomOptions;
};

const PresentationViewProvider = ({
  options,
}: PresentationViewProviderProps): ReactElement => {
  return (
    <RoomProvider
      chatConfig={PUBLIC_CHAT_CONFIG}
      moderation={options.moderation}
      occupant={options.occupant}
      participants={options.participants}
      pubnub={options.pubnub}
      questions={options.questions}
      room={options.room}
      user={options.user}
    >
      <QuestionsProvider>
        <PresentationView options={options} />
      </QuestionsProvider>
    </RoomProvider>
  );
};

export default PresentationViewProvider;
