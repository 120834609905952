import Linkify from 'linkify-react';
import { ReactElement, ReactNode, useState } from 'react';
import { useTitle } from '~/lib/dom';

import { Room } from '~/types/rooms';

import LobbyVideoWrapper from './LobbyVideoWrapper';

export type DevicesOptions = {
  audioEnabled: boolean;
  videoEnabled: boolean;
  videoSource: string | undefined;
};

export type LobbyProps = {
  children: (opts: DevicesOptions) => ReactNode;
  room: Pick<Room, 'name' | 'description'>;
};

const Lobby = ({ room: { name, description }, children }: LobbyProps): ReactElement<LobbyProps> => {
  useTitle(name);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoSource, setVideoSource] = useState<string>();

  return (
    <div className="lobby container -lg">
      <div className="lobby__column">
        <LobbyVideoWrapper
          audio={audioEnabled}
          video={videoEnabled}
          videoSource={videoSource}
          onAudioChanged={setAudioEnabled}
          onVideoChanged={setVideoEnabled}
          onVideoSourceChanged={setVideoSource}
        />
      </div>

      <div className="lobby__info">
        <h1 className="lobby__title">{name}</h1>

        <div className="lobby__description">{description && <Linkify>{description}</Linkify>}</div>

        <div className="lobby__join">{children({ audioEnabled, videoEnabled, videoSource })}</div>
      </div>
    </div>
  );
};

export default Lobby;
