import { ApolloProvider } from '@apollo/client';
import clsx from 'clsx';
import { ReactElement, ReactNode, useMemo } from 'react';
import { Link, Route, Router, useLocation, useRoute } from 'wouter';

import ContentChannel from '~/components/channel/ContentChannel';
import { page } from '~/lib/analytics';
import { init } from '~/lib/apollo';
import currentSlug from '~/lib/currentSlug';
import useEffectAfterMount from '~/lib/useEffectAfterMount';
import { UserContext } from '~/lib/user';
import { User } from '~/types/rooms';

type ChannelSwitcherLinkProps = {
  children: ReactNode;
  href: string;
  icon: string;
};

const ChannelSwitcherLink = ({ href, icon, children }: ChannelSwitcherLinkProps): ReactElement => {
  const [isActive] = useRoute(href);

  return (
    <div className="switcher__option">
      <div className={clsx('switcher-option', `-${href}`, { '-active': isActive })}>
        <Link className="li-link" href={href} />
        <div className="switcher-option__content">
          <div className="brand-icon">{icon}</div>
          <p className="switcher-option__name">{children}</p>
        </div>
      </div>
    </div>
  );
};

export type ContentChannelPageProps = {
  apiUrl: string;
  channels: Channel[];
  liveStreamPoster?: string;
  user: User;
};

type Channel = {
  icon: string;
  locationId: string;
  slug: string;
  title: string;
  trackId: string;
};

const ContentChannelPage = ({
  apiUrl,
  user,
  channels,
  liveStreamPoster,
}: ContentChannelPageProps): ReactElement<ContentChannelPageProps> => {
  const client = useMemo(() => init({ uri: apiUrl }), [apiUrl]);
  const [location] = useLocation();

  // Only track subsequent page loads as the initial page load is already tracked.
  useEffectAfterMount(page, [location]);

  const switcherEnabled = channels.length > 1;

  return (
    <UserContext.Provider value={user}>
      <ApolloProvider client={client}>
        <Router base={`/${currentSlug()}/channels/`}>
          <div
            className={clsx('container', '-lg', {
              '-under-switcher': switcherEnabled,
              '-without-switcher': !switcherEnabled,
            })}
          >
            {switcherEnabled && (
              <div className="switcher">
                <div className="container -md">
                  <div className="switcher__list">
                    {channels.map(({ slug, title, icon }) => (
                      <ChannelSwitcherLink key={slug} href={slug} icon={icon}>
                        {title}
                      </ChannelSwitcherLink>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {channels.map(({ locationId, trackId, slug }) => (
              <Route key={slug} path={slug}>
                <ContentChannel
                  channelId={slug}
                  liveStreamPoster={liveStreamPoster}
                  locationId={locationId}
                  trackId={trackId}
                />
              </Route>
            ))}
          </div>
        </Router>
      </ApolloProvider>
    </UserContext.Provider>
  );
};

export default ContentChannelPage;
