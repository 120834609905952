import Pubnub, { StatusEvent } from 'pubnub';
import { useEffect, useState } from 'react';

import { pubnubLog } from '~/lib/loggers';
import { Participant, PubnubClientConfig } from '~/types/rooms';

import usePubnub from './usePubnub';

export type UsePubnubSubscriberOptions = {
  channelGroups?: string[];
  channels?: string[];
  config: PubnubClientConfig;
  withPresence?: boolean;
  withState?: WithState;
};

export type WithState = {
  channelGroups?: string[];
  channels?: string[];
  state: Participant;
};

enum Category {
  Connected = 'PNConnectedCategory',
  NetworkUp = 'PNNetworkUpCategory',
  Reconnected = 'PNReconnectedCategory',
}

export type Subscriber = {
  isConnected: boolean;
  pubnub?: Pubnub;
  userIdentity: string;
  uuid: string;
};

const usePubnubSubscriber = ({
  channels,
  channelGroups,
  config,
  withPresence,
  withState,
}: UsePubnubSubscriberOptions): Subscriber => {
  const [isConnected, setIsConnected] = useState(false);
  const { pubnub, identity, uuid } = usePubnub(config);

  useEffect(() => {
    if (!pubnub || !(channels || channelGroups)) {
      return () => {
        // No cleanup needed
      };
    }

    const setPubnubState = async (ws: WithState) => {
      try {
        return pubnub.setState({
          channelGroups: ws.channelGroups,
          channels: ws.channels,
          state: {
            accreditation: ws.state.accreditation,
            avatarUrl: ws.state.avatarUrl,
            companyName: ws.state.companyName,
            id: ws.state.id,
            identity: ws.state.identity,
            jobTitle: ws.state.jobTitle,
            name: ws.state.name,
            role: ws.state.role,
            timestamp: Date.now(),
          },
        });
      } catch (err) {
         
        console.log(err);
        return Promise.reject();
      }
    };

    const statusHandler = (e: StatusEvent) => {
      const connected =
        e.category === Category.Connected ||
        e.category === Category.NetworkUp ||
        e.category === Category.Reconnected;

      if (connected && withState) {
        void setPubnubState(withState);
      }

      setIsConnected(connected);
    };

    pubnubLog('subscribe', channels, channelGroups, withPresence, withState);

    pubnub.addListener({
      status: statusHandler,
    });

    pubnub.subscribe({
      channelGroups,
      channels,
      withPresence,
    });

    return () => {
      pubnubLog('unsubscribe', channels, channelGroups);
      pubnub.removeListener({
        status: statusHandler,
      });
      pubnub.unsubscribe({ channelGroups, channels });
    };
  }, [pubnub, channels, channelGroups, withPresence, withState]);

  return {
    isConnected,
    pubnub: pubnub || undefined,
    userIdentity: identity,
    uuid,
  };
};

export default usePubnubSubscriber;
