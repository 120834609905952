 
import { useSelect } from 'downshift';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import ChevronIcon from '../icons/ChevronIcon';

const Wrapper = styled.div`
  position: relative;
`;

const TitleButton = styled.button<{
  open: boolean;
}>`
  display: flex;
  border-radius: var(--s-bw);
  background-color: transparent;
  padding: 0.375rem;
  line-height: 1.25rem;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${({ open }) =>
    open &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
      svg {
        fill: var(--c-brand-primary);
      }
    `}
`;

const Title = styled.span`
  font-weight: 600;
  font-size: var(--fs-md);
  color: var(--c-black);
  margin-right: 0.5rem;
`;

const Icon = styled(ChevronIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 1.25rem;
`;

const Menu = styled.ul<{
  open: boolean;
}>`
  position: absolute;
  top: calc(100% + 2px);
  width: max-content;
  font-size: var(--fs-sm);

  ${({ open }) =>
    open &&
    css`
      max-height: 15rem;
      overflow-y: scroll;
      box-shadow: 0 0 0 1px var(--c-silver), 0 0.125rem 0.125rem rgba(0, 0, 0, 0.05),
        0 0.5rem 1rem rgba(0, 0, 0, 0.1);
      border-radius: var(--s-bw);
      border: 1px solid var(--c-light-silver);
      background-color: var(--c-white);
      z-index: 1000;
    `}
`;

const MenuItem = styled.li<{
  highlighted: boolean;
}>`
  padding: 0.5rem 1rem;
  cursor: pointer;

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: var(--c-offwhite);
    `}
`;

export type Item<T> = {
  label: string;
  value: T;
};

export type TitleSelectProps<T> = {
  className?: string;
  items: Item<T>[];
  onChange?: (value: T | null) => void;
  value?: T | null;
};

const TitleSelect = <T,>({ value, items, onChange }: TitleSelectProps<T>): ReactElement => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect<Item<T>>({
    itemToString: (i) => i?.label || '',
    items,
    onSelectedItemChange: (changes) => onChange?.(changes.selectedItem?.value || null),
    selectedItem: items.find((i) => i.value === value) || null,
  });

  return (
    <Wrapper>
      <TitleButton {...getToggleButtonProps()} open={isOpen}>
        <Title>{selectedItem?.label || ''}</Title>
        <Icon />
      </TitleButton>
      <Menu {...getMenuProps()} open={isOpen}>
        {isOpen &&
          items.map((item, index) => (
            <MenuItem
              key={`${item.label}-${item.value}}`}
              highlighted={index === highlightedIndex}
              {...getItemProps({ index, item })}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </Wrapper>
  );
};

export default TitleSelect;
