import { ReactElement, SVGProps } from 'react';

const ExploreIcon = ({ ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 13c2.33 0 7 1.17 7 3.5V19H1v-2.5C1 14.17 5.67 13 8 13zM18 1v4h6v14h-7v-2.685c-.005-.927-.083-2.143-1.304-3.315H16v-2h-2l.002.828A13.902 13.902 0 0012 11c.166-.014 1.212-1.93.939-3.889-.125-.892-.437-1.615-.938-2.17L12 3h-2l.001.802c-.583-.152-1.252-.227-2.006-.226H8V1h10zm4 6h-4v2h2v2h-2v2h2v2h-2v2h4V7zM8 5c1.66 0 2.99 1.34 2.99 3S9.66 11 8 11c-1.66 0-3-1.34-3-3s1.34-3 3-3zm8 2h-2v2h2V7zm0-4h-2v2h2V3z"
        fill="#333"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ExploreIcon;
