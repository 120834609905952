import clsx from 'clsx';
import throttle from 'lodash/throttle';
import type Pubnub from 'pubnub';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components';

import { IS_TYPING } from '~/constants';
import ChatSendIcon from '~/components/icons/ChatSendIcon';

import { FormatMessage, formatMessage as formatChatMessage } from './ChatUtils';

const TYPING_SIGNAL_THROTTLE = 1500;
const URL_REGEX =
  /([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,5})(:[0-9]+)?(\/.*)?/;

export const stringContainsUrl = (string: string): boolean => URL_REGEX.test(string);
export const findUrl = (string: string): RegExpMatchArray | null => string.match(URL_REGEX);

const isDomainAllowed = (message: string, whiteListedDomains: string[]) => {
  const url = findUrl(message)?.[0];
  let formattedUrl = url?.startsWith('www.') ? url.replace('www.', 'http://') : url;

  if (!formattedUrl?.match(/^[a-zA-Z0-9]+:\/\//)) {
    formattedUrl = `http://${formattedUrl}`;
  }

  try {
    const { hostname } = new URL(formattedUrl);
    return whiteListedDomains.find((domain) => hostname === domain);
  } catch (err) {
    return false;
  }
};

const StyledChatForm = styled.div`
  background-color: var(--c-white);
  flex: none;
  border-top: 1px solid var(--c-silver);
`;

type ChatFormProps = {
  channel: string;
  isDisabled: boolean;
  isSending: boolean;
  isSingleLineInput?: boolean;
  messageMaxLength: number;
  pubnub: Pubnub;
  sendMessage: (messagePayload: FormatMessage, callback: () => void) => void;
  whiteListedDomains?: string[];
  withTypingSignal?: boolean;
};

const ChatForm = ({
  isDisabled,
  isSending,
  sendMessage,
  pubnub,
  channel,
  messageMaxLength,
  isSingleLineInput = true,
  whiteListedDomains,
  withTypingSignal = false,
}: ChatFormProps): ReactElement<ChatFormProps> => {
  const [message, setMessage] = useState('');

  const [messageContainsBannedDomain, setMessageContainsBannedDomain] = useState(false);
  const chatForm = useRef(null);

  const sendTextMessage = (newMessage: string) =>
    sendMessage(formatChatMessage(newMessage), () => setMessage(''));

  const sendTypingSignal = useCallback(() => {
    return pubnub.signal({
      channel,
      message: IS_TYPING,
    });
  }, [channel, pubnub]);

  useEffect(() => {
    const sendTypingSignalThrottled = throttle(() => {
      void sendTypingSignal();
    }, TYPING_SIGNAL_THROTTLE);

    if (!message || !withTypingSignal)
      return () => {
        // no cleanup needed
      };
    sendTypingSignalThrottled();
    return () => {
      sendTypingSignalThrottled.cancel();
    };
  }, [message, sendTypingSignal, withTypingSignal]);

  const setTyping = (event: SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (messageContainsBannedDomain) {
      setMessageContainsBannedDomain(false);
    }
    setMessage((event.target as HTMLTextAreaElement | HTMLInputElement).value);
  };

  const isMessageTooLong = message.length > messageMaxLength;
  const isButtonDisabled = isDisabled || !message.trim().length || isMessageTooLong;
  const showErrors = messageContainsBannedDomain || isMessageTooLong;

  const submitMessage = () => {
    const isBannedDomain =
      whiteListedDomains &&
      stringContainsUrl(message) &&
      !isDomainAllowed(message, whiteListedDomains);

    if (isBannedDomain) {
      setMessageContainsBannedDomain(true);
      return;
    }

    if (message.trim().length && !isMessageTooLong) {
      sendTextMessage(message);
    }
  };

  return (
    <StyledChatForm ref={chatForm}>
      <form
        className={`chat-form${isSingleLineInput ? ' -single-line' : ' -textarea'}`}
        onSubmit={(e) => {
          e.preventDefault();

          // Don't send another message while one is still sending.
          // We don't disable the form because that removes focus from
          // input.
          if (isSending) {
            return;
          }

          submitMessage();
        }}
      >
        <div className="fields">
          {isSingleLineInput ? (
            <input
              className={showErrors ? 'error' : ''}
              disabled={isDisabled}
              placeholder="Type your message"
              type="text"
              value={message}
              onChange={setTyping}
            />
          ) : (
            <TextareaAutosize
              className={showErrors ? 'error' : ''}
              disabled={isDisabled}
              maxLength={messageMaxLength}
              maxRows={5}
              placeholder="Type your message"
              rows={1}
              value={message}
              onChange={setTyping}
              onKeyDown={(e) => {
                if (e.code === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  submitMessage();
                }
              }}
            />
          )}
        </div>

        {showErrors && (
          <span className="error-messages">
            {messageContainsBannedDomain && (
              <p className="error-message">Link sharing from this domain is not permitted.</p>
            )}
            {isMessageTooLong && <p className="error-message">Message is too long.</p>}
          </span>
        )}
        <div className="actions">
          <button
            className={clsx('btn -icon', isButtonDisabled && '-disabled')}
            disabled={isButtonDisabled}
            type="submit"
          >
            <ChatSendIcon />
          </button>
        </div>
      </form>
    </StyledChatForm>
  );
};

export default ChatForm;
