import { PresenceEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useCallback, useEffect, useState } from 'react';

import { pubnubLog } from '~/lib/loggers';
import { Participant } from '~/types/rooms';

import { extractIdentity } from './usePubnub';

const BUSY_ROOM_OCCUPANCY = 100;
const VERY_BUSY_ROOM_OCCUPANCY = 300;
const PRESENCE_INTERVAL = 30_000;

export type UsePresenceOptions = {
  channel: string;
};

export type Presence = {
  occupancy: number;
  occupants: Participant[];
};

// PresenceMode describes how room presence is updated.
enum PresenceMode {
  // In normal mode occupancy and opccupants are updated in real-time.
  Normal,

  // In busy mode occupancy is updated periodically using pubnub interval events.
  // Occupants are not updated until the room drops back into normal mode.
  Busy,

  // In very busy mode we unsubscribe from pubnub presence and occupancy is updated
  // periodically by polling hereNow. Occupants are not updated until the room drops
  // back into normal mode.
  VeryBusy,
}

const presenceMode = (occupancy: number) => {
  if (occupancy < BUSY_ROOM_OCCUPANCY) {
    return PresenceMode.Normal;
  }
  if (occupancy < VERY_BUSY_ROOM_OCCUPANCY) {
    return PresenceMode.Busy;
  }
  return PresenceMode.VeryBusy;
};

const usePresence = ({ channel }: UsePresenceOptions): Presence => {
  const pubnub = usePubNub();
  const [occupants, setOccupants] = useState<Record<string, Participant>>({});
  const [occupancy, setOccupancy] = useState(0);
  const [loading, setLoading] = useState(true);
  const [presence, setPresence] = useState(true);

  const mode = presenceMode(occupancy);

  const refresh = useCallback(() => {
    const update = async () => {
      try {
        const res = await pubnub.hereNow({
          channels: [channel],
          includeState: mode === PresenceMode.Normal,
          includeUUIDs: mode === PresenceMode.Normal,
          queryParameters: { limit: BUSY_ROOM_OCCUPANCY },
        });

        setLoading(false);

        const ch = res.channels[channel];
        setOccupancy(ch.occupancy);

        if (ch.occupants.length > 0) {
          setOccupants(
            ch.occupants
              .map((o) => o.state)
              .filter(Boolean)
              .reduce((acc: Record<string, Participant>, state: Participant) => {
                acc[state.identity] = state;
                return acc;
              }, {}),
          );
        }
      } catch (err) {
         
        console.log(err);
      }
    };

    void update();
  }, [channel, pubnub, mode]);

  // Reload the present occupants on first load, or when the room transitions to a non-busy room.
  useEffect(() => {
    if (loading || mode === PresenceMode.Normal) {
      refresh();
    }
  }, [loading, mode, refresh]);

  useEffect(() => {
    if (loading) {
      return () => {
        // No cleanup needed
      };
    }

    // We unsubscribe from presence entirely in very busy mode, as pubnub still publishes every
    // `state-change` event in interval mode.
    if (mode === PresenceMode.VeryBusy) {
      const h = setInterval(refresh, PRESENCE_INTERVAL);
      pubnub.unsubscribe({
        channels: [`${channel}-pnpres`],
      });
      setPresence(false);
      return () => clearInterval(h);
    }

    // Refreshing a room page may lead to rapid leave/join events and no corresponding state-change.
    // Throttle leaves so that the current occupant state is preserved after a rapid leave/join.
    const leaves: Record<string, number> = {};

    const occupantLeft = (identity: string) => {
      const leaveId = window.setTimeout(() => {
        setOccupants((os) => {
          const { ...rest } = os;
          delete rest[identity];
          return rest;
        });
      }, 3000);

      leaves[identity] = leaveId;
    };

    const occupantJoined = (identity: string) => {
      const leaveId = leaves[identity];
      if (leaveId) {
        clearTimeout(leaveId);
      }
    };

    const listeners = {
      presence: (e: PresenceEvent) => {
        if (e.channel !== channel) {
          return;
        }

        pubnubLog(e.action, e);
        setOccupancy(e.occupancy);

        if (mode === PresenceMode.Busy) {
          return;
        }

        const identity = extractIdentity(e.uuid);

        if (e.state && e.action !== 'leave') {
          setOccupants((os) => ({
            ...os,
            [identity]: e.state,
          }));
        }

        if (e.action === 'leave' || e.action === 'timeout') {
          occupantLeft(identity);
        }

        if (e.action === 'join') {
          occupantJoined(identity);
        }
      },
    };

    pubnub.addListener(listeners);

    if (!presence) {
      pubnub.subscribe({
        channels: [`${channel}-pnpres`],
      });
      setPresence(true);
    }

    return () => pubnub.removeListener(listeners);
  }, [pubnub, mode, presence, refresh, channel, loading]);

  return {
    occupancy,
    occupants: Object.values(occupants),
  };
};

export default usePresence;
