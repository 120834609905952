const TIMETOKENS_KEY = 'lastReadTimetoken';

export const timetokenFor = (timestamp: number): string => (timestamp * 10_000).toString();
export const timestampFromTimetoken = (timetoken: string): Date =>
  new Date(parseInt(timetoken, 10) / 10_000);

const readTimetokens = (): Record<string, string> => {
  const payload = localStorage.getItem(TIMETOKENS_KEY);
  if (!payload) {
    return {};
  }
  return JSON.parse(payload);
};

const timetokens = readTimetokens();

export const setLastReadTimetoken = (channel: string, token: string): void => {
  timetokens[channel] = token;
  localStorage.setItem(TIMETOKENS_KEY, JSON.stringify(timetokens));
};

export const getLastReadTimetoken = (channel: string): string => {
  const token = timetokens[channel];

  if (!token) {
    // If we haven't seen the channel before, start tracking it from now on.
    // https://www.pubnub.com/docs/chat/features/messages#receive-messages
    const now = timetokenFor(Date.now() - 3_000);
    setLastReadTimetoken(channel, now);
    return now;
  }

  return token;
};
