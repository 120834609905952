import { z } from 'zod';

const windowHasArchivedTalksFilter = z.object({
  templateVariables: z.object({
    showArchivedTalksFilter: z.boolean(),
  }),
});

export const getArchivedTalksFlag = (): boolean => {
  const parsedTemplateVariables = windowHasArchivedTalksFilter.safeParse(window);

  return (
    parsedTemplateVariables.success &&
    parsedTemplateVariables.data.templateVariables.showArchivedTalksFilter
  );
};

const windowHasOnlineSessionsFilter = z.object({
  templateVariables: z.object({
    showOnlineSessionsFilter: z.boolean(),
  }),
});

export const getOnlineSessionsFlag = (): boolean => {
  const parsedTemplateVariables = windowHasOnlineSessionsFilter.safeParse(window);

  return (
    parsedTemplateVariables.success &&
    parsedTemplateVariables.data.templateVariables.showOnlineSessionsFilter
  );
};

const windowHasPastTalksFilter = z.object({
  templateVariables: z.object({
    showPastTalksFilter: z.boolean(),
  }),
});

export const getPastTalksFilterFlag = (): boolean => {
  const parsedTemplateVariables = windowHasPastTalksFilter.safeParse(window);

  return (
    parsedTemplateVariables.success &&
    parsedTemplateVariables.data.templateVariables.showPastTalksFilter
  );
};

const windowHasEndorsedByFilter = z.object({
  templateVariables: z.object({
    showEndorsedByFilter: z.boolean(),
  }),
});

export const getEndorsedByFilter = (): boolean => {
  const parsedTemplateVariables = windowHasEndorsedByFilter.safeParse(window);

  return (
    parsedTemplateVariables.success &&
    parsedTemplateVariables.data.templateVariables.showEndorsedByFilter
  );
};

const windowHasPremiumSessionsFilter = z.object({
  templateVariables: z.object({
    showPremiumSessionsFilter: z.boolean(),
  }),
});

export const getPremiumSessionsFlag = (): boolean => {
  const parsedTemplateVariables = windowHasPremiumSessionsFilter.safeParse(window);

  return (
    parsedTemplateVariables.success &&
    parsedTemplateVariables.data.templateVariables.showPremiumSessionsFilter
  );
};
