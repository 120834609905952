import anime from 'animejs';
import clsx from 'clsx';
import last from 'lodash/last';
import random from 'lodash/random';
import { useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import { usePubNub } from 'pubnub-react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { merge } from 'rxjs';
import { debounceTime, distinct, filter,map, throttleTime } from 'rxjs/operators';

import { useTracking } from '~/lib/analytics';
import { RoomReaction } from '~/operations/catalyst';

import ReactionsSprite from './ReactionsSprite';

type MessageType = {
  data: {
    body: string;
  };
  type: 'reaction';
};

export type IncomingMessageType = MessageType & {
  id: string;
  publisher: {
    uuid: string;
  };
  sent_at: string;
  timetoken: string;
};

type AnimateReactionTimelineProps = {
  opacity: number;
  parentElement: HTMLElement;
  reaction: string;
  reactionWidth: number;
  startPositionLeft: number;
  startPositionTop: number;
  translation: number;
};

type ReactionStreamProps = {
  isDisabled?: boolean;
  isRoom?: boolean;
  messages: IncomingMessageType[];
  reactionsLayout?: RoomReaction[];
  reactionsPerSecond?: number;
  sendMessage: (message: MessageType) => void;
};

export const formatReactionMessage = (givenReaction: string): MessageType => {
  return {
    data: { body: givenReaction },
    type: 'reaction',
  };
};

/**
 * Creates, animates and destorys a single reaction DOM node.
 * Do not run outside a side effect lifecycle (`useEffect` or `useSubscription`)
 */
const animateReactionTimeline = ({
  parentElement,
  reaction,
  reactionWidth,
  startPositionLeft,
  startPositionTop,
  translation,
  opacity,
}: AnimateReactionTimelineProps): void => {
  // Don't run outside of browser dom
  if (!window || !window.document) return;

  // Create DOM Nodes
  const reactionParticle = window.document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const useElem = window.document.createElementNS('http://www.w3.org/2000/svg', 'use');
  try {
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `#${reaction.toLowerCase()}`);
    reactionParticle.appendChild(useElem);

    parentElement.appendChild(reactionParticle);

    anime.set(reactionParticle, {
      left: `${startPositionLeft}px`,
      opacity,
      top: `${startPositionTop}px`,
      width: `${reactionWidth}px`,
    });

    anime
      .timeline({
        complete: () => {
          reactionParticle.remove();
        },
        easing: 'easeOutExpo',
        targets: reactionParticle,
      })
      .add({
        duration: 2000,
        translateY: translation,
      })
      .add(
        {
          duration: 700,
          opacity: 0,
        },
        1000,
      );
  } catch (e) {
    reactionParticle.remove();
    useElem.remove();
  }
};

const ReactionStream = ({
  messages,
  isDisabled = false,
  sendMessage,
  reactionsLayout = [],
  isRoom = false,
  reactionsPerSecond = 30,
}: ReactionStreamProps): ReactElement | null => {
  /**
   *  Time between reaction submittions
   * */
  const reactionFrameTimeInMilliSeconds = 1 / reactionsPerSecond;

  // Are we showing reactions?
  const [showReactions, setShowReactions] = useState(true);

  const onVisibilityChange = () => {
    setShowReactions(!document.hidden);
  };

  /**
   * Canvas div to paint reactions on
   * */
  const reactorCanvas = useRef<HTMLDivElement>(null);

  /**
   * Toolbar with reaction buttons
   * */
  const reactorPanel = useRef<HTMLDivElement>(null);

  /**
   * Current user publisher id
   * */
  const publisherUuid = usePubNub().getUUID();

  /*
   * Observables
   *
   * Observables can be best thought of as a stream of events over time
   * You can map, filter, and run any number of functions on a observable,
   * similar to what you would do with an array.
   */

  /**
   * Reactions incoming to client (every time `messages` changes)
   *
   * These reactions are distict from each other, do not include those send by the user,
   * and are throttled by `reactionFrameTimeInMilliSeconds`
   * */
  const incomingReactions$ = useObservable(
    (messageChanges$) =>
      messageChanges$.pipe(
        map(([incomingMessages]) => {
          if (incomingMessages.length > 0) {
            // Only push the latest message
            return last(incomingMessages) ?? null;
          }

          return null;
        }),
        filter(
          (reaction): reaction is IncomingMessageType =>
            reaction !== null && reaction.publisher.uuid !== publisherUuid,
        ),
        distinct((reaction) => reaction.id),
        throttleTime(reactionFrameTimeInMilliSeconds),
      ),
    [messages],
  );

  const [pushOutgoingReaction, outgoingReactions$] = useObservableCallback<MessageType>(
    (rawOutgoingReactions$) => rawOutgoingReactions$,
  );

  /**
   * Our reactions we are pushing out to pubnub
   * They are debounced by `reactionFrameTimeInMilliSeconds`
   * */
  const reactionsToPublish$ = useObservable(() =>
    outgoingReactions$.pipe(debounceTime(reactionFrameTimeInMilliSeconds)),
  );

  /**
   * All reactions we need to display and animate
   * Includes all our reactions, wherether or not we are publishing them,
   * and all filtered incoming reactions
   * */
  const reactionsToDisplay$ = useObservable(() => merge(incomingReactions$, outgoingReactions$));

  /* Effects and Subscriptions */

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);

  // Send reactions to pubnub
  useSubscription(reactionsToPublish$, (reaction) => sendMessage(reaction));

  // Process and animate reactions
  useSubscription(reactionsToDisplay$, (reaction) => {
    if (showReactions) {
      // Run reaction side effect
      const reactionId = reaction.data.body;

      const index = reactionsLayout.findIndex((el) => el === reactionId);

      // Handle null failure states (do nothing)
      if (index === -1) return;
      if (!reactorCanvas.current) return;
      if (!reactorPanel.current) return;

      const maxReactionWidth = reactorPanel.current.offsetWidth / reactionsLayout.length;
      const newReactionWidth = random(10, maxReactionWidth);
      const opacity = random(0.5, 1, true);
      const startPosition = index * maxReactionWidth;
      const dx = (maxReactionWidth - newReactionWidth) / 2;

      animateReactionTimeline({
        opacity,
        parentElement: reactorCanvas.current,
        reaction: reactionId,
        reactionWidth: newReactionWidth,
        startPositionLeft: startPosition + dx,
        startPositionTop: reactorCanvas.current.offsetHeight,
        translation: -random(
          reactorPanel.current.offsetHeight + newReactionWidth,
          reactorCanvas.current.offsetHeight - newReactionWidth,
        ),
      });
    }
  });

  const track = useTracking();

  /* Rendering */

  if (isDisabled) {
    return null;
  }

  return (
    <div className={clsx('reactor')}>
      <div ref={reactorCanvas} className={clsx('reactor-canvas', isRoom && '-room')} />
      <div ref={reactorPanel} className={clsx('reactor__reaction-panel')}>
        {reactionsLayout.map((reaction) => (
          <button
            key={reaction}
            className={clsx('reactor__option')}
            type="button"
            onClick={() => {
              track('Sent reaction', reaction);
              pushOutgoingReaction(formatReactionMessage(reaction));
            }}
          >
            <svg>
              <use href={`#${reaction.toString().toLowerCase()}`} />
            </svg>
          </button>
        ))}
      </div>
      <ReactionsSprite />
    </div>
  );
};

export default ReactionStream;
