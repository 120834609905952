import clsx from 'clsx';
import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import ButtonBase from '~/components/button/ButtonBase';
import AttendeeConnectionButton from '~/components/connections/AttendeeConnectionButton';
import urlFor from '~/lib/urlFor';
import { AttendeeSidebarDetailsFragment } from '~/operations/catalyst';
import Portrait from '~/components/attendees/Portrait';
import ProfileSidebar from '~/components/attendees/ProfileSidebar';
import SafetyControls from '~/components/attendees/SafetyControls';
import ChatIcon from '~/components/icons/ChatIcon';
import ExternalUrls from '~/components/attendees/ExternalUrls';

export type AttendeeProfileSidebarProps = {
  attendance?: AttendeeSidebarDetailsFragment;
  externalUrls?: Record<string, string> | null;
  incidentReportUrl: string;
  loading?: boolean;
};

const AttendeeProfileSidebar = ({
  loading,
  attendance,
  incidentReportUrl,
  externalUrls,
}: AttendeeProfileSidebarProps): ReactElement<AttendeeProfileSidebarProps> => (
  <ProfileSidebar
    actions={
      <>
        <div className="profile-page__external">
          {loading ? <Skeleton count={2} /> : <ExternalUrls externalUrls={externalUrls ?? {}} />}
        </div>
        {attendance && !attendance.blocked && attendance.chatAvailable && (
          <SafetyControls
            attendeeId={attendance.id}
            firstName={attendance.firstName}
            incidentReportUrl={incidentReportUrl}
            isBlocked={attendance.blocked}
          />
        )}
      </>
    }
    stickyContent={
      <>
        <div
          className={clsx('portrait-container', {
            '-blocked': attendance?.blocked,
            '-interactions-unavailable': !attendance?.chatAvailable,
          })}
        >
          <Portrait
            avatarUrl={attendance?.avatarUrl}
            id={attendance?.id}
            isLink={false}
            name={attendance?.name}
            size="profile"
          />
        </div>
        <ProfileSidebar.Interactions>
          {!loading && attendance?.__typename === 'Attendee' ? (
            <>
              {attendance.chatAvailable ? (
                <>
                  <AttendeeConnectionButton id={attendance.id} name={attendance.firstName} />
                  <ButtonBase as="a" href={urlFor(`/my/conversations/?attendee=${attendance.id}`)}>
                    <ChatIcon />
                    <span>Chat</span>
                  </ButtonBase>
                </>
              ) : null}
              {(attendance.blocked || !attendance.chatAvailable) && (
                <SafetyControls
                  attendeeId={attendance.id}
                  firstName={attendance.firstName}
                  incidentReportUrl={incidentReportUrl}
                  isBlocked={attendance.blocked}
                />
              )}
            </>
          ) : (
            <Skeleton height={48} />
          )}
        </ProfileSidebar.Interactions>
      </>
    }
  />
);

export default AttendeeProfileSidebar;
