import type { ReactElement } from 'react';

import { ExibitorHitListType } from '~/types/search';
import ExhibitorCard from '~/components/exhibitors/ExhibitorCard';
import { urlParameters } from '~/lib/utils';

type ExhibitorHitsProps = { hits: ExibitorHitListType };

const imgixSettings = {
  fill: 'color',
  fit: 'fillmax',
  h: 90,
  trim: 'color',
  w: 280,
};

const ExhibitorHits = ({ hits }: ExhibitorHitsProps): ReactElement<ExhibitorHitsProps> => {
  return (
    <div className="directory-list exhibitor-list">
      {hits.map(({ id, industry, logo_urls, name }) => (
        <ExhibitorCard
          key={id}
          heading={name}
          id={id}
          info={industry}
          logoUrl={`${logo_urls.original}&${urlParameters(imgixSettings)}`}
        />
      ))}
    </div>
  );
};

export default ExhibitorHits;
