import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import AttendeeCarousel from '~/components/attendees/AttendeeCarousel';
import { AttendeePartsFragment } from '~/operations/catalyst';

type SimilarPeopleProps = {
  attendees: AttendeePartsFragment[];
  firstName: string;
  loading?: boolean;
};

const SimilarPeople = ({
  attendees,
  firstName,
  loading = false,
}: SimilarPeopleProps): ReactElement<SimilarPeopleProps> | null => {
  const { t } = useTranslation();

  if (loading) {
    return <AttendeeCarousel />;
  }

  if (attendees.length === 0) {
    return null;
  }

  return (
    <AttendeeCarousel
      attendees={attendees}
      title={t('profile.peopleSimilarTo', { name: firstName })}
    />
  );
};

export default SimilarPeople;
