import { ReactElement } from 'react';

import ConfirmationPopover from '~/components/popover/ConfirmationPopover';
import LoadingIcon from '~/components/icons/LoadingIcon';

import { ActionButton, ActionButtonProps } from './ActionButton';

export type ConfirmationButtonProps = ActionButtonProps & {
  cancel?: string;
  confirm?: string;
  confirmation: string;
};

const ConfirmationButton = ({
  confirmation,
  cancel,
  confirm,
  size,
  icon,
  styling,
  disabled,
  text,
  onClick,
}: ConfirmationButtonProps): ReactElement<ConfirmationButtonProps> => {
  return (
    <ConfirmationPopover
      cancelLabel={cancel}
      confirmLabel={confirm}
      text={confirmation}
      onConfirm={onClick}
    >
      {({ active }) => (
        <ActionButton
          disabled={disabled}
          icon={active ? <LoadingIcon /> : icon}
          size={size}
          styling={styling}
          text={text}
        />
      )}
    </ConfirmationPopover>
  );
};

export default ConfirmationButton;
