import axios from 'axios';
import clsx from 'clsx';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { RSVP_API_ENDPOINTS } from '~/endpoints';
import Card from '~/components/perks/Card';
import { authorizationHeader } from '~/lib/authToken';
import { Perk } from '~/types/perks';

type ExhibitorPerksProps = {
  id: string;
  logoUrl?: string;
  title?: string;
};

const getPerks = async (exhibitorAvengerId: string, authorization: string): Promise<Perk[]> => {
  try {
    const {
      data: { data: perks },
    } = await axios.get<{ data: Perk[] }>(RSVP_API_ENDPOINTS.perksByExhibitor(exhibitorAvengerId), {
      headers: {
        Authorization: authorization,
      },
    });

    return perks.filter((event: { event_type: string }) => event.event_type === 'perk');
  } catch (e) {
    return [];
  }
};

const ExhibitorPerks = ({
  id,
  logoUrl,
  title = 'Redeem Our Perk',
}: ExhibitorPerksProps): ReactElement | null => {
  const authorization = authorizationHeader();
  const [perks, setPerks] = useState<Perk[]>([]);

  const refreshPerks = useCallback(async () => {
    const fetchedPerks = await getPerks(id, authorization);

    setPerks(fetchedPerks);
  }, [id, authorization]);

  useEffect(() => {
    void refreshPerks();
  }, [refreshPerks]);

  if (perks.length === 0) {
    return null;
  }

  return (
    <section className="profile-page__pane pane">
      <header className="pane-header">
        <h3 className="pane-header__heading">{title}</h3>
      </header>
      <div className="pane-content">
        <div className={clsx('directory-list', 'perks-list', '-two')}>
          {perks.map((perk) => (
            <Card
              key={perk.id}
              exhibitorId={id}
              logoUrl={logoUrl}
              perk={perk}
              onClaim={refreshPerks}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExhibitorPerks;
