import { ReactElement, SVGProps } from 'react';

const HamburgerIcon = ({ ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      height={24}
      version="1.1"
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="0">
        <path
          d="M3,18 L21,18 L21,16 L3,16 L3,18 Z M3,6 L3,8 L21,8 L21,6 L3,6 Z M3,13 L21,13 L21,11 L3,11 L3,13 Z"
          fill="#333333"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default HamburgerIcon;
