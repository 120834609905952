import { ReactElement } from 'react';
import StatusMessage from '~/components/connections/StatusMessage';
import Button from '~/components/button/Button';
import AcceptIcon from '~/components/icons/AcceptIcon';
import ConnectIcon from '~/components/icons/ConnectIcon';
import { ConnectionState, useAttendeeConnection } from '~/lib/connections';

const getButtonsCopy = {
  [ConnectionState.Connected]: 'Connected',
  [ConnectionState.NotConnected]: 'Connect',
  [ConnectionState.RequestReceived]: 'Accept connection',
  [ConnectionState.RequestSent]: 'Pending',
};

const getButtonOrStatus = {
  [ConnectionState.Connected]: 'status',
  [ConnectionState.NotConnected]: 'button',
  [ConnectionState.RequestReceived]: 'button',
  [ConnectionState.RequestSent]: 'status',
};

const ConnectionStatus = ({ id, className }: { className?: string; id: string }): ReactElement => {
  const { state, connect } = useAttendeeConnection(id);
  const buttonOrStatus = state ? getButtonOrStatus[state] : null;
  const copy = state ? getButtonsCopy[state] : null;

  return (
    <>
      {buttonOrStatus === 'status' ? (
        <StatusMessage
          className={className}
          size="small"
          variant={state === ConnectionState.Connected ? 'primary' : 'secondary'}
        >
          <AcceptIcon />
          {copy}
        </StatusMessage>
      ) : null}
      {buttonOrStatus === 'button' ? (
        <Button
          className={className}
          icon={state === ConnectionState.NotConnected ? <ConnectIcon /> : <AcceptIcon />}
          size="small"
          type="button"
          variant="secondary-alt"
          onClick={connect}
        >
          {copy}
        </Button>
      ) : null}
    </>
  );
};

export default ConnectionStatus;
