import LOADER from '~/../assets/images/loading.gif';

const setupWebSocketConnection = (
  baseUrl: string,
  appUrl: string,
  tokenCallback: (token: string) => void,
  successCallback: (token: string) => void,
): (() => void) => {
  let ws: WebSocket;
  let timeout: number;
  const setATimeout = (delay: number) => {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(
      () => setupWebSocketConnection(baseUrl, appUrl, tokenCallback, successCallback),
      delay,
    );
  };
  if ('WebSocket' in window) {
    ws = new WebSocket(`${baseUrl}/gencode?appUrl=${encodeURIComponent(appUrl)}`);
    const hello = { op: 'hello' };

    ws.onopen = () => {
      ws.send(JSON.stringify(hello));
    };
    ws.onmessage = (evt) => {
      const obj = JSON.parse(evt.data);
      if (obj.op === 'hello') {
        tokenCallback(obj.token);
        setATimeout(60 * 1000);
      } else if (obj.op === 'authdone') {
        ws.close();
        tokenCallback(LOADER);
        successCallback(obj.accessToken);
      } else if (obj.op === 'authfail' || obj.op === 'authrefresh') {
        ws.close();
        tokenCallback(LOADER);
        setATimeout(1000);
      }
    };

    ws.onclose = () => {
      // No cleanup needed
    };
  }
  return () => {
    if (ws?.readyState === WebSocket.OPEN) ws.close();
  };
};

export default setupWebSocketConnection;
