import styled from 'styled-components';

const LoginForm = styled.form`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    font-size: var(--fs-16) !important;
    line-height: var(--fs-24);
  }
`;

export default LoginForm;
