import { ReactElement, SVGProps } from 'react';

type ConversationsIconProps = SVGProps<SVGSVGElement>;

const ConversationsIcon = (props: ConversationsIconProps): ReactElement<ConversationsIconProps> => (
  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M19.2,4 L4.8,4 C3.81,4 3,4.81 3,5.8 L3,22 L6.6,18.4 L19.2,18.4 C20.19,18.4 21,17.59 21,16.6 L21,5.8 C21,4.81 20.19,4 19.2,4 Z"
        fill="#333333"
      />
      <polygon points="0 0 24 0 24 24 0 24" />
    </g>
  </svg>
);

export default ConversationsIcon;
