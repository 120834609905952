import { DateTime } from 'luxon';

/**
 * Converts an ISO 8601 datestring into a localized
 * string showing the day of the month
 * e.g `'2020-06-21' => 'June 21'`
 */
const isoDateToDayOfMonth = (isoStringDate: string): string =>
  DateTime.fromISO(isoStringDate).toLocaleString({ day: 'numeric', month: 'long' });

export default isoDateToDayOfMonth;
