import { ReactElement } from 'react';
import styled from 'styled-components';

import ChatBubbleStartIcon from '~/components/icons/ChatBubbleStartIcon';

const StyledEmptyChannels = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  color: var(--c-darkgrey);
  font-size: var(--fs-sm);

  svg {
    width: 3.25rem;
    height: 3.25rem;
    margin-bottom: 1rem;
    color: var(--c-light-gray);
  }
`;

const EmptyChannels = (): ReactElement => {
  return (
    <StyledEmptyChannels>
      <ChatBubbleStartIcon />
      <p>No chat messages yet</p>
    </StyledEmptyChannels>
  );
};

export default EmptyChannels;
