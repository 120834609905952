import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import bodyMixin from '~/style-mixins/typography/bodyMixin';

const Wrapper = styled.div`
  a {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: calc(28 / 20);
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  padding: 1rem;
  border-radius: 100%;
  border: 1px solid var(--c-silver);
  color: var(--c-brand-primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export type LoginMessageProps = {
  children?: ReactNode;
  icon?: ReactNode;
  title: string;
};

export const LoginParagraph = styled.p`
  ${bodyMixin}
  margin-bottom: 0.5rem;
`;

export const LoginLink = styled.a`
  &&& {
    text-decoration: underline;
    color: var(--c-brand-primary);
  }
`;

const LoginMessage = ({
  title,
  icon,
  children,
}: LoginMessageProps): ReactElement<LoginMessageProps> => {
  return (
    <Wrapper>
      {icon ? <IconWrapper>{icon}</IconWrapper> : null}
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
};

export default LoginMessage;
