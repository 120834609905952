import { CreateSessionOptions } from 'opentok-react';
import { VideoElementCreatedEvent } from 'opentok-react/types/opentok';
import { ComponentType, ReactElement, ReactNode } from 'react';
import { Subtract } from 'utility-types';

import { opentokLog } from '~/lib/loggers';
import { Participant } from '~/types/rooms';

import { EventStreamContext } from './EventStreamContext';
import OpenTokSession from './OpenTokSession';

let exampleTarget: (EventTarget & { muted?: boolean }) | undefined;
let exampleCallback: (value: boolean) => void | undefined;
let timer: number | undefined;

const isVideoEvent = (
  e: VideoElementCreatedEvent | { type: string },
): e is VideoElementCreatedEvent => e.type === 'videoElementCreated';

const intervalCheck = () => {
  if (!exampleTarget || !exampleCallback) return;
  if (exampleTarget.muted) {
    exampleCallback(false);
  } else {
    exampleCallback(true);
    window.clearInterval(timer);
  }
};

const onEvent = (
  event?:
    | VideoElementCreatedEvent
    | { error?: { name: string }; origin: string, type: 'error'; }
    | { type: string },
  callback?: (value: boolean) => void,
) => {
  if (!event) return;
  opentokLog(event);

  if (isVideoEvent(event)) {
    const target = event.element as HTMLMediaElement;

    if (target.muted ?? false) {
       
      console.log('%cVIDEO IS MUTED BECAUSE OF AUTO PLAY', 'color: white; background-color: brown');
      if (callback) {
        callback(false);
        exampleCallback = callback;
        exampleTarget = target;
        window.clearInterval(timer);
        timer = window.setInterval(intervalCheck, 1000);
      }
    } else if (callback) {
      callback(true);
    }
  }
};

const onError = (error: { name: string }, origin: string): void => {
  onEvent({ error, origin, type: 'error' });
};

type OTSessionWrapperProps = {
  fallback?: ReactNode;
  opentok?: CreateSessionOptions;
  participants?: Participant[];
  refreshAccess?: () => Promise<{ opentok: { token: string } } | null>;
};

type OTHOCProps<P> = OTSessionWrapperProps & P;

const eventStreamContextValue = {
  onEvent
};

const withOTSession =
  <P extends Record<string, unknown>>(
    Component: ComponentType<
      Subtract<OTHOCProps<P>, Exclude<OTSessionWrapperProps, 'participants'>>
    >,
  ) =>
  ({
    participants = [],
    opentok,
    refreshAccess,
    fallback,
    ...restProps
  }: OTHOCProps<P>): ReactElement => {
    if (opentok === undefined) {
       
      return fallback ? <>{fallback}</> : <Component {...restProps} />;
    }

    return (
      <EventStreamContext.Provider value={eventStreamContextValue}>
        <OpenTokSession
          apiKey={opentok.apiKey}
          fallback={fallback}
          initialToken={opentok.token}
          loadingDelegate={fallback}
          refreshAccess={refreshAccess}
          sessionId={opentok.sessionId}
          onError={onError}
        >
          { }
          <Component {...restProps} participants={participants} onError={onError} />
        </OpenTokSession>
      </EventStreamContext.Provider>
    );
  };

export default withOTSession;
