import { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Asset } from '~/lib/assets';
import { desktop } from '~/style-mixins/theme';
import PoweredBySummitEngineLink from '~/components/shared/atoms/PoweredBySummitEngineLink';
import Center from '~/components/util/Center';
import Container from '~/components/util/Container';

const Wrapper = styled(Center)`
  min-height: 100vh;
  position: relative;
`;

const Panel = styled(Container)`
  ${({ disableGutters }) => css`
    background-color: var(--c-white);
    padding: 2rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    font-size: var(--fs-sm);
    line-height: calc(20 / 14);

    ${desktop(css`
      padding: 4rem;
      gap: 4rem;
      height: auto;
      width: auto;
      border-radius: 1rem;
      min-width: 48rem;

      ${disableGutters
        ? css`
            gap: 0;
            padding: 0;
          `
        : css`
            gap: 4rem;
            padding: 4rem;
          `}
    `)}
  `}
`;

const SummitEngineLinkPositioned = styled(PoweredBySummitEngineLink)`
  position: absolute;
  bottom: 4rem;
  left: auto;
  right: auto;
`;

const Logo = styled.img<{ $paddedLogo: boolean }>`
  width: 12rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  ${({ $paddedLogo }) =>
    $paddedLogo
      ? css`
          margin-top: 2rem;
          margin-bottom: 2rem;
        `
      : css`
          margin-top: 0;
          margin-bottom: 0;
        `}
`;

export type LoginPanelProps = {
  children: ReactNode;
  disableGutters?: boolean;
  paddedLogo?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const LoginPanel = ({
  children,
  size,
  disableGutters,
  paddedLogo,
}: LoginPanelProps): ReactElement => {
  return (
    <Wrapper>
      <Panel disableGutters={disableGutters} size={size}>
        {Boolean(Asset.logoPrimary) && (
          <Logo
            $paddedLogo={paddedLogo ?? false}
            alt="Conference logo"
            height={56}
            src={Asset.logoPrimary}
            width={192}
          />
        )}
        {children}
      </Panel>
      <SummitEngineLinkPositioned />
    </Wrapper>
  );
};

export default LoginPanel;
