import { ReactElement, SVGProps } from 'react';

type MingleIconProps = SVGProps<SVGSVGElement>;

const MingleConnectionIcon = (props: MingleIconProps): ReactElement<MingleIconProps> => {
  return (
    <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <rect height="24" id="Bounds" width="24" x="0" y="0" />
        <path
          d="M6.81271067,6 L14.0766602,16.1075699 L17.2533562,16.1075699 L15.635137,14.4792822 L17.0547945,13.0596247 L21.109589,17.1144192 L17.0547945,21.1692137 L15.635137,19.7495562 L17.2533562,18.1212685 L13.0341797,18.1212685 L5.98229015,8.27099664 L2,8.27099664 L2,6 L6.81271067,6 Z M7.639,13.584 L8.87,15.305 L6.81271067,18.1692137 L2,18.1692137 L2,15.8982171 L5.98229015,15.8982171 L7.639,13.584 Z M17.0547945,3 L21.109589,7.05479452 L17.0547945,11.109589 L15.635137,9.68993151 L17.2533562,8.06164384 L14.0766602,8.06164384 L12.403,10.388 L11.162,8.662 L13.0341797,6.04794521 L17.2533562,6.04794521 L15.635137,4.41965753 L17.0547945,3 Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
};

export default MingleConnectionIcon;
