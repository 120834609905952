import clsx from 'clsx';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { usePermissions } from '~/lib/rooms';
import { AudienceMember } from '~/lib/rooms/audience';

import Attendee from './Attendee';
import AttendeeSettings from './AttendeeSettings';

export type AttendeeListProps = {
  audience?: AudienceMember[];
  withSettings?: boolean;
};

type AttendeeRowProps = {
  children?: ReactElement;
  isModerator?: boolean;
  member: AudienceMember;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  withSettings?: boolean;
};

const AttendeeRow = ({
  member,
  withSettings,
  isModerator,
  children,
  onMouseEnter = (): void => {
    // no op
  },
  onMouseLeave = (): void => {
    // no op
  },
}: AttendeeRowProps): ReactElement<AttendeeRowProps> => (
  <div
    key={member.id}
    className={clsx('audience-item', `-${member.role?.toLowerCase()}`, member.isHere && '-present')}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Attendee info={member} />
    {children}
    {isModerator && withSettings && <AttendeeSettings participant={member} />}
  </div>
);

const Wrapper = styled.div`
  overflow-y: scroll;
`;

const AttendeeList = ({
  audience = [],
  withSettings = false,
}: AttendeeListProps): ReactElement<AttendeeListProps> => {
  const { isModerator } = usePermissions();
  const [currentUser, ...audienceWithoutCurrentUser] = audience;

  return (
    <Wrapper>
      {currentUser && (
        <AttendeeRow isModerator={isModerator} member={currentUser} withSettings={false} />
      )}
      {audienceWithoutCurrentUser.map((member) => (
        <AttendeeRow
          key={member.id}
          isModerator={isModerator}
          member={member}
          withSettings={withSettings}
        />
      ))}
    </Wrapper>
  );
};

export default AttendeeList;
