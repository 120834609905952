import { ReactElement, SVGProps } from 'react';

type TwitterIconProps = SVGProps<SVGSVGElement>;

const TwitterIcon = (props: TwitterIconProps): ReactElement<TwitterIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <rect height="24" width="24" x="0" y="0" />
      <path
        d="M19.1497896,8.58873693 C19.1612155,8.74622941 19.1612155,8.90375652 19.1612155,9.061249 C19.1612155,13.8649774 15.4492986,19.4 8.66499479,19.4 C6.57487725,19.4 4.63326029,18.8037241 3,17.7687685 C3.296966,17.8024971 3.58247103,17.8137515 3.89086285,17.8137515 C5.61545944,17.8137515 7.20305157,17.240019 8.47082606,16.2612663 C6.8489916,16.2275031 5.48984566,15.1812585 5.02156258,13.7412481 C5.25000879,13.7749767 5.47841984,13.7974856 5.71829187,13.7974856 C6.04950019,13.7974856 6.38074367,13.7524679 6.68910035,13.6737563 C4.99874609,13.3362279 3.73093645,11.8737433 3.73093645,10.1074936 L3.73093645,10.0625106 C4.22203602,10.3325125 4.79315154,10.5012594 5.39843906,10.5237336 C4.40477892,9.87122021 3.7537881,8.75748382 3.7537881,7.49747473 C3.7537881,6.82248718 3.93649585,6.20373704 4.2563135,5.66373315 C6.07231669,7.86873174 8.80203438,9.3087075 11.8629252,9.46623461 C11.8058313,9.19623266 11.7715538,8.91501093 11.7715538,8.63375457 C11.7715538,6.63123147 13.4162399,5 15.4606541,5 C16.5228341,5 17.4822167,5.43874884 18.1560944,6.14749962 C18.9898281,5.99000714 19.7893195,5.68624197 20.4974746,5.27000195 C20.2233251,6.11377102 19.6408541,6.82252181 18.8756401,7.27249042 C19.6180376,7.19377881 20.3375833,6.99123407 21,6.71001234 C20.4975449,7.4299829 19.8693354,8.07120723 19.1497896,8.58873693 Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default TwitterIcon;
