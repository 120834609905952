import axios from 'axios';
import clsx from 'clsx';
import { ReactElement } from 'react';

import { API_ENDPOINTS } from '~/endpoints';
import ExitIcon from '~/components/icons/ExitIcon';
import urlFor from '~/lib/urlFor';

type LogoutButtonProps = {
  className?: string;
};

const LogoutButton = ({ className }: LogoutButtonProps): ReactElement<LogoutButtonProps> => {
  const logout = async (): Promise<void> => {
    const csrfToken = (document.querySelector('meta[name=csrf-token]') as HTMLMetaElement)?.content;
    const { status } = await axios.delete(API_ENDPOINTS.session(), {
      data: { authenticity_token: csrfToken },
    });
    if (status === 204) {
      window.location.href = urlFor('/login');
    }
  };

  return (
    <button className={clsx('btn -danger', className)} type="button" onClick={logout}>
      <ExitIcon />
      Log out
    </button>
  );
};

export default LogoutButton;
