import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

const DesktopIcon = ({
  ref,
  ...props
}: SVGProps<SVGSVGElement>): ReactElement<SVGProps<SVGSVGElement>> => (
  <IconBase version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd" id="Icon-/-SyncDesktop" stroke="none" strokeWidth="1">
      <path
        d="M4,6 L22,6 L22,4 L4,4 C2.9,4 2,4.9 2,6 L2,17 L0,17 L0,20 L14,20 L14,17 L4,17 L4,6 Z M23,8 L17,8 C16.45,8 16,8.45 16,9 L16,19 C16,19.55 16.45,20 17,20 L23,20 C23.55,20 24,19.55 24,19 L24,9 C24,8.45 23.55,8 23,8 Z M22,17 L18,17 L18,10 L22,10 L22,17 Z"
        fill="#333333"
        id="Shape"
      />
    </g>
  </IconBase>
);

export default DesktopIcon;
