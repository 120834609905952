import axios from 'axios';
import { z } from 'zod';

const refreshAccess = async (
  grantAccessUrl: string,
): Promise<{
  opentok: {
    token: string;
  };
} | null> => {
  if (!grantAccessUrl) return null;

  try {
    const response = await axios.post<unknown>(grantAccessUrl);
    const {
      data: {
        room_props: {
          opentok: { token: newToken },
        },
      },
    } = z
      .object({
        data: z.object({
          room_props: z.object({
            opentok: z.object({
              token: z.string(),
            }),
          }),
        }),
      })
      .parse(response.data);
    return {
      opentok: {
        token: newToken,
      },
    };
  } catch (_e) {
    return null;
  }
};
export default refreshAccess;
