import { ReactElement, ReactNode } from 'react';

export type AttendeeCardListProps = {
  children: ReactNode;
  title: string;
};

const AttendeeCardList = ({ title, children }: AttendeeCardListProps): ReactElement => {
  return (
    <div className="profile-page__pane pane">
      <header className="pane-header">
        <h3 className="pane-header__heading">{title}</h3>
      </header>
      <div className="pane-content">
        <div className="directory-list attendee-list">{children}</div>
      </div>
    </div>
  );
};

export default AttendeeCardList;
