import { PubnubMessage } from '~/types/pubnub';
import { MinimalOccupant, Participant } from '~/types/rooms';

export enum Mode {
  content = 'content',
  live = 'live',
  offline = 'offline',
}

export type SetModeAction = {
  setMode: Mode;
};

export type EvictOccupantAction = {
  evictOccupant: MinimalOccupant;
};

export type ChatMessage = {
  author: Participant;
  body: string;
};

export type PinMessageAction = {
  pinMessage: ChatMessage | null;
};

export type ShowCountdownAction = {
  endAt: number;
};

export type ModeratorAction =
  | SetModeAction
  | EvictOccupantAction
  | PinMessageAction
  | ShowCountdownAction;

export type ModerationAction = {
  data: ModeratorAction;
  type: 'moderation';
};

export type IncomingModerationAction = PubnubMessage & ModerationAction;
