import { ReactElement, useCallback, useEffect, useState } from 'react';

import { firstName, plural, sentence } from '~/lib/stringutil';
import { Participant, PubnubClientConfig } from '~/types/rooms';

import Stack from '../attendees/Stack';
import usePubnub from '../realtime/usePubnub';

const REFRESH_INTERVAL = 20000;

const usePreview = ({
  config,
  channel,
}: LobbyPreviewProps): {
  occupancy: number | undefined;
  occupants: Participant[] | undefined;
} => {
  const { pubnub } = usePubnub(config);
  const [occupancy, setOccupancy] = useState<number>();
  const [occupants, setOccupants] = useState<Participant[]>();

  const refresh = useCallback(() => {
    if (!pubnub) {
      return;
    }

    pubnub.hereNow(
      {
        channels: [channel],
        includeState: true,
        includeUUIDs: true,
        queryParameters: { limit: 6 },
      },
      (_status, response) => {
        const ch = response.channels[channel];
        setOccupancy(ch.occupancy);
        setOccupants(ch.occupants.map((o) => o.state).filter(Boolean));
      },
    );
  }, [channel, pubnub]);

  useEffect(() => {
    refresh();
    const handle = setInterval(refresh, REFRESH_INTERVAL);
    return () => clearInterval(handle);
  }, [refresh]);

  return {
    occupancy,
    occupants,
  };
};

const NAME_COUNT = 3;

export type LobbyPreviewProps = {
  channel: string;
  config: PubnubClientConfig;
};

const LobbyPreview = ({ channel, config }: LobbyPreviewProps): ReactElement => {
  const { occupants, occupancy } = usePreview({ channel, config });

  if (typeof occupants === 'undefined' || typeof occupancy === 'undefined') {
    return <span className="lobby__preview">Looking for people in the room...</span>;
  }

  if (occupancy < 1) {
    return <span className="lobby__preview">There is no-one in this room yet.</span>;
  }

  const words = occupants.slice(0, NAME_COUNT - 1).map(({ name }) => firstName(name));
  const extra = Math.max(0, occupancy - NAME_COUNT);

  if (extra > 0) {
    words.push(`${extra} more`);
  }

  return (
    <div className="lobby__preview">
      <Stack participants={occupants} size="sm" />
      <span className="lobby__names">{`${sentence(words)} ${plural(
        occupancy,
      )} in this room.`}</span>
    </div>
  );
};

export default LobbyPreview;
