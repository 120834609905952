import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { exportLeads, useListLeads } from '~/lib/avenger/api';
import Button from '../button/Button';
import StandaloneFlashNotification from '../notifications/StandaloneFlashNotification';
import Pagination from '../search/atoms/Pagination';
import ErrorScreen from '../shared/molecules/ErrorScreen';

export type ExhibitorLeadsProps = {
  appearanceId: string;
};

const ExhibitorLeads = ({ appearanceId }: ExhibitorLeadsProps) => {
  const [page, setPage] = useState(1);
  const { data: leads, error } = useListLeads(appearanceId, page);
  const [toast, setToast] = useState<{ title: string; message: string }>();

  if (error) {
    return <ErrorScreen />;
  }

  if (!leads) {
    return <Skeleton count={20} height="2rem" />;
  }

  const handleExportLeads = async () => {
    await exportLeads(appearanceId);
    setToast({
      title: 'Check your email',
      message: 'We have sent you a CSV file export of your collected leads.',
    });
  };

  return (
    <section className="pane">
      <header className="pane-header">
        <h2 className="pane-header__heading">Leads</h2>
        <nav className="pane-header__nav">
          <Button onClick={handleExportLeads}>
            Export leads
          </Button>
        </nav>
      </header>
      <div className="pane-content">
        {leads.length === 0 && (
          <div className="empty-block">
            You have not collected any leads yet. We&apos;ll display them here once you have.
          </div>
        )}
        {leads.length > 0 && (
          <>
            <table className="simple-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Source</th>
                  <th>Author</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead) => (
                  <tr key={lead.id}>
                    <td>{lead.subject.name}</td>
                    <td>{lead.subject.company_name}</td>
                    <td>{lead.source}</td>
                    <td>{lead.author?.name}</td>
                    <td>{lead.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={page}
              handleDecrease={() => setPage(page - 1)}
              handleIncrease={() => setPage(page + 1)}
              totalPages={leads.length < 20 ? page : page + 1}
             />
          </>
        )}
      </div>
      {toast && (
        <StandaloneFlashNotification
          fadeOutTimeout={10_000}
          header={toast.title}
          message={toast.message}
          type="success"
          onDismiss={() => setToast(undefined)}
        />
      )}
    </section>
  );
};

export default ExhibitorLeads;
