import clsx from 'clsx';
import { ReactElement } from 'react';

import AcceptIcon from '../icons/AcceptIcon';
import InteractButton from '../shared/atoms/InteractButton';

export type RequestSentButtonProps = {
  className?: string;
};

const RequestSentButton = ({
  className,
}: RequestSentButtonProps): ReactElement<RequestSentButtonProps> => {
  return (
    <InteractButton isStatic className={clsx('interact-btn -connection', className)}>
      <AcceptIcon />
      <span>Requested</span>
    </InteractButton>
  );
};

export default RequestSentButton;
