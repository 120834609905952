import clsx from 'clsx';
import { DateTime, Interval } from 'luxon';
import { ReactElement } from 'react';

import LiveIcon from '~/components/icons/LiveIcon';

type TimestampProps = {
  className?: string;
  endDateIso: string;
  showLiveIcon?: boolean;
  startDateIso: string;
};

const Timestamp = ({
  endDateIso,
  startDateIso,
  className,
  showLiveIcon = false,
}: TimestampProps): ReactElement<TimestampProps> => {
  const startDate = DateTime.fromISO(startDateIso);
  const endDate = DateTime.fromISO(endDateIso);

  const sessionInterval = Interval.fromDateTimes(startDate, endDate);
  const isLive = sessionInterval.contains(DateTime.local());

  const formattedStartDate = startDate.setLocale('en-US').toFormat('d MMMM');
  const formattedStartTime = startDate.setLocale('en-US').toFormat('h:mma');
  const formattedEndTime = endDate.setLocale('en-US').toFormat('h:mma');

  const fullDate = `${formattedStartDate}   \u00B7   ${formattedStartTime} \u2014 ${formattedEndTime}`;

  return (
    <div className={clsx('timestamp', className)}>
      {showLiveIcon && isLive ? (
        <LiveIcon className="timestamp__live" />
      ) : null}
      <span>{fullDate}</span>
    </div>
  );
};

export default Timestamp;
