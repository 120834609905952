/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { ReactElement } from 'react';

import { useTracking } from '~/lib/analytics';
import { Attendee } from '~/operations/catalyst';
import Stack from '~/components/attendees/Stack';
import Modal from '~/components/others/Modal';

import Linkify from 'linkify-react';
import Pinnable from './Pinnable';
import TimeslotInfo from './TimeslotInfo';
import Timestamp from './Timestamp';

const NUMBER_OF_FRONT_LINE_PARTICIPANTS = 3;

type TimeslotParticipant = Pick<Attendee, 'id' | 'name' | 'companyName' | 'avatarUrl'>;

type TimeslotSponsor = {
  id: string;
  logoUrl: string;
  name: string;
};

type TimeslotCardProps = {
  calendarEventId?: string;
  className?: string;
  description?: string;
  endDateIso?: string;
  ended: boolean;
  id?: string;
  link?: string;
  participants?: TimeslotParticipant[];
  sponsor?: TimeslotSponsor;
  startDateIso?: string;
  title: string;
  withModal?: boolean;
};

const TimeslotCard = ({
  id,
  calendarEventId,
  title,
  endDateIso,
  startDateIso,
  ended,
  participants,
  link,
  sponsor,
  description,
  className,
  withModal = false,
}: TimeslotCardProps): ReactElement<TimeslotCardProps> => {
  const rootClassName = clsx('timeslot-card', ended && 'timeslot-card--disabled', className);
  const track = useTracking();

  const innerContent = (
    <>
      {!!calendarEventId && <Pinnable calendarEventId={calendarEventId} />}
      {endDateIso && startDateIso && (
        <Timestamp showLiveIcon endDateIso={endDateIso} startDateIso={startDateIso} />
      )}
      <h1 className="timeslot-card__heading">{title}</h1>
      <TimeslotInfo
        participantLimit={NUMBER_OF_FRONT_LINE_PARTICIPANTS}
        participants={participants}
      />
      {sponsor ? (
        <div className="timeslot-card__logo">
          <img key={sponsor.name} alt={sponsor.name} src={sponsor.logoUrl} />
        </div>
      ) : (
        <Stack
          limit={NUMBER_OF_FRONT_LINE_PARTICIPANTS}
          participants={participants ?? []}
          size="lg"
        />
      )}
    </>
  );

  if (withModal) {
    return (
      <Modal
        modalTrigger={({ openModal }) => (
          <div className={rootClassName} role="button" onClick={openModal}>
            {innerContent}
          </div>
        )}
        size="lg"
      >
        {() => (
          <div className="timeslot-card -modal">
            {innerContent}
            {description && (
              <div className="timeslot-card__description">
                <Linkify>{description}</Linkify>
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }

  if (link) {
    return (
      <div className={rootClassName}>
        <a className="timeslot-card__link" href={link}>
          Join session
        </a>
        {innerContent}
      </div>
    );
  }

  return (
    <div
      className={rootClassName}
      onClick={() => track('Clicked timeslot card', `${title} (${id})`)}
    >
      {innerContent}
    </div>
  );
};

export default TimeslotCard;
