import { ReactElement } from 'react';
import styled from 'styled-components';

import { Participation } from '~/types/search';
import Participant from '~/components/search/atoms/Participant';

type ParticipantListProps = {
  className?: string;
  participations: Participation[];
};

const ParticipantListItem = styled(Participant)`
  display: none;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    flex: 1 33%;
    display: inherit;
  }
`;

const ParticipantListBase = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  overflow: hidden;
`;

const ParticipantList = ({
  className,
  participations,
}: ParticipantListProps): ReactElement<ParticipantListProps> => (
  <ParticipantListBase className={className}>
    {participations.map((participation) => (
      <ParticipantListItem
        key={`timeslot_participation::${participation.id}`}
        participation={participation}
      />
    ))}
  </ParticipantListBase>
);

export default ParticipantList;
