import { ReactElement } from 'react';

import Login, { LoginProps } from '~/components/login/Login';
import LoginContext, { Login as LoginContextType } from '~/components/login/LoginContext';
import { useTitle } from '~/lib/dom';

export type LoginPageProps = LoginContextType & LoginProps;

const LoginPage = ({ defaultMethod, fallbackMethod, ...props }: LoginPageProps): ReactElement => {
  useTitle('Login');

  return (
    <LoginContext.Provider value={props}>
      <Login defaultMethod={defaultMethod} fallbackMethod={fallbackMethod} />
    </LoginContext.Provider>
  );
};

export default LoginPage;
