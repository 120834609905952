import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import VideoIcon from '~/components/icons/VideoIcon';

type JoinCallMessageProps = {
  link: string;
  name: string;
};

const JoinCallMessage = ({
  link,
  name,
}: JoinCallMessageProps): ReactElement<JoinCallMessageProps> => {
  const { t } = useTranslation();

  return (
    <div className="chat-stream__video-call">
      <div>{t('chat.startedVideoCall', { name })}</div>
      <a className="btn -svg" href={link} rel="noreferrer" target="_blank">
        <VideoIcon />
        <span>{t('chat.joinVideoCall')}</span>
      </a>
    </div>
  );
};

export default JoinCallMessage;
