import { ReactElement, SVGProps } from 'react';

type AudienceIconProps = SVGProps<SVGSVGElement>;

const AudienceIcon = (props: AudienceIconProps): ReactElement<AudienceIconProps> => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M16,11 C17.66,11 18.99,9.66 18.99,8 C18.99,6.34 17.66,5 16,5 C14.34,5 13,6.34 13,8 C13,9.66 14.34,11 16,11 Z M8,11 C9.66,11 10.99,9.66 10.99,8 C10.99,6.34 9.66,5 8,5 C6.34,5 5,6.34 5,8 C5,9.66 6.34,11 8,11 Z M8,13 C5.67,13 1,14.17 1,16.5 L1,19 L15,19 L15,16.5 C15,14.17 10.33,13 8,13 Z M16,13 C15.71,13 15.38,13.02 15.03,13.05 C16.19,13.89 17,15.02 17,16.5 L17,19 L23,19 L23,16.5 C23,14.17 18.33,13 16,13 Z"
      fill="#000000"
    />
  </svg>
);

export default AudienceIcon;
