import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { COUNTRY_FILTER_LIMIT } from '../constants';
import ConnectedRefinementList from './ConnectedRefinementList';
import ConnectedToggleRefinementList from './ConnectedToggleRefinementList';

const AttendeesSearchFilters = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="search-filters">
      <ConnectedToggleRefinementList attribute="role" label={t('explore.filters.role.label')} />
      <ConnectedRefinementList
        attribute="industry"
        label={t('explore.filters.industry.label')}
        limit={100}
        placeholderText={t('explore.filters.industry.placeholder')}
      />
      <ConnectedRefinementList
        attribute="country"
        label={t('explore.filters.location.label')}
        limit={COUNTRY_FILTER_LIMIT}
        placeholderText={t('explore.filters.location.placeholder')}
      />
    </div>
  );
};

export default AttendeesSearchFilters;
