import { css } from 'styled-components';

const displayTitleMixin = css`
  font-weight: 600;
  font-size: var(--fs-display);
  line-height: var(--lh-display);
  letter-spacing: normal;
`;

export default displayTitleMixin;
