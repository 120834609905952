import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_ENDPOINTS } from '~/endpoints';
import { PubnubClientConfig } from '~/types/rooms';

const useNotifications = (
  enabled = true,
): {
  channel: string;
  pubnubConfig: PubnubClientConfig;
} => {
  const [pubnubConfig, setPubnubConfig] = useState<PubnubClientConfig>({
    authKey: '',
    publishKey: '',
    subscribeKey: '',
    uuid: '',
  });
  const [channel, setChannel] = useState('');

  useEffect(() => {
    if (!enabled) {
      return;
    }

    async function getData() {
      try {
        const {
          data: {
            data: {
              notifications_channel_id: notificationsChannel,
              pubnub: {
                auth_key: authKey,
                publish_key: publishKey,
                subscribe_key: subscribeKey,
                uuid,
              },
            },
          },
        } = await axios.get(API_ENDPOINTS.notificationsConfig());
        setChannel(notificationsChannel);
        setPubnubConfig({
          authKey,
          publishKey,
          subscribeKey,
          uuid,
        });
      } catch (_e) {
        // need better logger
        console.error(`Notifications error: ${_e}`);
      }
    }

    void getData();
  }, [enabled]);

  return {
    channel,
    pubnubConfig,
  };
};
export default useNotifications;
