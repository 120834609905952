import { css } from 'styled-components';

const headlineMixin = css`
  font-weight: 600;
  font-size: var(--fs-headline);
  line-height: var(--lh-headline);
  letter-spacing: normal;
`;

export default headlineMixin;
