import { ReactElement, useMemo } from 'react';
import styled from 'styled-components';

import AttendeeRoleTag from '~/components/attendees/AttendeeRoleTag';
import { useFilteredInterlocutors } from '~/lib/rooms/interlocutors';
import { formatCompanyInfo } from '~/lib/utils';
import { ChatParticipants } from '~/types/chat';

import Portrait from '../attendees/Portrait';
import AttendeeConnectionButton from '../connections/AttendeeConnectionButton';

const StyledChatHeader = styled.header`
  border-bottom: 1px solid var(--c-silver);

  h2,
  h3 {
    font-size: var(--fs-xl);
  }
`;

const AccreditationBanner = styled.div`
  background-size: cover;
  background-position: center;
  height: var(--s-conversation-profile-cover-photo-y);
  background-color: var(--c-silver);
`;

const DetailsContainer = styled.div`
  display: flex;
  padding: 0 1rem;
`;

const Avatar = styled(Portrait)`
  margin-top: -1.25rem;
  align-self: flex-start;
  box-shadow: var(--c-white) 0 0 0 0.25em;
`;

const Details = styled.div`
  padding: 1rem 0 1rem 1rem;
  display: flex;
  flex: 1;
  font-size: var(--fs-sm);
  align-items: flex-start;
`;

const ParticipantDetails = styled.div`
  flex: 1;
  .-info {
    color: var(--c-grey);
  }
  .-bold {
    font-weight: 600;
  }
`;

const Name = styled.p`
  font-weight: 600;
`;

const Title = styled.p`
  color: var(--c-grey);
`;

const Interactions = styled.div`
  display: flex;
  .interact-btn,
  .btn {
    width: auto;
    padding: 0.6rem;
    margin-left: 1rem;
  }
`;

export interface ChatHeaderProps {
  participants: ChatParticipants;
  userIdentity: string;
}
const ChatHeader = ({
  participants,
  userIdentity,
}: ChatHeaderProps): ReactElement => {
  const { interlocutors } = useFilteredInterlocutors(userIdentity, participants);

  // For now, there is no desktop group chats support
  const [singleParticipant] = interlocutors;

  const participant = useMemo(() => {
    const { name, profile } = singleParticipant;
    return {
      avatarUrl: profile.avatarUrl,
      companyName: profile.companyName,
      id: profile.id,
      jobTitle: profile.jobTitle,
      name,
      role: profile.role,
    };
  }, [singleParticipant]);

  const { name, jobTitle, companyName, role, id, avatarUrl } = participant;

  return (
    <StyledChatHeader>
      <AccreditationBanner className={`accreditation -${role?.toLocaleLowerCase()}`} />
      <DetailsContainer>
        <Avatar avatarUrl={avatarUrl} id={id} size="xl" />
        <Details>
          <ParticipantDetails>
            <Name>
              {name}
              <AttendeeRoleTag accreditation={role} visibleAccreditations={['Staff']} />
            </Name>
            <Title>{formatCompanyInfo(jobTitle, companyName)}</Title>
          </ParticipantDetails>
          <Interactions>
            <AttendeeConnectionButton id={participant.id} name={participant.name} />
          </Interactions>
        </Details>
      </DetailsContainer>
    </StyledChatHeader>
  );
};

export default ChatHeader;
