import { ReactElement } from 'react';

import { Attendee } from '~/operations/catalyst';

type TimeslotParticipant = Pick<Attendee, 'id' | 'name' | 'companyName' | 'avatarUrl'>;

type ParticipantInfoSuccess = {
  frontLineParticipants: TimeslotParticipant[];
  numberOfBackLineParticipants: number;
  typename: 'participant_info_success';
};

type ParticipantInfoFail = {
  typename: 'participant_info_fail';
};

type ParticipantInfo = ParticipantInfoSuccess | ParticipantInfoFail;

type TimeslotInfoProps = {
  participantLimit?: number;
  participants?: TimeslotParticipant[];
};

const TimeslotInfo = ({ participants, participantLimit = 3 }: TimeslotInfoProps): ReactElement | null => {
  const participantInfo: ParticipantInfo = participants
    ? {
        frontLineParticipants: participants.slice(0, participantLimit),
        numberOfBackLineParticipants:
          participants.length >= participantLimit ? participants.length - participantLimit : 0,
        typename: 'participant_info_success',
      }
    : {
        typename: 'participant_info_fail',
      };

  if (participantInfo.typename === 'participant_info_fail') return null;

  const speakerListText = participantInfo.frontLineParticipants
    .map(({ name, companyName }) => {
      if (!companyName) {
        return name;
      }

      return `${name} (${companyName})`;
    })
    .join(', ');

  let infoText = speakerListText;
  if (participantInfo.numberOfBackLineParticipants === 1) {
    infoText = `${speakerListText}, + 1 speaker`;
  } else if (participantInfo.numberOfBackLineParticipants > 0) {
    infoText = `${speakerListText}, + ${participantInfo.numberOfBackLineParticipants} speakers`;
  }

  return <p className="timeslot-card__info">{infoText}</p>;
};

export default TimeslotInfo;
