import { ApolloProvider } from '@apollo/client';
import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Router } from 'wouter';
import Spinner from '~/components/loading/Spinner';

import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { TrackingContext } from '~/lib/analytics';
import { useAlgoliaSettings } from '~/lib/avenger/api';
import { useTitle } from '~/lib/dom';
import urlFor from '~/lib/urlFor';
import ChatProvider from '~/components/chat/ChatProvider';
import Conversations from '~/components/conversations/Conversations';
import AlgoliaSearchProvider from '~/components/search/providers/AlgoliaSearchProvider';
import { init } from '~/lib/apollo';

const trackingContextProvider = { category: 'Chat' };

const ChatPage = (): ReactElement => {
  useTitle('Chats');

  const client = init();
  const { data, error } = useAlgoliaSettings();

  if (error) {
    return <ErrorScreen />;
  }

  if (!data) {
    return <Spinner />;
  }

  return (
    <ErrorBoundary fallback={<ErrorScreen />}>
      <TrackingContext.Provider value={trackingContextProvider}>
        <AlgoliaSearchProvider
          appId={data.app_id}
          replicaName={data.indexes.attendances}
          searchAPIKey={data.key}
        >
          <ApolloProvider client={client}>
            <ChatProvider>
              <Router base={urlFor('/my/conversations')}>
                <Conversations />
              </Router>
            </ChatProvider>
          </ApolloProvider>
        </AlgoliaSearchProvider>
      </TrackingContext.Provider>
    </ErrorBoundary>
  );
};

export default ChatPage;
