import { ReactElement, SVGProps } from 'react';

type HandIconProps = SVGProps<SVGSVGElement>;

const HandIcon = (props: HandIconProps): ReactElement<HandIconProps> => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M19.3333333,6.58333333 L19.3333333,18.6666667 C19.3333333,20.5 17.8333333,22 16,22 L9.91666667,22 C9.01666667,22 8.16666667,21.6416667 7.54166667,21.0083333 L1,14.3583333 C1,14.3583333 2.05,13.3333333 2.08333333,13.3166667 C2.26666667,13.1583333 2.49166667,13.075 2.74166667,13.075 C2.925,13.075 3.09166667,13.125 3.24166667,13.2083333 C3.275,13.2166667 6.83333333,15.2583333 6.83333333,15.2583333 L6.83333333,5.33333333 C6.83333333,4.64166667 7.39166667,4.08333333 8.08333333,4.08333333 C8.775,4.08333333 9.33333333,4.64166667 9.33333333,5.33333333 L9.33333333,11.1666667 L10.1666667,11.1666667 L10.1666667,3.25 C10.1666667,2.55833333 10.725,2 11.4166667,2 C12.1083333,2 12.6666667,2.55833333 12.6666667,3.25 L12.6666667,11.1666667 L13.5,11.1666667 L13.5,4.08333333 C13.5,3.39166667 14.0583333,2.83333333 14.75,2.83333333 C15.4416667,2.83333333 16,3.39166667 16,4.08333333 L16,11.1666667 L16.8333333,11.1666667 L16.8333333,6.58333333 C16.8333333,5.89166667 17.3916667,5.33333333 18.0833333,5.33333333 C18.775,5.33333333 19.3333333,5.89166667 19.3333333,6.58333333 Z" />
  </svg>
);

export default HandIcon;
