import { ReactElement, SVGProps } from 'react';
import IconBase from './IconBase';

const DownloadIcon = ({
  ref,
  ...props
}: SVGProps<SVGSVGElement>): ReactElement<SVGProps<SVGSVGElement>> => (
  <IconBase version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M20,20 L20,22 L4,22 L4,20 L20,20 Z M13,2 L13,14.17 L18.59,8.59 L20,10 L12,18 L4,10 L5.42,8.58 L11,14.17 L11,2 L13,2 Z"
        fill="currentColor"
      />
    </g>
  </IconBase>
);

export default DownloadIcon;
