import { ReactElement } from 'react';
import styled from 'styled-components';

import ArrowDownIcon from '~/components/icons/ArrowDownIcon';

type RoomNewMessagesProps = {
  counter: number;
  readNewMessages: () => void;
};

const Button = styled.button`
  position: absolute;
  bottom: 4.5rem;
  background-color: var(--c-brand-primary);
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  font-size: var(--fs-xs);
  color: var(--c-white);
  border-radius: 4px;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  svg {
    width: 1.5rem;
    margin-right: 0.5rem;

    path {
      fill: var(--c-white);
    }
  }
`;

const RoomNewMessages = ({
  counter,
  readNewMessages,
}: RoomNewMessagesProps): ReactElement<RoomNewMessagesProps> => {
  return (
    <Button type="button" onClick={readNewMessages}>
      <ArrowDownIcon />
      {`${counter} new ${counter === 1 ? 'message' : 'messages'}`}
    </Button>
  );
};

export default RoomNewMessages;
