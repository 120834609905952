import { ApolloProvider } from '@apollo/client';
import { ReactElement } from 'react';

import { TrackingContext } from '~/lib/analytics';
import MingleComponent, { MingleProps as MingleComponentProps } from '~/components/mingle/Mingle';
import { init } from '~/lib/apollo';

const trackingContextValue = { category: 'Mingle' };

type MingleProps = {
  apiUrl: string;
} & MingleComponentProps;

const Mingle = ({ apiUrl, ...restProps }: MingleProps): ReactElement<MingleProps> => {
  const client = init({ uri: apiUrl });
  return (
    <TrackingContext.Provider value={trackingContextValue}>
      <ApolloProvider client={client}>
        <MingleComponent
           
          {...restProps}
        />
      </ApolloProvider>
    </TrackingContext.Provider>
  );
};

export default Mingle;
