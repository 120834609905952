import { ReactElement, SVGProps } from 'react';

type PerkOffIconProps = SVGProps<SVGSVGElement>;

const PerkOffIcon = (props: PerkOffIconProps): ReactElement<PerkOffIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M11,2 C11.55,2 12.05,2.22 12.41,2.58 L12.41,2.58 L21.41,11.58 C21.77,11.94 22,12.45 22,13 C22,13.55 21.78,14.05 21.41,14.41 L21.41,14.41 L14.41,21.41 C14.05,21.78 13.55,22 13,22 C12.45,22 11.95,21.78 11.59,21.42 L11.59,21.42 L2.59,12.42 C2.22,12.05 2,11.55 2,11 L2,11 L2,4 C2,2.9 2.9,2 4,2 L4,2 Z M11,3.99 L11,4 L4,4 L4,11 L13,20.01 L20,12.99 L11,3.99 Z M6.5,5 C7.32842712,5 8,5.67157288 8,6.5 C8,7.32842712 7.32842712,8 6.5,8 C5.67157288,8 5,7.32842712 5,6.5 C5,5.67157288 5.67157288,5 6.5,5 Z"
        fill="#333333"
      />
    </g>
  </svg>
);

export default PerkOffIcon;
