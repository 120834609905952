import { OTPublisher } from 'opentok-react';
import { StreamCreatedEvent, StreamDestroyedEvent } from 'opentok-react/types/opentok';
import { ReactElement, useContext } from 'react';

import { usePublisher } from '~/lib/rooms';

import { publisherOptions } from '../../configs/opentokConfig';
import { EventStreamContext } from './EventStreamContext';
import { OpenTokContext } from './OpenTokSession';
import { useAudioLevel } from './room_elements/AudioLevelIndicator';
import VideoStream from './VideoStream';

type WebcamVideoPublisherProps = {
  avatarUrl?: string;
  userName: string;
};

const WebcamVideoPublisher = ({
  userName,
  avatarUrl,
}: WebcamVideoPublisherProps): ReactElement<WebcamVideoPublisherProps> => {
  const { audioLevelUpdated, volumeLevel } = useAudioLevel();
  const { isPublishingAudio, isPublishingVideo, isRaisingHand, videoSource } = usePublisher();

  const { onEvent } = useContext(EventStreamContext);
  const { session, setPublishError } = useContext(OpenTokContext);

  const publisherEventHandlers = {
    accessDialogClosed: onEvent,
    accessDialogOpened: onEvent,
    audioLevelUpdated,
    destroyed: onEvent,
    mediaStopped: onEvent,
    streamCreated: (e: StreamCreatedEvent) => {
      onEvent(e);
    },
    streamDestroyed: (e: StreamDestroyedEvent) => {
      onEvent(e);
    },
    videoElementCreated: onEvent,
  };

  const onInit = () => {
    onEvent({ type: 'initSuccess' });
  };

  const onPublish = () => {
    onEvent({ type: 'publishSuccess' });
  };

  return (
    <VideoStream
      avatarUrl={avatarUrl}
      isPublishingAudio={isPublishingAudio}
      isPublishingVideo={isPublishingVideo}
      isRaisingHand={isRaisingHand}
      name={userName}
      volumeLevel={volumeLevel}
    >
      {session ? (
        <OTPublisher
          eventHandlers={publisherEventHandlers}
          properties={{
            ...publisherOptions,
            name: userName,
            publishAudio: isPublishingAudio,
            publishVideo: isPublishingVideo,
            videoSource,
          }}
          session={session}
          onError={setPublishError}
          onInit={onInit}
          onPublish={onPublish}
        />
      ) : null}
    </VideoStream>
  );
};

export default WebcamVideoPublisher;
