import clsx from 'clsx';
import { ReactElement } from 'react';

import ConnectIcon from '../icons/ConnectIcon';
import InteractButton from '../shared/atoms/InteractButton';

export type NotConnectedButtonProps = {
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
};

const NotConnectedButton = ({
  className,
  onClick,
  isDisabled,
}: NotConnectedButtonProps): ReactElement<NotConnectedButtonProps> => {
  return (
    <InteractButton
      className={clsx('-connection', className)}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <ConnectIcon />
      <span>Connect</span>
    </InteractButton>
  );
};

export default NotConnectedButton;
