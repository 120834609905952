import clsx from 'clsx';
import { MouseEventHandler, ReactElement, ReactNode } from 'react';

type TabSwitcherLinkProps = {
  children?: ReactNode;
  className?: string;
  href: string;
  selected?: boolean;
};

type TabSwitcherButtonProps = {
  children?: ReactNode;
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
};

type TabSwitcherChild =
  | ReactElement<TabSwitcherLinkProps>
  | ReactElement<TabSwitcherButtonProps>
  | boolean
  | undefined;

type TabSwitcherProps = {
  children?: TabSwitcherChild | TabSwitcherChild[];
  className?: string;
};

const getButtonClassname = (current?: boolean, className?: string) =>
  clsx('tab-switcher__button', (current ?? false) && 'tab-switcher__button--selected', className);

const TabSwitcher = ({ children, className }: TabSwitcherProps): ReactElement<TabSwitcherProps> => (
  <nav className={clsx('tab-switcher', className)}>{children}</nav>
);

TabSwitcher.Link = ({
  href,
  children,
  selected,
  className,
}: TabSwitcherLinkProps): ReactElement<TabSwitcherLinkProps> => (
  <a className={getButtonClassname(selected, className)} href={href}>
    {children}
  </a>
);

TabSwitcher.Button = ({
  children,
  onClick,
  className,
  selected,
}: TabSwitcherButtonProps): ReactElement<TabSwitcherButtonProps> => (
  <button className={getButtonClassname(selected, className)} type="button" onClick={onClick}>
    {children}
  </button>
);

export default TabSwitcher;
