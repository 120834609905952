import {
  EvictOccupantAction,
  ModerationAction,
  ModeratorAction,
  PinMessageAction,
  SetModeAction,
  ShowCountdownAction,
} from '~/types/rooms-moderation';

export const formatModeratorActionMessage = (
  givenModeration: ModeratorAction,
): ModerationAction => ({
  data: givenModeration,
  type: 'moderation',
});

export const isSetModeAction = (action: ModeratorAction): action is SetModeAction => {
  return (action as SetModeAction).setMode !== undefined;
};

export const isEvictOccupantAction = (action: ModeratorAction): action is EvictOccupantAction => {
  return (action as EvictOccupantAction).evictOccupant !== undefined;
};

export const isPinMessageAction = (action: ModeratorAction): action is PinMessageAction => {
  return (action as PinMessageAction).pinMessage !== undefined;
};

export const isCountdownActionMessage = (
  action: ModeratorAction,
): action is ShowCountdownAction => {
  return (action as ShowCountdownAction).endAt !== undefined;
};
