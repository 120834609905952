import { ReactElement, SVGProps } from 'react';

type ChatSendIconProps = SVGProps<SVGSVGElement>;

const ChatSendIcon = (props: ChatSendIconProps): ReactElement<ChatSendIconProps> => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <polygon fill="#333333" points="2.01 21 23 12 2.01 3 2 10 17 12 2 14" />
    </g>
  </svg>
);

export default ChatSendIcon;
