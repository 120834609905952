import { StackParticipant } from '~/components/attendees/Stack';

export enum LiveState {
  AfterEvent = 'LiveState/AFTER_EVENT',
  BeforeEvent = 'LiveState/BEFORE_EVENT',
  IncompleteDates = 'LiveState/IMCOMPLETE_DATES',
  LiveNow = 'LiveState/LIVE_NOW',
}

export type TimeslotCardParticipant = StackParticipant & {
  companyName?: string | null;
};
