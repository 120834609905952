import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

type ProfileSidebarProps = {
  actions?: ReactNode;
  className?: string;
  stickyContent?: ReactNode;
};

const ProfileSidebar = ({
  className,
  stickyContent,
  actions,
}: ProfileSidebarProps): ReactElement<ProfileSidebarProps> => (
  <aside className={clsx('profile-sidebar', className)}>
    <div className="profile-sidebar__sticky-container">
      <div className="profile-sidebar__sticky-box">{stickyContent}</div>
    </div>
    {actions && <div className="profile-sidebar__actions -stick-bottom">{actions}</div>}
  </aside>
);

type InteractionsProps = {
  children: ReactNode;
};
ProfileSidebar.Interactions = ({
  children,
}: InteractionsProps): ReactElement<InteractionsProps> => (
  <div className="profile-sidebar__interactions">{children}</div>
);

export default ProfileSidebar;
