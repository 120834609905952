import clsx from 'clsx';
import { ReactElement } from 'react';

import BaseButton, { BaseButtonProps } from '~/components/shared/atoms/BaseButton';

type InteractButtonProps = {
  isDisabled?: boolean;
  isFlexible?: boolean;
  isStatic?: boolean;
  size?: 'xs' | 'sm';
} & BaseButtonProps;

/**
 * @deprecated use `components/button/Button`
 */
const InteractButton = ({
  className,
  size,
  isFlexible,
  isDisabled,
  isStatic,
  ...restProps
}: InteractButtonProps): ReactElement<InteractButtonProps> => {
  const internalClassName = clsx(className, 'interact-btn', size && `-${size}`, {
    '-disabled': isDisabled,
    '-flexible': isFlexible,
    '-static': isStatic,
  });

  // Disabling eslint here, because we extends it's props type
   
  return <BaseButton className={internalClassName} isDisabled={isDisabled} {...restProps} />;
};

export default InteractButton;
