import { ReactElement, useEffect, useState } from 'react';

import DevDebug from '~/components/DevDebug';
import MingleBooth from '~/components/mingle/MingleBooth';
import MingleLobby, { MingleLobbySkeleton } from '~/components/mingle/MingleLobby';
import MingleNavigation from '~/components/mingle/MingleNavigation';
import useImmediateMingleAvailability from '~/components/mingle/useImmediateMingleAvailability';
import useMingle from '~/components/mingle/useMingle';
import useServerClock from '~/components/mingle/useServerClock';
import PublisherProvider from '~/components/room/PublisherProvider';
import { mingleLog } from '~/lib/loggers';
import urlFor from '~/lib/urlFor';
import { UserContext } from '~/lib/user';
import { Sponsor } from '~/types/mingle';
import { OpentokConfig, Participant, User } from '~/types/rooms';

type MingleMainProps = {
  channel: string;
  lobby: { opentok: OpentokConfig };
  reportUrl?: string;
  sessionName?: string;
  sponsor?: Sponsor;
  user: User;
};

const MingleMain = ({
  channel,
  lobby: { opentok: lobbyOpentok },
  user,
  reportUrl,
  sponsor,
  sessionName,
}: MingleMainProps): ReactElement<MingleMainProps> => {
  useImmediateMingleAvailability();

  const { serverClockOffset } = useServerClock();
  const [userIsReady, setUserIsReady] = useState(false);
  const [videoSource, setVideoSource] = useState<string>();
  const [isFirstMingle, setIsFirstMingle] = useState<boolean>(true);

  const mingle = useMingle({ channel, serverClockOffset });

  const {
    debug,
    reset,
    expectedToEndAt,
    isReady: mingleIsReady,
    opentok: mingleOpentok,
    startsAt,
    partner,
  } = mingle;

  // Reset ready state if backend updated mingle state.
  useEffect(() => {
    if (!mingleIsReady) {
      setUserIsReady(false);
    }
  }, [mingleIsReady]);

  const onMingleEnd = (): void => {
    window.location.href = urlFor('/mingle');
  };

  const onMingleEnded = (continueMingling?: boolean): void => {
    if (!continueMingling) {
      onMingleEnd();
      return;
    }

    setUserIsReady(false);
    setIsFirstMingle(false);
    // The mingle may have been ended by a client-side action and it may
    // take some time before we are notified of this by pubnub. End the mingle
    // locally first if possible.
    if (reset) {
      reset();
    }
  };

  const reportPartner = (): void => {
    if (reportUrl) {
      const newWindow = window.open('', '_blank') || { location: '' };
      newWindow.location = reportUrl;
    }
    onMingleEnded();
  };

  mingleLog({ mingle, mingleIsReady, userIsReady });

  const isLocal =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  return (
    <>
      {debug && <DevDebug hiddenByDefault info={debug} />}

      {userIsReady && mingleIsReady ? (
        <PublisherProvider
          initialMediaSettings={{
            isPublishingAudio: !isLocal,
            isPublishingVideo: true,
            videoSource,
          }}
          opentok={mingleOpentok}
        >
          <div className="mingle-room -booth">
            <main className="mingle-booth">
              <MingleNavigation
                endsAt={expectedToEndAt as string}
                serverClockOffset={serverClockOffset}
                onEnd={onMingleEnded}
              >
                {(): ReactElement => (
                  <MingleBooth
                    partner={partner as Participant}
                    reportPartner={reportPartner}
                    sessionName={sessionName}
                    sponsor={sponsor}
                    user={user}
                    onMingleEnded={onMingleEnded}
                  />
                )}
              </MingleNavigation>
            </main>
          </div>
        </PublisherProvider>
      ) : (
        <UserContext.Provider value={user}>
          <MingleLobby
            fallback={<MingleLobbySkeleton />}
            isFirstMingle={isFirstMingle}
            opentok={lobbyOpentok}
            participants={[]}
            serverClockOffset={serverClockOffset}
            sessionName={sessionName}
            setVideoSource={setVideoSource}
            sponsor={sponsor}
            startsAt={mingleIsReady ? (startsAt as string) : undefined}
            videoSource={videoSource}
            onMingleEnd={onMingleEnd}
            onUserReadyChange={(): void => setUserIsReady(true)}
          />
        </UserContext.Provider>
      )}
    </>
  );
};

export default MingleMain;
