import { ReactElement, SVGProps } from 'react';

const WatchIcon = ({ ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <rect />
        <path
          d="M8 1v2h8V1h2v2h1c1.05 0 1.918.82 1.994 1.851L21 5v5.5A7.5 7.5 0 1110.5 21H5a2 2 0 01-1.995-1.851L3 19l.01-14c0-1.05.802-1.918 1.84-1.994L5 3h1V1h2zm8.5 10a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm.7 1.504v3.707l2.295 2.294-.99.99L15.8 16.79v-4.286h1.4zM19 8H5v11l4.427.001a7.5 7.5 0 019.574-9.574L19 8z"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default WatchIcon;
