import clsx from 'clsx';
import { ReactElement,ReactNode } from 'react';

type ToolbarIconButtonProps = {
  children?: ReactNode;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
};

const ToolbarIconButton = ({
  onClick = () => null,
  isActive = false,
  children = '',
  className = '',
}: ToolbarIconButtonProps): ReactElement<ToolbarIconButtonProps> => (
  <button
    className={clsx(
      '-active-state',
      isActive ? 'btn -toolbar -on' : 'btn -toolbar -off',
      className,
    )}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);

export default ToolbarIconButton;
