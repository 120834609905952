import { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react';

import usePresence from '~/components/realtime/usePresence';
import { OpenTokContext } from '~/components/room/OpenTokSession';
import { Participant } from '~/types/rooms';

import useRoom from './useRoom';

export type Audience = {
  audience: AudienceMember[];
  headcount: number;
};

export type AudienceMember = Participant & {
  isCurrentUser?: boolean;
  isEvicted?: boolean;
  isHere?: boolean;
};

export const AudienceContext = createContext<Audience>({
  audience: [],
  headcount: 0,
});

export type AudienceProviderProps = {
  children: ReactNode;
};

export const AudienceProvider = ({
  children,
}: AudienceProviderProps): ReactElement<AudienceProviderProps> => {
  const { participants, user, moderation } = useRoom();
  const { streamsByParticipantId } = useContext(OpenTokContext);
  const { occupancy, occupants } = usePresence({ channel: moderation.channel });

  // Audience is composed of three data sources as follows:
  // 1. You
  // 2. Active room participants
  // 3. Present pubnub occupants
  const activeParticipants = useMemo(() => {
    const streams = streamsByParticipantId;
    return participants.filter((p) => streams[p.id] !== undefined && p.id !== user.id);
  }, [participants, streamsByParticipantId, user]);

  const pubnubOccupants = useMemo(() => {
    const occupantsByParticipantId = occupants.reduce((acc: Record<string, Participant>, p) => {
      acc[p.id] = p;
      return acc;
    }, {});
    delete occupantsByParticipantId[user.id];
    activeParticipants.forEach((p) => delete occupantsByParticipantId[p.id]);
    return Object.values(occupantsByParticipantId);
  }, [occupants, activeParticipants, user]);

  const audience = useMemo(() => {
    const presentParticipants = [user, ...activeParticipants, ...pubnubOccupants];
    return presentParticipants.map((p) => ({
      ...p,
      isEvicted: false,
      isHere: true,
      role: 'spectator',
    }));
  }, [user, activeParticipants, pubnubOccupants]);

  const audienceContextValue = useMemo(() => {
    // The presence headcount may have been computed before we connected to the presence channel.
    const adjustment = occupancy < audience.length ? 1 : 0;

    return {
      audience, headcount: occupancy + adjustment
    };
  }, [audience, occupancy]);

  return (
    <AudienceContext.Provider value={audienceContextValue}>
      {children}
    </AudienceContext.Provider>
  );
};

export const useAudience = (): Audience => useContext(AudienceContext);
