import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { updateProfile, useCountries, useIndustries, useMe, useTopics } from '~/lib/avenger/api';
import { UpdateAttendeeProfileFormData } from '~/types/attendees';
import { useQuery } from '@apollo/client';
import { MyProfileDocument } from '~/operations/catalyst';
import ProfileSidebar from '../attendees/ProfileSidebar';
import AvatarUpload from '../forms/AvatarUpload';
import MyProfileFormFields from '../forms/MyProfileFormFields';

export type EditProfileProps = {
  onSave?: () => void;
};

const EditProfile = ({ onSave }: EditProfileProps): ReactElement => {
  const { refetch } = useQuery(MyProfileDocument);
  const { data: me, mutate } = useMe();
  const { data: countries } = useCountries();
  const { data: industriesData } = useIndustries();
  const { data: topicsData } = useTopics();

  const form = useForm<UpdateAttendeeProfileFormData>({
    defaultValues: {
      offeringTopicIds: [],
      seekingTopicIds: [],
    },
    mode: 'onChange',
  });

  useEffect(() => {
    form.reset({
      bio: me?.person.bio || '',
      city: me?.person.city || '',
      companyName: me?.person.company_name || '',
      countryId: me?.person.country_id || '',
      firstName: me?.person.first_name || '',
      industryId: me?.conference_industry_id || '',
      jobTitle: me?.person.job_title || '',
      lastName: me?.person.last_name || '',
      offeringTopicIds: me?.offering_topic_ids || [],
      seekingTopicIds: me?.seeking_topic_ids || [],
    });
  }, [form, me]);

  const industries = industriesData?.map((i) => ({ id: i.id, name: i.industry.name }));
  const topics = topicsData?.map((i) => ({ id: i.id, name: i.topic.name }));

  const onSubmit = async (data: UpdateAttendeeProfileFormData): Promise<void> => {
    const response = await updateProfile({
      seeking_topic_ids: data.seekingTopicIds,
      offering_topic_ids: data.offeringTopicIds,
      conference_industry_id: data.industryId,
      person: {
        first_name: data.firstName,
        last_name: data.lastName,
        job_title: data.jobTitle,
        company_name: data.companyName,
        bio: data.bio,
        city: data.city,
        country_id: data.countryId,
      }
    })
  
    mutate(response);
    refetch();
    onSave?.();
  };

  return (
    <form className="profile-page__grid -my-profile" onSubmit={form.handleSubmit(onSubmit)}>
      <ProfileSidebar
        className="-upload"
        stickyContent={
          <div className="portrait-container">
            <AvatarUpload avatarUrl={me?.person.avatar_url} form={form} />
          </div>
        }
      />
      <MyProfileFormFields
        countries={countries}
        form={form}
        industries={industries}
        topics={topics}
      />
    </form>
  );
};

export default EditProfile;
