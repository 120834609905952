import { ReactElement, SVGProps } from 'react';

type GithubIconProps = SVGProps<SVGSVGElement>;

const GithubIcon = (props: GithubIconProps): ReactElement<GithubIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <rect height="24" width="24" x="0" y="0" />
      <path
        d="M9.09715225,22.7130897 C9.09715225,22.3669757 9.07893847,21.2193344 9.07893847,19.9988271 C5.41796923,20.6728386 4.47085281,19.1062172 4.17943237,18.2864735 C4.01550838,17.8674933 3.30517106,16.5741199 2.68590263,16.2280058 C2.17591687,15.9547579 1.44736577,15.2807464 2.66768885,15.2625299 C3.81515683,15.2443133 4.63477681,16.3190885 4.90798347,16.7562851 C6.21937543,18.960485 8.31395982,18.341123 9.15179358,17.958576 C9.27929002,17.0113165 9.66177935,16.3737381 10.0806962,16.0094075 C6.83864386,15.645077 3.45088128,14.3881365 3.45088128,8.81387908 C3.45088128,7.22904117 4.01550838,5.91745118 4.94441102,4.89732563 C4.7987008,4.53299508 4.28871504,3.03923981 5.09012124,1.03542177 C5.09012124,1.03542177 6.31044432,0.652874684 9.09715225,2.52917703 C10.262834,2.20127954 11.5013709,2.03733079 12.7399077,2.03733079 C13.9784446,2.03733079 15.2169814,2.20127954 16.3826632,2.52917703 C19.1693711,0.634658157 20.3896942,1.03542177 20.3896942,1.03542177 C21.1911004,3.03923981 20.6811146,4.53299508 20.5354044,4.89732563 C21.4643071,5.91745118 22.0289341,7.21082464 22.0289341,8.81387908 C22.0289341,14.4063531 18.6229578,15.645077 15.3809054,16.0094075 C15.909105,16.4648207 16.3644494,17.339214 16.3644494,18.7054536 C16.3644494,20.6546221 16.3462356,22.2212434 16.3462356,22.7130897 C16.3462356,22.9701273 13.0735333,23.0544585 10.9260576,22.9660833 C9.87746127,22.9229304 9.09715225,22.8385992 9.09715225,22.7130897 Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default GithubIcon;
