import { ReactElement, SVGProps } from 'react';

type ConnectIconProps = SVGProps<SVGSVGElement>;

const ConnectIcon = (props: ConnectIconProps): ReactElement<ConnectIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M15,14 C17.67,14 23,15.34 23,18 L23,18 L23,20 L7,20 L7,18 C7,15.34 12.33,14 15,14 Z M6,7 L6,10 L9,10 L9,12 L6,12 L6,15 L4,15 L4,12 L1,12 L1,10 L4,10 L4,7 L6,7 Z M15,4 C17.21,4 19,5.79 19,8 C19,10.21 17.21,12 15,12 C12.79,12 11,10.21 11,8 C11,5.79 12.79,4 15,4 Z"
        fill="#333333"
        id="Combined-Shape"
      />
    </g>
  </svg>
);

export default ConnectIcon;
