import { ApolloProvider, TypedDocumentNode, useMutation, useQuery } from '@apollo/client';
import {
  Dispatch,
  FunctionComponent,
  MouseEventHandler,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { TrackingContext, useTracking } from '~/lib/analytics';
import {
  ConnectedAttendeesDocument,
  ConnectedExhibitorsDocument,
  PageInfo,
  PendingRequestsDocument,
  SendMyConnectionsEmailDocument,
} from '~/operations/catalyst';
import AttendeeCard from '~/components/attendees/AttendeeCard';
import OldAttendeeConnectionButton from '~/components/connections/OldAttendeeConnectionButton';
import ExhibitorCard from '~/components/exhibitors/ExhibitorCard';
import StandaloneFlashNotification from '~/components/notifications/StandaloneFlashNotification';
import PaginationButton, { PaginationButtonType } from '~/components/shared/atoms/PaginationButton';
import TabSwitcher from '~/components/shared/atoms/TabSwitcher';
import EmptyConnections from '~/components/icons/EmptyConnections';
import ForwardIcon from '~/components/icons/ForwardIcon';
import { init } from '~/lib/apollo';
import { Asset } from '~/lib/assets';

const ELEMENTS_PER_PAGE = 24;

type PaginationProps = {
  hasNext?: boolean;
  hasPrev?: boolean;
  onNext: () => void;
  onPrev: () => void;
};

type PaginationVariables = {
  afterCursor?: string | undefined | null;
  beforeCursor?: string | undefined | null;
  first?: number | undefined | null;
  last?: number | undefined | null;
};

const { emptyMyConnectionsImage } = Asset;

const Pagination = ({
  onNext,
  onPrev,
  hasPrev = false,
  hasNext = false,
}: PaginationProps): ReactElement => {
  return (
    <div className="pagination">
      <div className="pagination__content">
        <PaginationButton
          isDisabled={!hasPrev}
          type={PaginationButtonType.Backward}
          onClick={(): void => {
            onPrev();
          }}
        />
        <PaginationButton
          isDisabled={!hasNext}
          type={PaginationButtonType.Forward}
          onClick={(): void => {
            onNext();
          }}
        />
      </div>
    </div>
  );
};

const PaginationWithInfo = ({
  pageInfo,
  updateQueryVariables,
}: {
  pageInfo: PageInfo;
  updateQueryVariables: (variables: PaginationVariables) => void;
}): ReactElement | null => {
  const { hasPreviousPage, hasNextPage, endCursor, startCursor } = pageInfo;

  const next = (): void => {
    if (hasNextPage) {
      const newVariables = {
        afterCursor: endCursor,
        beforeCursor: null,
        first: ELEMENTS_PER_PAGE,
        last: null,
      };

      updateQueryVariables(newVariables);
    }
  };

  const prev = (): void => {
    if (hasPreviousPage) {
      const newVariables = {
        afterCursor: null,
        beforeCursor: startCursor,
        first: null,
        last: ELEMENTS_PER_PAGE,
      };
      updateQueryVariables(newVariables);
    }
  };

  const showPagination = hasNextPage || hasPreviousPage;

  return showPagination ? (
    <Pagination hasNext={hasNextPage} hasPrev={hasPreviousPage} onNext={next} onPrev={prev} />
  ) : null;
};

const usePaginationQuery = <T,>(
  document: TypedDocumentNode<T, PaginationVariables>,
): {
  data: T | undefined;
  loading: boolean;
  setVariables: Dispatch<SetStateAction<PaginationVariables>>;
  variables: PaginationVariables;
} => {
  const [variables, setVariables] = useState<PaginationVariables>({
    first: ELEMENTS_PER_PAGE,
  });

  const { loading, data, fetchMore } = useQuery(document, {
    variables,
  });

  useEffect(() => {
    void fetchMore({ variables });
  }, [variables, fetchMore]);

  return {
    data,
    loading,
    setVariables,
    variables,
  };
};

// Stuff in tabs
const ConnectedAttendees = (): ReactElement | null => {
  const { loading, data, setVariables } = usePaginationQuery(ConnectedAttendeesDocument);

  if (loading) {
    return null;
  }

  const elements = data?.me?.attendees;

  if (elements === undefined || elements.edges.length === 0) {
    return (
      <div className="directory-placeholder">
        {emptyMyConnectionsImage ? (
          <img alt="Time to add some connections!" src={emptyMyConnectionsImage} />
        ) : (
          <EmptyConnections />
        )}
        <div>Time to add some connections!</div>
        <div>
          Search for attendees you would like to get to know and send them a connection request.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="directory-list attendee-list">
        {elements.edges
          .map((edge) => {
            const attendee = edge.node.entity;

            if (attendee.__typename !== 'Attendee') return null;

            return (
              <AttendeeCard
                key={attendee.id}
                avatarUrl={attendee.avatarUrl ?? undefined}
                cardRole={attendee.role ?? undefined}
                companyName={attendee.companyName ?? undefined}
                firstName={attendee.firstName ?? undefined}
                id={attendee.id}
                jobTitle={attendee.jobTitle ?? undefined}
                lastName={attendee.lastName ?? undefined}
              />
            );
          })
          .filter((link) => link !== null)}
      </div>{' '}
      <PaginationWithInfo pageInfo={elements.pageInfo} updateQueryVariables={setVariables} />
    </>
  );
};

const ConnectedCompanies = (): ReactElement | null => {
  const { loading, data, setVariables } = usePaginationQuery(ConnectedExhibitorsDocument);

  if (loading) {
    return null;
  }

  const elements = data?.me?.exhibitors;

  if (elements === undefined || elements.edges.length === 0) {
    return (
      <div className="directory-placeholder">
        {emptyMyConnectionsImage ? (
          <img alt="Time to add some connections!" src={emptyMyConnectionsImage} />
        ) : (
          <EmptyConnections />
        )}
        <div>Time to add some connections!</div>
        <div>
          Search for attendees you would like to get to know and send them a connection request.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="directory-list attendee-list">
        {elements.edges
          .map((edge) => {
            const appearance = edge.node.entity;

            if (appearance.__typename !== 'Appearance') return null;

            return (
              <ExhibitorCard
                key={appearance.id}
                heading={appearance.company.name}
                id={appearance.id}
                logoUrl={appearance.company.logoUrl ?? undefined}
              />
            );
          })
          .filter((link) => link !== null)}
      </div>{' '}
      <PaginationWithInfo pageInfo={elements.pageInfo} updateQueryVariables={setVariables} />
    </>
  );
};

const Requests = ({ currentAttendeeId }: { currentAttendeeId: string }): ReactElement | null => {
  const [variables, setVariables] = useState<PaginationVariables & { attendeeId: string }>({
    attendeeId: currentAttendeeId,
    first: ELEMENTS_PER_PAGE,
  });

  const { loading, data, fetchMore } = useQuery(PendingRequestsDocument, { variables });

  useEffect(() => {
    void fetchMore({ variables });
  }, [variables, fetchMore]);

  if (loading) {
    return null;
  }

  const elements = data?.me?.connectionRequests;

  if (elements === undefined || elements.edges.length === 0) {
    return (
      <div className="directory-placeholder">
        {emptyMyConnectionsImage ? (
          <img alt="Time to add some connections!" src={emptyMyConnectionsImage} />
        ) : (
          <EmptyConnections />
        )}
        <div>Time to add some connections!</div>
        <div>
          Search for attendees you would like to get to know and send them a connection request.
        </div>
      </div>
    );
  }

  const updateQueryVariables = (newVariables: PaginationVariables): void => {
    setVariables({
      ...variables,
      ...newVariables,
    });
  };

  return (
    <>
      <div className="directory-list attendee-list">
        {elements.edges
          .map((edge) => {
            const element = edge.node;
            const attendee = edge.node?.from;
            if (element.status !== 'pending' || attendee?.__typename !== 'Attendee') return null;

            return (
              <AttendeeCard
                key={attendee.id}
                avatarUrl={attendee.avatarUrl ?? undefined}
                cardRole="staff"
                companyName={attendee.companyName ?? undefined}
                firstName={attendee.firstName}
                id={attendee.id}
                jobTitle={attendee.jobTitle ?? undefined}
                lastName={attendee.lastName ?? undefined}
              >
                <OldAttendeeConnectionButton
                  attendee={{ id: attendee.id, name: attendee.firstName }}
                />
              </AttendeeCard>
            );
          })
          .filter((link) => link !== null)}
      </div>{' '}
      <PaginationWithInfo
        pageInfo={elements.pageInfo}
        updateQueryVariables={updateQueryVariables}
      />
    </>
  );
};

// TODO: Man this file needs a good splitting up
// Tabs

enum TabTypenames {
  ATTENDEES = 'Attendees',
  COMPANIES = 'Companies',
  REQUESTS = 'Requests',
}

const tabs = [TabTypenames.ATTENDEES, TabTypenames.COMPANIES, TabTypenames.REQUESTS];

type TabProps = { currentAttendeeId: string; name: TabTypenames };

type TabsProps = {
  currentAttendeeId: string;
  defaultSelection: TabTypenames;
  tabComponent: FunctionComponent<TabProps>;
};

const Tabs = ({
  defaultSelection,
  currentAttendeeId,
  tabComponent: TabComponent,
}: TabsProps): ReactElement => {
  const track = useTracking();
  const [selected, setSelected] = useState(defaultSelection);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [sendEmail] = useMutation(SendMyConnectionsEmailDocument, {
    onCompleted() {
      setIsEmailSent(true);
    },
  });

  const handleEmailEvent: MouseEventHandler = () => {
    if (isEmailSent) {
      return;
    }

    track('Exported connections');
    void sendEmail();
  };

  return (
    <>
      <div className="container -md -under-switcher -alpha -omega">
        <header className="page-header">
          <h1 className="page-header__heading">My connections</h1>
          <nav className="page-header__extra-actions">
            <button className="button -small -secondary" type="button" onClick={handleEmailEvent}>
              <ForwardIcon /> Email me the list of my connections
            </button>
          </nav>
          <TabSwitcher>
            {tabs.map((name: TabTypenames) => (
              <TabSwitcher.Button
                key={`tab::${name}`}
                selected={name === selected}
                onClick={(): void => setSelected(name)}
              >
                {name}
              </TabSwitcher.Button>
            ))}
          </TabSwitcher>
        </header>

        <TabComponent currentAttendeeId={currentAttendeeId} name={selected} />
      </div>

      {isEmailSent && (
        <StandaloneFlashNotification
          message="Email sent successfully"
          type="success"
          onDismiss={(): void => setIsEmailSent(false)}
        />
      )}
    </>
  );
};

const Tab = (props: TabProps): ReactElement | null => {
  switch (props.name) {
    case TabTypenames.ATTENDEES:
      return <ConnectedAttendees />;
    case TabTypenames.COMPANIES:
      return <ConnectedCompanies />;
    case TabTypenames.REQUESTS:
      return <Requests currentAttendeeId={props.currentAttendeeId} />;
    default:
      return null;
  }
};

// MyConnections

type MyConnectionsProps = {
  apiUrl: string;
  currentAttendeeId: string;
};

const trackingContextValue = { category: 'Profile' };

const MyConnections = ({
  apiUrl,
  currentAttendeeId,
}: MyConnectionsProps): ReactElement<MyConnectionsProps> => {
  const client = init({ uri: apiUrl });

  return (
    <TrackingContext.Provider value={trackingContextValue}>
      <ApolloProvider client={client}>
        <Tabs
          currentAttendeeId={currentAttendeeId}
          defaultSelection={TabTypenames.ATTENDEES}
          tabComponent={Tab}
        />
      </ApolloProvider>
    </TrackingContext.Provider>
  );
};

export default MyConnections;
