import clsx from 'clsx';
import { Stream } from 'opentok-react/types/opentok';
import { ReactElement, useContext, useMemo } from 'react';

import Stack from '~/components/attendees/Stack';
import { moderatorSubscriberOptions, subscriberOptions } from '~/configs/opentokConfig';
import { Participant } from '~/types/rooms';

import { OpenTokContext, participantFromStream } from './OpenTokSession';
import WebcamVideoSubscriber from './WebcamVideoSubscriber';

type WebcamVideoSubscriberListProps = {
  audience?: Participant[];
  limit?: number;
  participants: Participant[];
  roomId: string;
  userCanModerate?: boolean;
};

const WebcamVideoSubscriberList = ({
  participants = [],
  audience = [],
  limit,
  userCanModerate = false,
  roomId,
}: WebcamVideoSubscriberListProps): ReactElement => {
  const { streamsByParticipantId, streams } = useContext(OpenTokContext);

  const prioritisedStreams = useMemo(() => {
    const prioritised: Stream[] = [];
    participants.forEach((participant) => {
      const stream = streamsByParticipantId[participant.id];
      if (!stream) return;
      prioritised.push(stream);
    });

    const prioritisedStreamIds = prioritised.map(({ streamId }) => streamId);
    streams.forEach((stream) => {
      if (!prioritisedStreamIds.includes(stream.streamId)) {
        prioritised.push(stream);
      }
    });

    // Speaker who is screensharing should be always on the top
    const screenSharingStreamIdx = prioritised.findIndex((stream) => stream.videoType === 'screen');
    if (screenSharingStreamIdx > -1) {
      // if an index is greater than -1 it means it's safe to take that index from the prioritised array
      const screenSharingStream = prioritised.splice(screenSharingStreamIdx, 1)[0];
      const screenShareOwnerId = participantFromStream(screenSharingStream).id;

      const webcamStreamIdx = prioritised.findIndex(
        (stream) =>
          stream.videoType === 'camera' && participantFromStream(stream).id === screenShareOwnerId,
      );
      if (webcamStreamIdx !== -1) {
        // again, if an index is greater than -1 it means it's safe to take that index from the prioritised array
        const webcamStream = prioritised.splice(webcamStreamIdx, 1)[0];
        return [screenSharingStream, webcamStream, ...prioritised];
      }

      return [screenSharingStream, ...prioritised];
    }

    return prioritised;
  }, [participants, streams, streamsByParticipantId]);

  const participantsWithHiddenStreams = useMemo(() => {
    if (limit && prioritisedStreams.length > limit) {
      const hiddenStreams = prioritisedStreams.slice(limit - 1);
      return hiddenStreams
        .map((stream) => audience.find(({ id }) => id === participantFromStream(stream).id))
        .filter((participant): participant is Participant => participant !== undefined);
    }
    return [];
  }, [audience, limit, prioritisedStreams]);

  const subscriberOptionsByRole = {
    ...subscriberOptions,
    ...(userCanModerate ? moderatorSubscriberOptions : {}),
  };

  return (
    <>
      {prioritisedStreams.map((stream, index) => {
        const isHidden = limit && prioritisedStreams.length > limit && index + 2 > limit;
        const participant = audience.find(({ id }) => id === participantFromStream(stream).id);
        const isScreenShare = stream.videoType === 'screen';
        return (
          <WebcamVideoSubscriber
            key={stream.streamId}
            className={clsx(isHidden && '-hidden')}
            participant={participant}
            roomId={roomId}
            stream={stream}
            subscriberOptions={{
              ...subscriberOptionsByRole,
              fitMode: isScreenShare ? 'contain' : subscriberOptionsByRole.fitMode,
            }}
          />
        );
      })}
      {limit && prioritisedStreams.length > limit && (
        <div className="stage__stream -centered-content">
          <Stack limit={3} participants={participantsWithHiddenStreams} />
        </div>
      )}
    </>
  );
};

export default WebcamVideoSubscriberList;
