import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/loading/Spinner';
import ExhibitorHits from '~/components/search/molocules/ExhibitorHits';
import CompanySearchFilters from '~/components/search/organisms/CompanySearchFilters';
import AlgolaSearchProvider from '~/components/search/providers/AlgoliaSearchProvider';
import SearchTemplate, {
  connectHitsWithPlaceholder,
} from '~/components/search/templates/SearchTemplate';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { TrackingContext } from '~/lib/analytics';
import { useAlgoliaSettings } from '~/lib/avenger/api';
import { SearchPageVariant } from '~/types/search';

const trackingContextValue = { category: 'Explore' };
const ConnectedExhibitorHits = connectHitsWithPlaceholder(ExhibitorHits);
const filters = 'track:startup OR track:alpha OR track:beta OR track:growth';

const ExploreStartupsPage = (): ReactElement => {
  const { t } = useTranslation();
  const { data, error } = useAlgoliaSettings();

  if (error) {
    return <ErrorScreen />;
  }

  if (!data) {
    return <Spinner />;
  }

  return (
    <ErrorBoundary fallback={<ErrorScreen />}>
      <TrackingContext.Provider value={trackingContextValue}>
        <AlgolaSearchProvider
          appId={data.app_id}
          filters={filters}
          hitsPerPage={24}
          replicaName={data.indexes.appearances}
          searchAPIKey={data.key}
        >
          <SearchTemplate
            filters={(): ReactElement => (
              <CompanySearchFilters variant={SearchPageVariant.Startups} />
            )}
            searchBoxPlaceholder={t('explore.searchStartups')}
          >
            <ConnectedExhibitorHits />
          </SearchTemplate>
        </AlgolaSearchProvider>
      </TrackingContext.Provider>
    </ErrorBoundary>
  );
};

export default ExploreStartupsPage;
