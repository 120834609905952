import { ReactElement, SVGProps } from 'react';

const HideOnIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.068 5c6.983 0 9.639 5.93 9.913 6.59l.02.05.004.008.131.352-.135.36-.02.05c-.274.66-2.93 6.59-9.913 6.59-6.983 0-9.639-5.93-9.913-6.59l-.02-.05-.004-.008L2 12l.131-.352.004-.009.02-.05C2.429 10.93 5.085 5 12.068 5zm0 2a8.65 8.65 0 00-7.909 5 8.649 8.649 0 007.909 5 8.651 8.651 0 007.909-5 8.651 8.651 0 00-7.909-5zm2 2.25a.749.749 0 01.53 1.28L13.129 12l1.469 1.47a.75.75 0 01-1.06 1.06l-1.47-1.469-1.47 1.469a.75.75 0 11-1.06-1.06L11.007 12l-1.469-1.47a.75.75 0 011.06-1.06l1.47 1.469 1.47-1.469c.14-.14.331-.22.53-.22z"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default HideOnIcon;
