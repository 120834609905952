import { ReactElement } from 'react';

type HeroBannerProps = {
  altText: string;
  bannerHref?: string;
  bannerImgSrc: string;
};

const HeroBanner = ({ bannerHref, bannerImgSrc, altText }: HeroBannerProps): ReactElement => {
  return (
    <header className="page-header">
      <div className="page-header__banner">
        {bannerHref ? (
          <a href={bannerHref} rel="noreferrer" target="_blank">
            <img alt={altText} src={bannerImgSrc} />
          </a>
        ) : (
          <img alt={altText} src={bannerImgSrc} />
        )}
      </div>
    </header>
  );
};

export default HeroBanner;
