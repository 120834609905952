import { DateTime } from 'luxon';
import { ReactElement, useEffect, useState } from 'react';

export const timeFormat = 'h:mm:ssa ZZZZ';

const Clock = (): ReactElement => {
  const [time, setTime] = useState(DateTime.local());

  const updateClock = () => {
    setTime(DateTime.local());
  };

  useEffect(() => {
    const interval = 500;
    const clockTicker = setInterval(updateClock, interval);
    return () => {
      clearInterval(clockTicker);
    };
  }, [time]);

  return <div className="clock">{time.toFormat(timeFormat)}</div>;
};

export default Clock;
