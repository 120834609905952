import { ReactElement, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { usePermissions, usePublisher,useRoom } from '~/lib/rooms';
import { useAudience } from '~/lib/rooms/audience';
import { useRoomConfig } from '~/lib/rooms/config';
import { useModeration } from '~/lib/rooms/moderation';
import { useQuestions } from '~/lib/rooms/questions';
import OfflineScreen from '~/components/room/OfflineScreen';
import RoomCountdown from '~/components/room/room_elements/RoomCountdown';
import RoomModeBadge from '~/components/room/room_elements/RoomModeBadge';
import StageStreams from '~/components/room/StageStreams';
import urlFor from '~/lib/urlFor';
import { Mode } from '~/types/rooms-moderation';

import Player from '../video/Player';
import { OpenTokContext } from './OpenTokSession';
import QuestionCard from './questions/QuestionCard';
import ScreenSharePublisher from './ScreenSharePublisher';
import WebcamVideoPublisher from './WebcamVideoPublisher';
import WebcamVideoSubscriberList from './WebcamVideoSubscriberList';

// used to limit webcam feeds during screensharing, styles should be adjusted accordingly
const SCREENSHARING_WEBCAM_ROWS_COUNT = 4;

const CurrentQuestionCard = styled(QuestionCard)`
  width: 70%;
  margin-bottom: 1rem;
`;

const VideoStreams = (): ReactElement => {
  const { streams } = useContext(OpenTokContext);
  const {
    participants,
    calendarEvent,
    user: { name: userName, avatarUrl, id: userId },
    room: { id: roomId, featuresBackstage },
  } = useRoom();
  const { canModerateQuestions, currentQuestion, setCurrentQuestion, markAnswered } =
    useQuestions();
  const { audience } = useAudience();
  const { isScreenSharing, isParticipating, videoConsent, countdown, clearCountdown } =
    usePublisher();
  const { videoCall, contentLiveStream, videoCallLiveStream } = useRoomConfig();

  const { mode, evictedOccupantIds } = useModeration();
  const { isModerator: userCanModerate, videoChat } = usePermissions();
  const canPublish = videoChat?.canPublish;
  const canSubscribe = !!videoChat?.sessionId;

  const isContent = mode === Mode.content;
  const isLive = mode === Mode.live;
  const isOffline = mode === Mode.offline;
  const isBackstage = isOffline && canPublish;

  const subscriberIsScreenSharing = !!streams.find(({ videoType }) => videoType === 'screen');
  const isWaiting = canSubscribe && !isParticipating && streams.length === 0;
  const showBadge = featuresBackstage && (!isOffline || canPublish);

  const showVideoCall = !!videoCall && (isLive || isBackstage);
  const showContentLiveStream = !!contentLiveStream && isContent;
  const showVideoCallLiveStream = !!videoCallLiveStream && isLive && !showVideoCall;
  const showOfflineScreen =
    (!showVideoCall && !showContentLiveStream && !showVideoCallLiveStream) ||
    (showVideoCall && isWaiting);

  const isScreenSharingLayout = showVideoCall && (isScreenSharing || subscriberIsScreenSharing);

  const screenSharingStreamsLimit =
    SCREENSHARING_WEBCAM_ROWS_COUNT +
    // screen sharing is counted as a webcam output
    +subscriberIsScreenSharing -
    // subtract one slot for the current user webcam feed (if participating)
    +(isParticipating && canPublish);

  useEffect(() => {
    if (evictedOccupantIds.includes(userId)) {
      window.location.href = urlFor('/');
    }
  }, [evictedOccupantIds, userId]);

  return (
    <>
      {showBadge && <RoomModeBadge mode={mode} />}
      {!videoConsent && (
        <h1
          style={{
            color: 'white',
            fontSize: '110%',
            left: '10%',
            padding: '0.5rem',
            position: 'fixed',
            top: '80px',
            zIndex: 100000,
          }}
        >
          Having audio issues? <br />
          Try clicking on any video on your screen to unmute.
        </h1>
      )}
      {canPublish && countdown && (
        <RoomCountdown countdownEndsAt={countdown} onComplete={clearCountdown} />
      )}

      <StageStreams
        isFullSize={showContentLiveStream || showVideoCallLiveStream}
        isQuestion={isLive && !!currentQuestion}
        isScreensharing={isScreenSharingLayout}
      >
        {showOfflineScreen && <OfflineScreen eventFormat={calendarEvent?.format ?? undefined} />}

        {showContentLiveStream && (
          <Player
            src={{
              gatepass: contentLiveStream.gatepassUrl || undefined,
              hls: contentLiveStream.hlsUrl || undefined,
              iframe: contentLiveStream.iframeUrl || undefined,
            }}
          />
        )}

        {showVideoCall && (
          <>
            {isParticipating && (
              <>
                <WebcamVideoPublisher avatarUrl={avatarUrl ?? undefined} userName={userName} />
                <ScreenSharePublisher
                  subscriberIsScreenSharing={subscriberIsScreenSharing}
                  userName={userName}
                />
              </>
            )}
            <WebcamVideoSubscriberList
              audience={audience}
              limit={isScreenSharingLayout ? screenSharingStreamsLimit : undefined}
              participants={participants}
              roomId={roomId}
              userCanModerate={userCanModerate}
            />
          </>
        )}

        {showVideoCallLiveStream && (
          <Player
            src={{
              gatepass: videoCallLiveStream.gatepassUrl || undefined,
              hls: videoCallLiveStream.hlsUrl || undefined,
              iframe: videoCallLiveStream.iframeUrl || undefined,
            }}
          />
        )}
      </StageStreams>

      {showVideoCall && currentQuestion && (
        <CurrentQuestionCard
          {...currentQuestion}
          current
          moderator={canModerateQuestions}
          onMarkAnswered={() => {
            markAnswered(currentQuestion);
            setCurrentQuestion(null);
          }}
        />
      )}
    </>
  );
};

export default VideoStreams;
