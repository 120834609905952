import { ReactElement, useMemo } from 'react';
import styled from 'styled-components';

import NoMingles from './NoMingle';
import PastMingle from './PastMingle';
import useMingleHistory, { MingleHistory } from './useMingleHistory';

enum MingleListStateType {
  LIST_EMPTY = 'mingle-list-state/LIST_EMPTY',
  LIST_FULL = 'mingle-list-state/LIST_FULL',
  LOADING = 'mingle-list-state/LOADING'
}

const useMingleListState = () => {
  const { isLoading, history } = useMingleHistory();

  const result = useMemo<
    | {
        value: MingleListStateType.LIST_EMPTY | MingleListStateType.LOADING;
      }
    | {
        history: MingleHistory[];
        value: MingleListStateType.LIST_FULL;
      }
  >(() => {
    if (!isLoading && !(history.length > 0)) {
      return {
        value: MingleListStateType.LIST_EMPTY,
      };
    }

    if (!isLoading) {
      return {
        history,
        value: MingleListStateType.LIST_FULL,
      };
    }

    return {
      value: MingleListStateType.LOADING,
    };
  }, [isLoading, history]);

  return result;
};

const Title = styled.h2`
  font-weight: 600;
  color: var(--c-black);
  font-size: var(--fs-lg);
  padding: 1rem 1rem;
`;

const BaseAside = styled.aside`
  border-radius: var(--s-bw);
  box-shadow: var(--c-silver) 0 0 0 1px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const List = styled.ul`
  overflow-y: scroll;
  overflow-x: hidden;
`;

const MyMingles = (): ReactElement => {
  const listState = useMingleListState();

  return (
    <BaseAside>
      <Title>My Mingles</Title>
      {listState.value === MingleListStateType.LOADING && (
        <List>
          {[0, 1, 2, 3, 4, 5, 6, 7].map((val) => (
            <PastMingle key={`no-mingle::${val}`} />
          ))}
        </List>
      )}
      {listState.value === MingleListStateType.LIST_FULL && (
        <List>
          {listState.history.map((mingle) => (
            <PastMingle key={`past-mingle::${mingle.id}`} mingler={mingle.partner} />
          ))}
        </List>
      )}
      {listState.value === MingleListStateType.LIST_EMPTY && <NoMingles>No mingles yet.</NoMingles>}
    </BaseAside>
  );
};

export default MyMingles;
