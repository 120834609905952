import axios from 'axios';
import {
  createContext,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTracking } from '../analytics';
import { authorizationHeader } from '../authToken';
import config from '../config';

export interface CalendarPins {
  isPinned: (id: string) => boolean;
  pin: (id: string) => Promise<void>;
  unPin: (id: string) => Promise<void>;
}

export const CalendarPinsContext = createContext<CalendarPins | undefined>(undefined);

type GetPinsResponse = {
  data: {
    calendar_event_id: string;
    id: string;
  }[];
};

const headers = {
  Accept: 'application/json',
  Authorization: authorizationHeader(),
  'Content-Type': 'application/json',
};

const fetchPins = async () => {
  const res = await axios.get<GetPinsResponse>(`${config.calendarUrl}/pins`, { headers });
  return res.data;
};

const addPin = async (id: string) => {
  await axios.post(`${config.calendarUrl}/calendar_events/${id}/pin`, {}, { headers });
};

const removePin = async (id: string) => {
  await axios.delete(`${config.calendarUrl}/calendar_events/${id}/pin`, { headers });
};

export type CalendarPinsProviderProps = {
  children?: ReactNode;
};

export const CalendarPinsProvider = ({
  children,
}: CalendarPinsProviderProps): ReactElement<CalendarPinsProviderProps> => {
  const [pins, setPins] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const update = async () => {
      const res = await fetchPins();
      const pinsMap: Record<string, boolean> = {};

      res.data.forEach((pin) => {
        pinsMap[pin.calendar_event_id] = true;
      });

      setPins(pinsMap);
    };

    void update();
  }, []);

  const pin = useCallback(async (id: string) => {
    setPins((prev) => ({
      ...prev,
      [id]: true,
    }));

    await addPin(id);
  }, []);

  const unPin = useCallback(async (id: string) => {
    setPins((prev) => ({
      ...prev,
      [id]: false,
    }));

    await removePin(id);
  }, []);

  const isPinned = useCallback((id: string) => pins[id], [pins]);

  const pinsValue = useMemo(() => ({
    isPinned, pin, unPin
  }), [isPinned, pin, unPin])

  return (
    <CalendarPinsContext.Provider value={pinsValue}>
      {children}
    </CalendarPinsContext.Provider>
  );
};

export type UsePinType = [boolean, (event: SyntheticEvent) => Promise<void>];

export const usePin = (id: string): UsePinType => {
  const pins = useContext(CalendarPinsContext);
  const track = useTracking();

  if (!pins) {
    throw new Error('usePin called outside of CalendarPinsProvider');
  }

  const { pin, unPin, isPinned } = pins;
  const pinned = isPinned(id);
  const res: UsePinType = useMemo(() => {
    const fn = (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (pinned) {
        track('Unpinned calendar event', id);
        return unPin(id);
      }

      track('Pinned calendar event');
      return pin(id);
    };
    return [pinned, fn];
  }, [id, pinned, pin, unPin, track]);

  return res;
};
