import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import Pubnub, { MessageActionEvent, MessageEvent, SignalEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PubnubMessage } from '~/types/pubnub';
import { Participant } from '~/types/rooms';

type PubnubChannelMessages = Pubnub.FetchMessagesResponse['channels'][string];

const parseTimeToken = (value: string | number): number =>
  typeof value === 'string' ? parseInt(value, 10) : value;

const sortMessagesInDescOrder = (messages: PubnubChannelMessages): PubnubChannelMessages =>
  messages.sort((m1, m2) => parseTimeToken(m2.timetoken) - parseTimeToken(m1.timetoken));

export type UsePubnubMessagesParameters = {
  channel?: string | null;
  fetchAllHistory?: boolean;
  includeMessageActions?: boolean;
  isReverse?: boolean;
  /**
   * Max 25
   */
  messagePerPage?: number;
  sortingOrder?: 'asc' | 'desc';
  user?: Participant;
}

export type SendMessageOpts = {
  storeInHistory?: boolean;
};

export type AddMessageActionParams = {
  action: {
    type: string;
    value: string;
  };
  messageTimetoken: string;
};

export type RemoveMessageActionParams = {
  actionTimetoken: string;
  messageTimetoken: string;
};

export type UsePubnubMessagesReturnValue<TMessage extends PubnubMessage> = {
  addMessageAction: (params: AddMessageActionParams) => void;
  clearHistory: () => void;
  fetchMessages: (start?: string | number) => void;
  historyLoaded: boolean;
  isErrored: boolean;
  isFetching: boolean;
  messages: TMessage[];
  pubnub: Pubnub;
  removeMessageAction: (params: RemoveMessageActionParams) => void;
  sendMessage: (
    payload: Record<string, unknown>,
    callback?: () => void,
    opts?: SendMessageOpts,
  ) => void;
  isSending: boolean;
}

const usePubnubMessages = <TMessage extends PubnubMessage>({
  channel,
  isReverse = false,
  // max 25
  messagePerPage = 10,
  sortingOrder = 'asc',
  user = undefined,
  fetchAllHistory = false,
  includeMessageActions = false,
}: UsePubnubMessagesParameters): UsePubnubMessagesReturnValue<TMessage> => {
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [historyLoaded, setHistoryLoaded] = useState(false);
  const [startToken, setStartToken] = useState<string | number>(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const updateMessageState = useCallback(
    (prevState: TMessage[], givenMessages: PubnubChannelMessages) => {
      const sortedMessages =
        sortingOrder === 'desc' ? sortMessagesInDescOrder(givenMessages) : givenMessages;
      const mappedMessages = sortedMessages.map((message) => {
        const messageContent =
          message.message ||
          // FIXME: Official pubnub typings does not have typings for entry, disabling typechecking for that property
          (message as Record<string, unknown>).entry;
        return { actions: message.actions, timetoken: message.timetoken, ...messageContent };
      });
      const result = isReverse
        ? [...prevState, ...mappedMessages]
        : [...mappedMessages, ...prevState];
      return uniqBy(result, 'id');
    },
    [isReverse, sortingOrder],
  );

  const fetchMessages = useCallback<UsePubnubMessagesReturnValue<TMessage>['fetchMessages']>(
    (start?: string | number) => {
      if (isFetching || historyLoaded || !channel) return;

      const callback = (status: Pubnub.PubnubStatus, response: Pubnub.FetchMessagesResponse) => {
        if (status.error || !channel || !response) {
          setIsErrored(status.error ?? true);
          return;
        }

        const responseMessages = response.channels[channel];
        const firstToken = responseMessages && response.channels[channel][0].timetoken;

        if (responseMessages?.length > 0) {
          setStartToken(firstToken);
          setMessages((prevState) => updateMessageState(prevState, responseMessages));
        }

        if (!responseMessages || responseMessages.length < messagePerPage) setHistoryLoaded(true);

        if (fetchAllHistory && responseMessages?.length === messagePerPage) {
          fetchMessages(firstToken);
        }

        setIsFetching(false);
      };

      const historySetup = {
        count: messagePerPage,
        includeTimetoken: true,
        start: start || startToken,
        stringifiedTimeToken: true,
      };
      setIsErrored(false);
      setIsFetching(true);
      pubnub.fetchMessages(
        { channels: [channel], includeMessageActions, ...historySetup },
        callback,
      );
    },
    [
      isFetching,
      historyLoaded,
      channel,
      messagePerPage,
      startToken,
      pubnub,
      includeMessageActions,
      fetchAllHistory,
      updateMessageState,
    ],
  );

  useEffect(() => {
    if (!pubnub || !channel) return () => {
      // No cleanup needed
    };

    const listener = {
      message: (e: MessageEvent) => {
        if (e.channel === channel) {
          setMessages((prevMessages) => {
            const newMessage = {
              timetoken: e.timetoken,
              ...e.message,
            };
            return isReverse ? [newMessage, ...prevMessages] : [...prevMessages, newMessage];
          });
        }
      },

      messageAction: (e: MessageActionEvent) => {
        if (e.channel === channel) {
          setMessages((prevMessages) => {
            return prevMessages.map((m) => {
              if (m.timetoken !== e.data.messageTimetoken) {
                return m;
              }

              const newMessage = { ...m };
              newMessage.actions = newMessage.actions || {};
              newMessage.actions[e.data.type] = m.actions?.[e.data.type] || {};
              let actions = newMessage.actions[e.data.type][e.data.value] || [];

              if (e.event === 'added') {
                actions.push({
                  actionTimetoken: e.data.actionTimetoken,
                  uuid: e.data.uuid,
                });
              } else {
                actions = actions.filter((v) => v.actionTimetoken !== e.data.actionTimetoken);
              }

              newMessage.actions[e.data.type][e.data.value] = actions;

              return newMessage;
            });
          });
        }
      },

      signal: (e: SignalEvent) => {
        if (e.channel !== channel) {
          return;
        }

        if (e.message === 'clear') {
          setMessages([]);
        }
      },
    };

    pubnub.addListener(listener);

    return () => {
      pubnub.removeListener(listener);
      setHistoryLoaded(false);
      setMessages([]);
    };
  }, [pubnub, channel, isReverse]);

  const sendMessage = useMemo(
    (): UsePubnubMessagesReturnValue<TMessage>['sendMessage'] =>
      (messagePayload, callback = () => {
        // Default no-op
      }, opts = {}) => {
        if (!channel) return;
        setIsSending(true);

        pubnub.publish(
          {
            channel,
            message: {
              id: uuidv4(),
              publisher: {
                ...(user
                  ? {
                      accreditation: user.accreditation,
                      avatarUrl: user.avatarUrl,
                      companyName: user.companyName,
                      id: user.id,
                      identity: user.identity,
                      jobTitle: user.jobTitle,
                      name: user.name,
                    }
                  : {}),
                uuid,
              },
              sent_at: DateTime.utc().toISO(),
              ...messagePayload,
            },
            storeInHistory: opts?.storeInHistory,
          },
          () => {
            setIsSending(false);
            callback?.();
          },
        );
      },
    [pubnub, channel, uuid, user],
  );

  const addMessageAction = useMemo(() => {
    return (params: AddMessageActionParams) => {
      if (!channel) {
        return Promise.reject();
      }

      return pubnub.addMessageAction({
        channel,
        ...params,
      });
    };
  }, [channel, pubnub]);

  const removeMessageAction = useMemo(() => {
    return (params: RemoveMessageActionParams) => {
      if (!channel) {
        return Promise.reject();
      }

      return pubnub.removeMessageAction({
        channel,
        ...params,
      });
    };
  }, [channel, pubnub]);

  const clearHistory = useMemo(() => {
    return () => {
      if (!channel) {
        return Promise.reject();
      }

      return Promise.all([
        pubnub.deleteMessages({
          channel,
          end: Date.now() * 1000,
          start: 0,
        }),
        pubnub.signal({
          channel,
          message: 'clear',
        })
      ]);
    };
  }, [channel, pubnub]);

  return {
    addMessageAction,
    clearHistory,
    fetchMessages,
    historyLoaded,
    isErrored,
    isFetching,
    isSending,
    messages,
    pubnub,
    removeMessageAction,
    sendMessage,
  };
};

export default usePubnubMessages;
