import { ReactElement } from 'react';
import styled from 'styled-components';

const Label = styled.div`
  font-size: var(--fs-14);
  line-height: var(--fs-20);
  color: var(--c-grey);
`;

export type TrackLabelProps = {
  format?: string;
  location?: string;
  track?: string;
};

const TrackLabel = ({
  format,
  track,
  location,
}: TrackLabelProps): ReactElement<TrackLabelProps> => {
  const parts = [format, track, location];

  // Only show track and location if they are different from each other.
  if (track === location) {
    parts.pop();
  }

  // "Format | Track | Location" where all parts are optional.
  const str = parts.filter(Boolean).join(' | ');

  return <Label>{str}</Label>;
};

export default TrackLabel;
