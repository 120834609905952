import { ReactElement, useState } from 'react';

import { TrackingContext } from '~/lib/analytics';
import EditProfile from '~/components/profile/EditProfile';
import { ApolloProvider } from '@apollo/client';
import { init } from '~/lib/apollo';
import Profile from '~/components/profile/Profile';

const trackingContextValue = { category: 'Profile' };

const MyProfile = (): ReactElement => {
  const client = init();
  const [edit, setEdit] = useState(false);

  return (
    <ApolloProvider client={client}>
      <TrackingContext.Provider value={trackingContextValue}>
        {edit ? (
          <EditProfile onSave={() => setEdit(false)} />
        ) : (
          <Profile onEdit={() => setEdit(true)} />
        )}
      </TrackingContext.Provider>
    </ApolloProvider>
  );
};

export default MyProfile;
