import { ReactElement,SVGProps } from 'react';

const FavouriteOffIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.414 4A5.6 5.6 0 0012 5.166a5.585 5.585 0 00-9 4.42C3 15.55 11.2 19.72 11.552 19.9l.211.105H12c.707 0 9-4.438 9-10.414C21 6.505 18.5 4.003 15.414 4zm-3.42 13.867C9.687 16.6 5 13.26 5 9.586a3.585 3.585 0 016.255-2.394l.745.831.744-.83A3.586 3.586 0 0119 9.586c0 4.059-5.407 7.386-7.006 8.281z"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default FavouriteOffIcon;
