import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import AlertIcon from '../icons/AlertIcon';
import LoginMessage, { LoginLink, LoginParagraph } from './LoginMessage';
import LoginPanel from './LoginPanel';

const SSOErrorPanel = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <LoginPanel>
      <LoginMessage icon={<AlertIcon />} title={t('login.SSOError.title')}>
        <LoginParagraph>
          {t('login.SSOError.regMessage')}
          <LoginLink href={t('login.SSOError.regLink')}>please follow this link</LoginLink>
        </LoginParagraph>
        <LoginParagraph>
          {t('login.SSOError.supportMessage')}
          <LoginLink href={t('login.SSOError.supportLink')}>
            {t('login.SSOError.supportLink')}.
          </LoginLink>
        </LoginParagraph>
      </LoginMessage>
    </LoginPanel>
  );
};

export default SSOErrorPanel;
