import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

type MapIconProps = SVGProps<SVGSVGElement>;

const MapIcon = ({ ref, ...props }: MapIconProps): ReactElement<MapIconProps> => (
  <IconBase viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12 3a5.929 5.929 0 0 1 5.929 5.929 9.817 9.817 0 0 1-.622 3.313H21v9H3v-9h3.693a9.817 9.817 0 0 1-.622-3.313A5.929 5.929 0 0 1 12 3Zm0 2a3.934 3.934 0 0 0-3.929 3.929c0 3.371 2.656 6.568 3.929 7.907 1.273-1.339 3.929-4.536 3.929-7.907A3.934 3.934 0 0 0 12 5Zm0 1.993a2.117 2.117 0 1 1 0 4.234 2.117 2.117 0 0 1 0-4.234Z"
        fill="#333"
      />
    </g>
  </IconBase>
);

export default MapIcon;
