import Pubnub from 'pubnub';
import { createContext, useContext } from 'react';

import {
  CalendarEvent,
  ChatConfig,
  Occupant,
  OpentokConfig,
  Participant,
  PubnubClientConfig,
  PubnubFeatureConfig,
  ReactionsConfig,
  RefreshAccess,
  Room,
  RoomBroadcast,
  Sponsor,
  Stream,
  User,
} from '~/types/rooms';

type RoomContextType = {
  broadcast?: RoomBroadcast;
  calendarEvent?: CalendarEvent;
  chat?: PubnubFeatureConfig;
  chatConfig: ChatConfig;
  debug?: Record<string, unknown>;
  moderation: PubnubFeatureConfig;
  occupant: Occupant;
  opentok?: OpentokConfig;
  participants: Participant[];
  pubnub?: Pubnub;
  pubnubConfig: PubnubClientConfig;
  questions?: PubnubFeatureConfig;
  reactions?: ReactionsConfig;
  refreshAccess: RefreshAccess;
  room: Room;
  sponsor?: Sponsor;
  stream?: Stream;
  user: User;
};

export const RoomContext = createContext<RoomContextType | undefined>(undefined);

const useRoom = (): RoomContextType => {
  const room = useContext(RoomContext);
  if (room === undefined) {
    throw new Error('useRoom called with no RoomProvider');
  }
  return room;
};
export default useRoom;
