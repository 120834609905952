import type { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorScreen from '~/components/shared/molecules/ErrorScreen';

import QuestionsForm from '../questions/QuestionsForm';
import QuestionsStream from '../questions/QuestionsStream';
import DiscussionPanelTab from './DiscussionPanelTab';

const QuestionsTab = (): ReactElement => {
  return (
    <DiscussionPanelTab actions={<QuestionsForm />}>
      <ErrorBoundary fallback={<ErrorScreen />}>
        <QuestionsStream />
      </ErrorBoundary>
    </DiscussionPanelTab>
  );
};

export default QuestionsTab;
