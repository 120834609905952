import { OTPublisher } from 'opentok-react';
import { ReactElement, useCallback, useContext, useEffect } from 'react';

import { usePublisher } from '~/lib/rooms';

import { EventStreamContext } from './EventStreamContext';
import { OpenTokContext } from './OpenTokSession';
import VideoStream from './VideoStream';

type ScreenSharePublisherProps = {
  subscriberIsScreenSharing: boolean;
  userName: string;
};

const ScreenSharePublisher = ({
  userName,
  subscriberIsScreenSharing,
}: ScreenSharePublisherProps): ReactElement<ScreenSharePublisherProps> | null => {
  const { isScreenSharing, setIsScreenSharing } = usePublisher();

  const { session, setScreenShareError } = useContext(OpenTokContext);
  const { onEvent } = useContext(EventStreamContext);

  const stopSharing = useCallback(() => setIsScreenSharing(false), [setIsScreenSharing]);

  useEffect(() => {
    if (subscriberIsScreenSharing) {
      stopSharing();
    }
  }, [subscriberIsScreenSharing, stopSharing]);

  const screenSharePublisherEvents = {
    mediaStopped: stopSharing,
  };

  const onInit = () => {
    onEvent({ type: 'initScreenSharingSuccess' });
  };

  return isScreenSharing ? (
    <VideoStream isScreenSharingStream name={userName}>
      {session ? (
        <OTPublisher
          eventHandlers={screenSharePublisherEvents}
          properties={{
            fitMode: 'contain',
            name: userName,
            publishAudio: false,
            publishVideo: true,
            showControls: false,
            videoSource: 'screen',
          }}
          session={session}
          onError={setScreenShareError}
          onInit={onInit}
        />
      ) : null}
    </VideoStream>
  ) : null;
};

export default ScreenSharePublisher;
