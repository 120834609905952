import type { Error } from 'opentok-react/types/opentok';
import { ReactElement, ReactNode } from 'react';

import { format } from '~/lib/template';
import Modal from '~/components/others/Modal';
import Button from '~/components/shared/atoms/Button';
import CameraBlockedIcon from '~/components/icons/CameraBlockedIcon';

import ArrowRightIcon from '../icons/ArrowRightIcon';

type DisplayError = {
  code?: string;
  message: string;
  title: string;
};

enum KnownErrors {
  HardwareUnavailable = 'OT_HARDWARE_UNAVAILABLE',
  MediaAccessDenied = 'OT_USER_MEDIA_ACCESS_DENIED',
  NoDevicesFound = 'OT_NO_DEVICES_FOUND',
}

const displayErrors: { [err in KnownErrors]: DisplayError } = {
  [KnownErrors.MediaAccessDenied]: {
    message:
      '{conferenceName} requires access to your camera and microphone. Please click the camera-blocked icon in your browser’s address bar and allow {conferenceName} access.',
    title: 'Camera and microphone are blocked',
  },
  [KnownErrors.HardwareUnavailable]: {
    message:
      '{conferenceName} requires access to your camera and microphone. Please turn off other applications or services, such as video call software, that may be using your camera and microphone. Please also close any other Internet tabs or browsers that you aren’t using.',
    title: 'Camera and microphone are being used by another application',
  },
  [KnownErrors.NoDevicesFound]: {
    message:
      '{conferenceName} requires access to your camera and microphone. Ensure your camera and microphone are plugged in and turned on. You may need to check that these have the correct inputs, especially if you are using an external camera and microphone or headset.',
    title: 'No camera and microphone found ',
  },
};

const getDisplayError = (name: string): DisplayError => {
  if (Object.values<string>(KnownErrors).includes(name)) {
    return displayErrors[name as KnownErrors]
  }

  return {
    code: name,
    message:
      'Please refresh the web page and try again. You can also restart your web browser. If you require further assistance, please email our event support team at {supportEmail}.',
    title: 'Something went wrong',
  };
};

type ErrorModalProps = {
  children: ReactNode;
  header?: ReactNode;
  title: string;
};

const ErrorModal = ({ title, header, children }: ErrorModalProps) => {
  return (
    <Modal openOnInit>
      {({ closeModal }) => (
        <>
          <div className="preview__generic">
            {header}
            <h2 className="preview__generic__heading">{title}</h2>
            <div className="preview__generic__text">{children}</div>
          </div>
          <nav className="preview__nav">
            <Button theme="secondary" onClick={closeModal}>
              Dismiss
            </Button>
          </nav>
        </>
      )}
    </Modal>
  );
};

const MediaAccessDeniedModal = () => {
  const { title, message } = getDisplayError(KnownErrors.MediaAccessDenied);

  return (
    <ErrorModal
      header={
        <div
          style={{
            background: '#f1f3f4',
            borderRadius: '0 100px 100px 0',
            display: 'flex',
            marginBottom: '2rem',
            padding: '.5rem',
          }}
        >
          <span style={{ flex: '1' }}>{window.location.host}</span>
          <ArrowRightIcon style={{ height: '24px', width: '24px' }} />
          <CameraBlockedIcon />
        </div>
      }
      title={title}
    >
      {format(message)}
    </ErrorModal>
  );
};

export type OpentokErrorModalProps = {
  error: Error;
};

const OpentokErrorModal = ({
  error,
}: OpentokErrorModalProps): ReactElement<OpentokErrorModalProps> => {
  const { title, message, code } = getDisplayError(error.name);

  switch (error.name) {
    case KnownErrors.MediaAccessDenied:
      return <MediaAccessDeniedModal />;
    default:
      return (
        <ErrorModal title={title}>
          <p style={{ marginBottom: '1rem' }}>{format(message)}</p>
          {code && <h3 className="-b">Error code: {code}</h3>}
        </ErrorModal>
      );
  }
};

export default OpentokErrorModal;
