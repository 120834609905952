import { ReactElement, SVGProps } from 'react';

type LinkedinIconProps = SVGProps<SVGSVGElement>;

const LinkedinIcon = (props: LinkedinIconProps): ReactElement<LinkedinIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <rect height="24" id="Bounds" width="24" x="0" y="0" />
      <path
        d="M7.6,19.3 L4,19.3 L4,9.4 L7.6,9.4 L7.6,19.3 Z M5.8,7.6 C4.8063197,7.6 4,6.78075856 4,5.79167438 C4,4.80259019 4.8063197,4 5.8,4 C6.7936803,4 7.6,4.80259019 7.6,5.79167438 C7.6,6.78075856 6.7936803,7.6 5.8,7.6 Z M19.2965815,19.3 L16.1276243,19.3 L16.1276243,14.5986301 C16.1276243,13.47818 16.1036948,12.0412916 14.4764848,12.0412916 C12.8253453,12.0412916 12.5723757,13.2586106 12.5723757,14.5179061 L12.5723757,19.3 L9.4,19.3 L9.4,9.64217221 L12.4458909,9.64217221 L12.4458909,10.959589 L12.4903315,10.959589 C12.9142265,10.2007828 13.9500345,9.4 15.4952003,9.4 C18.7085981,9.4 19.3,11.398728 19.3,13.9948141 L19.3,19.3 L19.2965815,19.3 Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default LinkedinIcon;
