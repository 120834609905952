import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { Participant } from '~/types/rooms';
import Portrait from '~/components/attendees/Portrait';
import HideOffIcon from '~/components/icons//HideOffIcon';
import HideOnIcon from '~/components/icons//HideOnIcon';
import AcceptIcon from '~/components/icons/AcceptIcon';
import FavouriteOffIcon from '~/components/icons/FavouriteOffIcon';
import FavouriteOnIcon from '~/components/icons/FavouriteOnIcon';

import Button from '../../button/Button';
import IconButton from '../../button/IconButton';
import ScreenSharingIcon from '../../icons/ScreenSharingIcon';

const AnsweredIcon = styled(AcceptIcon)`
  color: var(--c-state-success);
`;

const FilledFavouriteIcon = styled(FavouriteOnIcon)`
  color: var(--c-state-error);
`;

const Avatar = styled(Portrait)`
  display: block;
`;

const Name = styled.h3`
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Info = styled.span`
  color: var(--c-grey);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Timestamp = styled.span`
  text-align: right;
  font-size: var(--fs-xs);
  color: var(--c-grey);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Message = styled.span`
  font-size: var(--fs-sm);
  text-align: left;
  overflow-wrap: break-word;
  overflow: hidden;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

const ModeratorBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Root = styled.div<{ hidden?: boolean }>`
  border-radius: var(--s-bw);
  box-shadow: var(--c-silver) 0 0 0 1px;
  display: grid;
  grid-template:
    'avatar name timestamp'
    'avatar info info'
    'message message message'
    'modbar modbar modbar'
    / min-content auto auto;
  font-size: var(--fs-sm);
  background-color: ${({ hidden = false }) => (!hidden ? `var(--c-white)` : `var(--c-offwhite)`)};
  padding: 1rem;
  z-index: 1;

  > ${Avatar} {
    grid-area: avatar;
    margin-right: 0.5rem;
    align-self: center;
  }
  > ${Name} {
    grid-area: name;
  }
  > ${Info} {
    grid-area: info;
  }
  > ${Timestamp} {
    grid-area: timestamp;
  }
  > ${Message} {
    grid-area: message;
    margin: 1rem 0;
  }
  > ${ModeratorBar} {
    grid-area: modbar;
  }
`;

export type QuestionCardProps = {
  answered?: boolean;
  askedAt: DateTime;
  author: Pick<Participant, 'avatarUrl' | 'name' | 'companyName' | 'id' | 'jobTitle'>;
  body: string;
  className?: string;
  current?: boolean;
  favourite?: boolean;
  hidden?: boolean;
  moderator?: boolean;
  onFavourite?: () => void;
  onHide?: () => void;
  onMarkAnswered?: () => void;
  onShowOnScreen?: () => void;
  onUnfavourite?: () => void;
  onUnhide?: () => void;
};

const QuestionCard = ({
  author,
  body,
  askedAt,
  // I'm not happy about the number of booleans here :(
  moderator = false,
  hidden = false,
  favourite = false,
  current = false,
  answered = false,
  className,
  onShowOnScreen,
  onMarkAnswered,
  onHide,
  onUnhide,
  onFavourite,
  onUnfavourite,
}: QuestionCardProps): ReactElement<QuestionCardProps> => (
  <Root className={className} hidden={hidden}>
    <Avatar avatarUrl={author.avatarUrl} id={author.id} name={author.name} size="sm" />
    <Name>{author.name}</Name>
    <Info>
      {author.jobTitle} at {author.companyName}
    </Info>
    {moderator && <Timestamp>{askedAt.toLocaleString(DateTime.TIME_24_SIMPLE)}</Timestamp>}
    <Message>{body}</Message>
    {moderator && (
      <ModeratorBar>
        {current ? (
          <>
            <IconContainer />
            <Button icon={<AcceptIcon />} size="small" onClick={onMarkAnswered}>
              Mark as answered
            </Button>
          </>
        ) : (
          <>
            <IconContainer>
              {hidden ? (
                <IconButton size="small" title="Unhide" onClick={onUnhide}>
                  <HideOnIcon />
                </IconButton>
              ) : (
                <>
                  <IconButton size="small" title="Hide" onClick={onHide}>
                    <HideOffIcon />
                  </IconButton>
                  {favourite ? (
                    <IconButton size="small" title="Unfavourite" onClick={onUnfavourite}>
                      <FilledFavouriteIcon />
                    </IconButton>
                  ) : (
                    <IconButton size="small" title="Favourite" onClick={onFavourite}>
                      <FavouriteOffIcon />
                    </IconButton>
                  )}
                  {answered && (
                    <IconButton size="small" title="Answered">
                      <AnsweredIcon />
                    </IconButton>
                  )}
                </>
              )}
            </IconContainer>
            {!hidden && (
              <Button
                icon={<ScreenSharingIcon />}
                size="small"
                variant="secondary"
                onClick={onShowOnScreen}
              >
                Show on screen
              </Button>
            )}
          </>
        )}
      </ModeratorBar>
    )}
  </Root>
);

export default QuestionCard;
