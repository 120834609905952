import {
  ClipboardEvent,
  KeyboardEventHandler,
  ReactElement,
  ReactEventHandler,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import IconButton from '../button/IconButton';
import SendIcon from '../icons/SendIcon';

export type RoomTextFormProps = {
  isDisabled?: boolean;
  linksAllowed?: boolean;
  maxLength?: number;
  onSubmit?: (text: string) => void;
  placeholder?: string;
};

const Form = styled.form`
  border-radius: var(--s-bw);
  display: flex;
  flex-wrap: wrap;
  transition: box-shadow 0.25s ease;
`;

const FormError = styled.span`
  color: var(--c-state-error);
  flex-basis: 100%;
  font-size: var(--fs-xs);
  padding: 0 1rem;
`;

const ChatBox = styled.div`
  caret-color: var(--c-brand-primary);
  flex: 1;
  font-size: var(--fs-14);
  margin: 0.5rem 1rem;
  max-height: 7rem;
  overflow-y: scroll;
  word-wrap: anywhere;
  &:empty:before {
    background-color: transparent;
    color: gray;
    content: attr(placeholder);
    position: absolute;
  }
`;

const Submit = styled(IconButton)<{ error: boolean }>`
  align-self: end;
  color: var(--c-brand-primary);
  padding: 0.5rem;
  ${({ error }) =>
    error &&
    css`
      &:disabled {
        color: var(--c-state-error);
      }
    `}
`;

const RoomTextForm = ({
  isDisabled = false,
  linksAllowed = false,
  maxLength = 255,
  onSubmit,
  placeholder,
}: RoomTextFormProps): ReactElement<RoomTextFormProps> => {
  const [text, setText] = useState('');
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const resetChatBox = () => {
    if (chatBoxRef.current !== null) {
      chatBoxRef.current.innerText = '';
    }
  };

  const isMessageTooLong = useMemo(() => {
    return text.length > maxLength;
  }, [maxLength, text]);

  const isForbiddenUrlPresent = useMemo(() => {
    const URL_REGEX = /([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,5})(:[0-9]+)?(\/.*)?/;

    return !linksAllowed && !!text.match(URL_REGEX);
  }, [linksAllowed, text]);

  const isErrorPresent = useMemo(() => {
    return isMessageTooLong || isForbiddenUrlPresent;
  }, [isForbiddenUrlPresent, isMessageTooLong]);

  const handleOnInput: ReactEventHandler<HTMLDivElement> = (e) => {
    setText(e.currentTarget.innerText);
  };

  const handleOnSubmit: ReactEventHandler = (e) => {
    e.preventDefault();
    onSubmit?.(text);
    setText('');
    resetChatBox();
  };

  const handleOnKeyDown: KeyboardEventHandler = (e) => {
    if (!isErrorPresent && e.code === 'Enter' && !e.shiftKey) {
      handleOnSubmit(e);
    }
  };

  const handleOnPaste: ReactEventHandler = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const clipboardText = e.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, clipboardText);
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      {isErrorPresent && (
        <FormError>
          <p>
            {isMessageTooLong && `${t('chat.errorMessage.tooLong')} `}
            {isForbiddenUrlPresent && `${t('chat.errorMessage.linksForbidden')} `}
          </p>
        </FormError>
      )}
      <ChatBox
        ref={chatBoxRef}
        contentEditable
        placeholder={placeholder}
        role="textbox"
        onInput={handleOnInput}
        onKeyDown={handleOnKeyDown}
        onPaste={handleOnPaste}
      />
      <Submit
        disabled={isDisabled || isErrorPresent || text.length < 1}
        error={isErrorPresent}
        title="Submit"
        type="submit"
      >
        <SendIcon />
      </Submit>
    </Form>
  );
};

export default RoomTextForm;
