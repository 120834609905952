import { ReactElement } from 'react';

import ExhibitorConnectionPopup from '~/components/room/ExhibitorConnectionPopup';
import RoomProvider from '~/components/room/RoomProvider';
import RoomSpace from '~/components/room/RoomSpace';
import { PUBLIC_CHAT_CONFIG } from '~/configs/chatConfig';
import { RoomOptions } from '~/types/rooms';

type RoomViewProps = {
  options: RoomOptions;
};

const RoomView = ({ options }: RoomViewProps): ReactElement<RoomViewProps> => {
  return (
    <RoomProvider
      broadcast={options.broadcast}
      calendarEvent={options.calendarEvent}
      chat={options.chat}
      chatConfig={PUBLIC_CHAT_CONFIG}
      debug={options.debug}
      heartbeatInterval={options.heartbeatInterval}
      initialMediaSettings={options.initialMediaSettings}
      moderation={options.moderation}
      occupant={options.occupant}
      opentok={options.opentok}
      participants={options.participants}
      pubnub={options.pubnub}
      questions={options.questions}
      reactions={options.reactions}
      room={options.room}
      sponsor={options.sponsor}
      stream={options.stream}
      user={options.user}
    >
      {options.sponsor && (
        <ExhibitorConnectionPopup attributionSource={options.room.name} sponsor={options.sponsor} />
      )}
      <RoomSpace />
    </RoomProvider>
  );
};

export default RoomView;
