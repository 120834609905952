import { usePubNub } from 'pubnub-react';
import { ReactElement, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import AttendeeRoleTag from '~/components/attendees/AttendeeRoleTag';
import { useFilteredInterlocutors } from '~/lib/rooms/interlocutors';
import urlFor from '~/lib/urlFor';
import { ChatChannel as ChatChannelType, ChatMessage } from '~/types/chat';

import Stack from '../attendees/Stack';
import { extractIdentity } from '../realtime/usePubnub';

const formatMessage = (msg: { data: ChatMessage; type?: string }): string | null | undefined =>
  msg.type === 'video_call' ? 'Started a video call' : msg.data.body;

type ChatChannelProps = {
  active?: boolean;
  channel?: ChatChannelType;
  link?: boolean;
  onClick?: () => void;
};

const StyledChatChannel = styled.div<{
  active?: boolean;
}>`
  position: relative;
  display: grid;
  grid-template-columns: 2.5rem minmax(0, 1fr) min-content;
  align-items: center;
  padding: 0.75rem 1rem;
  transition: 0.25s ease all;
  height: auto;
  cursor: pointer;
  background-color: ${({ active }) => (active ? 'var(--c-offwhite)' : 'var(--c-white)')};

  &:hover {
    background-color: var(--c-offwhite);
    color: var(--c-black);
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  padding-left: 0.75rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.div<{
  active?: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 85%;
  font-size: var(--fs-sm);
  font-weight: ${({ active }) => (active ? 'bold' : '400')};
`;

const Info = styled.div<{
  active?: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: var(--fs-xs);
  max-width: 85%;
  color: ${({ active }) => (active ? 'var(--c-black)' : '')};
`;

const UnreadIndicator = styled.div`
  background-color: var(--c-red);
  border-radius: var(--s-br-chat-indicator);
  font-size: var(--fs-3xs);
  color: var(--c-white);
  padding: 2px 7px;
  font-weight: 600;
`;

const ChatChannel = ({
  channel,
  active = false,
  onClick,
  link = false,
}: ChatChannelProps): ReactElement => {
  const pubnub = usePubNub();
  const identity = extractIdentity(pubnub.getUUID());
  const { id, name, participants, lastMessage, hasUnread } = channel || {};

  const { interlocutors } = useFilteredInterlocutors(identity, participants || []);
  const isGroup = interlocutors.length > 1;

  const interlocutorsWithFlattenedProfile = useMemo(() => {
    return interlocutors.map(
      ({
        name: participantName,
        profile: { id: profileId, avatarUrl, jobTitle, companyName, role },
      }) => ({
        avatarUrl,
        companyName,
        id: profileId,
        jobTitle,
        name: participantName,
        role,
      }),
    );
  }, [interlocutors]);

  const chatChannel = (
    <StyledChatChannel active={active} role="presentation" onClick={onClick}>
      {participants ? (
        <Stack group={isGroup} participants={interlocutorsWithFlattenedProfile} size="md" />
      ) : (
        <Skeleton circle height={42} width={42} />
      )}

      <Content>
        <Name active={active || hasUnread}>
          {name || <Skeleton />}
          {!isGroup && (
            <AttendeeRoleTag
              accreditation={interlocutors[0]?.profile.role}
              visibleAccreditations={['Staff']}
            />
          )}
        </Name>
        <Info active={active || hasUnread}>
          {!name && <Skeleton />}
          {lastMessage?.body && formatMessage({ data: lastMessage })}
        </Info>
      </Content>
      {hasUnread && <UnreadIndicator>NEW</UnreadIndicator>}
    </StyledChatChannel>
  );

  if (link) {
    return (
      <a href={urlFor(`/my/conversations/${id}`)} rel="noreferrer" target="_blank">
        {chatChannel}
      </a>
    );
  }

  return chatChannel;
};

export default ChatChannel;
