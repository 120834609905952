import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

type PinTimeslotIconProps = SVGProps<SVGSVGElement> & {
  isOn: boolean;
};

const PinTimeslotIcon = ({
  ref,
  isOn = false,
  ...rest
}: PinTimeslotIconProps): ReactElement<PinTimeslotIconProps> => (
  <IconBase viewBox="0 0 18 20" {...rest}>
    {isOn ? (
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-3.000000, -1.000000)">
          <path d="M8,1 L8,3 L16,3 L16,1 L18,1 L18,3 L19,3 C20.1,3 21,3.9 21,5 L21,5 L21,19 C21,20.1 20.1,21 19,21 L19,21 L5,21 C3.89,21 3,20.1 3,19 L3,19 L3.01,5 C3.01,3.9 3.89,3 5,3 L5,3 L6,3 L6,1 L8,1 Z M16.06,9 L11.18,13.88 L9.06,11.76 L8,12.82 L11.18,16 L17.12,10.06 L16.06,9 Z" />
        </g>
      </g>
    ) : (
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-3.000000, -1.000000)">
          <path d="M19,3 L18,3 L18,1 L16,1 L16,3 L8,3 L8,1 L6,1 L6,3 L5,3 C3.89,3 3.01,3.9 3.01,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,8 L19,8 L19,19 Z M7.79953319,14.2495332 L11.2502143,14.2495332 L11.2502143,17.7002143 L12.7492807,17.7002143 L12.7492807,14.2495332 L16.1999617,14.2495332 L16.1999617,12.7504668 L12.7492807,12.7504668 L12.7492807,9.29978572 L11.2502143,9.29978572 L11.2502143,12.7504668 L7.79953319,12.7504668 L7.79953319,14.2495332 Z" />
        </g>
      </g>
    )}
  </IconBase>
);

export default PinTimeslotIcon;
