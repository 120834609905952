/* eslint-disable jsx-a11y/media-has-caption */
import { ReactElement } from 'react';

import { useTracking } from '~/lib/analytics';

type StaticPlayerProps = {
  mp4Url: string;
  subtitles?: boolean;
};

/**
 *  Play an MP4 file
 */
const StaticPlayer = ({ mp4Url, subtitles }: StaticPlayerProps): ReactElement => {
  const track = useTracking();

  return (
    <div className="video-player">
      <div className="video-content-wrapper">
        <video
          controls
          className="video-content"
          controlsList="nodownload"
          crossOrigin={subtitles ? 'anonymous' : undefined}
          onEnded={() => track('Finished video')}
          onPause={() => track('Paused video')}
          onPlay={() => track('Played video')} // Video files must be loaded with cross-origin access controls in order to support loading of external subtitles.
        >
          <source src={mp4Url} type="video/mp4" />
          {subtitles && (
            <>
              <track
                default
                kind="subtitles"
                label="English"
                src={`${mp4Url}_en.vtt`}
                srcLang="en"
              />
              <track kind="subtitles" label="French" src={`${mp4Url}_fr.vtt`} srcLang="fr" />
              <track kind="subtitles" label="Portuguese" src={`${mp4Url}_pt.vtt`} srcLang="pt" />
            </>
          )}
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default StaticPlayer;
