import { cssVar, setLightness } from 'polished';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import bodyMixin from '~/style-mixins/typography/bodyMixin';

import { LiveState } from './types';

const LiveLabelList = styled.div`
  display: flex;
  gap: 1rem;
`;

const LiveLabelBase = styled.p<{ variant?: string }>`
  ${bodyMixin}
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: var(--s-bw);
  transform: translateY(-50%);
  z-index: 11;

  ${({ variant = 'primary' }) => {
    if (variant === 'live') {
      return css`
        box-shadow: var(--c-red) 0 0 0 2px;
        color: var(--c-red);
        background-color: ${setLightness(0.97, `${cssVar('--c-red')}`)};
      `;
    }

    if (variant === 'green') {
      return css`
        box-shadow: var(--c-green) 0 0 0 2px;
        color: var(--c-green);
        background-color: ${setLightness(0.97, `${cssVar('--c-green')}`)};
      `;
    }

    return css`
      box-shadow: var(--c-brand-primary) 0 0 0 2px;
      color: var(--c-brand-primary);
      background-color: ${setLightness(0.97, `${cssVar('--c-brand-primary')}`)};
    `;
  }}
`;

const VideoOnDemandLabel = (): ReactElement => {
  const { t } = useTranslation();

  return <LiveLabelBase variant="primary">{t('schedule.label.videoOnDemand')}</LiveLabelBase>;
};

const LiveNowLabel = (): ReactElement => {
  const { t } = useTranslation();

  return <LiveLabelBase variant="live">{t('schedule.label.liveNow')}</LiveLabelBase>;
};

const StreamingAvailableLabel = (): ReactElement => {
  const { t } = useTranslation();

  return <LiveLabelBase variant="primary">{t('schedule.label.streamingAvailable')}</LiveLabelBase>;
};

const PremiumLabel = (): ReactElement => {
  const { t } = useTranslation();

  return <LiveLabelBase variant="green">{t('schedule.label.premium')}</LiveLabelBase>;
};

type LiveLabelProps = {
  className?: string;
  hasArchivedVideo?: boolean;
  liveState: LiveState;
  onlineSession?: boolean;
  premium?: boolean;
};

const LiveLabel = ({
  liveState,
  onlineSession = false,
  hasArchivedVideo = false,
  premium = false,
  className,
}: LiveLabelProps): ReactElement | null => {
  return (
    <LiveLabelList className={className}>
      {onlineSession && liveState === LiveState.BeforeEvent && <StreamingAvailableLabel />}
      {onlineSession && liveState === LiveState.LiveNow && <LiveNowLabel />}
      {hasArchivedVideo && <VideoOnDemandLabel />}
      {premium && <PremiumLabel />}
    </LiveLabelList>
  );
};

export default LiveLabel;
