import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type MediaDevices = {
  audioInputs: MediaDeviceInfo[];
  cameraAvailable: boolean;
  microphoneAvailable: boolean;
  refresh: () => void;
  videoInputs: MediaDeviceInfo[];
};

const enumerateDevices = async () => {
  const audioInputs: MediaDeviceInfo[] = [];
  const videoInputs: MediaDeviceInfo[] = [];

  const devices = await navigator.mediaDevices.enumerateDevices();

  devices.forEach((d) => {
    if (d.label === '') {
      return;
    }
    if (d.kind === 'audioinput') {
      audioInputs.push(d);
      return;
    }
    if (d.kind === 'videoinput') {
      videoInputs.push(d);
    }
  });

  return {
    audioInputs,
    videoInputs,
  };
};

const MediaDevicesContext = createContext<MediaDevices>({
  audioInputs: [],
  cameraAvailable: false,
  microphoneAvailable: false,
  refresh: () => {
    // Default no op
  },
  videoInputs: [],
});

export type MediaDevicesProviderProps = {
  children: ReactNode;
};

export const MediaDevicesProvider = ({
  children,
}: MediaDevicesProviderProps): ReactElement<MediaDevicesProviderProps> => {
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([]);
  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>([]);

  const updateDevices = () => {
    void (async () => {
      const { audioInputs: audio, videoInputs: video } = await enumerateDevices();

      setAudioInputs(audio);
      setVideoInputs(video);
    })();
  };

  useEffect(() => {
    updateDevices();
    navigator.mediaDevices.addEventListener('devicechange', updateDevices);
    return () => navigator.mediaDevices.removeEventListener('devicechange', updateDevices);
  }, []);

  const mediaDevices = useMemo(
    () => ({
      audioInputs,
      cameraAvailable: videoInputs.length > 0,
      microphoneAvailable: audioInputs.length > 0,
      refresh: updateDevices,
      videoInputs,
    }),
    [audioInputs, videoInputs],
  );

  return (
    <MediaDevicesContext.Provider value={mediaDevices}>{children}</MediaDevicesContext.Provider>
  );
};

export const useMediaDevices = (): MediaDevices => {
  return useContext(MediaDevicesContext);
};
