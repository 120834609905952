import { ReactElement, useEffect } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';

import Portrait from '~/components/attendees/Portrait';
import AttendeeProfileForm from '~/components/forms/attendee/AttendeeProfileForm';
import AvatarUpload from '~/components/forms/AvatarUpload';
import Button from '~/components/shared/atoms/Button';
import LoadingIcon from '~/components/icons/LoadingIcon';
import { UpdateAttendeeProfileFormData } from '~/types/attendees';

import Footer from './Footer';
import Header, { Description, Title } from './Header';
import { useOnboarding } from './OnboardingContext';
import OnboardingForm from './OnboardingForm';

export type PublicProfileScreenProps = {
  disableAvatarUploads?: boolean;
  onSubmit: SubmitHandler<UpdateAttendeeProfileFormData>;
};

const PublicProfileScreen = ({
  onSubmit,
  disableAvatarUploads,
}: PublicProfileScreenProps): ReactElement<PublicProfileScreenProps> => {
  const { profileData, countries, industries } = useOnboarding();
  const form = useForm<UpdateAttendeeProfileFormData>({
    defaultValues: profileData,
    mode: 'onChange',
  });

  const { isSubmitting } = form.formState;
  const isSubmitDisabled = isSubmitting || !form.formState.isValid;

  const submitForm = form.handleSubmit(onSubmit);

  return (
    <OnboardingForm onSubmit={submitForm}>
      <Header>
        <Title>Public profile</Title>
        <Description>
          Fill out your profile to make it easy for other attendees to find you.
        </Description>
      </Header>

      {disableAvatarUploads ? (
        <Portrait
          avatarUrl={profileData.avatarUrl ?? ''}
          className="onboarding__portrait"
          size="profile"
        />
      ) : (
        <AvatarUpload
          avatarUrl={profileData.avatarUrl ?? ''}
          form={form}
          helperText={
            form.formState.errors?.avatar && (
              <div className="form__field-error">{form.formState.errors?.avatar.message}</div>
            )
          }
          mode="horizontal"
        />
      )}
      <AttendeeProfileForm
        showSectionHeaders
        countries={countries}
        form={form}
        industries={industries}
      />
      <Footer>
        <Button
          className="onboarding__step__interaction-button -svg"
          isDisabled={isSubmitDisabled}
          size="md"
          theme="tertiary"
          type="submit"
        >
          {isSubmitting && <LoadingIcon />}
          Next
        </Button>
      </Footer>
    </OnboardingForm>
  );
};

export default PublicProfileScreen;
