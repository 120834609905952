import { ReactElement, useState } from 'react';

type DevDebugProps = {
  hiddenByDefault?: boolean;
  info: Record<string, unknown>;
};

const DevDebug = ({ info, hiddenByDefault = true }: DevDebugProps): ReactElement<DevDebugProps> => {
  const [hidden, setHidden] = useState(hiddenByDefault);

  return (
    <div
      style={{
        background: 'rgba(255,255,255,0.8)',
        left: '10rem',
        maxHeight: '60vh',
        overflowY: 'scroll',
        padding: '0.5rem',
        position: 'fixed',
        top: '2.55px',
        zIndex: 100000,
      }}
    >
      <button type="button" onClick={() => setHidden((current) => !current)}>
        {hidden ? 'show' : 'hide'} debug info
      </button>
      {hidden || (
        <pre
          // Non-production debug helper component
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(info, null, 2) }}
          style={{ fontSize: '0.6em' }}
        />
      )}
    </div>
  );
};

export default DevDebug;
