import { useQuery } from '@apollo/client';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useTracking } from '~/lib/analytics';
import { ExhibitorProfileDocument } from '~/operations/catalyst';
import ExternalUrls from '~/components/attendees/ExternalUrls';

import ProfileSidebar from '../attendees/ProfileSidebar';
import ButtonBase from '../button/ButtonBase';
import ExternalIcon from '../icons/ExternalIcon';
import ExhibitorConnectionButton from './ExhibitorConnectionButton';
import ExhibitorLogo from './ExhibitorLogo';

export type ExhibitorProfileSidebarProps = {
  id: string;
};

const ExhibitorProfileSidebar = ({
  id,
}: ExhibitorProfileSidebarProps): ReactElement<ExhibitorProfileSidebarProps> => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(ExhibitorProfileDocument, {
    fetchPolicy: 'cache-first',
    variables: { id },
  });
  const track = useTracking();

  const logoUrl = data?.appearance?.company.logoUrl || undefined;
  const name = data?.appearance?.company.name;
  const connectionState = data?.appearance?.connectionState;
  const alternativeUrl = data?.appearance?.company.alternativeUrl;

  const externalUrls = {
    facebook: data?.appearance?.company.facebookUrl,
    homepage: data?.appearance?.company.homepageUrl,
    instagram: data?.appearance?.company.instagramUrl,
    linkedin: data?.appearance?.company.linkedinUrl,
    'pitch deck': data?.appearance?.pitchDeckUrl,
    twitter: data?.appearance?.company.twitterUrl,
    youtube: data?.appearance?.company.youtubeUrl,
  };

  return (
    <ProfileSidebar
      actions={
        externalUrls && (
          <div className="profile__external">
            {loading ? <Skeleton count={2} /> : <ExternalUrls externalUrls={externalUrls} />}
          </div>
        )
      }
      stickyContent={
        <div className="profile-sidebar__sticky-box">
          <ExhibitorLogo
            alt={t('exhibitors.logoAlt', { exhibitor: data?.appearance?.company.name })}
            logoUrl={logoUrl}
          />
          <div className="profile-sidebar__interactions">
            {loading ? (
              <Skeleton height={48} />
            ) : (
              <>
                {connectionState && (
                  <ExhibitorConnectionButton attributionSource="Partner page" id={id} name={name} />
                )}
                {alternativeUrl && (
                  <ButtonBase
                    as="a"
                    href={alternativeUrl}
                    target="_blank"
                    variant="secondary"
                    onClick={(): void => track('Clicked microsite link')}
                  >
                    <ExternalIcon height="24px" width="24px" />
                    {t('exhibitors.externalSiteLinkText')}
                  </ButtonBase>
                )}
              </>
            )}
          </div>
        </div>
      }
    />
  );
};

export default ExhibitorProfileSidebar;
