import styled from 'styled-components';

const Footer = styled.footer`
  margin-top: 2.75rem;
  margin-top: 2.75rem;
  gap: 1rem;
  display: flex;
  flex-direction: row;
`;

export default Footer;
