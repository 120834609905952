import { createContext } from 'react';

export type AttendeeContextTypes = {
  showAttendeeConnectModal: boolean;
  setShowAttendeeConnectModal: (value: boolean) => void;
};

const AttendeeContext = createContext<AttendeeContextTypes>({
  showAttendeeConnectModal: false,
  setShowAttendeeConnectModal: () => {
    // false
  },
});

export default AttendeeContext;
