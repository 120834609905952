import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/loading/Spinner';
import AttendeeHits from '~/components/search/organisms/AttendeeHits';
import AttendeesSearchFilters from '~/components/search/organisms/AttendeesSearchFilters';
import AlgolaSearchProvider from '~/components/search/providers/AlgoliaSearchProvider';
import SearchTemplate, {
  connectHitsWithPlaceholder,
} from '~/components/search/templates/SearchTemplate';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { TrackingContext } from '~/lib/analytics';
import { useAlgoliaSettings } from '~/lib/avenger/api';

const trackingContextValue = { category: 'Explore' };
const ConnectedAttendeeHits = connectHitsWithPlaceholder(AttendeeHits);

const ExploreAttendeesPage = (): ReactElement => {
  const { t } = useTranslation();
  const { data, error } = useAlgoliaSettings();

  if (error) {
    return <ErrorScreen />;
  }

  if (!data) {
    return <Spinner />;
  }

  return (
    <ErrorBoundary fallback={<ErrorScreen />}>
      <TrackingContext.Provider value={trackingContextValue}>
        <AlgolaSearchProvider
          appId={data.app_id}
          hitsPerPage={24}
          replicaName={data.indexes.attendances}
          searchAPIKey={data.key}
        >
          <SearchTemplate
            filters={(): ReactElement => <AttendeesSearchFilters />}
            searchBoxPlaceholder={t('explore.searchAttendees')}
          >
            <ConnectedAttendeeHits />
          </SearchTemplate>
        </AlgolaSearchProvider>
      </TrackingContext.Provider>
    </ErrorBoundary>
  );
};

export default ExploreAttendeesPage;
