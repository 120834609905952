import { ReactElement, ReactNode } from 'react';

import FlagIcon from '~/components/icons/FlagIcon';
import { useTracking } from '~/lib/analytics';
import BuildingsIcon from '~/components/icons/BuildingsIcon';
import CopyIcon from '~/components/icons/CopyIcon';
import LocationIcon from '~/components/icons/LocationIcon';
import Linkify from 'linkify-react';
import SkeletonProfileHead from './SkeletonProfileHead';

type ProfileHeadProps = {
  description?: string;
  email?: string;
  // Can contain links
  endorsementLogoUrl?: string;
  industry?: string;
  info?: ReactNode;
  location?: string;
  name?: string;
  role?: string;
  standInfo?: string;
  standUrl?: string;
};

const ProfileHead = ({
  role,
  name,
  info,
  endorsementLogoUrl,
  email,
  description,
  location,
  industry,
  standInfo,
  standUrl,
}: ProfileHeadProps): ReactElement<ProfileHeadProps> => {
  const track = useTracking();

  if (!name) {
    return <SkeletonProfileHead />;
  }

  return (
    <article className="profile-head">
      {role ? <span className="profile-head__role">{role}</span> : null}
      <h1 className="profile-head__name">{name}</h1>
      {info ? <h2 className="profile-head__info">{info}</h2> : null}
      {endorsementLogoUrl && (
        <span className="profile-head__logo-container">
          <img alt="Endorsement logo" src={endorsementLogoUrl} />
        </span>
      )}
      {email && (
        <div className="profile-head__email">
          <a href={`mailto:${email}`}>{email}</a>
          <button
            className="profile-head__copy-button"
            type="button"
            onClick={() => {
              void navigator.clipboard.writeText(email);
            }}
          >
            <CopyIcon className="profile-head__copy-icon" />
          </button>
        </div>
      )}
      {description && (
        <div className="profile-head__description">
          <Linkify tagName="p">{description}</Linkify>
        </div>
      )}
      <div className="profile-head__summary-row">
        {location && (
          <span className="profile-head__summary-item">
            <FlagIcon />
            {location}
          </span>
        )}
        {industry && (
          <span className="profile-head__summary-item">
            <BuildingsIcon />
            {industry}
          </span>
        )}
        {standInfo && (
          <span className="profile-head__summary-item">
            <LocationIcon />
            {standUrl ? (
              <a
                href={standUrl}
                rel="noreferrer"
                target="_blank"
                onClick={() => track('Clicked location link')}
              >
                {standInfo}
              </a>
            ) : (
              standInfo
            )}
          </span>
        )}
      </div>
    </article>
  );
};

export default ProfileHead;
