import clsx from 'clsx';
import { ReactElement } from 'react';

import BaseButton, { BaseButtonProps } from '~/components/shared/atoms/BaseButton';

type ButtonProps = {
  isSelected?: boolean;
  size?: 'xs' | 'sm' | 'small' | 'md' | 'medium' | 'lg';
  theme?: 'primary' | 'secondary' | 'tertiary' | 'invisible' | 'danger' | 'hazard' | 'ghost';
} & BaseButtonProps;

/**
 * @deprecated use `components/button/Button`
 */
const Button = ({
  className,
  theme,
  size,
  isSelected = false,
  isDisabled = false,
  ...otherButtonProps
}: ButtonProps): ReactElement => {
  const internalClassName = clsx(
    'btn',
    theme && `-${theme}`,
    size && `-${size}`,
    { '-disabled': isDisabled, '-selected': isSelected },
    className,
  );

  // Disabling eslint here, because we extends it's props type
   
  return <BaseButton className={internalClassName} isDisabled={isDisabled} {...otherButtonProps} />;
};

export default Button;
