import { ReactElement } from 'react';
import { useMe } from '~/lib/avenger/api';

import TabSwitcher from '~/components/shared/atoms/TabSwitcher';
import urlFor from '~/lib/urlFor';

type SwitcherProps = {
  current: 'profile' | 'attendees' | 'timeslots' | 'connections' | 'meeting_room';
};

const Switcher = ({ current }: SwitcherProps): ReactElement<SwitcherProps> => {
  const { data: me } = useMe();

  return (
    <TabSwitcher>
      <TabSwitcher.Link href={urlFor('/my/exhibitor')} selected={current === 'profile'}>
        Profile
      </TabSwitcher.Link>
      <TabSwitcher.Link href={urlFor('/my/exhibitor/attendees')} selected={current === 'attendees'}>
        Attendees
      </TabSwitcher.Link>
      <TabSwitcher.Link href={urlFor('/my/exhibitor/timeslots')} selected={current === 'timeslots'}>
        Timeslots
      </TabSwitcher.Link>
      {me?.features.partner_leads && (
        <TabSwitcher.Link
          href={urlFor('/my/exhibitor/connections')}
          selected={current === 'connections'}
        >
          Leads
        </TabSwitcher.Link>
      )}
    </TabSwitcher>
  );
};

export default Switcher;
