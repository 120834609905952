import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';

import Button from '../button/Button';
import Spinner from '../loading/Spinner';
import LoginForm from './LoginForm';
import LoginPanel from './LoginPanel';

export type SSOLoginPanelProps = {
  fallbackMethod?: string;
};

const SSOLoginPanel = ({
  fallbackMethod,
}: SSOLoginPanelProps): ReactElement<SSOLoginPanelProps> => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const isMobile = searchParams.get('mobile')?.match(/^(android|ios)$/i);
  const mobileRedirect = searchParams.get('mobile')?.match(/^android$/i);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const startRedirection = (): () => void => {
    setIsRedirecting(true);
    const closeTimeout = setTimeout(() => {
      setIsRedirecting(false);
    }, 30000);

    return () => {
      clearTimeout(closeTimeout);
      setIsRedirecting(false);
    };
  };

  useEffect(() => {
    if (mobileRedirect) {
      const redirect = startRedirection();
      window.location.href = 'https://omnia.summitengine.com/social/connect/okta';
      return redirect;
    }

    return () => {
      // No cleanup needed
    };
  }, [mobileRedirect]);

  const onSubmit = (): void => {
    startRedirection();
  };

  return (
    <LoginPanel size="small">
      <LoginForm
        action="https://omnia.summitengine.com/social/connect/okta"
        method="POST"
        onSubmit={onSubmit}
      >
        <Button size="large" type="submit">
          {isRedirecting ? <Spinner size="2rem" /> : t('login.ssoLogIn')}
        </Button>
        {!isMobile && !!fallbackMethod && (
          <Link href={`/${fallbackMethod}`}>{t('login.troubleLoggingIn')}</Link>
        )}
      </LoginForm>
    </LoginPanel>
  );
};

export default SSOLoginPanel;
