import { Event, SignalEvent } from 'opentok-react/types/opentok';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMediaDevices } from '~/lib/opentok/mediadevices';
import { PublisherContext } from '~/lib/rooms/publisher';
import { MediaSettings } from '~/types/rooms';

import { OpenTokContext } from './OpenTokSession';
import withOTSession from './withOTSession';

export type PublisherProviderProps = {
  children: ReactNode;
  initialMediaSettings?: MediaSettings;
};

const isSignalEvent = (event: Event): event is SignalEvent => {
  return (event as SignalEvent).data !== undefined;
};

const PublisherProvider = ({
  children,
  initialMediaSettings,
}: PublisherProviderProps): ReactElement<PublisherProviderProps> => {
  const [videoConsent, setVideoConsent] = useState(true);

  const [isPublishingVideo, setIsPublishingVideo] = useState(
    initialMediaSettings?.isPublishingVideo ?? false,
  );
  const [isPublishingAudio, setIsPublishingAudio] = useState(
    initialMediaSettings?.isPublishingAudio ?? false,
  );
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isRaisingHand, setIsRaisingHand] = useState(false);
  const [isParticipating, setIsParticipating] = useState(
    initialMediaSettings?.isParticipating ?? true,
  );
  const [countdown, setCountdown] = useState<number | null>(null);

  const { session, publishError, screenShareError } = useContext(OpenTokContext);
  const { cameraAvailable, microphoneAvailable } = useMediaDevices();

  const [videoSource, setVideoSource] = useState(initialMediaSettings?.videoSource);
  const toggleVideo = useCallback(() => {
    if (cameraAvailable && !publishError) {
      setIsPublishingVideo((current) => !current);
    }
  }, [cameraAvailable, publishError]);
  const toggleAudio = useCallback(() => {
    if (microphoneAvailable && !publishError) {
      setIsPublishingAudio((current) => !current);
    }
  }, [microphoneAvailable, publishError]);

  const toggleScreenSharing = useCallback(() => {
    setIsScreenSharing((current) => !current);
  }, []);

  const toggleParticipating = () => {
    setIsParticipating((current) => !current);
    setIsScreenSharing(false);
  };

  const toggleRaisingHand = useCallback(() => {
    if (!session) return;

    session?.signal(
      {
        type: isRaisingHand ? 'loweredHand' : 'raisedHand',
      },
      (error) => {
        if (error) {
           
          console.log(`signal error ${error}`);
          return;
        }
        setIsRaisingHand((prevState) => !prevState);
      },
    );
  }, [session, isRaisingHand]);

  const startCountdown = useCallback(
    (delayInMs: number) => {
      if (!session) return;
      session?.signal({ data: String(delayInMs), type: 'countdown' });
    },
    [session],
  );

  const clearCountdown = () => {
    setCountdown(null);
  };

  const sessionConnection = session?.connection;
  useEffect(() => {
    if (!sessionConnection || !session)
      return () => {
        // No cleanup needed
      };

    const {
      connection: { connectionId },
    } = session;

    const handleAudioSignal = (e: Event, shouldBeMuted: boolean) => {
      if (isSignalEvent(e) && connectionId === e.data) {
        setIsPublishingAudio(!shouldBeMuted);
      }
    };

    const handleVideoSignal = (e: Event) => {
      if (isSignalEvent(e) && connectionId === e.data) {
        setIsPublishingVideo(false);
      }
    };

    const handleCountdown = (e: Event) => {
      // OpenTok types does not have "data" property here, but take my word for it, it is there.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const duration = parseInt(e.data, 10);
      setCountdown(Date.now() + duration);
    };

    const handleMute = (e: Event) => handleAudioSignal(e, true);
    const handleUnmute = (e: Event) => handleAudioSignal(e, false);

    session.on('signal:mute', handleMute);
    session.on('signal:unmute', handleUnmute);
    session.on('signal:video_off', handleVideoSignal);
    session.on('signal:countdown', handleCountdown);

    return () => {
      session.off('signal:mute', handleMute);
      session.off('signal:unmute', handleUnmute);
      session.off('signal:video_off', handleVideoSignal);
      session.off('signal:countdown', handleCountdown);
    };
  }, [sessionConnection, session]);

  useEffect(() => {
    if (publishError) {
      setIsPublishingAudio(false);
      setIsPublishingVideo(false);
    }
  }, [publishError]);

  useEffect(() => {
    if (screenShareError) {
      setIsScreenSharing(false);
    }
  }, [screenShareError]);

  const publisherContextValue = useMemo(
    () => ({
      clearCountdown,
      countdown,
      isParticipating,
      isPublishingAudio,
      isPublishingVideo,
      isRaisingHand,
      isScreenSharing,
      setIsScreenSharing,
      setVideoConsent,
      setVideoSource,
      startCountdown,
      toggleAudio,
      toggleParticipating,
      toggleRaisingHand,
      toggleScreenSharing,
      toggleVideo,
      videoConsent,
      videoSource,
    }),
    [
      countdown,
      isParticipating,
      isPublishingAudio,
      isPublishingVideo,
      isRaisingHand,
      isScreenSharing,
      startCountdown,
      toggleRaisingHand,
      toggleScreenSharing,
      toggleAudio,
      videoSource,
      videoConsent,
      toggleVideo,
    ],
  );

  return (
    <PublisherContext.Provider value={publisherContextValue}>{children}</PublisherContext.Provider>
  );
};

export default withOTSession<PublisherProviderProps>(PublisherProvider);
