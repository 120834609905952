import { ReactElement } from 'react';
import Countdown from 'react-countdown';

import CountdownLabel from '~/components/shared/atoms/CountdownLabel';

type RoomCountdownProps = {
  countdownEndsAt: number;
  onComplete: () => void;
};
const RoomCountdown = ({
  countdownEndsAt,
  onComplete,
}: RoomCountdownProps): ReactElement<RoomCountdownProps> => {
  return (
    <div className="room-countdown">
      <Countdown
        date={countdownEndsAt}
        renderer={({ seconds }) => (
          <h1 className="room-countdown__heading">
            Starting session in{' '}
            <CountdownLabel className="room-countdown__seconds">{seconds}</CountdownLabel>
          </h1>
        )}
        onComplete={onComplete}
      />
    </div>
  );
};

export default RoomCountdown;
