import useRoom from './useRoom';

type Permissions = {
  chat: {
    canReadMessages?: boolean;
    canSendMessages?: boolean;
    channelId: string | null | undefined;
  };
  isAdmin: boolean;
  isModerator: boolean;
  questions: { channelId: string | null | undefined };
  reactions: { channelId: string | null | undefined };
  videoChat: {
    apiKey: string | null | undefined;
    canPublish: boolean;
    canRaiseHand: boolean;
    canShareScreen: boolean;
    maxPublishers: number | null | undefined;
    sessionId: string | null | undefined;
    token: string | null | undefined;
  };
};

const defaultPermissions = {
  chat: { channelId: null },
  isAdmin: false,
  isModerator: false,
  questions: { channelId: null },
  reactions: { channelId: null },
  videoChat: {
    apiKey: null,
    canPublish: false,
    canRaiseHand: false,
    canShareScreen: false,
    maxPublishers: null,
    sessionId: null,
    token: null,
  },
};

const usePermissions = (): Permissions => {
  const {
    chat: chatConfig,
    occupant,
    opentok,
    questions: questionsConfig,
    reactions: reactionsConfig,
    room,
    user,
  } = useRoom();
  if (!room || !occupant || !user) return defaultPermissions;

  const { isAdmin, isModerator } = occupant;
  const chat = {
    canReadMessages: occupant.canReadMessages,
    canSendMessages: occupant.canSendMessages,
    channelId: chatConfig?.channel,
  };
  const questions = { channelId: questionsConfig?.channel };
  const reactions = { channelId: reactionsConfig?.channel };
  const videoChat = {
    apiKey: opentok?.apiKey,
    canPublish: occupant.canShareVideo,
    canRaiseHand: occupant.canRaiseHand,
    canShareScreen: occupant.canShareScreen,
    maxPublishers: room?.maxPublishers,
    sessionId: opentok?.sessionId,
    token: opentok?.token,
  };

  return {
    chat,
    isAdmin,
    isModerator,
    questions,
    reactions,
    videoChat,
  };
};

export default usePermissions;
