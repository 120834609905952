import { ReactElement, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { formatMessage } from '~/components/chat/ChatUtils';
import usePubnubMessages from '~/components/realtime/usePubnubMessages';
import usePubnubMessagesCounter from '~/components/realtime/usePubnubMessagesCounter';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { useTracking } from '~/lib/analytics';
import usePermissions from '~/lib/rooms/usePermissions';
import { useCurrentUser } from '~/lib/user';
import { Message } from '~/types/chat';

import RoomChatStream from '../chat/RoomChatStream';
import RoomTextForm from '../RoomTextForm';
import DiscussionPanelTab from './DiscussionPanelTab';

export type ChatTabProps = {
  channel: string;
  messageMaxLength?: number;
};

const ChatTab = ({ channel, messageMaxLength = 255 }: ChatTabProps): ReactElement => {
  const user = useCurrentUser();
  const { isModerator } = usePermissions();
  const { t } = useTranslation();
  const track = useTracking();

  const { messages, historyLoaded, sendMessage, fetchMessages, addMessageAction, isFetching } =
    usePubnubMessages<Message>({
      channel,
      includeMessageActions: true,
      user,
    });

  usePubnubMessagesCounter({ channel });

  const sendMessageAction = useMemo(() => {
    return (actionType: string, actionValue: string, messageTimeToken: string) => {
      addMessageAction({
        action: {
          type: actionType,
          value: actionValue,
        },
        messageTimetoken: messageTimeToken,
      });
    };
  }, [addMessageAction]);

  const submitMessage = useMemo(() => {
    return (message: string) => {
      track('Sent chat message');
      sendMessage(formatMessage(message));
    };
  }, [track, sendMessage]);

  return (
    <DiscussionPanelTab
      actions={
        <RoomTextForm
          linksAllowed={isModerator}
          maxLength={messageMaxLength}
          placeholder={t('chat.placeholder.typeMessage')}
          onSubmit={submitMessage}
        />
      }
    >
      <ErrorBoundary fallback={<ErrorScreen />}>
        <RoomChatStream
          historyLoaded={historyLoaded}
          isFetching={isFetching}
          loadMore={fetchMessages}
          messages={messages}
          sendMessageAction={sendMessageAction}
        />
      </ErrorBoundary>
    </DiscussionPanelTab>
  );
};

export default ChatTab;
