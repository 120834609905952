import { createContext, useContext } from 'react';

import { RoomConfig } from '~/operations/catalyst';

export const RoomConfigContext = createContext<RoomConfig | undefined>(undefined);

export const useRoomConfig = (): RoomConfig => {
  const config = useContext(RoomConfigContext);
  if (!config) {
    throw new Error('useRoomConfig called outside of RoomConfigContext');
  }
  return config;
};
