import clsx from 'clsx';
import { ReactElement } from 'react';

import ChatProvider, { useChat } from '~/components/chat/ChatProvider';
import { useTracking } from '~/lib/analytics';
import { Participant } from '~/types/rooms';
import ConversationsIcon from '~/components/icons/ConversationsIcon';
import urlFor from '~/lib/urlFor';

import ChatChannel from '../conversations/ChatChannel';
import ChatChannelList from '../conversations/ChatChannelList';
import Popper from '../popper/Popper';

export type ConversationChannel = {
  id: string;
  name: string;
  participants: Participant[];
  pubnubId: string;
};

const ChatMenu = (): ReactElement | null => {
  const track = useTracking();
  const { channels, fetchRecent, isLoading, unreadCount } = useChat();

  const onOpen = (callback: () => void) => {
    fetchRecent(5);
    callback();
  };

  const headers = () => (
    <>
      <span>My chats</span>
      <a href={urlFor('/my/conversations')}>
        <span>View all</span>
      </a>
    </>
  );

  const popoutContent = (open: boolean | undefined) => {
    if (!open) {
      return null;
    }

    return (
      <ChatChannelList>
        {channels.length === 0 &&
          (isLoading ? (
            <>
              <ChatChannel />
              <ChatChannel />
              <ChatChannel />
            </>
          ) : (
            <div className="empty">
              <span>You don’t have any conversations yet.</span>
            </div>
          ))}
        {channels.map((channel) => (
          <ChatChannel
            key={channel.pubnubId}
            link
            channel={channel}
            onClick={() => track('Clicked chat channel', channel.name)}
          />
        ))}
      </ChatChannelList>
    );
  };

  return (
    <Popper greyOverlay headers={headers} popoutContent={popoutContent}>
      {(activationClassName, activatePopper, isPopperPanelOpen = false) => (
        <button
          className={clsx(
            'indicator__icon',
            {
              'indicator__icon--on': isPopperPanelOpen,
            },
            activationClassName,
          )}
          type="button"
          onClick={() => onOpen(activatePopper)}
        >
          {unreadCount > 0 && (
            <div className="attention-indicator -count">
              <span>{unreadCount}</span>
            </div>
          )}
          <ConversationsIcon />
        </button>
      )}
    </Popper>
  );
};

export type ChatMenuProps = {
  notifications: boolean;
};

const ChatMenuWrapper = ({ notifications }: ChatMenuProps): ReactElement<ChatMenuProps> => {
  return (
    <ChatProvider listen={notifications}>
      <ChatMenu />
    </ChatProvider>
  );
};

export default ChatMenuWrapper;
