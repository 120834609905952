import { ReactElement, SVGProps } from 'react';

type GearIconProps = SVGProps<SVGSVGElement>;

const GearIcon = (props: GearIconProps): ReactElement<GearIconProps> => {
  return (
    <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <g fill="none" fillRule="evenodd" id="Icon-/-Settings" stroke="none" strokeWidth="1">
        <path
          d="M13.8506174,3 C14.0753352,3 14.2736157,3.16714286 14.3132718,3.37285714 L14.6569579,5.75142857 C15.2253618,5.98285714 15.7408909,6.27857143 16.216764,6.63857143 L18.5168171,5.73857143 C18.7283162,5.66142857 18.9662527,5.73857143 19.085221,5.93142857 L20.9358384,9.04285714 C21.0548067,9.23571429 21.0019319,9.48 20.8300888,9.62142857 L18.8737218,11.1128571 C18.9133779,11.4085714 18.9398153,11.7042857 18.9398153,12 C18.9398153,12.2957143 18.9133779,12.5914286 18.8737218,12.8742857 L20.8300888,14.3657143 C21.0019319,14.5071429 21.0548067,14.7514286 20.9358384,14.9442857 L19.085221,18.0557143 C18.9662527,18.2485714 18.7283162,18.3257143 18.5168171,18.2485714 L16.216764,17.3485714 C15.7408909,17.7085714 15.2121431,18.0042857 14.6569579,18.2357143 L14.3132718,20.6142857 C14.2736157,20.8328571 14.0753352,21 13.8506174,21 L10.1493826,21 C9.92466475,21 9.72638431,20.8328571 9.68672823,20.6271429 L9.34304214,18.2485714 C8.77463821,18.0171429 8.25910908,17.7214286 7.78323603,17.3614286 L5.48318295,18.2614286 C5.27168381,18.3385714 5.03374729,18.2614286 4.91477903,18.0685714 L3.06416161,14.9571429 C2.94519335,14.7642857 2.99806813,14.52 3.16991118,14.3785714 L5.12627816,12.8871429 C5.08662207,12.5914286 5.06018468,12.2957143 5.06018468,12 C5.06018468,11.7042857 5.08662207,11.4085714 5.12627816,11.1257143 L3.16991118,9.63428571 C2.99806813,9.49285714 2.94519335,9.24857143 3.06416161,9.05571429 L4.91477903,5.94428571 C5.03374729,5.75142857 5.27168381,5.67428571 5.48318295,5.75142857 L7.78323603,6.65142857 C8.25910908,6.29142857 8.78785691,5.99571429 9.34304214,5.76428571 L9.68672823,3.38571429 C9.72638431,3.16714286 9.92466475,3 10.1493826,3 L13.8506174,3 Z M12,9.42857143 C10.5857143,9.42857143 9.42857143,10.5857143 9.42857143,12 C9.42857143,13.4142857 10.5857143,14.5714286 12,14.5714286 C13.4142857,14.5714286 14.5714286,13.4142857 14.5714286,12 C14.5714286,10.5857143 13.4142857,9.42857143 12,9.42857143 Z"
          fill="#333333"
          id="Shape"
        />
      </g>
    </svg>
  );
};

export default GearIcon;
