import { ReactElement, SVGProps } from 'react';

type RoomIconProps = SVGProps<SVGSVGElement>;

const RoomIcon = (props: RoomIconProps): ReactElement<RoomIconProps> => (
  <svg height="24px" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <rect height="24" width="24" x="0" y="0" />
      <path
        className="room-icon"
        d="M21,3 L3,3 C1.89,3 1,3.89 1,5 L1,19 C1,20.11 1.89,21 3,21 L21,21 C22.11,21 23,20.11 23,19 L23,5 C23,3.89 22.11,3 21,3 Z M21,4.98 L21,19.02 L3,19.02 L3,4.98 L21,4.98 Z M10.5,13 C9.335,13 7,13.585 7,14.75 L7,16 L14,16 L14,14.75 C14,13.585 11.665,13 10.5,13 Z M14.5,13 C14.355,13 14.19,13.01 14.015,13.025 C14.595,13.445 15,14.01 15,14.75 L15,16 L18,16 L18,14.75 C18,13.585 15.665,13 14.5,13 Z M14.5,9 C13.67,9 13,9.67 13,10.5 C13,11.33 13.67,12 14.5,12 C15.33,12 15.995,11.33 15.995,10.5 C15.995,9.67 15.33,9 14.5,9 Z M10.5,9 C9.67,9 9,9.67 9,10.5 C9,11.33 9.67,12 10.5,12 C11.33,12 11.995,11.33 11.995,10.5 C11.995,9.67 11.33,9 10.5,9 Z"
        fill="#333333"
      />
    </g>
  </svg>
);

export default RoomIcon;
