import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import type Pubnub from 'pubnub';
import {
  Fragment,
  ReactElement,
  UIEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import { CHAT_MESSAGES_GROUP_FORMAT } from '~/utils/dateFormats';
import { ABSTRACT_TYPING_INDICATOR_TYPE } from '~/components/chat/ChatUtils';
import { extractIdentity } from '~/components/realtime/usePubnub';
import { IS_TYPING } from '~/constants';
import useCompare from '~/lib/useCompare';
import type { ChatParticipant, ChatParticipants, Message as MessageType } from '~/types/chat';
import Spinner from '../loading/Spinner';
import MessagesGroup from './MessagesGroup';

const groupByUser = <T extends { publisher: { uuid: string } }>(messages: T[]) =>
  messages.reduce<T[][]>((acc, message) => {
    if (acc && acc.length && acc[acc.length - 1][0].publisher.uuid === message.publisher.uuid) {
      last(acc)?.push(message);
    } else {
      acc.push([message]);
    }

    return acc;
  }, []);

const StyledChatStream = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

type ChatStreamProps = {
  historyLoaded: boolean;
  isErrored: boolean;
  isFetching: boolean;
  loadMore: () => void;
  messages: MessageType[];
  participants: ChatParticipants;
  pubnub: Pubnub;
  userIdentity: string;
};

const ChatStream = ({
  messages = [],
  loadMore,
  participants = [],
  historyLoaded,
  userIdentity,
  pubnub,
  isFetching,
  isErrored,
}: ChatStreamProps): ReactElement<ChatStreamProps> => {
  const chatStream = useRef<HTMLDivElement>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  useEffect(() => {
    if (isTyping) {
      const timeout = setTimeout(() => {
        setIsTyping(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }

    return () => {
      // No cleanup needed
    };
  }, [isTyping]);

  const isLastMessageChanged = useCompare(messages.length ? messages[messages.length - 1] : '');

  const participantsByIdentity = useMemo(() => {
    if (!participants) return {};
    return participants.reduce<Record<string, ChatParticipant>>((acc, member) => {
      const memberIdentity = member.identity;
      acc[memberIdentity] = member;
      return acc;
    }, {});
  }, [participants]);

  const scrollToBottom = useCallback(() => {
    if (chatStream && chatStream.current) {
      chatStream.current.scrollTop = chatStream.current.scrollHeight;
    }
  }, []);

  const signalHandler = useCallback(
    (e: any) => {
      if (e.message === IS_TYPING && extractIdentity(e.publisher) !== userIdentity) {
        setIsTyping(true);
        scrollToBottom();
      }
    },
    [userIdentity, scrollToBottom],
  );

  useEffect(() => {
    if (!pubnub) return () => {
      // No cleanup needed
    };
    const signalListener = {
      signal: signalHandler,
    };

    pubnub.addListener(signalListener);

    return () => {
      pubnub.removeListener(signalListener);
    };
  }, [pubnub, signalHandler]);

  useEffect(() => {
    if (isLastMessageChanged) {
      setIsTyping(false);
    }
  }, [isLastMessageChanged]);

  useEffect(() => {
    if (!isLastMessageChanged) return;
    if (
      (messages && last(messages)?.publisher?.identity === userIdentity) ||
      shouldScrollToBottom
    ) {
      scrollToBottom();
    }
  }, [messages, isLastMessageChanged, scrollToBottom, userIdentity, shouldScrollToBottom]);

  useLayoutEffect(() => {
    if (isTyping) {
      scrollToBottom();
    }
  }, [scrollToBottom, isTyping]);

  const otherUserIdentities = useMemo(() => {
    return Object.keys(participantsByIdentity).filter(
      (participantId) => participantId !== userIdentity,
    );
  }, [userIdentity, participantsByIdentity]);

  const messagesWithTypingIndicator = useMemo(() => {
    if (isTyping) {
      const participant = participantsByIdentity[otherUserIdentities[0]];
      return [
        ...messages,
        {
          data: null,
          id: 'typing-indicator',
          publisher: { ...participant, uuid: participant.identity },
          sent_at: new Date().toISOString(),
          type: ABSTRACT_TYPING_INDICATOR_TYPE,
        } as const,
      ];
    }
    return messages;
  }, [isTyping, messages, participantsByIdentity, otherUserIdentities]);

  const groupedByDate = useMemo(() => {
    const dedupedMessages = uniqBy(messagesWithTypingIndicator, 'id');

    return groupBy(dedupedMessages, (message) => DateTime.fromISO(message.sent_at).startOf('day'));
  }, [messagesWithTypingIndicator]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const elem = e.currentTarget;
    if (elem.scrollHeight - elem.scrollTop === elem.clientHeight) {
      setShouldScrollToBottom(true);
    } else if (shouldScrollToBottom) {
      setShouldScrollToBottom(false);
    }
  };

  return (
    <StyledChatStream ref={chatStream} onScroll={handleScroll}>
      {isFetching && !isErrored && !messages.length && (
        <div className="chat-loader">
          <Spinner size="5rem" />
        </div>
      )}
      {isErrored && !messages.length && (
        <div className="chat-error">
          <h3 className="-b">Oops. Something went wrong!</h3>
          <p>Please refresh the page and try again.</p>
        </div>
      )}
      <InfiniteScroll
        initialLoad
        isReverse
        hasMore={!historyLoaded}
        loadMore={() => loadMore()}
        threshold={35}
        useWindow={false}
      >
        {Object.keys(groupedByDate).map((day) => (
          <Fragment key={day}>
            <div className="chat-stream__day">
              {DateTime.fromISO(day).toLocaleString(CHAT_MESSAGES_GROUP_FORMAT)}
            </div>

            {groupByUser(groupedByDate[day]).map((group) => (
              <MessagesGroup
                key={group[0].sent_at}
                group={group}
                participantsByIdentity={participantsByIdentity}
                userIdentity={userIdentity}
              />
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
    </StyledChatStream>
  );
};

export default ChatStream;
