import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import NewMessagesCounter from '~/components/chat/NewMessagesCounter';
import { useRoom } from '~/lib/rooms';

import DiscussionPanelContent from './DiscussionPanelContent';
import DiscussionPanelTabs from './DiscussionPanelTabs';
import { generateTabs } from './discussionPanelTabsConfig';

export type DiscussionPanelProps = {
  collapsed?: boolean;
  onTabOpen?: () => void;
};

const Panel = styled.div<{ collapsed?: boolean }>`
  border-radius: var(--s-bw);
  border: 1px solid var(--c-silver);
  display: flex;
  flex-direction: column;
  position: relative;
  transition: width 0.325s ease;
  width: var(--s-room-sidebar-x);
  height: 100%;

  ${(props) =>
    props.collapsed &&
    css`
      width: 3rem;
      border-color: var(--c-white);
    `}
`;

const DiscussionPanel = ({
  collapsed,
  onTabOpen,
}: DiscussionPanelProps): ReactElement<DiscussionPanelProps> => {
  const {
    chat,
    questions,
    room: { featuresBackstage },
  } = useRoom();

  const tabs = generateTabs({
    withChat: Boolean(chat),
    withInfo: featuresBackstage,
    withQA: Boolean(questions),
  });

  return (
    <Panel collapsed={collapsed}>
      <NewMessagesCounter>
        <DiscussionPanelTabs isSidebarOpen={!collapsed} tabs={tabs} onTabOpen={onTabOpen}>
          {({ activeTab }) =>
            !collapsed && <DiscussionPanelContent attendeeSettingsEnabled activeTab={activeTab} />
          }
        </DiscussionPanelTabs>
      </NewMessagesCounter>
    </Panel>
  );
};

export default DiscussionPanel;
