import { ReactElement } from 'react';

import AttendeeCard from '~/components/attendees/AttendeeCard';
import BlockAttendeeButton from '~/components/attendees/BlockAttendeeButton';
import OptionsBlock from '~/components/forms/OptionsBlock';
import AlertIcon from '~/components/icons/AlertIcon';
import { Participant } from '~/types/rooms';

type MinglePartnerProps = {
  attendee: Participant;
  onMingleEnded: () => void;
  reportPartner: () => void;
};

const MinglePartner = ({
  attendee,
  reportPartner,
  onMingleEnded,
}: MinglePartnerProps): ReactElement<MinglePartnerProps> => {
  return (
    <aside className="mingle-aside">
      <div className="mingle-aside__content">
        <div className="mingle-aside__partner">
          <AttendeeCard
            avatarUrl={attendee.avatarUrl ?? ''}
            cardRole={attendee.role}
            companyName={attendee.companyName}
            firstName={attendee.name}
            id={attendee.id}
            theme="dense"
          />
          {(attendee?.seekingInterests?.length || 0) > 0 && (
            <div className="mingle-room__prompt">
              <div className="mingle-room__prompt-header">Ask {attendee.name} about:</div>
              <div className="mingle-room__prompt-interests">
                <OptionsBlock<string>
                  availableOptions={attendee?.seekingInterests ?? []}
                  selectedOptions={attendee?.seekingInterests ?? []}
                />
              </div>
            </div>
          )}
        </div>
        <nav className="mingle-room__safety-controls">
          <button className="btn -danger" type="button" onClick={reportPartner}>
            <AlertIcon />
            Report
          </button>

          <BlockAttendeeButton attendeeId={attendee.id} onBlock={onMingleEnded} />
        </nav>
      </div>
    </aside>
  );
};

export default MinglePartner;
