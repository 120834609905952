import { ReactElement, useCallback, useContext, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import LobbyVideo, { LobbyVideoSkeleton } from '~/components/lobby/LobbyVideo';
import AttendeeCard from '~/components/attendees/AttendeeCard';
import MingleHeader from '~/components/mingle/MingleHeader';
import Button from '~/components/shared/atoms/Button';
import { Sponsor } from '~/types/mingle';
import Spinner from '../loading/Spinner';

import { OpenTokContext } from '../room/OpenTokSession';
import withOTSession from '../room/withOTSession';
import MingleCountdown from './MingleCountdown';
import useMingleHistory from './useMingleHistory';
import AttendeeConnectionButton from '../connections/AttendeeConnectionButton';

type MingleLobbyTypes = {
  isFirstMingle?: boolean;
  onMingleEnd?: () => void;
  onUserReadyChange?: (isReady: boolean) => void;
  serverClockOffset: number;
  sessionName?: string;
  setVideoSource: (value: string | undefined) => void;
  sponsor?: Sponsor;
  startsAt?: string;
  videoSource?: string;
};

export const MingleHistoryPanelSkeleton = (): ReactElement => {
  return (
    <aside className="mingle-history -loading">
      <Skeleton height="23rem" width="100%" />
    </aside>
  );
};

const MingleHistoryPanel = () => {
  const { history, isLoading } = useMingleHistory();
  const mingle = history?.length > 0 && history[0];

  if (isLoading) {
    return <MingleHistoryPanelSkeleton />;
  }

  if (!mingle) return null;

  const { partner } = mingle;

  return (
    <aside className="mingle-history">
      <header className="mingle-room__text">
        <h4 className="mingle-history__title">Previous mingle:</h4>
        <p className="mingle-history__lead">
          If you enjoyed this conversation, make sure to connect with {partner.name}.
        </p>
      </header>

      <div className="mingle-room__verdict">
        <AttendeeCard
          avatarUrl={partner.avatar_url}
          cardRole={partner.accreditation}
          companyName={partner.company_name}
          firstName={partner.name}
          id={partner.id}
          theme="dense"
        >
          <AttendeeConnectionButton id={partner.id} name={partner.name} />
        </AttendeeCard>
      </div>
    </aside>
  );
};

export const MingleLobbySkeleton = (): ReactElement => {
  return (
    <div className="mingle-room -waiting -lobby">
      <main className="mingle-lobby">
        <MingleHeader />
        <div className="mingle-lobby__content">
          <div className="mingle-lobby__preview-pane">
            <LobbyVideoSkeleton />
          </div>
          <MingleHistoryPanelSkeleton />
        </div>
      </main>
    </div>
  );
};

const MingleLobby = ({
  startsAt,
  setVideoSource,
  videoSource,
  isFirstMingle,
  onUserReadyChange,
  onMingleEnd,
  serverClockOffset,
  sponsor,
  sessionName,
}: MingleLobbyTypes): ReactElement<MingleLobbyTypes> => {
  const { session } = useContext(OpenTokContext);

  const onCountdownFinished = useCallback(() => {
    onUserReadyChange?.(true);
  }, [onUserReadyChange]);

  const loadingText = useMemo(() => {
    if (startsAt) {
      return (
        <MingleCountdown
          initTime={startsAt}
          serverClockOffset={serverClockOffset}
          onEnd={onCountdownFinished}
        />
      );
    }
    if (sessionName) {
      return (
        <>
          Connecting you to someone special… <Spinner color="white" size="2rem" />
        </>
      );
    }
    return (
      <>
        Connecting you now… <Spinner color="white" size="2rem" />
      </>
    );
  }, [onCountdownFinished, serverClockOffset, sessionName, startsAt]);

  return (
    <div className="mingle-room -waiting -lobby">
      <main className="mingle-lobby">
        <MingleHeader sessionTitle={sessionName} sponsor={sponsor} />
        <div className="mingle-lobby__content">
          <div className="mingle-lobby__preview-pane">
            {session && (
              <LobbyVideo
                audio
                publisherActionsDisabled
                video
                videoOverlay={
                  <>
                    <div className="lobby__video__overlay-text">{loadingText}</div>
                    {!isFirstMingle && (
                      <Button theme="ghost" onClick={onMingleEnd}>
                        Leave mingle
                      </Button>
                    )}
                  </>
                }
                videoSource={videoSource}
                onAudioChanged={(): void => {
                  // Default no-op
                }}
                onVideoChanged={(): void => {
                  // Default no-op
                }}
                onVideoSourceChanged={setVideoSource}
              />
            )}
            {!session && <LobbyVideoSkeleton />}
          </div>
          <MingleHistoryPanel />
        </div>
      </main>
    </div>
  );
};

export default withOTSession<MingleLobbyTypes>(MingleLobby);
