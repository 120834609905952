// Will only render the `content` or `render` elements if the tippy is mounted to the DOM.
// Replace <Tippy /> with <LazyTippy /> component and it should work the same.

import Tippy, { TippyProps } from '@tippyjs/react';
import { ReactElement, ReactNode, useState } from 'react';

export type LazyTooltipProps = Omit<TippyProps, 'children'> & {
  children?: ReactNode;
};

const LazyTippy = (props: LazyTooltipProps): ReactElement<LazyTooltipProps> => {
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = {
    fn: () => ({
      onHidden: () => setMounted(false),
      onShow: () => setMounted(true),
    }),
  };

  const computedProps = { ...props };

  const { plugins, render } = props;
  computedProps.plugins = [lazyPlugin, ...(plugins || [])];

  if (render) {
    computedProps.render = (...args) => (mounted ? render(...args) : '');
  } else {
    computedProps.content = mounted ? props.content : '';
  }
   
  return <Tippy {...computedProps}>{computedProps.children as ReactElement}</Tippy>;
};

export default LazyTippy;
