import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

const MailIcon = ({
  ref,
  ...props
}: SVGProps<SVGSVGElement>): ReactElement<SVGProps<SVGSVGElement>> => (
  <IconBase version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 Z M20,18 L4,18 L4,8 L12,13 L20,8 L20,18 Z M12,11 L4,6 L20,6 L12,11 Z"
        id="Shape"
      />
    </g>
  </IconBase>
);

export default MailIcon;
