import { ReactElement } from 'react';
import styled from 'styled-components';

import captionOneMixin from '~/style-mixins/typography/captionOneMixin';
import SummitEngineLogo from '~/components/icons/brand/SummitEngineLogo';

const PoweredBy = styled.a`
  color: var(--c-black);
  &:hover {
    color: var(--c-black);
    text-decoration: underline;
  }
  ${captionOneMixin}
  display: flex;
  flex: row nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  gap: 4px;
`;

const PoweredBySummitEngineLink = ({ className }: { className?: string }): ReactElement => (
  <PoweredBy className={className} href="https://summitengine.com" target="_blank">
    Powered by <SummitEngineLogo />
  </PoweredBy>
);

export default PoweredBySummitEngineLink;
