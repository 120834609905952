import clsx from 'clsx';
import { ReactElement } from 'react';

type ToggleButtonProps = {
  id?: string;
  isDisabled?: boolean;
  name: string;
  selected: boolean;
  setSelected: (selected: boolean) => void;
};

type ToggleControlLabelChildNode = string | JSX.Element;

type ToggleControlLabelProps = {
  children: ToggleControlLabelChildNode | ToggleControlLabelChildNode[];
  htmlFor?: string; // Empty Label is incorrect
};

type ToggleControlChildNode = ReactElement<ToggleButtonProps> | ReactElement<ToggleButtonProps>;

type ToggleControlProps = {
  children: ToggleControlChildNode | ToggleControlChildNode[];
};

const ToggleControl = ({ children }: ToggleControlProps): ReactElement<ToggleControlProps> => (
  <div className="toggle-control">{children}</div>
);

ToggleControl.Label = ({
  children,
  htmlFor,
}: ToggleControlLabelProps): ReactElement<ToggleControlLabelProps> => (
  <label className="toggle-control__label" htmlFor={htmlFor}>
    {children}
  </label>
);

ToggleControl.Button = ({
  id,
  name,
  selected,
  setSelected,
  isDisabled = false,
}: ToggleButtonProps): ReactElement<ToggleButtonProps> => (
  <div className={clsx('toggle-button', selected && '-selected', isDisabled && '-disabled')}>
    <input
      checked={selected}
      className="toggle-button__checkbox"
      disabled={isDisabled}
      id={id}
      name={name}
      type="checkbox"
      onChange={(e) => setSelected(e.target?.checked ?? false)}
    />
    <div className="toggle-button__switcher" />
    <div className="toggle-button__layer" />
  </div>
);

export default ToggleControl;
