import { ReactElement, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import FormField from './FormField';
import OptionsBlock, { OptionsBlockProps } from './OptionsBlock';

type InterestsInputProps = {
  availableTopics?: {
    id: string;
    name: string;
  }[];
  error?: FieldError;
  isRequired?: boolean;
  label?: ReactNode;
  name: string;
  onChange: (topics: string[]) => void;
  styling?: OptionsBlockProps['styling'];
  value: string[];
};

const InterestsInput = ({
  availableTopics = [],
  styling,
  label = '',
  isRequired,
  name,
  error,
  value,
  onChange,
}: InterestsInputProps): ReactElement<InterestsInputProps> | null => {
  const toggleInterest = (interestId: string): void => {
    if (value.includes(interestId)) {
      onChange(value.filter((selectedInterestId) => selectedInterestId !== interestId));
    } else {
      onChange([...value, interestId]);
    }
  };

  if (!availableTopics) return null;

  return (
    <FormField error={error} isRequired={isRequired} label={label} name={name}>
      <OptionsBlock
        availableOptions={availableTopics}
        id={name}
        selectedOptions={value}
        styling={styling}
        toggle={toggleInterest}
      />
    </FormField>
  );
};

export default InterestsInput;
