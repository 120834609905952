import type { ReactElement } from 'react';
import type { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList } from 'react-instantsearch-dom';

import { useTracking } from '~/lib/analytics';
import Checkbox from '~/components/shared/atoms/Checkbox';
import CheckboxCollection, {
  CheckboxCollectionBody,
} from '~/components/shared/molecules/CheckboxCollection';
import CheckboxControl from '~/components/shared/molecules/CheckboxControl';

import { sortAlphabeticallyWithFirstItems } from '../helpers';

type ConnectedToggleRefinementItemProps = {
  currentRefinement: string[];
  refine: (value: string[]) => void;
  value: string;
};

type ConnectedToggleRefinementListProps = RefinementListProvided & {
  label: string;
};

const ConnectedToggleRefinementItem = ({
  currentRefinement,
  refine,
  value,
}: ConnectedToggleRefinementItemProps): ReactElement<ConnectedToggleRefinementItemProps> => (
  <CheckboxControl>
    <CheckboxControl.Label>
      <Checkbox
        checked={!!currentRefinement.includes(value)}
        setChecked={(isChecked) =>
          isChecked
            ? refine([...currentRefinement, value])
            : refine(currentRefinement.filter((currentFilter) => currentFilter !== value))
        }
      />
      {value}
    </CheckboxControl.Label>
  </CheckboxControl>
);

const ConnectedToggleRefinementList = connectRefinementList<ConnectedToggleRefinementListProps>(
  ({ items, refine, currentRefinement, label }) => {
    const track = useTracking();
    const sortedItems = sortAlphabeticallyWithFirstItems(items);

    return (
      <CheckboxCollection>
        <h2 className="-b">{label}</h2>
        <CheckboxCollectionBody>
          {sortedItems.map(({ label: itemLabel }) => (
            <ConnectedToggleRefinementItem
              key={itemLabel}
              currentRefinement={currentRefinement}
              refine={(value) => {
                track('Applied filter', label);
                refine(value);
              }}
              value={itemLabel}
            />
          ))}
        </CheckboxCollectionBody>
      </CheckboxCollection>
    );
  },
);

export default ConnectedToggleRefinementList;
