import axios from 'axios';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import useSWR from 'swr';

import { CALENDAR_API_ENDPOINTS } from '~/endpoints';
import Clock from '~/components/others/Clock';
import Popper from '~/components/popper/Popper';
import CalendarIcon from '~/components/icons/CalendarIcon';
import { authorizationHeader } from '~/lib/authToken';
import urlFor from '~/lib/urlFor';

type CalendarEvent = {
  id: string;
  starts_at: string;
  title: string;
};

const fetchUpcomingEvents = async (): Promise<CalendarEvent[]> => {
  const {
    data: { data: calendarEvents },
  } = await axios.get(CALENDAR_API_ENDPOINTS.calendar(), {
    headers: { Authorization: authorizationHeader() },
  });

  return calendarEvents;
};

const LazyCalendar = () => {
  const {
    data: calendarEvents,
    error,
    isValidating,
  } = useSWR('upcomingEvents', fetchUpcomingEvents, {});

  let emptyState;
  if (!calendarEvents && isValidating) {
    emptyState = 'Loading...';
  }
  if (error) {
    emptyState = 'Something went wrong, please try again.';
  }
  if (!isValidating && calendarEvents?.length === 0) {
    emptyState = 'You don’t have any upcoming personal calendar events.';
  }

  if (emptyState) {
    return (
      <div className="empty">
        <span>{emptyState}</span>
      </div>
    );
  }

  return (
    <ol className="calendar-events">
      {calendarEvents?.map((calendarEvent) => (
        <li key={calendarEvent.id}>
          <a href={urlFor(`/my/calendar/${calendarEvent.id}`)}>
            <div className="calendar-event">
              <div className="calendar-event__starts_at">
                {DateTime.fromISO(calendarEvent.starts_at).toFormat('h:mma / MMMM dd')}
              </div>
              <div className="calendar-event__title">{calendarEvent.title}</div>
            </div>
          </a>
        </li>
      ))}
    </ol>
  );
};

const CalendarMenu = (): ReactElement => {
  const onOpen = (callback: () => void) => {
    callback();
  };

  const headers = () => (
    <>
      <span>My schedule</span>
      <a href={urlFor('/my/calendar')}>
        <span>View all</span>
      </a>
    </>
  );

  const popoutContent = (open: boolean | undefined) => {
    if (!open) {
      return null;
    }

    return <LazyCalendar />;
  };

  return (
    <Popper greyOverlay headers={headers} popoutContent={popoutContent}>
      {(activationClassName, activatePopper, isPopperPanelOpen = false) => (
        <button
          className={clsx(
            'indicator__icon',
            {
              'indicator__icon--on': isPopperPanelOpen,
            },
            activationClassName,
          )}
          type="button"
          onClick={() => onOpen(activatePopper)}
        >
          <CalendarIcon />
          <Clock />
        </button>
      )}
    </Popper>
  );
};

export default CalendarMenu;
