import axios from 'axios';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';

import { API_ENDPOINTS } from '~/endpoints';
import { FlashTypes } from '~/components/notifications/FlashNotification';
import StandaloneFlashNotification from '~/components/notifications/StandaloneFlashNotification';
import AcceptIcon from '~/components/icons/AcceptIcon';
import AlertEmptyIcon from '~/components/icons/AlertEmptyIcon';
import CloseIcon from '~/components/icons/CloseIcon';
import PerkIcon from '~/components/icons/PerkIcon';
import PerkOffIcon from '~/components/icons/PerkOffIcon';
import { Perk } from '~/types/perks';

import { authorizationHeader } from '~/lib/authToken';
import Modal from '../others/Modal';

type Notification = {
  header?: string;
  message: string;
  type?: FlashTypes;
};

const successNoticiation: Notification = {
  header: 'Perk applied successfully!',
  message:
    'You have successfully claimed a partner perk. Please check your email for more information.',
  type: 'success',
};

const errorNotification: Notification = {
  message: 'Something went wrong. Please try again',
};

const ClaimedButton = (): ReactElement => (
  <button disabled className="interact-btn perk-item__button -applied" type="button">
    <PerkIcon />
    Applied
  </button>
);

const ActivationClosedButton = (): ReactElement => (
  <button disabled className="interact-btn perk-item__button -closed" type="button">
    <AlertEmptyIcon />
    No spaces available
  </button>
);

type CardProps = {
  exhibitorId: string;
  logoUrl?: string;
  onClaim: () => void;
  perk: Perk;
};

const Card = ({ perk, exhibitorId, onClaim, logoUrl }: CardProps): ReactElement => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const claimPerk = async ({ closeModal }: { closeModal: () => void }) => {
    const csrfToken = (document.querySelector('meta[name=csrf-token]') as HTMLMetaElement)?.content;

    try {
      await axios.post(API_ENDPOINTS.claimPerk(exhibitorId, `${perk.id}`), null, {
        headers: {
          Authorization: authorizationHeader(),
        },
        params: { claim_url: perk.redeem_url, authenticity_token: csrfToken },
      });
      onClaim();
      setNotification(successNoticiation);
      closeModal();
    } catch (err) {
      setNotification(errorNotification);
      closeModal();
    }
  };

  const activationClosed = !perk.spaces_available && !perk.already_responded;

  let button;
  if (activationClosed) {
    button = <ActivationClosedButton />;
  } else if (perk.already_responded) {
    button = <ClaimedButton />;
  } else {
    button = (
      <Modal
        modalTrigger={({ openModal }) => (
          <button
            className="interact-btn perk-item__button -apply"
            type="button"
            onClick={openModal}
          >
            <PerkOffIcon />
            Apply now
          </button>
        )}
      >
        {({ closeModal }) => (
          <>
            <div className="preview__generic">
              <h2 className="preview__generic__heading">{perk.title}</h2>
              <div className="preview__generic__text">
                <p>{perk.terms_and_conditions}</p>
              </div>
            </div>
            <nav className="preview__nav">
              <button
                className={clsx('btn', '-svg', '-secondary')}
                type="button"
                onClick={closeModal}
              >
                <CloseIcon />
                <span>No, I don&apos;t agree</span>
              </button>
              <button
                className={clsx('btn', '-svg', '-primary')}
                type="button"
                onClick={() => claimPerk({ closeModal })}
              >
                <AcceptIcon />
                <span>Yes, I agree</span>
              </button>
            </nav>
          </>
        )}
      </Modal>
    );
  }

  return (
    <>
      <div className={clsx('perk-item directory-item card -link', activationClosed && '-disabled')}>
        <div className="card__content">
          {logoUrl && <img alt="company logo" className="perk-item__logo" src={logoUrl} />}
          <div className="perk-item__title">{perk.title}</div>
          <div className="perk-item__description">{perk.description}</div>
        </div>
        <div className="perk-item__actions">{button}</div>
      </div>
      {notification && (
        <StandaloneFlashNotification
          header={notification.header}
          message={notification.message}
          type={notification.type}
          onDismiss={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default Card;
