import { ReactElement, useMemo } from 'react';

import MingleHeader, { MingleHeaderTextProps } from '~/components/mingle/MingleHeader';
import Button from '~/components/shared/atoms/Button';
import defaultMingleImageUrl from '~/../assets/images/illustrations/mingle.svg';
import defaultDisabledMingleImageUrl from '~/../assets/images/illustrations/mingle-disabled.svg';
import defaultMingleVipImageUrl from '~/../assets/images/illustrations/mingle-vip.svg';
import { Asset } from '~/lib/assets';

import MyMingles from './MyMingles';

const mingleVipImageUrl = Asset.mingleVipImage || defaultMingleVipImageUrl;
const mingleDefaultImageUrl = Asset.mingleImage || defaultMingleImageUrl;
const mingleDisabledImageUrl = Asset.mingleDisabledImage || defaultDisabledMingleImageUrl;
const mingleSponsoredImageUrl = Asset.mingleSponsoredImage || defaultMingleImageUrl;

type ExplanationProps = MingleHeaderTextProps & {
  username?: string;
};
const Explanation = ({
  isDisabled,
  sessionTitle,
  sponsor,
  username,
}: ExplanationProps): ReactElement<ExplanationProps> => {
  if (isDisabled) {
    return (
      <p className="mingle-info--text">
        Mingle is a speed network tool where we introduce you to an attendee we think you should
        know. You’ll have just three minutes to make the best of it!
      </p>
    );
  }

  return (
    <>
      {sessionTitle && username && !sponsor && (
        <p className="mingle-info--text mingle-info--spacing">
          Welcome <span className="mingle-info--bold">{username}</span>,
        </p>
      )}
      <p className="mingle-info--text">
        We’re about to introduce you to an attendee we think you should know based on your profile,
        so make sure your camera and microphone are working.
      </p>
      <p className="mingle-info--text">
        You’ll have just <span className="mingle-info--bold">three minutes</span> to talk before we
        connect you to someone else. Make them count!
      </p>
    </>
  );
};

export type MingleStartScreenProps = {
  onStart?: () => void;
  username: string;
} & MingleHeaderTextProps;

const MingleStartScreen = ({
  onStart,
  username,
  isDisabled,
  sponsor,
  sessionTitle,
}: MingleStartScreenProps): ReactElement<MingleStartScreenProps> => {
  const imageUrl = useMemo(() => {
    if (isDisabled) {
      return mingleDisabledImageUrl;
    }
    if (sponsor) {
      return mingleSponsoredImageUrl;
    }
    if (sessionTitle) {
      return mingleVipImageUrl;
    }
    return mingleDefaultImageUrl;
  }, [isDisabled, sessionTitle, sponsor]);

  return (
    <div className="mingle-room -start-screen">
      <main className="mingle-start-screen">
        <MingleHeader
          defaultNode={<div className="mingle-header__title">Get ready to mingle</div>}
          isDisabled={isDisabled}
          sessionTitle={sessionTitle}
          sponsor={sponsor}
        />
        <div className="mingle-info">
          <div className="mingle-info__illustration  mingle-info--spacing">
            <img alt="Mingle with fellow attendees" src={imageUrl} />
          </div>

          <div className="mingle-info__explanation ">
            <Explanation
              isDisabled={isDisabled}
              sessionTitle={sessionTitle}
              sponsor={sponsor}
              username={username}
            />

            <div className="mingle-info__divider" />

            <div className="mingle-info__actions">
              <Button
                isDisabled={isDisabled}
                size="lg"
                theme="primary"
                type="button"
                onClick={onStart}
              >
                Start Mingle
              </Button>
            </div>
          </div>
        </div>
        <MyMingles />
      </main>
    </div>
  );
};

export default MingleStartScreen;
