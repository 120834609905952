import clsx from 'clsx';
import { ReactElement, useCallback } from 'react';

import { usePublisher, useRoom } from '~/lib/rooms';
import { useRoomConfig } from '~/lib/rooms/config';
import { useCurrentUser } from '~/lib/user';
import ToolbarIconButton from '~/components/room/ToolbarIconButton';
import RoomIcon from '~/components/icons/RoomIcon';
import urlFor from '~/lib/urlFor';

import AudioIcon from '../icons/AudioIcon';
import EndCallIcon from '../icons/EndCallIcon';
import ForwardIcon from '../icons/ForwardIcon';
import GearIcon from '../icons/GearIcon';
import HandIcon from '../icons/HandIcon';
import ScreenShareIcon from '../icons/ScreenShareIcon';
import VideoIcon from '../icons/VideoIcon';
import ReactionsContent from '../reactions/ReactionsContent';
import RoomSettingsModal from './RoomSettingsModal';

const Toolbar = (): ReactElement<Record<string, never>> => {
  const { backstageEnabled, videoCall } = useRoomConfig();
  const {
    occupant,
    room,
    room: { id: roomId },
  } = useRoom();

  const {
    isParticipating,
    isPublishingAudio,
    isPublishingVideo,
    isRaisingHand,
    isScreenSharing,
    toggleAudio,
    toggleVideo,
    toggleRaisingHand,
    toggleParticipating,
    toggleScreenSharing,
  } = usePublisher();

  const endCall = useCallback(() => {
    window.location.href = urlFor(`/rooms/${roomId}`);
  }, [roomId]);

  const isSpectator = !occupant.canShareVideo && !isParticipating;

  return (
    <nav className={clsx('toolbar', isSpectator && '-spectator')}>
      <section className="toolbar__eq toolbar__reactions">
        {occupant.canReact && <ReactionsContent isRoom reactionsLayout={room.reactionsLayout} />}
      </section>
      {isParticipating && (
        <section className="toolbar__eq">
          <button
            className={clsx('btn', '-toolbar', '-switch', isPublishingVideo ? '-on' : '-off')}
            type="button"
            onClick={toggleVideo}
          >
            <VideoIcon isOn={isPublishingVideo} />
          </button>
          <button
            className={clsx('btn', '-toolbar', '-switch', '-bordered', '-off')}
            type="button"
            onClick={endCall}
          >
            <EndCallIcon />
          </button>
          <button
            className={clsx('btn', '-toolbar', '-switch', isPublishingAudio ? '-on' : '-off')}
            type="button"
            onClick={toggleAudio}
          >
            <AudioIcon isOn={isPublishingAudio} />
          </button>
        </section>
      )}
      <section className="toolbar__eq toolbar__actions -full-height">
        {isParticipating && (
          <>
            {occupant.canRaiseHand && (
              <ToolbarIconButton isActive={isRaisingHand} onClick={toggleRaisingHand}>
                <HandIcon />
                Raise hand
              </ToolbarIconButton>
            )}
            {occupant.canShareScreen && (
              <ToolbarIconButton isActive={isScreenSharing} onClick={toggleScreenSharing}>
                <ScreenShareIcon isOn={isScreenSharing} />
                Share screen
              </ToolbarIconButton>
            )}
          </>
        )}
        {Boolean(videoCall) && occupant.canShareVideo && backstageEnabled && (
          <ToolbarIconButton onClick={toggleParticipating}>
            {isParticipating ? <ForwardIcon /> : <RoomIcon />}
            {isParticipating ? 'Leave stage' : 'Join stage'}
          </ToolbarIconButton>
        )}
        {occupant.isModerator && (
          <RoomSettingsModal
            modalTrigger={({ openModal }) => (
              <ToolbarIconButton onClick={openModal}>
                <GearIcon />
              </ToolbarIconButton>
            )}
            roomId={roomId}
          />
        )}
      </section>
    </nav>
  );
};

export default Toolbar;
