import clsx from 'clsx';
import { ReactElement, SVGProps } from 'react';

const CopyIcon = ({ className, ...props }: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      className={clsx(['copy-icon', !!className && className])}
      height={24}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          className="copy-icon__path"
          d="M16 2H5c-1.1 0-2 .9-2 2v13h2V4h11V2zm1 4H9c-1.1 0-1.99.9-1.99 2L7 20c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V8a2 2 0 00-2-2h-2zM9 20V8h10v12H9z"
          fill="#999"
        />
      </g>
    </svg>
  );
};

export default CopyIcon;
