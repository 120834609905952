import clsx from 'clsx';
import { MouseEventHandler,ReactElement, ReactNode } from 'react';

type OptionItemProps = {
  children: ReactNode;
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLLIElement>;
};

const OptionItem = ({
  children,
  isSelected = false,
  onClick,
}: OptionItemProps): ReactElement<OptionItemProps> => (
  <li
    className={clsx('option-block__item', {
      'option-block__item--button': onClick !== undefined,
      'option-block__item--selected': isSelected,
    })}
    role="presentation"
    onClick={onClick}
  >
    {children}
  </li>
);

export default OptionItem;
