import { ReactElement, SVGProps } from 'react';

const SendIcon = (props: SVGProps<SVGSVGElement>): ReactElement<SVGProps<SVGSVGElement>> => (
  <svg version="1.1" viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-381 -732)">
        <g transform="translate(82 204)">
          <g transform="translate(0 508)">
            <g transform="translate(298 16)">
              <path
                d="m21.457 10.901-13.21-6.79c-0.31009-0.15932-0.68077-0.14565-0.97828 0.036083s-0.4789 0.50529-0.47872 0.85392v3.933l-5.79 5.79h5.79v3.857c-1.8595e-4 0.34863 0.1812 0.67218 0.47872 0.85392 0.29751 0.18173 0.66819 0.19541 0.97828 0.036083l13.21-6.79c0.33397-0.1711 0.54404-0.51476 0.54404-0.89 0-0.37524-0.21007-0.7189-0.54404-0.89zm-12.667 6.041v-3.012l3.289-3.289h-3.289v-4l10.022 5.15-10.022 5.151z"
                fill="#333"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SendIcon;
