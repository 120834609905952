import styled from 'styled-components';

import bodyMixin from '~/style-mixins/typography/bodyMixin';
import displayTitleMixin from '~/style-mixins/typography/displayTitleMixin';

const Header = styled.header`
  border-bottom: 1px solid var(--c-light-silver);
  margin-bottom: 2rem;
`;

export const Title = styled.h1`
  ${displayTitleMixin}
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  ${bodyMixin}
  margin-bottom: 1rem;
`;

export default Header;
