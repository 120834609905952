import styled, { css } from 'styled-components';

const Container = styled.div<{ disableGutters?: boolean, size?: 'small' | 'medium' | 'large'; }>`
  padding: 0 1rem;
  margin: 0 auto;

  ${({ size = 'medium' }) => {
    switch (size) {
      case 'small':
        return css`
          max-width: 48rem;
        `;
      case 'medium':
        return css`
          max-width: 64rem;
        `;
      case 'large':
        return css`
          max-width: 86rem;
        `;
      default:
        return null;
    }
  }}

  ${({ disableGutters }) =>
    disableGutters &&
    css`
      padding: 0;
    `}
`;

export default Container;
