import axios from 'axios';
import { FormEventHandler, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'wouter';

import urlFor from '~/lib/urlFor';

import Button from '../button/Button';
import FormField from '../forms/FormField';
import LoadingIcon from '../icons/LoadingIcon';
import LoginForm from './LoginForm';
import LoginPanel from './LoginPanel';
import SuccessMessage from './SuccessMessage';

const BackLink = styled(Link)`
  margin-top: 1rem;
`;

const ForgotBookingReferencePanel = (): ReactElement => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    setLoading(true);

    try {
      await axios.post(urlFor('/login/forgot.json'), {
        email,
      });
    } catch (err) {
      if (axios.isAxiosError(err) || err instanceof Error) {
        window.newrelic?.noticeError(err);
      }
    }

    setLoading(false);
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <LoginPanel size="small">
        <SuccessMessage>
          <p>{t('login.forgotEmailSent')}</p>
        </SuccessMessage>
        <BackLink href="/ticket">{t('login.backToLogin')}</BackLink>
      </LoginPanel>
    );
  }

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    void submit();
  };

  return (
    <LoginPanel size="small">
      <LoginForm
        onSubmit={handleSubmit}
      >
        <FormField isRequired label={t('login.emailLabel')} name="email">
          <input
            required
            id="email"
            name="email"
            placeholder={t('login.emailPlaceholder')}
            type="email"
            value={email}
            onChange={(e): void => setEmail(e.target.value)}
          />
        </FormField>
        <Button disabled={loading} icon={loading && <LoadingIcon />} size="large">
          {t('labels.submit')}
        </Button>
        <p>{t('login.forgotEmailHelp')}</p>
      </LoginForm>
    </LoginPanel>
  );
};

export default ForgotBookingReferencePanel;
