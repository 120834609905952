import { ReactElement } from 'react';

import { Attendee } from '~/operations/catalyst';
import GroupChat from '~/components/icons/GroupChat';

import AttendeeTooltip from './AttendeeTooltip';
import Portrait from './Portrait';

export type StackParticipant = Pick<Attendee, 'id' | 'avatarUrl' | 'name'>;

type ExpandedStackParticipant = Pick<
  Attendee,
  'id' | 'avatarUrl' | 'name' | 'jobTitle' | 'companyName' | 'role'
>;

type StackPropsWithoutAttendeeTooltip = { withAttendeeTooltip?: false | null };
type StackPropsWithAttendeeTooltip = {
  participants: ExpandedStackParticipant[];
  withAttendeeTooltip: true;
};

export type StackProps = {
  group?: boolean;
  limit?: number;
  participants: StackParticipant[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
} & (StackPropsWithAttendeeTooltip | StackPropsWithoutAttendeeTooltip);

const Stack = ({
  participants,
  size = 'md',
  group = false,
  limit,
  ...restProps
}: StackProps): ReactElement<StackProps> => {
  const frontLineParticipants = participants.slice(0, limit);
  const numberOfBackLineParticipants =
    limit && participants.length >= limit ? participants.length - limit : 0;

  return (
    <div className={`attendee-stack -${size}`}>
      {numberOfBackLineParticipants > 0 && (
        <div className="attendee-stack__more">{`+ ${numberOfBackLineParticipants}`}</div>
      )}
      {group ? (
        <GroupChat className={`portrait -${size}`} />
      ) : (
        frontLineParticipants.reverse().map((participant) => (
          <div key={participant.id} className="attendee-stack__attendee">
            {restProps.withAttendeeTooltip ? (
              <AttendeeTooltip info={participant} portraitSize={size} />
            ) : (
              <Portrait avatarUrl={participant.avatarUrl} name={participant.name} size={size} />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default Stack;
