import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

type FlagIconProps = SVGProps<SVGSVGElement>;

const FlagIcon = ({ ref, ...props }: FlagIconProps): ReactElement => {
  return (
    <IconBase viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="m20.988 9.643-6.349-6.35a1 1 0 0 0-1.426.012l-1.969 2.038-.233-.236a.88.88 0 0 0-.716-.293 1 1 0 0 0-.71.3L7.616 7.155l-.234-.233a1.174 1.174 0 0 0-.716-.293 1 1 0 0 0-.709.3L3.281 9.7a.952.952 0 0 0-.122.189 1 1 0 0 0-.087.136.931.931 0 0 0-.045.228A1.055 1.055 0 0 0 3 10.4c.006.08.022.16.047.238a.909.909 0 0 0 .027.136 1 1 0 0 0 .218.329l6.3 6.3 2.676 2.676a1 1 0 0 0 1.414-1.414l-1.969-1.969 1.308-1.307.2.2a1 1 0 0 0 1.414 0l2.014-2.014.2.2a1 1 0 0 0 1.414 0l2.721-2.72a1 1 0 0 0 .004-1.412Zm-10.685 5.642-4.9-4.9 1.284-1.33 4.924 4.923-1.308 1.307Zm3.629-1.814-4.9-4.9 1.279-1.328 4.924 4.924-1.303 1.304Zm3.628-1.815-4.9-4.9 1.285-1.329 4.923 4.924-1.308 1.305Z"
          fill="#333"
        />
      </g>
    </IconBase>
  );
};

export default FlagIcon;
