import { ReactElement } from 'react';
import { FieldError, FieldValues, Path,RegisterOptions, UseFormRegister } from 'react-hook-form';

import FormField from './FormField';

export type TextAreaProps<IValues extends FieldValues = FieldValues> = {
  className?: string;
  error?: FieldError;
  isDisabled?: boolean;
  label: string;
  name: Path<IValues>;
  placeholder?: string;
  register: UseFormRegister<IValues>;
  rows?: number;
  validation?: RegisterOptions;
};

const TextArea = <IValues extends FieldValues = FieldValues>({
  label,
  register,
  name,
  error,
  validation = {},
  isDisabled = false,
  placeholder,
  rows,
  className,
}: TextAreaProps<IValues>): ReactElement<TextAreaProps<IValues>> => (
  <FormField
    className={className}
    error={error}
    isDisabled={isDisabled}
    isRequired={!!validation.required}
    label={label}
    name={name}
  >
    <textarea
      aria-errormessage={`${name}-error`}
      aria-invalid={!!error?.type}
      disabled={isDisabled}
      id={name}
      placeholder={placeholder}
      rows={rows}
      {...register(name, validation)}
    />
  </FormField>
);

export default TextArea;
