import { ReactElement } from 'react';

const BrowserRecommendation = (): ReactElement | null =>
  navigator.userAgent.includes('Chrome') ? null : (
    <p className="browser-recommendation__description">
      To enjoy the best Web Summit experience, we recommend using{' '}
      <a
        className="browser-recommendation__link"
        href="https://www.google.com/chrome/"
        rel="noreferrer"
        target="_blank"
      >
        Google Chrome
      </a>
      .
    </p>
  );

export default BrowserRecommendation;
