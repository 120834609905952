import axios from 'axios';
import { FormEventHandler, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '~/lib/config';
import urlFor from '~/lib/urlFor';

import Button from '../button/Button';
import FormField from '../forms/FormField';
import LoadingIcon from '../icons/LoadingIcon';
import StandaloneFlashNotification from '../notifications/StandaloneFlashNotification';
import LoginForm from './LoginForm';
import LoginPanel from './LoginPanel';
import SuccessMessage from './SuccessMessage';

type LoginResponse = {
  message?: string;
  status: 'ok' | 'pending' | 'error';
};

const TicketLoginPanel = (): ReactElement => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<LoginResponse['status']>();
  const [message, setMessage] = useState<string>();

  const login = async (): Promise<void> => {
    setLoading(true);

    try {
      const url = `${config.avengerUrl}/conferences${urlFor('/email_login_link')}`;
      await axios.post<LoginResponse>(url, {
        email,
      });

      setStatus('pending');
    } catch (err) {
      if (axios.isAxiosError(err) || err instanceof Error) {
        window.newrelic?.noticeError(err);
      }
      setStatus('error');
      setMessage('Login failed');
    }

    setLoading(false);
  };

  if (status === 'pending') {
    return (
      <LoginPanel size="small">
        <SuccessMessage>{t('login.confirmLogin')}</SuccessMessage>
      </LoginPanel>
    );
  }

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    void login();
  };

  return (
    <LoginPanel size="small">
      <LoginForm onSubmit={handleSubmit}>
        {message && (
          <StandaloneFlashNotification
            fadeOutTimeout={30_000}
            message={message}
            onDismiss={(): void => setMessage(undefined)}
          />
        )}
        <FormField isRequired label={t('login.emailLabel')} name="email">
          <input
            required
            id="email"
            name="email"
            placeholder={t('login.emailPlaceholder')}
            type="email"
            value={email}
            onChange={(e): void => setEmail(e.target.value)}
          />
        </FormField>
        <Button disabled={loading} icon={loading && <LoadingIcon />} size="large">
          {t('login.logIn')}
        </Button>
      </LoginForm>
    </LoginPanel>
  );
};

export default TicketLoginPanel;
