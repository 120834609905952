import { ReactElement, SVGProps } from 'react';

type LiveIconProps = SVGProps<SVGSVGElement>;

const LiveIcon = (props: LiveIconProps): ReactElement<LiveIconProps> => (
  <svg height={14} viewBox="0 0 32 14" width={32} {...props}>
    <path
      d="M25 0a7 7 0 010 14H7A7 7 0 017 0h18zM13.312 4.4h-1.183v5.1H15.2v-.945h-1.89V4.401zm3.944 0h-1.19v5.1h1.19V4.4zm1.677 0h-.951l2.044 5.1h1.07l2.044-5.1h-.959a.393.393 0 00-.248.08.441.441 0 00-.106.113l-.03.063-.98 2.73c-.043.119-.085.248-.128.388s-.081.288-.114.442a7.397 7.397 0 00-.128-.441l-.068-.203-.067-.186-.986-2.73a.372.372 0 00-.296-.248l-.097-.008zm8.11 0h-3.282v5.1h3.283v-.91H24.95V7.375h1.602V6.5h-1.602V5.31h2.093V4.4zM6 5a2 2 0 100 4 2 2 0 000-4z"
      fill="#ED1846"
      fillRule="evenodd"
    />
  </svg>
);

export default LiveIcon;
