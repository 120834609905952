import { ApolloProvider, useQuery } from '@apollo/client';
import { ReactElement, useMemo } from 'react';

import AttendeeCard from '~/components/attendees/AttendeeCard';
import AttendeeCardList from '~/components/attendees/AttendeeCardList';
import TimeslotSection from '~/components/breakout/TimeslotSection';
import PageNotFound from '~/components/errors/PageNotFound';
import ExhibitorCardSlider from '~/components/exhibitors/ExhibitorCardSlider';
import ExhibitorPerks from '~/components/exhibitors/ExhibitorPerks';
import ExhibitorPitchVideo from '~/components/exhibitors/ExhibitorPitchVideo';
import { ExhibitorProfileHead } from '~/components/exhibitors/ExhibitorProfileHead';
import ExhibitorProfileSidebar from '~/components/exhibitors/ExhibitorProfileSidebar';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { TrackingContext } from '~/lib/analytics';
import { init } from '~/lib/apollo';
import flags from '~/lib/flags';
import paramaterize from '~/lib/parametize';
import { ExhibitorProfileDocument } from '~/operations/catalyst';

export type ExhibitorProfilePageProps = {
  id: string;
};

export const ExhibitorProfilePage = ({
  id,
}: ExhibitorProfilePageProps): ReactElement<ExhibitorProfilePageProps> => {
  const { loading, data, error } = useQuery(ExhibitorProfileDocument, {
    variables: {
      id,
    },
  });

  const appearance = data?.appearance;
  const notFound = !loading && !appearance;

  const trackingContextValue = useMemo(() => ({
    category: 'Exhibitor',
    name: `${appearance?.company.name} (${appearance?.id})`
  }), [appearance?.id, appearance?.company.name])


  if (error) {
    return <ErrorScreen />;
  }

  if (notFound) {
    return <PageNotFound />;
  }

  const timeslots = appearance?.sponsoredSessions.edges.map((e) => e.node);
  const team = appearance?.team.edges.map((e) => e.node);
  const endorsedCompanies = appearance?.endorsees.edges.map((e) => e.node);

  return (
    <TrackingContext.Provider
      value={trackingContextValue}
    >
      <div className="container -md -omega">
        <div className="profile-page">
          <div
            className={`profile-page__banner accreditation -${paramaterize(
              appearance?.track || '',
            )}`}
          />
          <div className="profile-page__main profile-page__grid">
            <div className="profile-sidebar-container">
              <ExhibitorProfileSidebar id={id} />
            </div>
            <main className="profile-page__content">
              <ExhibitorProfileHead id={id} />

              {!flags.exhibitorVideoDisabled && (
                <div className="profile-page__section -spaced">
                  <ExhibitorPitchVideo exhibitorId={id} />
                </div>
              )}

              {timeslots && timeslots.length > 0 && (
                <TimeslotSection slideCount={2} timeslots={timeslots} title="Sessions" />
              )}

              {team && team.length > 0 && (
                <AttendeeCardList title="Speak to our team">
                  {team.map((a) => (
                    <AttendeeCard
                      key={a.id}
                      avatarUrl={a.avatarUrl || undefined}
                      cardRole={a.role || undefined}
                      companyName={a.companyName || undefined}
                      id={a.id}
                      jobTitle={a.jobTitle || undefined}
                      name={a.name}
                    />
                  ))}
                </AttendeeCardList>
              )}

              {endorsedCompanies && endorsedCompanies.length > 0 && (
                <ExhibitorCardSlider
                  exhibitors={endorsedCompanies.map((c) => ({
                    heading: c.company.name,
                    id: c.id,
                    info: c.industry?.name || '',
                    logoUrl: c.company.logoUrl || undefined,
                  }))}
                  heading="Supported startups"
                />
              )}

              {appearance && (
                <ExhibitorPerks
                  id={appearance.id}
                  logoUrl={appearance.company.logoUrl || undefined}
                  title={`${appearance.company.name} perks`}
                />
              )}
            </main>
          </div>
        </div>
      </div>
    </TrackingContext.Provider>
  );
};

const ExhibitorProfilePageWrapper = ({ id }: ExhibitorProfilePageProps): ReactElement => {
  const client = init();

  return (
    <ApolloProvider client={client}>
      <ExhibitorProfilePage id={id} />
    </ApolloProvider>
  );
};

export default ExhibitorProfilePageWrapper;
