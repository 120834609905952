import { CreateSessionOptions } from 'opentok-react';
import { useEffect, useState } from 'react';

import { Participant } from '~/types/rooms';
import { mingleLog } from '~/lib/loggers';
import { MingleMessage } from '~/types/mingle';

import usePubnubMessages from '../realtime/usePubnubMessages';
import timeWithOffset from './timeWithOffset';

const STARTED = 'started';
const ENDED = 'ended';

interface UseMinglePayload {
  debug: Record<string, unknown>;
  endedAt?: string;
  expectedToEndAt?: string;
  expectedToHaveEnded: boolean;
  hasEnded: boolean;
  hasStarted: boolean;
  id?: string;
  isReady: boolean;
  opentok?: CreateSessionOptions;
  partner?: Participant;
  reset: () => void;
  scheduledFor?: string;
  startsAt?: string;
}

const useMingle = ({
  channel,
  serverClockOffset,
}: {
  channel: string;
  serverClockOffset: number;
}): UseMinglePayload => {
  const [currentMingle, setCurrentMingle] = useState<MingleMessage['data']['mingle'] | null>(null);
  const [currentPartner, setCurrentPartner] = useState<MingleMessage['data']['partner']>();
  const [currentOpentokConfig, setOpentokConfig] = useState<CreateSessionOptions>();

  const { messages } = usePubnubMessages<MingleMessage>({
    channel,
  });

  const initMingle = (message: MingleMessage) => {
    setCurrentMingle(message.data.mingle);
    setCurrentPartner(message.data.partner);
    setOpentokConfig(message.data?.opentok);
  };

  const onMingleEnded = () => {
    setCurrentMingle(undefined);
  };

  const latestMessage = messages[messages.length - 1];

  useEffect(() => {
    if (!latestMessage) {
      return;
    }

    mingleLog('message', latestMessage);
    const mingle = latestMessage.data?.mingle;

    if (currentMingle && mingle && currentMingle?.id !== mingle?.id) {
      return;
    }

    if (latestMessage.type === ENDED) {
      onMingleEnded();
    }

    if (latestMessage.type === STARTED) {
      initMingle(latestMessage);
    }
  }, [latestMessage, currentMingle]);

  const serverTime = timeWithOffset({ offset: serverClockOffset });

  const mingleId = currentMingle?.id;
  const scheduledFor = currentMingle?.scheduledFor;

  const startedAt = currentMingle?.startedAt;
  const hasStarted = !!startedAt && (serverTime ? serverTime > new Date(startedAt) : false);

  const endedAt = currentMingle?.endedAt;
  const hasEnded = !!endedAt && (serverTime ? serverTime > new Date(endedAt) : false);

  const expectedToEndAt = currentMingle?.expectedToEndAt;
  const expectedToHaveEnded =
    !!expectedToEndAt && (serverTime ? serverTime > new Date(expectedToEndAt) : false);

  const debug = latestMessage?.debug;

  const isReady = mingleId ? !!scheduledFor && !hasEnded && !expectedToHaveEnded : false;

  mingleLog('useMingle', {
    debug,
    endedAt,
    expectedToEndAt,
    expectedToHaveEnded,
    latestMessage,
    mingleId,
    scheduledFor,
    startedAt,
  });

  return {
    debug,
    endedAt,
    expectedToEndAt,
    expectedToHaveEnded,
    hasEnded,
    hasStarted,
    id: mingleId,
    isReady,
    opentok: currentOpentokConfig ?? undefined,
    partner: currentPartner,
    reset: () => onMingleEnded(),
    scheduledFor,
    startsAt: startedAt || scheduledFor,
  };
};

export default useMingle;
