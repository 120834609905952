import { VideoElementCreatedEvent } from 'opentok-react/types/opentok';
import { createContext } from 'react';

export const EventStreamContext = createContext<{
  onEvent: (
    event?:
      | VideoElementCreatedEvent
      | { error?: { name: string }; origin: string, type: 'error'; }
      | { type: string },
    callback?: (value: boolean) => void,
  ) => void;
}>({
  onEvent: () => {
    // Default no-op
  },
});
