import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_ENDPOINTS } from '~/endpoints';

const useServerClock = (): {
  serverClockOffset: number;
} => {
  const [serverClockOffset, setServerClockOffset] = useState(0);

  useEffect(() => {
    async function getData() {
      const {
        data: { time },
      } = await axios.get(API_ENDPOINTS.mingleClock());

      const serverTimeMillisGMT = Date.parse(new Date(Date.parse(time)).toUTCString());
      const localMillisUTC = Date.parse(new Date().toUTCString());

      const offset = serverTimeMillisGMT - localMillisUTC;

      setServerClockOffset(offset);
    }
    void getData();
  }, []);

  return {
    serverClockOffset,
  };
};

export default useServerClock;
