import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';

import { authorizationHeader } from '~/lib/authToken';

import BitmovinPlayer from './BitmovinPlayer';
import Loader from './Loader';

export type GatepassPlayerProps = {
  muted?: boolean;
  url: string;
  videoId?: string;
  videoTitle?: string;
  poster?: string;
};

const useGatepassAuth = (url: string) => {
  const [hls, setHls] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUrl = async () => {
      setLoading(true);

      const res = await axios.get<{ data: string }>(url, {
        headers: {
          Authorization: authorizationHeader(),
        },
      });

      setHls(res.data.data);
      setLoading(false);
    };

    void fetchUrl();
  }, [url]);

  return {
    hls,
    loading,
  };
};

const GatepassPlayer = ({
  url,
  ...rest
}: GatepassPlayerProps): ReactElement<GatepassPlayerProps> => {
  const { hls } = useGatepassAuth(url);

  if (!hls) {
    return <Loader />;
  }

  return <BitmovinPlayer url={hls} {...rest} />;
};

export default GatepassPlayer;
