import { DateTime } from 'luxon';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import textClampMixin from '~/style-mixins/textClampMixin';
import bodyMixin from '~/style-mixins/typography/bodyMixin';
import titleThreeMixin from '~/style-mixins/typography/titleThreeMixin';
import type { TimeslotHitType } from '~/types/search';
import ParticipantList from '~/components/search/molocules/ParticipantList';
import Pinnable from '~/components/timeslot/Pinnable';
import Timestamp from '~/components/timeslot/Timestamp';
import urlFor from '~/lib/urlFor';

import getLiveState from './getLiveState';
import JoinSessionButton from './JoinSessionButton';
import LiveLabel from './LiveLabel';
import TrackLabel from './TrackLabel';

type TimeslotHitProps = { hit: TimeslotHitType };

const TimeslotTimestamp = styled(Timestamp)`
  ${bodyMixin}
`;

const LabelWithPosition = styled(LiveLabel)`
  position: absolute;
  top: 0rem;
  left: 1rem;
`;

const HeadingLink = styled.a`
  display: block;

  ::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
  }
`;

const Heading = styled.h2`
  ${titleThreeMixin}
  ${textClampMixin('2', 'ellipsis')}
  margin-top: 1rem;
  grid-row: 2;
  grid-column: 1 / 3;
  font-weight: 600;
  color: #000;
  color: var(--c-black);

  // Disable default hover colors
  a {
    :hover {
      color: inherit;
    }
  }
`;

const LocationPlusTime = styled.div`
  grid-row: 1;
  grid-column: 1;
`;

const Participants = styled(ParticipantList)`
  margin-top: 1.5rem;
  grid-row: 3;
  grid-column: 1;
`;

const ExtraSpeakers = styled.p`
  grid-row: 4;
  grid-column: 1;
  color: var(--c-grey);
`;

const WatchNow = styled.div`
  grid-row: 3;
  grid-column: 2;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const Card = styled.li`
  min-width: 0;
  position: relative;
  margin-top: 1.5rem;
  font-weight: 400;
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  font-size: var(--fs-sm);
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 4fr 1fr;
  grid-auto-rows: auto;
  cursor: pointer;
  overflow: visible;

  :hover {
    box-shadow: var(--c-brand-primary) 0 0 0 2px;
  }

  .pin {
    grid-row: 1;
    grid-column: 2;
    align-self: start;
    justify-self: end;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    width: 1.2rem;
    height: 1.2rem;
    z-index: 9;

    svg {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: auto;
      path {
        fill: var(--c-brand-primary);
      }
    }
  }
`;

const TimeslotListCard = ({ hit }: TimeslotHitProps): ReactElement<TimeslotHitProps> => {
  const limitVisible = 3;
  const visibleParticipations = hit.timeslot_participations.slice(0, limitVisible);
  const numHiddenParticipations = Math.max(0, hit.timeslot_participations.length - limitVisible);
  const startTime = DateTime.fromISO(hit.starts_at);
  const endTime = DateTime.fromISO(hit.ends_at);
  const liveTime = DateTime.now();
  const liveState = getLiveState({ endTime, liveTime, startTime });

  return (
    <Card aria-label="Learn more about this session" className="card">
      <LabelWithPosition
        hasArchivedVideo={hit.has_video_archive}
        liveState={liveState}
        onlineSession={hit.online_session}
        premium={hit.premium}
      />
      <Heading>
        <HeadingLink href={urlFor(`/schedule/${hit.id}`)}>{hit.title}</HeadingLink>
      </Heading>
      <LocationPlusTime>
        <TimeslotTimestamp endDateIso={hit.ends_at} startDateIso={hit.starts_at} />
        <TrackLabel
          format={hit.format?.label || undefined}
          location={hit.location?.name || undefined}
          track={hit.schedule_track?.name || undefined}
        />
      </LocationPlusTime>
      {!!hit.calendar_event_id && <Pinnable calendarEventId={hit.calendar_event_id} />}
      <Participants participations={visibleParticipations} />
      {numHiddenParticipations > 0 && (
        <ExtraSpeakers>
          +{numHiddenParticipations} {numHiddenParticipations === 1 ? 'speaker' : 'speakers'}
        </ExtraSpeakers>
      )}
      <WatchNow />
    </Card>
  );
};

export default TimeslotListCard;
