import Axios from 'axios';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import urlFor from '~/lib/urlFor';

import TimeslotSection from '../breakout/TimeslotSection';
import { BreakoutTimeslot } from '../breakout/types';

const UPDATE_UPCOMING_INTERVAL_MS = 2 * 60 * 1000;
const EMPTY_ARRAY: BreakoutTimeslot[] = [];

const fetchUpcoming = async (id: string): Promise<BreakoutTimeslot[]> => {
  const res = await Axios.get<BreakoutTimeslot[]>(urlFor(`/channels/${id}/upcoming`));
  return res.data;
};

const noUpcomingTimeslots: BreakoutTimeslot[] = [
  {
    id: '',
    name: 'No upcoming sessions',
    participants: [],
    startsAt: '',
    endsAt: '',
  },
];

const useUpcoming = (
  channelId: string,
): {
  data: BreakoutTimeslot[] | undefined;
  loading: boolean;
} => {
  const [data, setData] = useState<BreakoutTimeslot[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const update = async (): Promise<void> => {
      const res = await fetchUpcoming(channelId);
      setData(res.length > 0 ? res : noUpcomingTimeslots);
      setLoading(false);
    };

    void update();
    const h = window.setInterval(() => {
      void update();
    }, UPDATE_UPCOMING_INTERVAL_MS);
    return () => window.clearInterval(h);
  }, [channelId]);

  return {
    data,
    loading,
  };
};

export type UpcomingTimeslotsProps = {
  channelId: string;
  children?: ReactNode;
  title: string;
};

const UpcomingTimeslots = ({
  title,
  channelId,
  children,
}: UpcomingTimeslotsProps): ReactElement<UpcomingTimeslotsProps> => {
  const { data } = useUpcoming(channelId);

  return (
    <div className="channel-viewer__timetable">
      <TimeslotSection
        withModal
        linkToSchedule={(): ReactElement => (
          <>
            <a
              className="button -small -secondary"
              href={urlFor('/schedule')}
              rel="noreferrer"
              target="_blank"
            >
              View the full schedule
            </a>
            {children}
          </>
        )}
        slideCount={2}
        timeslots={data ?? EMPTY_ARRAY}
        title={title}
      />
    </div>
  );
};

export default withErrorBoundary(UpcomingTimeslots, {
  fallback: null,
});
