import axios from 'axios';
import { useEffect } from 'react';

import { API_ENDPOINTS } from '~/endpoints';
import { mingleLog } from '~/lib/loggers';

const leavePool = async (): Promise<void> => {
  const res = await axios.delete(API_ENDPOINTS.leaveMinglePool());

  mingleLog('LEFT_POOL', { res });
};

const joinPool = async (): Promise<void> => {
  const res = await axios.post(API_ENDPOINTS.joinMinglePool());

  mingleLog('JOINED_POOL', { res });
};

// Failsafe in case pubnub events aren't fired
const useImmediateMingleAvailability = (): void => {
  useEffect(() => {
    const getData = async(): Promise<void> => {
      await joinPool();
    }
    void getData();
  }, []);

  useEffect(() => {
    const eventListener = (): void => {
      void leavePool();
    };

    window.addEventListener('unload', eventListener);
    window.addEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('unload', eventListener);
      window.removeEventListener('beforeunload', eventListener);

      const getData = async (): Promise<void> => {
        await leavePool();
      }
      void getData();
    };
  }, []);
};

export default useImmediateMingleAvailability;
