import { ReactElement } from 'react';

import { Sponsor } from '~/types/rooms';

import ChevronIcon from '../icons/ChevronIcon';

type ExhibitorLinkProps = Pick<Sponsor, 'name' | 'logoUrl' | 'profileUrl' | 'country'>;

const ExhibitorLink = ({
  name,
  logoUrl,
  profileUrl,
  country,
}: ExhibitorLinkProps): ReactElement => {
  return (
    <a className="exhibitor-link" href={profileUrl} rel="noreferrer" target="_blank">
      <img alt="Exhibitor logo" className="exhibitor-link__logo" src={logoUrl} />
      <div className="exhibitor-link__content">
        <h3 className="exhibitor-link__header -b">{name}</h3>
        <div className="exhibitor-link__subheader">{country}</div>
      </div>
      <div className="exhibitor-link__icon">
        <ChevronIcon />
      </div>
    </a>
  );
};

export default ExhibitorLink;
