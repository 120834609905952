import chunk from 'lodash/chunk';
import uniqueId from 'lodash/uniqueId';
import { ReactElement, useMemo } from 'react';

import Carousel from '~/components/shared/molecules/Carousel';

import ExhibitorCard from './ExhibitorCard';

type ExhibitorCardSliderProps = {
  exhibitors: {
    heading: string;
    id: string;
    info: string;
    logoUrl?: string;
    url?: string;
  }[];
  heading: string;
};

const ExhibitorCardSlider = ({
  heading,
  exhibitors,
}: ExhibitorCardSliderProps): ReactElement<ExhibitorCardSliderProps> => {
  const exhibitorGroups = useMemo(() => chunk(exhibitors, 6), [exhibitors]);
  const exhibitorGroupsWithIds = useMemo(
    () => exhibitorGroups.map((group) => ({ group, id: uniqueId('group') })),
    [exhibitorGroups],
  );

  return (
    <section className="profile-page__pane pane">
      <Carousel className="-auto-height" slideCount={1} title={heading}>
        {exhibitorGroupsWithIds.map(({ id: groupId, group }, index) => (
          <Carousel.Slide key={`exhibitors_slide_${groupId}`} virtualIndex={index}>
            <Carousel.SlideContent>
              <div className="directory-list">
                {group.map(({ logoUrl, heading: innerHeading, info, id, url }) => (
                  <ExhibitorCard
                    key={id}
                    accreditation="startup"
                    heading={innerHeading}
                    id={id}
                    info={info}
                    logoUrl={logoUrl}
                    theme="dense"
                    url={url}
                  />
                ))}
              </div>
            </Carousel.SlideContent>
          </Carousel.Slide>
        ))}
      </Carousel>
    </section>
  );
};

export default ExhibitorCardSlider;
