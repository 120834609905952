import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import ConnectionStatus from '~/components/connections/ConnectionStatus';
import textClampMixin from '~/style-mixins/textClampMixin';
import AttendeeRoleTag from '~/components/attendees/AttendeeRoleTag';
import Portrait from '~/components/attendees/Portrait';

type Mingler = {
  accreditation: string;
  avatar_url: string;
  company_name: string;
  id: string;
  name: string;
};

type PastMingleProps = {
  mingler?: Mingler;
};

const Status = styled(ConnectionStatus)`
  margin-top: 0.25rem;
`;

const Content = styled.div`
  overflow: hidden;
  padding: 1px;
`;

const Info = styled.div`
  ${textClampMixin('2', 'ellipsis')}
`;

const Name = styled.div`
  ${textClampMixin('2', 'ellipsis')}
`;

const SkeletonPortrait = styled(Skeleton)`
  // need to override the internal CSS with this extra specificity
  &.react-loading-skeleton {
    margin-right: 0.75rem;
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Base = styled.li`
  max-width: 100%;
  display: flex;
`;

const PastMingle = ({ mingler }: PastMingleProps): ReactElement<PastMingleProps> => (
  <Base className="audience-item">
    {mingler ? (
      <Portrait avatarUrl={mingler.avatar_url} id={mingler.id} name={mingler.name} size="md" />
    ) : (
      <SkeletonPortrait />
    )}
    <Content className="audience-item__content">
      <Name className="audience-item__name">
        {mingler ? (
          <>
            {mingler.name}
            {mingler.accreditation && (
              <AttendeeRoleTag
                accreditation={mingler.accreditation}
                visibleAccreditations={['Staff']}
              />
            )}
          </>
        ) : (
          <Skeleton />
        )}
      </Name>
      <Info className="audience-item__info">{mingler ? mingler.company_name : <Skeleton />}</Info>
      {mingler ? <Status id={mingler.id} /> : <Skeleton />}
    </Content>
  </Base>
);

export default PastMingle;
