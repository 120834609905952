import { ReactElement, SVGProps } from 'react';

const HideOffIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12 6a9.768 9.768 0 019 6 9.75 9.75 0 01-18 0 9.768 9.768 0 019-6zm0 2.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 1.5a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5z"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default HideOffIcon;
