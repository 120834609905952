import { ReactElement, SVGProps } from 'react';

type PinIconProps = SVGProps<SVGSVGElement>;

const PinIcon = (props: PinIconProps): ReactElement<PinIconProps> => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3z" />
    </g>
  </svg>
);

export default PinIcon;
