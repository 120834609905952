import { ReactElement } from 'react';

import HalfMingle from '~/components/icons/HalfMingle';
import urlFor from '~/lib/urlFor';

const MingleCapacityExhausted = (): ReactElement => {
  return (
    <div className="mingle-room -capacity-exhausted">
      <main className="mingle-capacity-exhausted">
        <div className="mingle-capacity-exhausted__explanation">
          <span className="group-chat-logo-container">
            <HalfMingle className="mingle-info__illustration_svg" />
          </span>
          <p className="text">
            Mingle is experiencing a high demand, and is currently at capacity. Please check back
            later.
          </p>
          <a className="btn -mingle-action" href={urlFor('/mingle')}>
            Try again
          </a>
        </div>
      </main>
    </div>
  );
};
export default MingleCapacityExhausted;
