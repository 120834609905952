import { css, FlattenSimpleInterpolation } from 'styled-components';

export const MOBILE_MAX_WIDTH = 'only screen and (max-width: 768px)';
export const DESKTOP_MIN_WIDTH = 'only screen and (min-width: 769px)';

export const mobile = (styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation => {
  return css`
    @media ${MOBILE_MAX_WIDTH} {
      ${styles}
    }
  `;
};

export const desktop = (styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation => {
  return css`
    @media ${DESKTOP_MIN_WIDTH} {
      ${styles}
    }
  `;
};
