import { ReactElement, ReactNode, useContext } from 'react';

import Button from '~/components/button/Button';
import AttendeeContext from '~/lib/attendees/AttendeeContext';
import Modal, { ModalTriggerArguments } from '../others/Modal';

export type ConfirmAttendeeConnectionModalProps = {
  modalTrigger?: ((arg: ModalTriggerArguments) => ReactNode) | undefined;
  name: string;
  onConfirm?: () => void;
  showOnInit?: boolean;
};

const ConfirmAttendeeConnectionModal = ({
  name,
  onConfirm,
  modalTrigger,
  showOnInit = false,
}: ConfirmAttendeeConnectionModalProps): ReactElement<ConfirmAttendeeConnectionModalProps> => {
  const { setShowAttendeeConnectModal } = useContext(AttendeeContext);
  return (
    <Modal
      modalTrigger={modalTrigger}
      openOnInit={showOnInit}
      onClose={(): void => setShowAttendeeConnectModal(false)}
    >
      {({ closeModal }): ReactElement => (
        <>
          <div className="preview__generic">
            <h2 className="preview__generic__heading">{`Connect with ${name}`}</h2>
            <div className="preview__generic__text">
              <p>
                Your email address will be visible to accepted connections, so you can keep in touch
                after the event.
              </p>
            </div>
          </div>
          <nav className="preview__nav">
            <Button type="button" variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              onClick={(): void => {
                onConfirm?.();
                closeModal();
              }}
            >
              Request connection
            </Button>
          </nav>
        </>
      )}
    </Modal>
  );
};

export default ConfirmAttendeeConnectionModal;
