import { ReactElement } from 'react';

import { BreakoutTimeslot } from '~/components/breakout/types';
import { TrackingContext } from '~/lib/analytics';
import { CalendarPinsProvider } from '~/lib/calendar/pins';
import TimeslotCard from '~/components/timeslot/TimeslotCard';
import urlFor from '~/lib/urlFor';

const trackingContextValue = { category: 'Breakout' };

type BreakoutSessionsProps = {
  timeslots: BreakoutTimeslot[];
};

const BreakoutSessions = ({
  timeslots,
}: BreakoutSessionsProps): ReactElement<BreakoutSessionsProps> => {
  return (
    <TrackingContext.Provider value={trackingContextValue}>
      <CalendarPinsProvider>
        <div className="directory-list workshop-list">
          {timeslots.map((timeslot) => {
            return (
              <TimeslotCard
                key={timeslot.id}
                calendarEventId={timeslot.calendarEventId}
                endDateIso={timeslot.endsAt}
                ended={timeslot.ended ?? false}
                id={timeslot.id}
                link={urlFor(`/schedule/${timeslot.id}/join`)}
                participants={timeslot.participants}
                sponsor={timeslot.sponsor}
                startDateIso={timeslot.startsAt}
                title={timeslot.name}
              />
            );
          })}
        </div>
      </CalendarPinsProvider>
    </TrackingContext.Provider>
  );
};

export default BreakoutSessions;
