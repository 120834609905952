import { useQuery } from '@apollo/client';
import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ExhibitorProfileDocument } from '~/operations/catalyst';

import Player from '../video/Player';

type ExhibitorPitchVideoProps = {
  exhibitorId: string;
};

const ExhibitorPitchVideo = ({ exhibitorId }: ExhibitorPitchVideoProps): ReactElement | null => {
  const { loading, data } = useQuery(ExhibitorProfileDocument, {
    variables: { id: exhibitorId },
  });

  if (loading) {
    return <Skeleton height={375} />;
  }

  return (
    <Player
      src={{
        static: data?.appearance?.videoFileUrl || undefined,
        vimeo: data?.appearance?.pitchVimeoUrl || undefined,
      }}
    />
  );
};

export default ExhibitorPitchVideo;
