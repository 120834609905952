import clsx from 'clsx';
import { StreamPropertyChangedEvent } from 'opentok-react/types/opentok';
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';

import Modal from '~/components/others/Modal';
import { OpenTokContext } from '~/components/room/OpenTokSession';
import AudioIcon from '~/components/icons/AudioIcon';
import CloseIcon from '~/components/icons/CloseIcon';
import VideoIcon from '~/components/icons/VideoIcon';
import { AudienceMember } from '~/lib/rooms/audience';
import { useModeration } from '~/lib/rooms/moderation';
import { MinimalOccupant } from '~/types/rooms';

type AttendeeSettingsProps = {
  participant: AudienceMember;
};

const AttendeeSettings = ({
  participant,
}: AttendeeSettingsProps): ReactElement<AttendeeSettingsProps> => {
  const [isParticipantPublishingAudio, setIsParticipantPublishingAudio] = useState(false);
  const [isParticipantPublishingVideo, setIsParticipantPublishingVideo] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const opentok = useContext(OpenTokContext);
  const session = opentok?.session;
  const stream = opentok?.streamsByParticipantId[participant.id];

  const { evictOccupant } = useModeration();

  useEffect(() => {
    if (stream) {
      setIsParticipantPublishingAudio(stream.hasAudio);
      setIsParticipantPublishingVideo(stream.hasVideo);
    }
  }, [stream]);

  const evictParticipant = useCallback(
    (occupant: MinimalOccupant, closeModal: () => void) => {
      if (errorMessage) {
        setErrorMessage(null);
      }

      try {
        evictOccupant(occupant);

        if (session && stream?.connection) {
          session.forceDisconnect(stream.connection);
        }

        closeModal();
      } catch (e) {
        setErrorMessage('Something went wrong. Please try again');
      }
    },
    [evictOccupant, errorMessage, setErrorMessage, session, stream],
  );

  useEffect(() => {
    if (!session || !stream) return () => {
      // No cleanup needed
    };

    const handleStreamPropertyChange = (e: StreamPropertyChangedEvent) => {
      const {
        newValue,
        stream: {
          connection: { connectionId },
        },
      } = e;

      const participantConnectionId = stream.connection.connectionId;

      if (e.changedProperty === 'hasAudio' && connectionId === participantConnectionId) {
        setIsParticipantPublishingAudio(newValue as boolean);
      }
      if (e.changedProperty === 'hasVideo' && connectionId === participantConnectionId) {
        setIsParticipantPublishingVideo(newValue as boolean);
      }
    };

    session.on('streamPropertyChanged', handleStreamPropertyChange);
    return () => {
      session.off('streamPropertyChanged', handleStreamPropertyChange);
    };
  }, [session, stream]);

  const toggleSubscriberAudio = useCallback(() => {
    if (!session) return;

    const {
      connection: { connectionId },
    } = stream;

    session.signal(
      {
        data: connectionId,
        type: stream.hasAudio ? 'mute' : 'unmute',
      },
      (error) => {
        if (error) {
          // Again, no idea with these opentok types
          // I'm assuming the errors sometimes have message string and leaving it at that
          // - Ciarán
          //
           
          console.log(`signal error ${error.name}: ${(error as { message?: string })?.message}`);
        }
      },
    );
  }, [session, stream]);

  const turnOffSubscriberVideo = useCallback(() => {
    if (!session) return;

    const {
      connection: { connectionId },
    } = stream;

    session.signal(
      {
        data: connectionId,
        type: 'video_off',
      },
      (error) => {
        if (error) {
           
          console.log(`signal error ${error.name}: ${(error as { message?: string })?.message}`);
        }
      },
    );
  }, [session, stream]);

  return (
    <div className="audience-item__settings">
        {stream && (
          <>
            <button
              className={clsx('btn -toolbar -action', !isParticipantPublishingVideo && '-disabled')}
              disabled={!isParticipantPublishingVideo}
              type="button"
              onClick={turnOffSubscriberVideo}
            >
              <VideoIcon isOn={isParticipantPublishingVideo} />
            </button>
            <button className="btn -toolbar -action" type="button" onClick={toggleSubscriberAudio}>
              <AudioIcon isOn={isParticipantPublishingAudio} />
            </button>
          </>
        )}
        {!participant.isEvicted && (
          <Modal
            modalTrigger={({ openModal }) => (
              <div>
                <button className="btn -toolbar -action" type="button" onClick={openModal}>
                  <CloseIcon />
                </button>
              </div>
            )}
          >
            {({ closeModal }) => (
              <>
                <div className="preview__generic">
                  <h2 className="preview__generic__heading">Remove user</h2>
                  <div className="preview__generic__text">
                    <p>Are you sure you wish to remove this user?</p>
                    <p>Please note: Doing so will remove them permanently.</p>
                  </div>
                </div>
                <nav className="preview__nav">
                  <button className={clsx('btn', '-secondary')} type="button" onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    className="btn"
                    type="button"
                    onClick={() => evictParticipant(participant, closeModal)}
                  >
                    Remove
                  </button>
                </nav>
                {errorMessage && <div className="modal__error">{errorMessage}</div>}
              </>
            )}
          </Modal>
        )}
      </div>
  );
};

export default AttendeeSettings;
