import clsx from 'clsx';
import { ReactElement, useEffect } from 'react';

import { useMediaDevices } from '~/lib/opentok/mediadevices';
import Select from '~/components/forms/Select';

export type Input = {
  deviceId: string;
  kind: string;
  label: string;
};

export type VideoInputSelectProps = {
  isDisabled: boolean;
  onChange: (value: string | undefined) => void;
  value?: string;
};

const shouldSetDefaultDevice = (
  currentlySelectedDeviceId = '',
  videoDevices: Input[] = [],
): boolean => {
  // Opentok doesn't like changing devices rapidly, so don't select a device on initial render.
  if (!currentlySelectedDeviceId) {
    return false;
  }

  // If we already selected a device and it is still on the video devices list, don't reset to default
  if (videoDevices.some((device) => device.deviceId === currentlySelectedDeviceId)) {
    return false;
  }

  // If we have not selected a device or it is no longer on the devices list
  // and there are devices to choose from, go ahead and set the device
  return videoDevices.length > 0;
};

const VideoInputSelect = ({
  value,
  onChange,
  isDisabled = false,
}: VideoInputSelectProps): ReactElement<VideoInputSelectProps> => {
  const { videoInputs, cameraAvailable } = useMediaDevices();

  useEffect(() => {
    if (videoInputs.length === 0) {
      onChange(undefined);
    }
    if (shouldSetDefaultDevice(value, videoInputs)) {
      onChange(videoInputs[0].deviceId);
    }
  }, [onChange, value, videoInputs]);

  const currentItem = videoInputs.find((input) => input.deviceId === value);

  if (!cameraAvailable) {
    return (
      <div className="video-input-select__single-input -disabled -with-icon">
        No camera detected
      </div>
    );
  }

  return (
    <div className="video-input-select__container">
      {videoInputs.length === 1 ? (
        <div className={clsx('video-input-select__single-input', isDisabled && '-disabled')}>
          {videoInputs[0].label}{' '}
        </div>
      ) : (
        <Select<Input>
          disabled={isDisabled}
          itemToString={(item): string => String(item?.label)}
          items={videoInputs}
          value={currentItem}
          onChange={({ selectedItem }): void => onChange(selectedItem?.deviceId || '')}
        />
      )}
    </div>
  );
};

export default VideoInputSelect;
