import { ComponentType, ReactElement } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { useTracking } from '~/lib/analytics';
import { desktop } from '~/style-mixins/theme';
import bodyMixin from '~/style-mixins/typography/bodyMixin';
import subheadMixin from '~/style-mixins/typography/subHeadMixin';
import DropdownInput from '~/components/forms/DropdownInput';
import Input, { InputProps } from '~/components/forms/Input';
import TextArea, { TextAreaProps } from '~/components/forms/TextArea';
import { maxLengthValidator, REQUIRED_ERROR_MESSAGE } from '~/components/forms/validation';
import { UpdateAttendeeProfileFormData } from '~/types/attendees';
import { EntityType } from '~/types/shared';

type GridFieldProps = {
  desktopColSpan?: number;
  mobileColSpan?: number;
};

type GridFieldInputProps = GridFieldProps & InputProps<UpdateAttendeeProfileFormData>;
type GridFieldTextAreaProps = GridFieldProps & TextAreaProps<UpdateAttendeeProfileFormData>;

const gridMixin = css<GridFieldProps>`
  grid-column: span ${({ mobileColSpan = 1 }) => mobileColSpan};

  ${({ desktopColSpan = 1 }) =>
    desktop(css`
      grid-column: span ${desktopColSpan};
    `)}
`;

const GridFieldInput = styled(Input)`
  ${bodyMixin}
  ${gridMixin}
` as ComponentType<GridFieldInputProps>;

const GridFieldTextArea = styled(TextArea)`
  ${gridMixin}
` as ComponentType<GridFieldTextAreaProps>;

const GridFieldDropdownInput = styled(DropdownInput)`
  ${gridMixin}
`;

const FieldHeader = styled.span<GridFieldProps>`
  ${subheadMixin}
  ${gridMixin}
`;

const AttendeeProfileRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1.5rem;
  gap: 1.5rem 2rem;

  ${desktop(css`
    grid-template-columns: 1fr 1fr;
  `)}
  }
`;

type AttendeeProfileFormProps = {
  industries: EntityType[];
  countries: EntityType[];
  form: UseFormReturn<UpdateAttendeeProfileFormData>;
  showSectionHeaders?: boolean;
};

const AttendeeProfileForm = ({
  countries,
  industries,
  form,
  showSectionHeaders = false,
}: AttendeeProfileFormProps): ReactElement<AttendeeProfileFormProps> => {
  const { register, formState, control } = form;
  const track = useTracking();

  return (
    <AttendeeProfileRoot>
      {showSectionHeaders && <FieldHeader desktopColSpan={2}>Personal details</FieldHeader>}
      <GridFieldInput
        error={formState.errors?.firstName}
        label="First name"
        name="firstName"
        placeholder="Please enter your first name"
        register={register}
        type="text"
        validation={{
          onBlur: () => track('Edited profile', 'First name'),
          required: REQUIRED_ERROR_MESSAGE,
        }}
      />
      <GridFieldInput
        error={formState.errors?.lastName}
        label="Last name"
        name="lastName"
        placeholder="Please enter your last name"
        register={register}
        type="text"
        validation={{
          onBlur: () => track('Edited profile', 'Last name'),
          required: REQUIRED_ERROR_MESSAGE,
        }}
      />
      <GridFieldInput
        error={formState.errors?.city}
        label="City"
        name="city"
        placeholder="Please enter your city"
        register={register}
        type="text"
        validation={{
          onBlur: () => track('Edited profile', 'City'),
          required: REQUIRED_ERROR_MESSAGE,
        }}
      />
      <Controller
        control={control}
        name="countryId"
        render={({ field: { onChange, onBlur, value, name } }) => (
          <GridFieldDropdownInput
            isRequired
            error={formState.errors?.countryId}
            label="Country"
            name={name}
            options={countries}
            placeholder="Please enter your country"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
        rules={{
          onBlur: () => track('Edited profile', 'Country'),
          required: REQUIRED_ERROR_MESSAGE,
        }}
      />
      <GridFieldTextArea
        desktopColSpan={2}
        error={formState.errors?.bio}
        label="Bio"
        name="bio"
        placeholder="Please enter your bio"
        register={register}
        rows={4}
        validation={{
          maxLength: maxLengthValidator(255),
          onBlur: () => track('Edited profile', 'Bio'),
          required: REQUIRED_ERROR_MESSAGE,
        }}
      />
      {showSectionHeaders && <FieldHeader desktopColSpan={2}>Work details</FieldHeader>}
      <GridFieldInput
        desktopColSpan={2}
        error={formState.errors?.jobTitle}
        label="Job title"
        name="jobTitle"
        placeholder="Please enter your job title"
        register={register}
        type="text"
        validation={{
          onBlur: () => track('Edited profile', 'Job title'),
          required: REQUIRED_ERROR_MESSAGE,
        }}
      />
      <GridFieldInput
        error={formState.errors?.companyName}
        isDisabled={false}
        label="Company name"
        name="companyName"
        placeholder="Please enter your company name"
        register={register}
        type="text"
        validation={{
          onBlur: () => track('Edited profile', 'Company name'),
          required: REQUIRED_ERROR_MESSAGE,
        }}
      />
      <Controller
        control={control}
        name="industryId"
        render={({ field: { onChange, onBlur, value, name } }) => (
          <GridFieldDropdownInput
            isRequired
            error={formState.errors?.industryId}
            label="Industry"
            name={name}
            options={industries}
            placeholder="Please enter your industry"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
        rules={{ required: REQUIRED_ERROR_MESSAGE }}
      />
    </AttendeeProfileRoot>
  );
};

export default AttendeeProfileForm;
