import { ApolloProvider } from '@apollo/client';
import { ReactElement, useMemo } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import PersonalSection from '~/components/navbar/PersonalSection';
import { TrackingContext } from '~/lib/analytics';
import { init } from '~/lib/apollo';
import { UserContext } from '~/lib/user';

const trackingContextValue = { category: 'Navigation' };

export type PersonalSectionWrapperProps = {
  avatarUrl?: string;
  listen?: boolean;
};

const PersonalSectionWrapper = ({
  listen,
  avatarUrl,
}: PersonalSectionWrapperProps): ReactElement<PersonalSectionWrapperProps> => {
  const client = init();

  const userContextValue = useMemo(
    () => ({
      avatarUrl,
      id: '',
      identity: '',
      name: '',
    }),
    [avatarUrl],
  );

  return (
    <TrackingContext.Provider value={trackingContextValue}>
      <UserContext.Provider value={userContextValue}>
        <ApolloProvider client={client}>
          <PersonalSection chatNotifications={listen || false} />
        </ApolloProvider>
      </UserContext.Provider>
    </TrackingContext.Provider>
  );
};

export default withErrorBoundary(PersonalSectionWrapper, {
  fallbackRender: () => null,
});
