import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

type StreamingOnIconProps = SVGProps<SVGSVGElement>;

const StreamingOnIcon = ({
  ref,
  ...props
}: StreamingOnIconProps): ReactElement<StreamingOnIconProps> => {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" id="2.-Rooms" stroke="none" strokeWidth="1">
        <g id="Backstage---Moderator1" transform="translate(-1191.000000, -729.000000)">
          <g id="Button/Medium/Primary/Enabled" transform="translate(1144.000000, 721.000000)">
            <g id="Icon-/-Accept" transform="translate(47.000000, 8.000000)">
              <rect height="24" id="Bounds" width="24" x="0" y="0" />
              <path
                d="M7.76,16.31 C6.67,15.23 6,13.73 6,12.07 C6,10.41 6.67,8.91 7.76,7.83 L9.18,9.25 C8.45,9.97 8,10.97 8,12.07 C8,13.17 8.45,14.17 9.17,14.9 L7.76,16.31 Z M16.24,16.31 C17.33,15.23 18,13.73 18,12.07 C18,10.41 17.33,8.91 16.24,7.83 L14.82,9.25 C15.55,9.97 16,10.97 16,12.07 C16,13.17 15.55,14.17 14.83,14.9 L16.24,16.31 Z M12,10.07 C10.9,10.07 10,10.97 10,12.07 C10,13.17 10.9,14.07 12,14.07 C13.1,14.07 14,13.17 14,12.07 C14,10.97 13.1,10.07 12,10.07 Z M20,12.07 C20,14.28 19.1,16.28 17.65,17.72 L19.07,19.14 C20.88,17.33 22,14.83 22,12.07 C22,9.31 20.88,6.81 19.07,5 L17.65,6.42 C19.1,7.86 20,9.86 20,12.07 Z M6.35,6.42 L4.93,5 C3.12,6.81 2,9.31 2,12.07 C2,14.83 3.12,17.33 4.93,19.14 L6.35,17.72 C4.9,16.28 4,14.28 4,12.07 C4,9.86 4.9,7.86 6.35,6.42 Z"
                fill="#FFFFFF"
                id="Combined-Colour"
              />
            </g>
          </g>
        </g>
      </g>
    </IconBase>
  );
};

export default StreamingOnIcon;
