import { useQuery } from '@apollo/client';
import { PubNubProvider } from 'pubnub-react';
import { ReactElement, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { useTracking } from '~/lib/analytics';
import { EventSponsorInfoDocument } from '~/operations/catalyst';
import { OpentokConfig, PubnubClientConfig, User } from '~/types/rooms';
import MingleStartScreen from '~/components/mingle/MingleStartScreen';
import usePubnubSubscriber from '~/components/realtime/usePubnubSubscriber';
import ErrorScreen from '~/components/shared/molecules/ErrorScreen';
import { MediaDevicesProvider } from '~/lib/opentok/mediadevices';

import MingleMain from './MingleMain';

export type MingleProps = {
  channel: string;
  lobby: {
    opentok: OpentokConfig;
  };
  pubnub: PubnubClientConfig;
  reportUrl: string;
  sessionName?: string;
  user: User;
};

const Mingle = ({
  channel,
  lobby,
  pubnub: pubnubConfig,
  user,
  reportUrl,
  sessionName,
}: MingleProps): ReactElement<MingleProps> => {
  const track = useTracking();
  const [start, setStart] = useState(false);
  const { data: eventInfo } = useQuery(EventSponsorInfoDocument);

  // Don't subscribe to the channel until the user starts the session.
  const channels = useMemo(() => {
    if (!start) {
      return undefined;
    }
    return [channel];
  }, [start, channel]);

  const { pubnub } = usePubnubSubscriber({
    channels,
    config: {
      ...pubnubConfig,
      presenceTimeout: 60,
    },
  });

  const sponsor = eventInfo?.event?.sponsor;

  if (!start || !pubnub) {
    return (
      <MingleStartScreen
        sessionTitle={sessionName || ''}
        sponsor={sponsor}
        username={user.name}
        onStart={(): void => {
          track('Started mingle');
          setStart(true);
        }}
      />
    );
  }

  return (
    <MediaDevicesProvider>
      <PubNubProvider client={pubnub}>
        <MingleMain
          channel={channel}
          lobby={lobby}
          reportUrl={reportUrl}
          sessionName={sessionName}
          sponsor={sponsor}
          user={user}
        />
      </PubNubProvider>
    </MediaDevicesProvider>
  );
};

export default withErrorBoundary(Mingle, { fallback: <ErrorScreen /> });
