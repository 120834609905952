import { JoinRoomMutation, TimeslotPartsFragment } from '~/operations/catalyst';
import { Participant, RoomOptions, User } from '~/types/rooms';

import urlFor from '../urlFor';
import PERMISSIONS_MAP from './permissionsMap';

const convertRoomV2Participants = (
  participantEdges?: TimeslotPartsFragment['participants'],
): Participant[] => {
  if (!participantEdges) {
    return [];
  }

  return participantEdges.edges.map(({ node }) => ({
    accreditation: node.role,
    avatarUrl: node.avatarUrl,
    companyName: node.companyName ?? undefined,
    id: node.id,
    identity: node.id,
    jobTitle: node.jobTitle ?? undefined,
    name: node.name,
    role: node.role ?? undefined,
  }));
};

const convertJoinRoomMutationDataToRoomOptions = (
  mutation: Required<JoinRoomMutation['roomJoin'] | null>,
  user: User,
): RoomOptions | null => {
  if (!mutation) {
    return null;
  }
  const {
    room,
    role,
    config: {
      chat,
      videoCall,
      moderation,
      questions,
      reactions,
      backstageEnabled,
      heartbeatInterval,
      videoCallLiveStream,
      contentLiveStream,
    },
  } = mutation || {};

  const participants = convertRoomV2Participants(room.timeslot?.participants);

  return {
    broadcast:
      videoCallLiveStream?.iframeUrl || videoCallLiveStream?.hlsUrl
        ? {
            hlsUrl: videoCallLiveStream.hlsUrl ?? undefined,
            url: videoCallLiveStream.iframeUrl ?? undefined,
          }
        : undefined,
    calendarEvent:
      room.timeslot?.startsAt && room.timeslot.endsAt
        ? {
            endsAt: room.timeslot.endsAt,
            startsAt: room.timeslot.startsAt,
          }
        : undefined,
    chat: chat ? { channel: chat.channel, whiteListedDomains: chat.allowedDomains } : undefined,
    heartbeatInterval,
    moderation: { channel: moderation.channel },
    occupant: PERMISSIONS_MAP[role],
    opentok: videoCall
      ? {
          apiKey: videoCall.apiKey,
          sessionId: videoCall.sessionId,
          token: videoCall.token,
        }
      : undefined,
    participants,
    pubnub: {
      authKey: moderation.authKey,
      heartbeatInterval,
      publishKey: moderation.publishKey,
      subscribeKey: moderation.subscribeKey,
      uuid: user.identity,
    },
    questions: questions ? { channel: questions.channel } : undefined,
    reactions: reactions
      ? {
          channel: reactions.channel,
          // FIXME
          hypeFactor: 30,
        }
      : undefined,
    room: {
      description: room.timeslot?.description,
      featuresBackstage: backstageEnabled,
      featuresQuestions: !!questions,
      featuresReaction: !!reactions,
      featuresTextChat: !!chat,
      id: room.id,
      name: room.title,
      participants,
      reactionsLayout: reactions?.enabled ?? [],
    },
    sponsor: room.timeslot?.sponsor
      ? {
          avengerUuid: room.timeslot.sponsor.id,
          country: room.timeslot.sponsor.company.countryName ?? undefined,
          logoUrl: room.timeslot.sponsor.company.logoUrl ?? undefined,
          name: room.timeslot.sponsor.company.name,
          profileUrl: urlFor(`/exhibitors/${room.timeslot.sponsor.id}`),
        }
      : undefined,
    stream: contentLiveStream?.iframeUrl
      ? {
          url: contentLiveStream.iframeUrl,
        }
      : undefined,
    user,
    whiteListedDomains: chat?.allowedDomains ?? [],
  };
};

export default convertJoinRoomMutationDataToRoomOptions;
