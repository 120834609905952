import { createContext, useContext } from 'react';

import { User } from '~/types/rooms';

export const UserContext = createContext<User>({
  companyName: 'Default Company',
  id: 'default',
  identity: 'default',
  name: 'Default User',
});

export const useCurrentUser = (): User => {
  return useContext(UserContext);
};
