import { ReactElement, SVGProps } from 'react';

import IconBase from './IconBase';

type StreamingOffIconProps = SVGProps<SVGSVGElement>;

const StreamingOffIcon = ({
  ref,
  ...props
}: StreamingOffIconProps): ReactElement<StreamingOffIconProps> => {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" id="2.-Rooms" stroke="none" strokeWidth="1">
        <g id="Backstage---Moderator4" transform="translate(-1194.000000, -673.000000)">
          <g id="Button/Medium/Primary/Enabled" transform="translate(1144.000000, 665.000000)">
            <g id="Icon-/-Accept" transform="translate(50.000000, 8.000000)">
              <rect height="24" id="Bounds" width="24" x="0" y="0" />
              <path
                d="M8.14,9.96 C8.05,10.29 8,10.64 8,11 C8,12.1 8.45,13.1 9.17,13.83 L7.75,15.25 C6.67,14.16 6,12.66 6,11 C6,10.07 6.21,9.2 6.58,8.41 L5.11,6.94 C4.4,8.13 4,9.52 4,11 C4,13.21 4.9,15.21 6.35,16.65 L4.93,18.07 C3.12,16.26 2,13.76 2,11 C2,8.96 2.61,7.07 3.66,5.49 L1.39,3.22 L2.8,1.81 L21.18,20.19 L19.77,21.6 L8.14,9.96 Z M17.42,13.59 C17.79,12.8 18,11.93 18,11 C18,9.34 17.33,7.84 16.24,6.76 L14.82,8.18 C15.55,8.9 16,9.9 16,11 C16,11.36 15.95,11.71 15.86,12.04 L17.42,13.59 Z M20,11 C20,12.48 19.6,13.87 18.89,15.06 L20.34,16.51 C21.39,14.93 22,13.04 22,11 C22,8.24 20.88,5.74 19.07,3.93 L17.65,5.35 C19.1,6.79 20,8.79 20,11 Z"
                fill="#FFFFFF"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </IconBase>
  );
};

export default StreamingOffIcon;
