import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { RoomHeartbeatDocument } from '~/operations/catalyst';

export type HeartbeatOpts = {
  interval: number;
  roomId: string;
};

export const useHeartbeat = ({ roomId, interval }: HeartbeatOpts): void => {
  const [heartbeat] = useMutation(RoomHeartbeatDocument, {
    ignoreResults: true,
    variables: {
      id: roomId,
    },
  });

  useEffect(() => {
    const checkForHeartbeat = () => {
      void heartbeat();
    };
    if (interval <= 0) {
      return () => {
        // No cleanup needed
      };
    }

    checkForHeartbeat();
    const h = setInterval(checkForHeartbeat, interval * 1000);

    return () => clearInterval(h);
  }, [roomId, interval, heartbeat]);
};
