import { ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { Link, useLocation, useRoute } from 'wouter';

import { useTracking } from '~/lib/analytics';
import AttendeeSearchDropdown, { AttendeeHit } from '~/components/chat/AttendeeSearchDropdown';
import { useChat } from '~/components/chat/ChatProvider';
import EmptyChannels from '~/components/conversations/EmptyChannels';
import Button from '~/components/shared/atoms/Button';
import NewChatIcon from '~/components/icons/NewChatIcon';
import { ChatChannel as ChatChannelType } from '~/types/chat';

import ChatChannel from './ChatChannel';
import ChatChannelList from './ChatChannelList';
import Conversation from './Conversation';
import EmptyChat from './EmptyChat';

type HeaderProps = {
  onNewChat: (value: AttendeeHit) => Promise<void>;
};

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--chat-header-y);
  padding: 0.5rem 1rem;
  position: relative;
`;

const Title = styled.h3`
  font-size: var(--fs-xl);
  font-weight: 600;
`;

const Search = styled(AttendeeSearchDropdown)`
  flex: 1;
`;

const Header = ({ onNewChat }: HeaderProps): ReactElement<HeaderProps> => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSearchVisible, setSearchVisible] = useState(false);

  useLayoutEffect(() => {
    if (isSearchVisible) {
      inputRef.current?.focus();
    }
  }, [isSearchVisible]);

  return (
    <StyledHeader>
      {!isSearchVisible && (
        <>
          <Title>Chats</Title>
          <Button className="-svg" theme="secondary" onClick={(): void => setSearchVisible(true)}>
            <NewChatIcon />
            New chat
          </Button>
        </>
      )}
      {isSearchVisible && (
        <Search
          inputRef={inputRef}
          name="new-chat"
          onIsOpenChange={({ isOpen, selectedItem }): void => {
            if (!isOpen) {
              setSearchVisible(false);
              if (selectedItem) {
                void onNewChat(selectedItem);
              }
            }
          }}
        />
      )}
    </StyledHeader>
  );
};

type ConversationsProps = {
  numChannels?: number;
};

const Layout = styled.div`
  height: 80vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: var(--s-chat-nav-x) 1fr;
  grid-auto-flow: dense;
  grid-template-areas: 'sidebar main';
  gap: 1rem;
  padding: 1rem 0;
  --chat-header-y: 4.25rem;
`;

const boxStyles = `
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--c-silver);
  border-radius: var(--s-bw);
  background-color: var(--c-white);
`;

const Box = styled.div`
  ${boxStyles}
`;

const BoxSkeleton = styled(Skeleton)`
  ${boxStyles}
`;

const Sidebar = styled.aside`
  grid-area: sidebar;
  overflow: hidden;
`;

const Main = styled.div`
  grid-area: main;
  overflow: hidden;
`;

const Conversations = ({ numChannels = 200 }: ConversationsProps): ReactElement | null => {
  const track = useTracking();
  const [, navigate] = useLocation();
  const [, params] = useRoute('/:channel');
  const [selectedChannel, setSelectedChannel] = useState<ChatChannelType>();
  const { channels, isLoading, isCreatingNewChat, fetchRecent, fetchNewChannel, createChannel } =
    useChat();

  useEffect(() => {
    fetchRecent(numChannels);
  }, [numChannels, fetchRecent]);

  useEffect(() => {
    if (channels.length === 0) {
      return;
    }

    if (!params?.channel) {
      setSelectedChannel(channels[0]);
      return;
    }

    const channel = channels.find(({ id }) => id === params.channel);

    if (!channel) {
      fetchNewChannel({ id: params.channel });
      return;
    }

    setSelectedChannel(channel);
  }, [channels, fetchNewChannel, params?.channel]);

  const createAndNavigateToChannel = useCallback(
    async (attendeeId: string) => {
      track('Created chat', attendeeId);
      const id = await createChannel({ participants: [attendeeId] });
      if (id) {
        navigate(`/${id}`);
      }
    },
    [navigate, createChannel, track],
  );

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const attendeeId = search.get('attendee');

    if (attendeeId) {
      void createAndNavigateToChannel(attendeeId);
    }
  }, [createAndNavigateToChannel]);

  const onNewChat = useCallback(
    async (value: AttendeeHit) => {
      return createAndNavigateToChannel(value.id);
    },
    [createAndNavigateToChannel],
  );

  const isLoadingChat = isLoading || isCreatingNewChat;

  return (
    <Layout className="container -md">
      <Sidebar>
        <Box>
          <Header onNewChat={onNewChat} />
          <ChatChannelList>
            {channels.length === 0 && isLoading && (
              <>
                <ChatChannel />
                <ChatChannel />
                <ChatChannel />
                <ChatChannel />
                <ChatChannel />
              </>
            )}
            {channels.length === 0 && !isLoading && <EmptyChannels />}
            {channels.map((channel) => (
              <Link key={channel.id} href={`/${channel.id}`}>
                <ChatChannel
                  active={!isLoadingChat && selectedChannel?.id === channel.id}
                  channel={channel}
                />
              </Link>
            ))}
          </ChatChannelList>
        </Box>
      </Sidebar>
      <Main>
        {isLoadingChat ? (
          <BoxSkeleton />
        ) : (
          <Box>
            {channels.length === 0 && <EmptyChat />}
            {selectedChannel && <Conversation key={selectedChannel.id} channel={selectedChannel} />}
          </Box>
        )}
      </Main>
    </Layout>
  );
};

export default Conversations;
