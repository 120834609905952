import { ReactElement } from 'react';

import Button from '~/components/button/Button';
import { usePin } from '~/lib/calendar/pins';
import PinTimeslotIcon from '~/components/icons/PinTimeslotIcon';

export type AddToScheduleButtonProps = {
  calendarEventId: string;
  className?: string;
};

const AddToScheduleButton = ({
  calendarEventId,
}: AddToScheduleButtonProps): ReactElement<AddToScheduleButtonProps> => {
  const [pinned, toggle] = usePin(calendarEventId);

  return (
    <Button
      icon={<PinTimeslotIcon height="24px" isOn={pinned} viewBox="0 0 24 24" width="24px" />}
      type="button"
      variant="secondary"
      onClick={toggle}
    >
      <span>{pinned ? 'Remove from my schedule' : 'Add to my schedule'}</span>
    </Button>
  );
};

export default AddToScheduleButton;
