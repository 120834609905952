import { createContext } from 'react';

export type Login = {
  appStoreUrl?: string;
  appUrl?: string;
  conferenceName: string;
  cta?: CTA;
  googlePlayUrl?: string;
  supportEmail?: string;
};

type CTA = {
  action: string;
  title: string;
  url: string;
};

const LoginContext = createContext<Login>({
  conferenceName: '',
});

export default LoginContext;
