type ReplaceArrayItem = <T>(array: T[], comparator: (val: T) => boolean, newValue: T) => T[];

const replaceArrayItem: ReplaceArrayItem = (array, comparator, newValue) => {
  const newArray = [...array];
  const itemIndexToReplace = array.findIndex(comparator);
  if (itemIndexToReplace !== -1) {
    newArray[itemIndexToReplace] = newValue;
  }
  return newArray;
};
export default replaceArrayItem;
