import { DateTime } from 'luxon';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { desktop } from '~/style-mixins/theme';
import captionOneMixin from '~/style-mixins/typography/captionOneMixin';
import LogoutButton from '~/components/attendees/LogoutButton';
import PoweredBySummitEngineLink from '~/components/shared/atoms/PoweredBySummitEngineLink';
import { useBreakpoint } from '~/components/util/BreakpointProvider';
import Container from '~/components/util/Container';

export type FooterProps = {
  conferenceName: string;
  exploreLinks: {
    attendees?: string;
    partners?: string;
    perks?: string;
    startups?: string;
  };
  footerConfigs: {
    entries: {
      title: string;
      url: string;
    }[];
    title: string;
  }[];
  showLogOut: boolean;
};

type TitleProps = { variant?: 'default' | 'captalized' };

type ColumnProps = {
  desktopPos?: number;
  variant?: 'default' | 'logo-container' | 'conf-info';
};

const ContentWrapper = styled(Container)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 1rem;

  ${desktop(css`
    flex-flow: row nowrap;
  `)}
`;

const Link = styled.a`
  color: var(--c-black);
  &:hover {
    color: var(--c-black);
    text-decoration: underline;
  }
`;

const Title = styled.h3`
  color: var(--c-black);
  font-weight: bold;
  margin-bottom: 1rem;

  ${({ variant }: TitleProps) =>
    variant === 'captalized' &&
    css`
      text-transform: uppercase;
    `}
`;

const ConferenceLogo = styled.div`
  img {
    object-fit: contain;
    max-height: var(--is-2xl);
  }
`;

const CopyrightDate = styled.p`
  ${captionOneMixin}
`;

const CopyrightNotice = styled.p`
  ${captionOneMixin}
`;

const Column = styled.div`
  ${({ variant = 'default', desktopPos }: ColumnProps) => css`
    display: flex;
    flex-direction: column;
    color: var(--c-grey);
    font-size: var(--fs-xs);

    ${variant === 'logo-container' &&
    css`
      width: calc(var(--is-2xl) * 3);
      align-items: flex-end;
      gap: 0.5rem;
    `}

    ${desktopPos &&
    desktop(css`
      order: ${desktopPos};
    `)}
  `}
`;

const Footer = ({
  conferenceName,
  showLogOut,
  footerConfigs,
  exploreLinks,
}: FooterProps): ReactElement => {
  const { t } = useTranslation();
  const { desktop: isDesktop } = useBreakpoint();

  const { logoFooter } = window.assets;
  const logoFooterHref = window.templateVariables?.logoFooterHref;

  return (
    <ContentWrapper size="large">
      <Column desktopPos={0}>
        {showLogOut ? (
          <LogoutButton />
        ) : (
          <>
            <Title variant="captalized">Explore</Title>
            {exploreLinks.partners ? (
              <Link href={exploreLinks.partners}>{t('labels.partners')}</Link>
            ) : null}
            {exploreLinks.startups ? <Link href={exploreLinks.startups}>Startups</Link> : null}
            {exploreLinks.attendees ? <Link href={exploreLinks.attendees}>Attendees</Link> : null}
            {exploreLinks.perks ? <Link href={exploreLinks.perks}>Perks</Link> : null}
          </>
        )}
      </Column>
      {footerConfigs.map((config) => (
        <Column key={config.title}>
          <Title variant="captalized">{config.title}</Title>
          {config.entries.map((e) => (
            <Link key={e.url} href={e.url}>
              {e.title}
            </Link>
          ))}
        </Column>
      ))}
      {isDesktop && (
        <Column variant="logo-container">
          {logoFooter ? (
            <>
              <ConferenceLogo>
                {logoFooterHref ? (
                  <a href={logoFooterHref} rel="noreferrer" target="_blank">
                    <img alt="Footer logo" src={logoFooter} />
                  </a>
                ) : (
                  <img alt="Footer logo" src={logoFooter} />
                )}
              </ConferenceLogo>
              <PoweredBySummitEngineLink />
            </>
          ) : null}
        </Column>
      )}
      <Column desktopPos={-1}>
        <Title>{conferenceName}</Title>
        <CopyrightDate>
          {t('footer.copyright')} {DateTime.now().year}
        </CopyrightDate>
        <CopyrightNotice>{t('footer.rights-reserved')}</CopyrightNotice>
      </Column>
    </ContentWrapper>
  );
};

export default Footer;
