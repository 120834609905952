import { CSSProperties,ReactElement } from 'react';

type SponsorOverlayProps = {
  backgroundUrl: string;
};

const COLS = 6;
const ROWS = 6;

const SponsorOverlay = ({ backgroundUrl }: SponsorOverlayProps): ReactElement => {
  const style = {
    '--sponsor-cols': COLS,
    '--sponsor-logo': `url(${backgroundUrl})`,
    '--sponsor-rows': ROWS,
  } as CSSProperties;

  const createRow = () =>
    Array.from(Array(COLS).keys()).map((val) => <div key={`sponsor-col::${val}`} className="stage__sponsored-background__logo" />);

  const createRows = () =>
    Array.from(Array(ROWS).keys()).map((val) => (
      <div key={`sponsor-row::${val}`} className="stage__sponsored-background__row">{createRow()}</div>
    ));

  return (
    <div className="stage__sponsored-background" style={style}>
      <div>{createRows()}</div>
    </div>
  );
};

export default SponsorOverlay;
