import styled from 'styled-components';

const Loader = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;

  &:before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
`;

export default Loader;
