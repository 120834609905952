import { ReactElement, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

import { useRoom } from '~/lib/rooms';
import { Question, useQuestions } from '~/lib/rooms/questions';
import urlFor from '~/lib/urlFor';

import IconButton from '../../button/IconButton';
import TitleSelect from '../../forms/TitleSelect';
import KeynoteIcon from '../../icons/KeynoteIcon';
import Center from '../../util/Center';
import QuestionCard from './QuestionCard';

enum Filter {
  AllQuestions = 'All questions',
  Answered = 'Answered',
  Favourite = 'Favourite',
  Hidden = 'Hidden',
  MyQuestions = 'My questions',
  Unanswered = 'Unanswered'
}

const moderatorFilters = [
  Filter.AllQuestions,
  Filter.Favourite,
  Filter.Unanswered,
  Filter.Answered,
  Filter.Hidden,
];

const spectatorFilters = [Filter.MyQuestions, Filter.Answered];

const getFilteredQuestions = (questions: Question[], filter: Filter) => {
  return questions.filter((q) => {
    switch (filter) {
      case Filter.AllQuestions:
        return !q.hidden;
      case Filter.MyQuestions:
        return q.askedByMe;
      case Filter.Favourite:
        return q.favourite;
      case Filter.Unanswered:
        return !q.answered && !q.hidden;
      case Filter.Answered:
        return q.answered && !q.hidden;
      case Filter.Hidden:
        return q.hidden;
      default:
        return false;
    }
  });
};

const getEmptyText = (filter: Filter) => {
  switch (filter) {
    case Filter.Favourite:
      return 'No favourite questions';
    case Filter.Answered:
      return 'No answered questions';
    case Filter.Unanswered:
      return 'No unanswered questions';
    case Filter.Hidden:
      return 'No hidden questions';
    default:
      return 'No questions yet';
  }
};

const Settings = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
`;

const NoQuestions = styled(Center)`
  color: var(--c-light-gray);
  font-size: var(--fs-sm);
`;

const Fade = styled.div<{
  state: TransitionStatus;
}>`
  height: 100%;
  transition: 0.25s ease;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
  transform: ${({ state }) => (state === 'entered' ? 'none' : 'translateY(0.5rem)')};
`;

const QuestionsListContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
`;

const QuestionsList = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 1rem 0 1rem;
`;

const Card = styled(QuestionCard)`
  margin: 0.5rem 0;
`;

const QuestionsStream = (): ReactElement => {
  const {
    questions,
    currentQuestion,
    hasMore,
    canModerateQuestions,
    loadMore,
    setCurrentQuestion,
    markAnswered,
    markHidden,
    markUnhidden,
    markFavourite,
    markUnfavourite,
  } = useQuestions();

  const { room } = useRoom();

  const filters = canModerateQuestions ? moderatorFilters : spectatorFilters;
  const [filter, setFilter] = useState(filters[0]);

  // Transition out the current list before applying a new filter. This makes toggling
  // between filters a much smoother experience.
  const [show, setShow] = useState(true);
  const [newFilter, setNewFilter] = useState<Filter | null>(null);

  const filteredQuestions = useMemo(
    () => getFilteredQuestions(questions, filter),
    [questions, filter],
  );

  useEffect(() => loadMore(), [loadMore]);

  return (
    <>
      <Settings>
        <TitleSelect
          items={filters.map((f) => ({ label: f.toString(), value: f }))}
          value={filter}
          onChange={(f) => {
            setShow(false);
            setNewFilter(f);
          }}
        />
        {canModerateQuestions && (
          <div>
            <a href={urlFor(`/rooms/${room.id}/present`)} rel="noreferrer" target="_blank">
              <IconButton title="Open presentation view">
                <KeynoteIcon />
              </IconButton>
            </a>
          </div>
        )}
      </Settings>
      <QuestionsListContainer>
        <Transition
          appear
          in={show}
          timeout={250}
          onExited={() => {
            if (newFilter) {
              setFilter(newFilter);
            }
            setShow(true);
          }}
        >
          {(state) => (
            <Fade state={state}>
              {filteredQuestions.length === 0 && <NoQuestions>{getEmptyText(filter)}</NoQuestions>}
              {filteredQuestions.length > 0 && (
                <QuestionsList
                  hasMore={hasMore}
                  loadMore={loadMore}
                  threshold={35}
                  useWindow={false}
                >
                  {filteredQuestions.map((question) => {
                    const { id, author, body, askedAt, answered, hidden, favourite } = question;

                    return (
                      <Card
                        key={`question-card::${id}`}
                        answered={answered}
                        askedAt={askedAt}
                        author={author}
                        body={body}
                        current={currentQuestion?.id === id}
                        favourite={favourite}
                        hidden={canModerateQuestions && hidden}
                        moderator={canModerateQuestions}
                        onFavourite={() => markFavourite(question)}
                        onHide={() => markHidden(question)}
                        onMarkAnswered={() => {
                          markAnswered(question);
                          setCurrentQuestion(null);
                        }}
                        onShowOnScreen={() => {
                          if (currentQuestion) {
                            markAnswered(currentQuestion);
                          }
                          setCurrentQuestion(question);
                        }}
                        onUnfavourite={() => markUnfavourite(question)}
                        onUnhide={() => markUnhidden(question)}
                      />
                    );
                  })}
                </QuestionsList>
              )}
            </Fade>
          )}
        </Transition>
      </QuestionsListContainer>
    </>
  );
};

export default QuestionsStream;
