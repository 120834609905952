import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from '~/style-mixins/theme';

const BreakpointContext = createContext<null | {
  desktop: boolean;
  mobile: boolean;
}>(null);

/**
 * based on https://usehooks.com/useMedia/
 */
const useMedia = () => {
  const mobileMql = window.matchMedia(MOBILE_MAX_WIDTH);
  const desktopMql = window.matchMedia(DESKTOP_MIN_WIDTH);

  const getValue = useCallback(() => {
    return {
      desktop: desktopMql.matches,
      mobile: mobileMql.matches,
    };
  }, [mobileMql, desktopMql]);

  const [value, setValue] = useState(getValue);
  useEffect(() => {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook callback is created once on mount).
    const handler = () => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mobileMql.addEventListener('change', handler);
    desktopMql.addEventListener('change', handler);
    // Remove listeners on cleanup
    return () => {
      mobileMql.removeEventListener('change', handler);
      desktopMql.removeEventListener('change', handler);
    };
  }, [mobileMql, desktopMql, getValue]);
  return value;
};

/**
 * Use context so we only register two event handlers for the whole app.
 */
const BreakpointProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const mediaMatches = useMedia();

  return <BreakpointContext.Provider value={mediaMatches}>{children}</BreakpointContext.Provider>;
};

export const useBreakpoint = (): {
  desktop: boolean;
  mobile: boolean;
} => {
  const context = useContext(BreakpointContext);
  if (context === null) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
};

export default BreakpointProvider;
