import { ReactElement, SVGProps } from 'react';

type StarIconProps = SVGProps<SVGSVGElement>;

const StarIcon = (props: StarIconProps): ReactElement<StarIconProps> => {
  return (
    <svg height="19px" viewBox="0 0 20 19" width="20px" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFB74C" transform="translate(-982.000000, -143.000000)">
          <g transform="translate(926.000000, 141.000000)">
            <g transform="translate(56.000000, 2.000000)">
              <polygon points="10 15.27 16.18 19 14.54 11.97 20 7.24 12.81 6.63 10 0 7.19 6.63 0 7.24 5.46 11.97 3.82 19" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StarIcon;
