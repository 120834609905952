import { FormEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { createOtpChallenge, verifyOtpChallenge } from '~/lib/avenger/api';

import Button from '../button/Button';
import LinkButton from '../button/LinkButton';
import FormField from '../forms/FormField';
import LoadingIcon from '../icons/LoadingIcon';
import StandaloneFlashNotification from '../notifications/StandaloneFlashNotification';
import LoginForm from './LoginForm';
import LoginPanel from './LoginPanel';
import SuccessMessage from './SuccessMessage';

// Strip whitespace and convert to uppercase.
const normalizeCode = (code: string): string => code.replace(/\s/g, '').toUpperCase();

const isOtpFlowStarted = (): boolean => {
  try {
    return !!localStorage.getItem('otp');
  } catch {
    return false;
  }
};

const OtpVerifyPanel = (): ReactElement => {
  const params = new URLSearchParams(window.location.search);
  const login = params.get('email');
  const initialCode = params.get('code');

  const { t } = useTranslation();
  const [code, setCode] = useState(initialCode);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const [_location, setLocation] = useLocation();
  const form = useRef<HTMLFormElement>(null);

  const id = params.get('id');

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!id || !code) {
      return;
    }

    setLoading(true);

    try {
      const res = await verifyOtpChallenge(id, code);
      window.location.href = `qr/${res.auth_token}`;
    } catch {
      setMessage(t('login.otp.error'));
      setLoading(false);
    }
  };

  const resend = async (): Promise<void> => {
    if (!login) {
      return;
    }

    const res = await createOtpChallenge(login);
    const resendParams = new URLSearchParams({
      id: res.id,
      email: login,
    });

    setLocation(`/verify?${resendParams.toString()}`, { replace: true });
    setMessage(t('login.otp.emailSent'));
  };

  // Auto-verify the code if it was provided as a query parameter and an otp login flow
  // was started from this browser. This prevents antivirus solutions and similar from
  // verifying the code when scanning the magic link.
  useEffect(() => {
    if (!initialCode || !id || !code || !form.current) {
      return;
    }

    if (!isOtpFlowStarted()) {
      return;
    }

    if (initialCode === code) {
      form.current.requestSubmit();
    }
  }, [initialCode, id, code]);

  return (
    <LoginPanel size="small">
      {message && (
        <StandaloneFlashNotification
          fadeOutTimeout={30_000}
          message={message}
          type="error"
          onDismiss={(): void => setMessage(undefined)}
        />
      )}
      <SuccessMessage>{t('login.otp.verifyMessage')}</SuccessMessage>
      <LoginForm ref={form} onSubmit={handleSubmit}>
        <FormField isRequired label="" name="code">
          <input
            required
            autoComplete="off"
            id="code"
            name="code"
            placeholder={t('login.otp.verifyPlaceholder')}
            type="text"
            value={code || ''}
            onChange={(e): void => setCode(normalizeCode(e.target.value))}
          />
        </FormField>
        <Button disabled={loading || !code} icon={loading && <LoadingIcon />} size="large">
          {t('login.otp.verify')}
        </Button>
        <LinkButton type="button" onClick={resend}>
          {t('login.otp.resend')}
        </LinkButton>
      </LoginForm>
    </LoginPanel>
  );
};

export default OtpVerifyPanel;
