import { useQuery } from '@apollo/client';
import { ReactElement } from 'react';

import { useTitle } from '~/lib/dom';
import urlFor from '~/lib/urlFor';
import { ExhibitorProfileDocument } from '~/operations/catalyst';
import ProfileHead from '~/components/shared/molecules/ProfileHead';

import SkeletonProfileHead from '../shared/molecules/SkeletonProfileHead';
import { ExhibitorProfileEndorsementText } from './molocules/ExhibitorProfileEndorsementText';

type ExhibitorProfileHeadProps = {
  id: string;
  mapEnabled?: boolean;
};

export const ExhibitorProfileHead = ({
  id,
  mapEnabled,
}: ExhibitorProfileHeadProps): ReactElement<ExhibitorProfileHeadProps> | null => {
  const { loading, data } = useQuery(ExhibitorProfileDocument, {
    fetchPolicy: 'cache-first',
    variables: { id },
  });

  useTitle(data?.appearance?.company.name);

  if (loading) return <SkeletonProfileHead />;
  if (typeof data?.appearance?.company.name !== 'string') return null;

  const { appearance } = data;

  const industry =
    appearance.industries.length > 0
      ? appearance.industries.map((i) => i.name).join(', ')
      : appearance.industry?.name;
  const mapUrl = mapEnabled ? urlFor(`/map?location=${appearance.standNumber}`) : undefined;

  return (
    <ProfileHead
      description={appearance.company.elevatorPitch ?? undefined}
      endorsementLogoUrl={appearance.endorsedBy?.company.logoUrl ?? undefined}
      industry={industry}
      info={
        appearance.endorsedBy && (
          <ExhibitorProfileEndorsementText
            name={appearance.endorsedBy.company.name}
            url={urlFor(`/exhibitors/${appearance.endorsedBy.id}`)}
          />
        )
      }
      location={appearance.company.countryName ?? undefined}
      name={appearance.company.name}
      role={appearance.track ?? undefined}
      standInfo={appearance.standNumber ?? undefined}
      standUrl={appearance.boothUrl || mapUrl}
    />
  );
};
