import clsx from 'clsx';
import { MessageEvent } from 'pubnub';
import { ReactElement,useEffect, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import FlashNotification, {
  CalendarEvent,
  FlashTypes,
} from '~/components/notifications/FlashNotification';
import useNotifications from '~/components/notifications/useNotifications';
import usePubnubSubscriber from '~/components/realtime/usePubnubSubscriber';

const TIMEOUT = 15_000;
const TIMEOUT_FACTOR = 300;

const calculateTimeout = (index: number): number => TIMEOUT + index * TIMEOUT_FACTOR;

export type FlashMessage = {
  data: {
    body: string;
    calendar_event?: CalendarEvent;
    type?: FlashTypes;
    url?: string;
  };
  id: string;
};

type FlashNotificationProps = {
  flashMessages: FlashMessage[];
  withNotifications?: boolean;
};

const FlashNotifications = ({
  flashMessages,
  withNotifications = true,
}: FlashNotificationProps): ReactElement<FlashNotificationProps> => {
  const [notifications, setNotifications] = useState(flashMessages);

  const { channel, pubnubConfig } = useNotifications(!withNotifications);
  const channels = useMemo(() => (channel ? [channel] : []), [channel]);
  const { pubnub } = usePubnubSubscriber({ channels, config: pubnubConfig });

  useEffect(() => {
    if (!pubnub) return () => {
      // No cleanup needed
    };

    const listener = {
      message: (messageEvent: MessageEvent): void => {
        setNotifications((prevState) => [...prevState, messageEvent.message]);
      },
    };

    pubnub.addListener(listener);

    return () => {
      pubnub.removeListener(listener);
    };
  }, [pubnub]);

  const removeNotification = (id: string): void => {
    setNotifications((prevState) => prevState.filter((notification) => notification.id !== id));
  };

  return (
    <div className={clsx('flash-notifications')}>
      {notifications.map(
        ({ id, data: { body, url, type, calendar_event: calendarEvent } }, index) => (
          <FlashNotification
            key={id}
            calendarEvent={calendarEvent}
            fadeOutTimeout={calculateTimeout(index)}
            message={body}
            type={type}
            url={url}
            onDismiss={(): void => removeNotification(id)}
          />
        ),
      )}
    </div>
  );
};

export default withErrorBoundary(FlashNotifications, {
  fallback: null,
});
