import { ReactElement, SVGProps } from 'react';

type AudioIconProps = SVGProps<SVGSVGElement> & {
  isOn: boolean;
};

const AudioIcon = ({ isOn = true }: AudioIconProps): ReactElement<AudioIconProps> => (
  <svg viewBox="0 0 24 24">
    {isOn ? (
      <path d="M12,15 C13.66,15 14.99,13.66 14.99,12 L15,6 C15,4.34 13.66,3 12,3 C10.34,3 9,4.34 9,6 L9,12 C9,13.66 10.34,15 12,15 Z M17.3,12 C17.3,15 14.76,17.1 12,17.1 C9.24,17.1 6.7,15 6.7,12 L5,12 C5,15.41 7.72,18.23 11,18.72 L11,22 L13,22 L13,18.72 C16.28,18.24 19,15.42 19,12 L17.3,12 Z" />
    ) : (
      <path d="M19,12 L17.3,12 C17.3,12.74 17.14,13.43 16.87,14.05 L18.1,15.28 C18.66,14.3 19,13.19 19,12 L19,12 Z M14.98,12.17 C14.98,12.11 15,12.06 15,12 L15,6 C15,4.34 13.66,3 12,3 C10.34,3 9,4.34 9,6 L9,6.18 L14.98,12.17 Z M4.27,4 L3,5.27 L9.01,11.28 L9.01,12 C9.01,13.66 10.34,15 12,15 C12.22,15 12.44,14.97 12.65,14.92 L14.31,16.58 C13.6,16.91 12.81,17.1 12,17.1 C9.24,17.1 6.7,15 6.7,12 L5,12 C5,15.41 7.72,18.23 11,18.72 L11,22 L13,22 L13,18.72 C13.91,18.59 14.77,18.27 15.54,17.82 L19.73,22 L21,20.73 L4.27,4 Z" />
    )}
  </svg>
);

export default AudioIcon;
