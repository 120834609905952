import { ReactElement } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import InterestsInput from '~/components/forms/InterestsInput';
import { interestInputLength } from '~/components/forms/validation';
import Button from '~/components/shared/atoms/Button';
import LoadingIcon from '~/components/icons/LoadingIcon';
import { UpdateAttendeeProfileFormData } from '~/types/attendees';

import Footer from './Footer';
import Header, { Description, Title } from './Header';
import { useOnboarding } from './OnboardingContext';
import OnboardingForm from './OnboardingForm';
import StepSection from './StepSection';

type InterestsScreenFieldValues = Pick<
  UpdateAttendeeProfileFormData,
  'seekingTopicIds' | 'offeringTopicIds'
>;

export type InterestsScreenProps = {
  onBackButtonClick: () => void;
  onSubmit: SubmitHandler<InterestsScreenFieldValues>;
};

const InterestsScreen = ({
  onSubmit,
  onBackButtonClick,
}: InterestsScreenProps): ReactElement<InterestsScreenProps> => {
  const {
    profileData: { seekingTopicIds, offeringTopicIds },
    topics,
  } = useOnboarding();

  const { formState, control, handleSubmit } = useForm<InterestsScreenFieldValues>({
    defaultValues: { offeringTopicIds, seekingTopicIds },
    mode: 'onChange',
  });

  const { isSubmitting } = formState;
  const isSubmitDisabled = isSubmitting || !formState.isValid;

  const submitForm = handleSubmit(onSubmit);

  return (
    <OnboardingForm onSubmit={submitForm}>
      <Header>
        <Title>Interests</Title>
        <Description>
          Select your interests and we’ll recommend talks to catch and people you should meet.
        </Description>
      </Header>
      <StepSection>
        <Controller
          control={control}
          name="seekingTopicIds"
          render={({ field: { value, name, onChange }, fieldState: { error } }): ReactElement => (
            <InterestsInput
              isRequired
              availableTopics={topics}
              error={error}
              label={
                <label htmlFor={name} id={`${name}-label`}>
                  <span className="-b">I want to learn about</span> (select at least 3 topics)
                </label>
              }
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
          rules={{
            validate: interestInputLength,
          }}
        />
      </StepSection>
      <StepSection>
        <Controller
          control={control}
          name="offeringTopicIds"
          render={({ field: { value, name, onChange }, fieldState: { error } }): ReactElement => (
            <InterestsInput
              isRequired
              availableTopics={topics}
              error={error}
              label={
                <label htmlFor={name} id={`${name}-label`}>
                  <span className="-b">I can share expertise in</span> (select at least 3 topics)
                </label>
              }
              name={name}
              styling="secondary"
              value={value}
              onChange={onChange}
            />
          )}
          rules={{
            validate: interestInputLength,
          }}
        />
      </StepSection>
      <Footer>
        <Button size="md" theme="secondary" onClick={onBackButtonClick}>
          Back
        </Button>
        <Button
          className="onboarding__step__interaction-button -svg"
          isDisabled={isSubmitDisabled}
          size="md"
          theme="tertiary"
          type="submit"
        >
          {isSubmitting && <LoadingIcon />}
          Complete profile
        </Button>
      </Footer>
    </OnboardingForm>
  );
};

export default InterestsScreen;
