import clsx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import { PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';
import SwiperCore, { Navigation, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '~/components/shared/atoms/Button';
import ChevronIcon from '~/components/icons/ChevronIcon';

SwiperCore.use([Navigation, Virtual]);

type CarouselProps = PropsWithChildren<{
  className?: string;
  fadeOut?: boolean;
  navButtonsPrefix?: ReactNode;
  seeAllUrl?: string;
  showSeeAllButton?: boolean;
  slideCount?: number;
  title?: string;
}>;

const Carousel = ({
  title,
  className,
  children,
  slideCount = 3,
  navButtonsPrefix,
  fadeOut = false,
  showSeeAllButton = false,
  seeAllUrl,
}: CarouselProps): ReactElement<CarouselProps> => {
  const backButtonId = useMemo(() => uniqueId('carousel-button-back__'), []);
  const nextButtonId = useMemo(() => uniqueId('carousel-button-next__'), []);

  return (
    <div className={clsx('carousel', className, fadeOut && '-fade-out')}>
      <div className="carousel__container">
        <Carousel.Header>
          <Carousel.Heading>{title}</Carousel.Heading>
          <Carousel.Navigation>
            {navButtonsPrefix}
            <div className="carousel__buttons">
              <button className="button -small -invisible-icon" id={backButtonId} type="button">
                <ChevronIcon className="chevron -back" style={{ transform: 'rotate(90deg)' }} />
              </button>
              <button className="button -small -invisible-icon" id={nextButtonId} type="button">
                <ChevronIcon className="chevron -fwd" style={{ transform: 'rotate(270deg)' }} />
              </button>
              {showSeeAllButton && (
                <Button as="a" href={seeAllUrl} size="sm" theme="secondary">
                  See all
                </Button>
              )}
            </div>
          </Carousel.Navigation>
        </Carousel.Header>
        <Swiper
          autoHeight
          virtual
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: slideCount,
            },
          }}
          className="carousel__swiper"
          navigation={{
            disabledClass: '-disabled',
            nextEl: `.button#${nextButtonId}`,
            prevEl: `.button#${backButtonId}`,
          }}
          spaceBetween={0}
        >
          {children}
        </Swiper>
      </div>
    </div>
  );
};

// Alias for pretty imports
Carousel.Slide = SwiperSlide;

type CarouselAtomProps = {
  children?: ReactNode;
};
Carousel.SlideContent = ({ children }: CarouselAtomProps): ReactElement<CarouselAtomProps> => (
  <div className="slide-wrapper">{children}</div>
);

Carousel.Header = ({ children }: CarouselAtomProps): ReactElement<CarouselAtomProps> => (
  <header className="carousel__header">{children}</header>
);
Carousel.Heading = ({ children }: CarouselAtomProps): ReactElement<CarouselAtomProps> => (
  <h3 className="carousel__heading">{children}</h3>
);
Carousel.Navigation = ({ children }: CarouselAtomProps): ReactElement<CarouselAtomProps> => (
  <nav className="carousel__nav">{children}</nav>
);

export default Carousel;
