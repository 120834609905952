import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';

export type RoomListProps = PropsWithChildren<{
  columnCount?: 3 | 2;
  header: string;
}>;

const RoomList = ({
  header,
  columnCount,
  children,
}: RoomListProps): ReactElement | null => {
  return (
    <>
      <header className="pane-header">
        <h3 className="pane-header__heading">{header}</h3>
      </header>
      <div className={clsx('directory-list', { '-two': columnCount === 2 })}>{children}</div>
    </>
  );
};

export default RoomList;
