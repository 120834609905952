import { ReactElement, ReactNode } from 'react';

import { CalendarPinsProvider } from '~/lib/calendar/pins';
import { TimeslotPartsFragment } from '~/operations/catalyst';
import Carousel from '~/components/shared/molecules/Carousel';
import TimeslotCard from '~/components/timeslot/TimeslotCard';
import urlFor from '~/lib/urlFor';

import { BreakoutTimeslot } from './types';

export type TimeslotSectionProps = {
  fadeOut?: boolean;
  linkToSchedule?: () => ReactNode;
  showChannelName?: boolean;
  showSeeAllButton?: boolean;
  slideCount?: number;
  timeslots: BreakoutTimeslot[] | TimeslotPartsFragment[];
  title: string;
  withModal?: boolean;
  join?: boolean;
};

const TimeslotSection = ({
  title,
  timeslots,
  slideCount = 3,
  linkToSchedule = (): null => null,
  withModal = false,
  fadeOut = false,
  showSeeAllButton = false,
  join = false,
}: TimeslotSectionProps): ReactElement<TimeslotSectionProps> => {
  if (timeslots.length === 0) {
    return (
      <Carousel.Header>
        <Carousel.Heading>{title}</Carousel.Heading>
        <Carousel.Navigation>{linkToSchedule()}</Carousel.Navigation>
      </Carousel.Header>
    );
  }

  return (
    <CalendarPinsProvider>
      <Carousel
        fadeOut={fadeOut}
        navButtonsPrefix={linkToSchedule()}
        seeAllUrl={urlFor('/schedule')}
        showSeeAllButton={showSeeAllButton}
        slideCount={slideCount}
        title={title}
      >
        {timeslots.map(
          (timeslot, i): ReactElement => (
            <Carousel.Slide key={timeslot.id} virtualIndex={i}>
              <Carousel.SlideContent>
                {'__typename' in timeslot ? (
                  <TimeslotCard
                    calendarEventId={timeslot.calendarEventId || undefined}
                    className="carousel__card"
                    description={timeslot.description || undefined}
                    endDateIso={timeslot.endsAt || undefined}
                    ended={false}
                    id={timeslot.id}
                    link={urlFor(`/schedule/${timeslot.id}${join ? '/join' : ''}`)}
                    participants={timeslot.participants.edges.map((e) => e.node)}
                    sponsor={
                      timeslot.sponsor
                        ? {
                            id: timeslot.sponsor.id,
                            logoUrl: timeslot.sponsor.company.logoUrl || '',
                            name: timeslot.sponsor.company.name,
                          }
                        : undefined
                    }
                    startDateIso={timeslot.startsAt || undefined}
                    title={timeslot.title}
                    withModal={withModal}
                  />
                ) : (
                  <TimeslotCard
                    calendarEventId={timeslot.calendarEventId}
                    className="carousel__card"
                    description={timeslot.description}
                    endDateIso={timeslot.endsAt}
                    ended={timeslot?.ended ?? false}
                    link={urlFor(`/schedule/${timeslot.id}/join`)}
                    participants={timeslot.participants}
                    sponsor={timeslot?.sponsor}
                    startDateIso={timeslot.startsAt}
                    title={timeslot.name}
                    withModal={withModal}
                  />
                )}
              </Carousel.SlideContent>
            </Carousel.Slide>
          ),
        )}
      </Carousel>
    </CalendarPinsProvider>
  );
};

export default TimeslotSection;
