import styled, { css } from 'styled-components';

import titleThreeMixin from '~/style-mixins/typography/titleThreeMixin';

const StatusMessage = styled.span<{
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary';
}>`
  ${({ size = 'medium', variant = 'secondary' }) => css`
    font-weight: 500;
    border-radius: var(--s-bw);
    user-select: none;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;

    svg {
      height: 1em;
      width: 1em;
    }

    svg,
    path,
    polygon {
      fill: currentColor;
    }

    ${variant === 'primary' &&
    css`
      color: var(--c-brand-primary);

      svg {
        font-size: 1rem;
        margin-right: 0.25rem;
      }
    `}

    ${size === 'small' &&
    css`
      padding: 0.25rem 0.5rem;
      font-size: var(--fs-xs);
      line-height: 1rem;

      svg {
        font-size: 1rem;
        margin-right: 0.25rem;
      }
    `}
    ${size === 'medium' &&
    css`
      padding: 0.75rem 1rem;
      font-size: var(--fs-xs);
      line-height: 1rem;

      svg {
        font-size: 1rem;
        margin-right: 0.5rem;
      }
    `}
    ${size === 'large' &&
    css`
      ${titleThreeMixin}
      padding: 1rem 2rem;
      line-height: 1rem;
      font-weight: 600;

      svg {
        font-size: 1rem;
        margin-right: 0.5rem;
      }
    `}
  `}
`;

export default StatusMessage;
