import { ReactElement } from 'react';
import { Redirect,Route, Router, Switch } from 'wouter';

import urlFor from '~/lib/urlFor';

import EmailLoginPanel from './EmailLoginPanel';
import ForgotBookingReferencePanel from './ForgotBookingReferencePanel';
import LoginClosedPanel from './LoginClosedPanel';
import OtpLoginPanel from './OtpLoginPanel';
import OtpVerifyPanel from './OtpVerifyPanel';
import QRCodeLoginPanel from './QRCodeLoginPanel';
import SSOErrorPanel from './SSOErrorPanel';
import SSOLoginPanel from './SSOLoginPanel';
import TicketLoginPanel from './TicketLoginPanel';

export type LoginProps = {
  defaultMethod?: 'ticket' | 'qrcode' | 'email' | 'sso' | 'otp';
  fallbackMethod?: 'ticket' | 'email';
};

const Login = ({
  defaultMethod = 'ticket',
  fallbackMethod = 'ticket',
}: LoginProps): ReactElement => {
  return (
    <Router base={urlFor('/login')}>
      <Switch>
        <Route path="/">
          <Redirect replace to={`/${defaultMethod}`} />
        </Route>
        <Route path="/qrcode">
          <QRCodeLoginPanel fallbackMethod={fallbackMethod} />
        </Route>
        <Route path="/ticket">
          <TicketLoginPanel />
        </Route>
        <Route path="/email">
          <EmailLoginPanel />
        </Route>
        <Route path="/sso">
          <SSOLoginPanel fallbackMethod={fallbackMethod} />
        </Route>
        <Route path="/forgot">
          <ForgotBookingReferencePanel />
        </Route>
        <Route path="/closed">
          <LoginClosedPanel />
        </Route>
        <Route path="/not-found">
          <SSOErrorPanel />
        </Route>
        <Route path="/otp">
          <OtpLoginPanel />
        </Route>
        <Route path="/verify">
          <OtpVerifyPanel />
        </Route>
      </Switch>
    </Router>
  );
};

export default Login;
