import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import LazyTooltip, { LazyTooltipProps } from '~/components/others/LazyTooltip';

const Actions = styled.div`
  margin-top: 0.75rem;
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  width: 100%;
`;

const Content = styled.div`
  padding: 1rem;
  line-height: 1.25rem;
`;

export type PopoverProps = LazyTooltipProps & {
  children?: ReactNode;
  content: ReactNode;
};

const Popover = ({ content, children, appendTo, ...props }: PopoverProps): ReactElement<PopoverProps> => {
  return (
    <LazyTooltip
      interactive
      appendTo={appendTo ?? ((): HTMLElement => document.body)}
      interactiveBorder={10}
      interactiveDebounce={25}
      maxWidth="none"
      moveTransition="transform 0.2s ease-out"
      offset={[0, 12]}
      placement="top"
      theme="light-border"
      trigger="click"
      // Disabling eslint here due to known and guarded by TS props of LazyTooltip component
       
      {...props}
      content={<Content>{content}</Content>}
    >
      {children}
    </LazyTooltip>
  );
};

Popover.Actions = Actions;

export default Popover;
