import clsx from 'clsx';
import { PropsWithChildren, ReactElement } from 'react';

export type OptionsLayoutProps = PropsWithChildren<{
  id?: string;
  styling?: 'default' | 'secondary';
}>;

const OptionsLayout = ({
  id,
  children,
  styling,
}: OptionsLayoutProps): ReactElement<OptionsLayoutProps> => (
  <div className={clsx('option-block', { '-secondary': styling === 'secondary' })}>
    <ul aria-labelledby={`${id}-label`} className="option-block__item-list" role="listbox">
      {children}
    </ul>
  </div>
);

export default OptionsLayout;
