import { ReactElement, SVGProps } from 'react';

type FacebookIconProps = SVGProps<SVGSVGElement>;

const FacebookIcon = (props: FacebookIconProps): ReactElement<FacebookIconProps> => (
  <svg version="1.1" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <rect height="24" width="24" x="0" y="0" />
      <path
        d="M14.8568182,5.98828125 L16.5,5.98828125 L16.5,3.1265625 C16.2170455,3.08789062 15.2420455,3 14.1068182,3 C11.7375,3 10.1147727,4.490625 10.1147727,7.22929688 L10.1147727,9.75 L7.5,9.75 L7.5,12.9492188 L10.1147727,12.9492188 L10.1147727,21 L13.3193182,21 L13.3193182,12.9492188 L15.8284091,12.9492188 L16.2272727,9.75 L13.3193182,9.75 L13.3193182,7.54570313 C13.3193182,6.62109375 13.5681818,5.98828125 14.8568182,5.98828125 Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default FacebookIcon;
