import { DateTime, Interval } from 'luxon';

import { LiveState } from './types';

const getLiveState = ({
  liveTime,
  startTime,
  endTime,
}: {
  endTime: DateTime;
  liveTime: DateTime;
  startTime: DateTime;
}): LiveState => {
  if (startTime === null || endTime === null) return LiveState.IncompleteDates;

  const interval = Interval.fromDateTimes(startTime.minus({ minutes: 1 }), endTime);

  if (interval.isAfter(liveTime)) return LiveState.BeforeEvent;
  if (interval.isBefore(liveTime)) return LiveState.AfterEvent;
  if (interval.contains(liveTime)) return LiveState.LiveNow;

  return LiveState.IncompleteDates;
};

export default getLiveState;
