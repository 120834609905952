import '~/configs/i18nConfig';
import 'react-loading-skeleton/dist/skeleton.css';
import '../stylesheets/application.scss';

import { page } from '~/lib/analytics';
import Turbolinks from 'turbolinks';
import ReactRailsUJS from 'react_ujs';

// Import all font resources.
import.meta.glob('../fonts', { eager: true });

Turbolinks.start();

// Imports all modules under the 'page-fragments' directory and returns a dictionary
// with module paths as keys and modules as values.
const context = import.meta.glob(['../page-fragments/**/*.tsx', '../page-fragments/**/*.ts'], {
  eager: true,
  import: 'default',
});

// Integrates the import context with react-rails. Returns the module from context corresponding to a class name
// passed to react-rails.
const getConstructor = (className) => {
  const module =
    context[`../page-fragments/${className}.tsx`] || context[`../page-fragments/${className}.ts`];

  if (!module) {
    throw new Error(`Module not found: '${className}'. Is it available under 'page-fragments'?`);
  }

  return module;
};

ReactRailsUJS.getConstructor = getConstructor;

// Register the module dictionary with ReactRailsUJS. Whenever the runtime encounters an HTML node
// with a `data-react-class` attribute it will attempt to render the corresponding entry from the
// module dictionary.
ReactRailsUJS.detectEvents();

// Unmount rendered components before rendering a new page with turbolinks. Not doing so can leave timers and other
// side-effects behind when navigating between pages. Additionally do _not_ unmount elements with the data-turbolinks-permanent
// attribute. This allows us to preserve some react elements such as the top navbar across page loads.
ReactRailsUJS.handleEvent('turbolinks:before-render', () =>
  ReactRailsUJS.unmountComponents('[data-react-class]:not([data-turbolinks-permanent])'),
);

// Record analytics pageviews for turbolinks page loads.
document.addEventListener('turbolinks:load', () => page());
