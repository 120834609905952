import axios from 'axios';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { RSVP_API_ENDPOINTS } from '~/endpoints';
import Card from '~/components/perks/Card';
import { authorizationHeader } from '~/lib/authToken';
import { Perk } from '~/types/perks';

const Perks = (): ReactElement => {
  const authorization = authorizationHeader();
  const [perks, setPerks] = useState<Perk[]>([]);

  const getPerks = useCallback(async () => {
    try {
      const {
        data: { data: perksData },
      } = await axios.get<{ data: Perk[] }>(RSVP_API_ENDPOINTS.perks(), {
        headers: {
          Authorization: authorization,
        },
      });
      setPerks(perksData);
    } catch (e) {
      setPerks([]);
    }
  }, [authorization]);

  useEffect(() => {
    void getPerks();
  }, [getPerks]);

  return (
    <div>
      <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 768: 4 }}>
        <Masonry gutter="24px">
          {perks.map((perk) => (
            <Card
              key={perk.id}
              exhibitorId={perk.appearance_id}
              logoUrl={perk.logo_url}
              perk={perk}
              onClaim={getPerks}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default Perks;
