import { css, FlattenSimpleInterpolation } from 'styled-components';

const textClampMixin = (lines: string, overflow: string): FlattenSimpleInterpolation => css`
  line-clamp: ${lines};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  text-overflow: ${overflow};
  overflow: hidden;
`;

export default textClampMixin;
