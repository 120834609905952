import { DateTime, Interval } from 'luxon';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectMenu, connectRange, connectToggleRefinement } from 'react-instantsearch-dom';
import styled from 'styled-components';

import { useTracking } from '~/lib/analytics';
import {
  getArchivedTalksFlag,
  getOnlineSessionsFlag,
  getPastTalksFilterFlag,
  getPremiumSessionsFlag,
} from '~/components/search/utils/filterFlags';
import ToggleControl from '~/components/shared/molecules/ToggleControl';

import DateFilterMenu from '../molocules/DateFilterMenu';

type RangeRefinementProps = {
  refine: (newRefinement: { min?: number }) => void;
};

type ToggleRefinementProps = {
  canRefine?: boolean;
  currentRefinement: boolean;
  label: string;
  refine: (newRefinement: boolean) => void;
};

const SearchToggleRow = styled.div`
  display: flex;
  .toggle-control {
    margin: 0 0.3rem;
    &:first-child {
      margin-left: 0;
    }
  }
`;

const PastTalksFilter = ({ refine }: RangeRefinementProps) => {
  const [liveNow, setLiveNow] = useState(false);
  const track = useTracking();
  const { t } = useTranslation();

  const id = 'upcoming-talks-toggle';

  useEffect(() => {
    const now = DateTime.local();

    if (!liveNow) {
      refine({ min: now.toSeconds() });
    } else {
      refine({});
    }
  }, [liveNow, refine]);

  return (
    <ToggleControl>
      <ToggleControl.Button
        id={id}
        name={id}
        selected={liveNow}
        setSelected={(selected) => {
          track('Applied filter', 'Show past talks');
          setLiveNow(selected);
        }}
      />
      <ToggleControl.Label htmlFor={id}>
        {t('schedule.filterLabel.showPastTalks')}
      </ToggleControl.Label>
    </ToggleControl>
  );
};

const NowStreamingFilter = ({
  refine,
  currentRefinement,
  canRefine,
  label,
}: ToggleRefinementProps) => {
  const track = useTracking();
  const id = 'streaming-live-toggle';

  if (!canRefine) {
    return null;
  }

  return (
    <ToggleControl>
      <ToggleControl.Button
        id={id}
        name={id}
        selected={currentRefinement}
        setSelected={(value) => {
          track('Applied filter', label);
          refine(value);
        }}
      />
      <ToggleControl.Label htmlFor={id}>{label}</ToggleControl.Label>
    </ToggleControl>
  );
};

const PremiumSessionsFilter = ({
  refine,
  currentRefinement,
  canRefine,
  label,
}: ToggleRefinementProps) => {
  const track = useTracking();
  const id = 'premium-sessions-toggle';

  if (!canRefine) {
    return null;
  }

  return (
    <ToggleControl>
      <ToggleControl.Button
        id={id}
        name={id}
        selected={currentRefinement}
        setSelected={(value) => {
          track('Applied filter', label);
          refine(value);
        }}
      />
      <ToggleControl.Label htmlFor={id}>{label}</ToggleControl.Label>
    </ToggleControl>
  );
};

const ToggleArchivedVideo = ({
  refine,
  currentRefinement,
  canRefine,
  label,
}: ToggleRefinementProps) => {
  const track = useTracking();
  const id = 'toggle-archived-video';

  if (!canRefine) {
    return null;
  }

  return (
    <ToggleControl>
      <ToggleControl.Label>
        <ToggleControl.Button
          id={id}
          name={id}
          selected={currentRefinement}
          setSelected={(value) => {
            track('Applied filter', label);
            refine(value);
          }}
        />
        {label}
      </ToggleControl.Label>
    </ToggleControl>
  );
};

const ConnectedUpcomingTalksFilter = connectRange(PastTalksFilter);
const ConnectedToggleArchivedVideo = connectToggleRefinement(ToggleArchivedVideo);
const ConnectedOnlineSessions = connectToggleRefinement(NowStreamingFilter);
const ConnectedPremiumSessions = connectToggleRefinement(PremiumSessionsFilter);
const ConnectedDateFilterMenu = connectMenu(DateFilterMenu);

const TimeslotNavBar = (): ReactElement => {
  const { t } = useTranslation();

  const showPastTalksFilter = getPastTalksFilterFlag();
  const showArchivedVideoFilter = getArchivedTalksFlag();
  const showOnlineSessionsFilter = getOnlineSessionsFlag();
  const showPremiumSessionsFilter = getPremiumSessionsFlag();

  return (
    <div className="search-navigation__schedule">
      <ConnectedDateFilterMenu attribute="date" />
      <SearchToggleRow>
        {showPastTalksFilter && <ConnectedUpcomingTalksFilter attribute="ends_at_timestamp" />}
        {showArchivedVideoFilter && (
          <ConnectedToggleArchivedVideo
            value
            attribute="has_video_archive"
            label={t('schedule.filterLabel.hasVideoArchive')}
          />
        )}
        {showOnlineSessionsFilter && (
          <ConnectedOnlineSessions
            value
            attribute="online_session"
            label={t('schedule.filterLabel.onlineSession')}
          />
        )}
        {showPremiumSessionsFilter && (
          <ConnectedPremiumSessions
            value
            attribute="premium"
            label={t('schedule.filterLabel.premium')}
          />
        )}
      </SearchToggleRow>
    </div>
  );
};

export default TimeslotNavBar;
