import clsx from 'clsx';
import { ReactElement } from 'react';

import AcceptIcon from '../icons/AcceptIcon';
import InteractButton from '../shared/atoms/InteractButton';

export type ConnectedButtonProps = {
  className?: string;
};

const ConnectedButton = ({
  className,
}: ConnectedButtonProps): ReactElement<ConnectedButtonProps> => {
  return (
    <InteractButton isStatic className={clsx('-connection -connected', className)}>
      <AcceptIcon />
      <span>Connected</span>
    </InteractButton>
  );
};

export default ConnectedButton;
