import throttle from 'lodash/throttle';
import { ReactElement, useEffect, useRef, useState } from 'react';

type AudioLevelIndicatorProps = {
  volumeLevel: number;
};

export const useAudioLevel = (): {
  audioLevelUpdated: (e: { audioLevel: number }) => void;
  volumeLevel: number;
} => {
  const [volumeLevel, setVolumeLevel] = useState(0);
  const ref = useRef(false);

  const handleVolumeLevelChange = ({ audioLevel }: { audioLevel: number }) => {
    // prevent updating state on unmounted component
    if (!ref.current) return;
    setVolumeLevel(audioLevel);
  };

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  const throttledVolumeLevelChange = throttle(handleVolumeLevelChange, 300);

  const audioLevelUpdated = (e: { audioLevel: number }) => throttledVolumeLevelChange(e);

  return { audioLevelUpdated, volumeLevel };
};

const AudioLevelIndicator = ({ volumeLevel }: AudioLevelIndicatorProps): ReactElement => {
  return (
    <div className="audio-indicator">
      <div className="audio-indicator__level" style={{ height: `${volumeLevel * 95 + 5}%` }}>
        {volumeLevel * 10}
      </div>
    </div>
  );
};

export default AudioLevelIndicator;
