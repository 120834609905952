import { useLazyQuery as useApolloLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import orderBy from 'lodash/orderBy';
import { MessageEvent } from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AVENGER_API_ENDPOINTS } from '~/endpoints';
import { authorizationHeader } from '~/lib/authToken';
import {
  getLastReadTimetoken,
  setLastReadTimetoken,
  timestampFromTimetoken,
  timetokenFor,
} from '~/lib/pubnub/timetokens';
import { nonNullable } from '~/lib/utils';
import {
  ChatChannelDocument,
  ChatChannelFragment,
  ChatChannelsDocument,
  CreateChatChannelDocument,
  CreateChatChannelMutationVariables,
} from '~/operations/catalyst';
import { ChatChannel, ChatMessage } from '~/types/chat';
import { PubnubClientConfig } from '~/types/rooms';

import { extractIdentity } from '../realtime/usePubnub';
import usePubnubSubscriber from '../realtime/usePubnubSubscriber';

const authorization = authorizationHeader();

const transformChannel = (channel: ChatChannelFragment) =>
  channel.providerId && channel.name
    ? {
        id: channel.id,
        lastMessage: channel.lastMessage ?? null,
        name: channel.name,
        participants: channel.members.edges.map(({ node: member }) => member),
        pubnubId: channel.providerId,
      }
    : null;

type ChatConfig = {
  channelGroups: string[];
  chatToken: string;
  identity: string;
  publishKey?: string;
  subscribeKey?: string;
};

const fetchChatConfig = async (tokenUrl: string): Promise<ChatConfig> => {
  const {
    data: {
      data: {
        chat_token: chatToken,
        channel_groups: channelGroups,
        identity,
        subscribe_key: subscribeKey,
        publish_key: publishKey,
      },
    },
  } = await axios.post(
    tokenUrl,
    { provider: 'pubnub' },
    { headers: { Authorization: authorization } },
  );

  return {
    channelGroups,
    chatToken,
    identity,
    publishKey,
    subscribeKey,
  };
};

const useChatConfig = () => {
  const [config, setConfig] = useState<ChatConfig>();

  useEffect(() => {
    const fetchConfig = async () => {
      const res = await fetchChatConfig(AVENGER_API_ENDPOINTS.me.chatToken());
      setConfig(res);
    };

    void fetchConfig();
  }, []);

  return config;
};

type UnreadChannels = Record<string, boolean>;

type ChatContextType = {
  channels: ChatChannel[];
  createChannel: (opts: {
    participants: string[];
    provider?: string;
  }) => Promise<string | undefined>;
  fetchNewChannel: (opts: { id?: string; providerId?: string }) => void;
  fetchRecent: (n: number) => void;
  isCreatingNewChat: boolean;
  isLoading: boolean;
  markAsRead: (channel: string) => void;
  pubnubConfig: PubnubClientConfig;
  unreadCount: number;
};

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

type LatestMessages = Record<string, ChatMessage>;

export type ChatProviderProps = {
  children: ReactElement;
  listen?: boolean;
};

const ChatProvider = ({ children, listen = true }: ChatProviderProps): ReactElement | null => {
  const config = useChatConfig();
  const clientConfig: PubnubClientConfig = useMemo(
    () => ({
      authKey: config?.chatToken || '',
      publishKey: config?.publishKey || '',
      subscribeKey: config?.subscribeKey || '',
      uuid: config?.identity || '',
    }),
    [config?.chatToken, config?.publishKey, config?.subscribeKey, config?.identity],
  );

  // Pubnub configs are required, but will short-circuit when passed falsy values. Thus pubnub will be null until
  // useChatConfig resolves.
  const { pubnub } = usePubnubSubscriber({
    channelGroups: config?.channelGroups,
    config: clientConfig,
  });

  const [getChannels, { data: channelsData, loading: isChannelsLoading }] = useApolloLazyQuery(
    ChatChannelsDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [getChannel, { data: channelData, loading: isChannelLoading }] = useApolloLazyQuery(
    ChatChannelDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [createChat, { loading: isCreatingNewChat }] = useMutation(CreateChatChannelDocument);

  const [channels, setChannels] = useState<ChatChannel[]>([]);
  const upsertChannel = useCallback((channel: ChatChannel) => {
    setChannels((existingChannels) => {
      const index = existingChannels.findIndex((c) => c.pubnubId === channel.pubnubId);
      if (index === -1) {
        return [channel, ...existingChannels];
      }
      return existingChannels.map((existingChannel, i) =>
        i === index ? channel : existingChannel,
      );
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const [latestMessages, setLatestMessages] = useState<LatestMessages>({});
  useEffect(() => {
    const channelsFromApi = (channelsData?.chatChannels?.edges ?? [])
      .map(({ node: channel }) => transformChannel(channel))
      .filter(nonNullable);
    setChannels(channelsFromApi);
    setLoading(false);
    setLatestMessages((existing) => {
      const result: LatestMessages = {};
      channelsFromApi.forEach((channel) => {
        const existingLatestMessage = existing[channel.pubnubId];
        const newLatestMessage = channel.lastMessage?.body ? channel.lastMessage : undefined;
        const latestMessage = orderBy(
          [existingLatestMessage, newLatestMessage].filter(nonNullable),
          ['sentAt'],
          ['desc'],
        )[0];
        if (latestMessage) {
          result[channel.pubnubId] = latestMessage;
        }
      });
      return result;
    });
  }, [channelsData]);

  useEffect(() => {
    if (!channelData?.chatChannel) return;

    const channel = transformChannel(channelData.chatChannel);
    if (channel) {
      upsertChannel(channel);
    }
  }, [channelData, upsertChannel]);

  const fetchRecent = useCallback(
    (numChannels: number) => {
      setLoading(true);
      return getChannels({ variables: { first: numChannels } });
    },
    [getChannels],
  );

  const fetchNewChannel = useCallback(
    ({ id, providerId }: { id?: string; providerId?: string }) =>
      getChannel({ variables: { id, providerId } }),
    [getChannel],
  );

  const createChannel = useCallback(
    async (opts: any) => {
      const response = await createChat({ variables: opts });
      return response.data?.chatChannelCreate?.chatChannel?.id ?? undefined;
    },
    [createChat],
  );

  const identity = config?.identity;
  useEffect(() => {
    if (!pubnub || !identity || !listen)
      return () => {
        // No cleanup needed
      };

    const listeners = {
      message: (e: MessageEvent) => {
        if (e.message.type !== 'text') return;

        const senderIdentity = extractIdentity(e.message.publisher.uuid);

        if (senderIdentity !== identity) {
          if (!channels.some((channel) => channel.pubnubId === e.channel)) {
            void fetchNewChannel({ providerId: e.channel });
          }
        }
        setLatestMessages((existing) => ({
          ...existing,
          [e.channel]: {
            body: e.message.data.body,
            id: e.message.id,
            providerId: e.message.id,
            sender: { identity: senderIdentity },
            sentAt: e.message.sent_at,
          },
        }));
      },
    };

    pubnub.addListener(listeners);

    return () => {
      pubnub.removeListener(listeners);
    };
  }, [fetchNewChannel, identity, listen, pubnub, channels]);

  const [unreadChannels, setUnreadChannels] = useState<UnreadChannels>({});
  const markAsRead = useCallback((channelId: string) => {
    setLastReadTimetoken(channelId, timetokenFor(Date.now()));
    setUnreadChannels((existing) => ({ ...existing, [channelId]: false }));
  }, []);
  useEffect(() => {
    setUnreadChannels(
      channels.reduce<UnreadChannels>((acc, channel) => {
        const timetoken = getLastReadTimetoken(channel.pubnubId);
        const latestMessage = latestMessages[channel.pubnubId];
        const hasUnread =
          latestMessage?.sentAt && latestMessage.sender.identity !== identity
            ? Date.parse(latestMessage.sentAt) > timestampFromTimetoken(timetoken).getTime()
            : false;
        acc[channel.pubnubId] = hasUnread;
        return acc;
      }, {}),
    );
  }, [channels, identity, latestMessages]);
  const unreadCount = useMemo(
    () => Object.values(unreadChannels).filter(Boolean).length,
    [unreadChannels],
  );

  const channelsForDisplay = useMemo(() => {
    return orderBy(
      channels.map((channel) => {
        const lastMessage = latestMessages[channel.pubnubId];
        return {
          ...channel,
          hasUnread: unreadChannels[channel.pubnubId],
          lastMessage,
        };
      }),
      (c) => c.lastMessage?.sentAt || '',
      ['desc'],
    );
  }, [channels, latestMessages, unreadChannels]);

  const chatContextValue = useMemo(
    () => ({
      channels: channelsForDisplay,
      createChannel,
      fetchNewChannel,

      fetchRecent,

      // when creating new channel, we also fetch it afterwards
      isCreatingNewChat: isCreatingNewChat || isChannelsLoading || isChannelLoading || loading,
      isLoading: loading,
      markAsRead,
      pubnubConfig: clientConfig,
      unreadCount,
    }),
    [
      channelsForDisplay,
      createChannel,
      fetchRecent,
      fetchNewChannel,
      isCreatingNewChat,
      isChannelLoading,
      isChannelsLoading,
      loading,
      markAsRead,
      clientConfig,
      unreadCount,
    ],
  );

  if (!pubnub) {
    return null;
  }

  return (
    <ChatContext.Provider value={chatContextValue}>
      <PubNubProvider client={pubnub}>{children}</PubNubProvider>
    </ChatContext.Provider>
  );
};

export default ChatProvider;

export const useChat = (): ChatContextType => {
  const chat = useContext(ChatContext);
  if (chat === undefined) {
    throw new Error('useChat called with no ChatProvider');
  }
  return chat;
};
