import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

export type DiscussionPanelTabProps = {
  actions?: ReactNode;
  children?: ReactNode;
};

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background: var(--c-white);
  border-radius: 0 0 var(--s-bw) var(--s-bw);
`;

const ActionsContainer = styled.div`
  background: var(--c-white);
  border-top: 1px solid var(--c-silver);
  padding: 0.5rem;
  z-index: 1;
`;

const DiscussionPanelTab = ({
  children,
  actions,
}: DiscussionPanelTabProps): ReactElement<DiscussionPanelTabProps> => {
  return (
    <Tab>
      {children}
      {actions && <ActionsContainer>{actions}</ActionsContainer>}
    </Tab>
  );
};

export default DiscussionPanelTab;
