import { useQuery } from '@apollo/client';
import { MyProfileDocument } from '~/operations/catalyst';
import AttendeeInterests from '../attendees/AttendeeInterests';
import LogoutButton from '../attendees/LogoutButton';
import Portrait from '../attendees/Portrait';
import ProfileSidebar from '../attendees/ProfileSidebar';
import PenIcon from '../icons/PenIcon';
import ProfileHead from '../shared/molecules/ProfileHead';

export type ProfileProps = {
  onEdit?: () => void;
};

const Profile = ({ onEdit }: ProfileProps) => {
  const { data } = useQuery(MyProfileDocument);
  const profile = data?.me?.profile;

  const offeringTopics = profile?.offeringTopics.edges.map((e) => e.node.name);
  const seekingTopics = profile?.seekingTopics.edges.map((e) => e.node.name);

  return (
    <div className="profile-page__grid -my-profile">
      <ProfileSidebar
        actions={
          <div className="profile-page__logout-button">
            <LogoutButton className="-stretch" />
          </div>
        }
        className="-upload"
        stickyContent={
          <>
            <div className="portrait-container">
              <Portrait avatarUrl={profile?.avatarUrl} size="profile" />
            </div>
            <ProfileSidebar.Interactions>
              <div className="profile-page__edit-button">
                <button className="btn -svg -secondary" type="button" onClick={onEdit}>
                  <PenIcon />
                  Edit
                </button>
              </div>
            </ProfileSidebar.Interactions>
          </>
        }
      />
      <div className="profile-page__content">
        <ProfileHead
          description={profile?.bio || undefined}
          industry={profile?.industry?.name || undefined}
          info={`${profile?.jobTitle} at ${profile?.companyName}`}
          location={[profile?.city, profile?.country?.name].filter(Boolean).join(', ')}
          name={profile?.name}
          role={profile?.role || undefined}
        />
        <section className="profile-page__pane pane">
          <header className="pane-header">
            <h3 className="pane-header__heading">Interests</h3>
          </header>
          <AttendeeInterests
            attendeeName={profile?.name || ''}
            offeringInterests={offeringTopics}
            seekingInterests={seekingTopics}
          />
        </section>
      </div>
    </div>
  );
};

export default Profile;
