import { ReactElement, SVGProps } from 'react';

type WomanWaitingProps = SVGProps<SVGSVGElement>;

const WomanWaiting = (props: WomanWaitingProps): ReactElement<WomanWaitingProps> => (
  <svg height={132} width={220} {...props}>
    <defs>
      <path
        d="M.43 20.023c.179.312 7.476 21.392 7.476 21.392s21.344.273 34.16.18c3.215-.024 5.543-.175 7.253-.182.746-.003 1.201-.047 1.625-.04l.644-.063c.04-2.635 1.276-34.75 1.276-34.75L25.779.313.431 20.023z"
        id="woman-waiting_svg__b"
      />
      <path d="M.213 7.17l13.533 34.424 17.788-6.877L18 .294" id="woman-waiting_svg__d" />
      <path d="M.213 7.17l13.533 34.424 17.788-6.877L18 .294" id="woman-waiting_svg__f" />
      <path d="M.213 7.17l13.533 34.424 17.788-6.877L18 .294" id="woman-waiting_svg__h" />
      <path d="M.213 7.17l13.533 34.424 17.788-6.877L18 .294" id="woman-waiting_svg__j" />
      <linearGradient id="woman-waiting_svg__a" x1="50%" x2="50%" y1="45.558%" y2="100%">
        <stop offset="0%" stopColor="#A5622A" />
        <stop offset="100%" stopColor="#875022" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M110.488 34c51.639 0 93.5 41.861 93.5 93.5l-.013-1.5H17c.793-50.432 41.515-91.17 91.942-91.987z"
        fill="#F5F5F5"
      />
      <path
        d="M103.112 58c15.165 0 27.458 12.171 27.458 27.185 0 2.021-.223 3.99-.646 5.886L144 120.994l-68.346.387 2.196-17.774-11.32 14.134c-4.396 5.488-12.411 6.872-18.48 3.244l-.292-.18-1.538-.97c-6.891-4.35-8.293-13.468-3.013-19.587l32.193-37.3c4.435-5.14 11.99-6.291 17.75-3.104A27.635 27.635 0 01103.112 58z"
        fill="url(#woman-waiting_svg__a)"
      />
      <path d="M0 132h220v-12H0z" fill="#333" />
      <g transform="translate(77 79)">
        <mask fill="#fff" id="woman-waiting_svg__c">
          <use xlinkHref="#woman-waiting_svg__b" />
        </mask>
        <path
          d="M.43 20.023c.179.312 7.476 21.392 7.476 21.392s21.344.273 34.16.18c3.215-.024 5.543-.175 7.253-.182.746-.003 1.201-.047 1.625-.04l.644-.063c.04-2.635 1.276-34.75 1.276-34.75L25.779.313.431 20.023z"
          mask="url(#woman-waiting_svg__c)"
        />
      </g>
      <path d="M75.356 62.882l-32.152 37.341c-5.275 6.126-3.875 15.253 3.008 19.608l1.537.972c6.094 3.856 14.287 2.515 18.749-3.068L97.115 79.42c4.41-5.519 3.69-13.333-1.654-17.98C92.804 59.13 89.474 58 86.155 58c-4.018 0-8.021 1.656-10.799 4.882" />
      <path
        d="M84 62l-17.616 55.732a13.892 13.892 0 01-6.432 4.503c-3.967 1.347-8.499.943-12.236-1.433l-1.53-.973c-6.854-4.357-8.248-13.49-2.996-19.619"
        fill="#A5622A"
      />
      <path
        d="M85.35 111.44c0-2.123 1.686-3.85 3.759-3.85v7.7c-2.073 0-3.76-1.726-3.76-3.85m20.735-11.614H93.7c-2.405 0-4.392 1.919-4.577 4.334h-.015c-3.92 0-7.109 3.266-7.109 7.28 0 4.015 3.19 7.281 7.109 7.281v.357c0 2.587 2.066 4.703 4.592 4.703h12.383c2.526 0 4.592-2.116 4.592-4.703v-14.549c0-2.586-2.066-4.703-4.592-4.703"
        fill="#654DA0"
      />
      <path
        d="M93.586 95.674a.308.308 0 01-.305-.312c0-1.436.492-2.156.968-2.853.442-.647.859-1.257.859-2.497 0-1.239-.417-1.85-.859-2.496-.476-.697-.968-1.418-.968-2.854 0-1.436.492-2.157.968-2.854.442-.646.859-1.257.859-2.496 0-.172.136-.312.304-.312.168 0 .305.14.305.312 0 1.436-.493 2.157-.969 2.853-.441.647-.858 1.258-.858 2.497 0 1.24.417 1.85.858 2.496.476.698.969 1.419.969 2.854 0 1.436-.493 2.157-.969 2.854-.441.646-.858 1.257-.858 2.496a.308.308 0 01-.304.312M99.635 95.674a.308.308 0 01-.304-.312c0-1.436.492-2.156.968-2.853.442-.647.859-1.257.859-2.497 0-1.239-.417-1.85-.859-2.496-.476-.697-.968-1.418-.968-2.854 0-1.436.492-2.157.968-2.854.442-.646.859-1.257.859-2.496 0-.172.136-.312.304-.312.168 0 .305.14.305.312 0 1.436-.493 2.157-.969 2.853-.441.647-.859 1.258-.859 2.497 0 1.24.418 1.85.859 2.496.476.698.969 1.419.969 2.854 0 1.436-.493 2.157-.969 2.854-.441.646-.859 1.257-.859 2.496a.308.308 0 01-.304.312M105.685 95.674a.308.308 0 01-.304-.312c0-1.436.492-2.156.968-2.853.442-.647.859-1.257.859-2.497 0-1.239-.417-1.85-.859-2.496-.476-.697-.968-1.418-.968-2.854 0-1.436.492-2.157.968-2.854.442-.646.859-1.257.859-2.496 0-.172.136-.312.304-.312.168 0 .305.14.305.312 0 1.436-.493 2.157-.97 2.853-.44.647-.857 1.258-.857 2.497 0 1.24.417 1.85.858 2.496.476.698.969 1.419.969 2.854 0 1.436-.493 2.157-.97 2.854-.44.646-.857 1.257-.857 2.496a.308.308 0 01-.305.312"
        fill="#FFF"
      />
      <path
        d="M106.98 101.36c0 .457-3.151.826-7.038.826-3.885 0-7.036-.37-7.036-.825 0-.456 3.15-.826 7.036-.826 3.887 0 7.037.37 7.037.826"
        fill="#000"
      />
      <path
        d="M79.367 54l-.141.168-36.331 43.034c-5.633 5.602-5.1 14.66 1.233 19.631l1.434 1.126c3.505 2.752 8.008 3.615 12.126 2.675a14.035 14.035 0 006.914-3.839L88 62.985 79.367 54M111.16 61.802c-5.543 5.426-5.548 14.514-.01 19.947l35.1 34.439c5.758 5.649 15.009 4.927 19.871-1.55l1.086-1.446c4.306-5.736 3.556-13.92-1.718-18.727l-36.185-32.993c-5.213-4.753-13.098-4.61-18.143.33"
        fill="#A5622A"
      />
      <path
        d="M81.44 124.275c3.042-2.478 9.708-4.544 10.861-4.618l.001-.02 9.186-1.569a4.715 4.715 0 015.476 4.066c.337 2.728-1.73 5.163-4.481 5.28-6.053.258-10.59.398-10.59.398v-.006c-.07.029-.14.057-.216.073-1.24.248-5.709 1.125-7.028 1.121-1.07-.004-2.592-1.918-3.42-3.083a1.21 1.21 0 01.212-1.642"
        fill="#884A37"
      />
      <path
        d="M111.37 36.572c-2.33-2.33-5.531-1.849-7.921 0-.213.164-1.263.052-1.37.062-1.52.137-2.814.556-4.056 1.455-.14.099-.253.18-.34.246a5.585 5.585 0 00-2.202 1.355c-.5.431-.93.926-1.294 1.48-.933.766-1.825 1.411-2.546 2.157-.701.275-1.38.646-2.014 1.12-3.77 2.817-3.57 8.504 1.166 10.173.316.891.761 1.832 1.423 2.55 1.33 1.444 2.992 2.33 4.904 2.693 1.887.357 3.922.013 5.58-.98 1.191-.714 2.336-2.154 3.36-2.71 3.93-2.132 3.254-6.649.666-8.964l.036-.011c1.795-.595 3.127-1.562 4.607-2.707 2.407-1.861 1.929-5.991 0-7.92"
        fill="#0B1518"
      />
      <path
        d="M94 42.249s5.988 13.226 3.448 20.896c-.161.486.039 1.099.416.755L112 50.985 108.628 37 94 42.249z"
        fill="#884A37"
      />
      <path d="M96 47c3.129-.319 12.28-.332 17-6" fill="#EBCCC7" />
      <path
        d="M92.51 34s-4.39-.08-6.51-1.679c0 0 3.7-17.3 9-18.321 0 0-3.033 15.768-2.49 20"
        fill="#7C3F31"
      />
      <path
        d="M106.574 15.039s-14.73-4.11-14.933 4.022c-.204 8.132-4.821 23.706-4.636 24.824.262 1.578 5.263 4.427 8.337 4.087 3.073-.339 7.063-.306 11.7-6.336 4.636-6.03 13.249-13.733-.468-26.597"
        fill="#884A37"
      />
      <path d="M98.79 34.5a2.5 2.5 0 10-4.58-2 2.5 2.5 0 004.58 2" fill="#7C3F31" />
      <path
        d="M113.29 64H92.71C91.218 64 90 62.835 90 61.41v-6.82c0-1.425 1.219-2.59 2.71-2.59h20.58c1.49 0 2.71 1.165 2.71 2.59v6.82c0 1.425-1.22 2.59-2.71 2.59"
        fill="#A5622A"
      />
      <path
        d="M135.005 37.48c-.048-.146.32-.232 0 0m-.034-.031l.027.035-.035.024.008-.06m5.082 8.169c-1.073-.76-2.301-.91-3.538-1.104-.629-.396-1.392-.65-2.291-.712-.38-.317-.497-.776-.003-1.455.922-1.27 2.63-1.612 3.563-2.905 1.235-1.714.495-3.763-1.01-5-.533-.44-1.099-.839-1.689-1.203-.037-1.058-.097-2.09-.121-3.055-.065-2.623-2.31-5.399-5.118-5.176a.184.184 0 00-.067.018c.052-.163.116-.423.19-.826.172-.932-.159-2.31-.434-3.2-.569-1.844-1.82-3.424-3.342-4.595a9.108 9.108 0 003.654-7.315c0-5.02-4.024-9.089-8.988-9.089-3.433 0-6.416 1.948-7.93 4.811-1.076.017-2.133.163-3.252.295-1.683.2-3.005-.076-4.666-.338-2.725-.43-5.487.138-7.482 1.892-4.698.66-8.305 3.65-7.385 9.161.278 1.667 1.121 2.728 2.207 3.28a5.445 5.445 0 001.723.995c2.013.728 4.187 1.146 5.99 2.337.884.583.234.116.168.247-.327.655-.93 1.211-1.374 1.797-2.19 2.89-3.614 6.789-1.932 10.313.943 1.976 2.475 3.294 4.474 4.105-.025.06-.05.121-.078.183-1.44 3.21-1.569 6.85.901 9.048-1.616 1.345-2.655 3.543-1.788 5.62.801 1.916 2.83 2.814 4.702 3.309 2.17.573 4.418.627 6.62.233 1.008-.18 1.576-.38 2.452.246a6.487 6.487 0 011.4 1.388c.939 1.265 1.317 2.662 1.426 4.212.071 1.012.383 1.941 1.307 2.464.812.459 1.927.376 2.829.395 2.08.043 4.152-.112 6.211-.411 1.696-.246 3.754-.45 5.03-1.751.76-.777 1.073-1.871.888-2.939-.149-.863-.608-1.56-.986-2.33-.205-.418-.471-1.13-.215-1.588.218-.39.92-.467 1.311-.568 1.85-.475 3.769-.792 5.431-1.804 1.509-.917 2.77-2.366 3.077-4.161.32-1.874-.3-3.717-1.865-4.824"
        fill="#0B1518"
      />
      <path
        d="M92.965 52.421a4.569 4.569 0 00-.389-2.017l-.036-.081a8.1 8.1 0 00-.695-1.571c-2.073-4.681-3.587-8.004-3.587-8.004l-.005.002a1.17 1.17 0 00-.029-.225c-.32-1.216-1.49-5.598-2.078-6.777-.476-.957-2.907-1.493-4.343-1.73-.642-.107-1.263.281-1.415.9-.93 3.796.119 10.658.56 11.723l-.018.01 1.564 5.311L79 54.037 88.007 63l4.988-9.608s.023-.377-.03-.97"
        fill="#884A37"
      />
      <path d="M102 118.096L149.118 103 161 112.17l-4.375 13.522L104.204 127z" fill="#A5622A" />
      <g>
        <path
          d="M185.295 79H130.65c-3.673 0-6.65 2.99-6.65 6.68v36.46c0 3.689 2.977 6.68 6.65 6.68h54.645c3.673 0 6.65-2.991 6.65-6.68V85.68c0-3.69-2.977-6.68-6.65-6.68"
          fill="#B6B6B1"
        />
        <path
          d="M164.309 104.072c0 3.515-2.837 6.365-6.336 6.365-3.5 0-6.337-2.85-6.337-6.365s2.837-6.364 6.337-6.364c3.499 0 6.336 2.85 6.336 6.364"
          fill="#FEFEFE"
        />
      </g>
      <g>
        <g transform="translate(96)">
          <mask fill="#fff" id="woman-waiting_svg__e">
            <use xlinkHref="#woman-waiting_svg__d" />
          </mask>
          <path
            d="M10.908 34.376c1.568 3.987 6.097 5.959 10.117 4.404 4.02-1.554 6.008-6.045 4.44-10.032-1.567-3.986-6.096-5.959-10.116-4.404-4.02 1.554-6.008 6.046-4.44 10.032"
            fill="#CECECD"
            mask="url(#woman-waiting_svg__e)"
          />
        </g>
        <g transform="translate(96)">
          <mask fill="#fff" id="woman-waiting_svg__g">
            <use xlinkHref="#woman-waiting_svg__f" />
          </mask>
          <path
            d="M13.275 33.461c1.058 2.69 4.115 4.02 6.827 2.972 2.713-1.049 4.055-4.08 2.997-6.77-1.058-2.69-4.114-4.02-6.827-2.972-2.712 1.049-4.054 4.08-2.997 6.77"
            fill="#B6B6B1"
            mask="url(#woman-waiting_svg__g)"
          />
        </g>
        <g transform="translate(96)">
          <mask fill="#fff" id="woman-waiting_svg__i">
            <use xlinkHref="#woman-waiting_svg__h" />
          </mask>
          <path
            d="M19.085 33.852a2.463 2.463 0 001.523-1.763c.435-1.965 4.112-19.414-.899-26.723-1.278-1.864-3.068-3.028-5.177-3.367-1.35-.216-2.624.693-2.842 2.033a2.46 2.46 0 002.05 2.819c.772.123 1.35.517 1.87 1.277 2.876 4.193 1.623 16.276.157 22.907a2.458 2.458 0 001.89 2.927c.495.108.989.06 1.428-.11"
            fill="#B6B6B1"
            mask="url(#woman-waiting_svg__i)"
          />
        </g>
        <g transform="translate(96)">
          <mask fill="#fff" id="woman-waiting_svg__k">
            <use xlinkHref="#woman-waiting_svg__j" />
          </mask>
          <path
            d="M17.674 23.971l3.614.338a1.748 1.748 0 001.902-1.563l.154-1.612a1.741 1.741 0 00-1.575-1.887l-3.614-.338a1.75 1.75 0 00-1.903 1.562l-.153 1.613a1.741 1.741 0 001.575 1.887"
            fill="#CECECD"
            mask="url(#woman-waiting_svg__k)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default WomanWaiting;
