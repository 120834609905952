import { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'wouter';

import { Asset } from '~/lib/assets';

import ButtonBase from '../button/ButtonBase';
import LinkButton from '../button/LinkButton';
import AppStoreBadge from '../icons/AppStoreBadge';
import DesktopIcon from '../icons/DesktopIcon';
import GooglePlayBadge from '../icons/GooglePlayBadge';
import LoginContext from './LoginContext';
import LoginPanel from './LoginPanel';
import QRLogin from './QRLogin';

const Content = styled.div`
  padding: 2rem 4rem;
  font-size: var(--fs-md);
  line-height: calc(24 / 17);
  text-align: left;
  display: flex;
  gap: 6rem;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: var(--fs-2xl);
  margin-bottom: 1rem;
`;

const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Entry = styled.h4`
  margin-bottom: 0.35rem;
`;

const AppSection = styled.div`
  display: grid;
  grid-template-columns: 4rem min-content auto;
  grid-template-areas:
    'logo text text'
    'logo appstore googleplay';
  gap: 0.5rem 1rem;

  p {
    grid-area: text;
    font-size: var(--fs-sm);
    line-height: calc(24 / 14);
  }
`;

const AppLogo = styled.img`
  width: 100%;
  grid-area: logo;
  align-self: end;
`;

const AppStoreLink = styled.a`
  grid-area: appstore;
`;

const GooglePlayLink = styled.a`
  grid-area: googleplay;
`;

const QRCodeWrapper = styled.div``;

const Help = styled.div`
  margin-top: 0.5rem;
  font-size: var(--fs-xs);
  text-align: center;
  line-height: calc(18 / 12);

  a {
    color: var(--c-brand-primary);
    font-weight: 600;
  }
`;

const Footer = styled.div`
  padding: 2rem 4rem;
  border-top: 1px solid var(--c-silver);
  display: flex;
  align-items: center;
  text-align: left;
  gap: 6rem;
`;

const Tagline = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  flex: 1;
`;

const ActionButton = styled(ButtonBase)`
  width: 40%;
`;

export type QRCodeLoginPanelProps = {
  fallbackMethod?: string;
};

const QRCodeLoginPanel = ({ fallbackMethod = 'ticket' }: QRCodeLoginPanelProps): ReactElement => {
  const { conferenceName, appStoreUrl, googlePlayUrl, supportEmail, appUrl, cta } =
    useContext(LoginContext);
  const hasAppLinks = Boolean(appStoreUrl || googlePlayUrl);

  // QR code login is not convenient on mobile devices.
  if (window.innerWidth <= 768) {
    return <Redirect href={`/${fallbackMethod}`} />;
  }

  const openFreshchat = (): void => {
    window.fcWidget?.open();
    window.fcWidget?.show();
  };

  return (
    <LoginPanel disableGutters paddedLogo size="medium">
      <Content>
        <Instructions>
          <section>
            <Title>Welcome to {conferenceName}!</Title>
            <Entry>
              <strong>Log in with QR code</strong>
            </Entry>
            <Entry>1. Open the {conferenceName} mobile app</Entry>
            <Entry>
              2. Tap your profile picture to open the menu, and select{' '}
              <strong>
                <span>
                  <DesktopIcon /> Web app login
                </span>
              </strong>
            </Entry>
            <Entry>3. Point your phone at this screen to scan the QR code</Entry>
          </section>
          {hasAppLinks && (
            <AppSection>
              <p>Don&apos;t have our mobile app yet? Download it here:</p>
              {Boolean(Asset.appLogo) && <AppLogo src={Asset.appLogo} />}
              {Boolean(appStoreUrl) && (
                <AppStoreLink href={appStoreUrl} rel="noopener noreferrer" target="_blank">
                  <AppStoreBadge />
                </AppStoreLink>
              )}
              {Boolean(googlePlayUrl) && (
                <GooglePlayLink href={googlePlayUrl} rel="noopener noreferrer" target="_blank">
                  <GooglePlayBadge />
                </GooglePlayLink>
              )}
            </AppSection>
          )}
        </Instructions>
        <QRCodeWrapper>
          <QRLogin appUrl={appUrl} />
          <Help>
            <p>
              <Link href={`/${fallbackMethod}`}>Trouble accessing QR code?</Link>
            </p>
            {Boolean(supportEmail) && (
              <p>
                You can also speak to our{' '}
                <LinkButton onClick={openFreshchat}>Support team</LinkButton> or email{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              </p>
            )}
          </Help>
        </QRCodeWrapper>
      </Content>
      {cta && (
        <Footer>
          <Tagline>{cta.title}</Tagline>
          <ActionButton as="a" href={cta.url} rel="noreferrer" size="large" target="_blank">
            {cta.action}
          </ActionButton>
        </Footer>
      )}
    </LoginPanel>
  );
};

export default QRCodeLoginPanel;
