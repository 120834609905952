import { ReactElement } from 'react';

import OptionsBlock from '~/components/forms/OptionsBlock';

type AttendeeInterestsProps = {
  attendeeName: string;
  offeringInterests?: string[];
  seekingInterests?: string[];
};

const AttendeeInterests = ({
  offeringInterests = [],
  seekingInterests = [],
  attendeeName,
}: AttendeeInterestsProps): ReactElement<AttendeeInterestsProps> => {
  const empty = (
    <div className="empty-block">
      <p>{attendeeName} hasn&apos;t provided this information.</p>
    </div>
  );

  const offersInterests = offeringInterests.length !== 0;
  const seeksInterests = seekingInterests.length !== 0;

  return (
    <div className="profile-page__interests">
      <div>
        <h4 className="profile-page__interests_heading">I can share expertise in:</h4>
        {offersInterests ? (
          <OptionsBlock<string>
            availableOptions={offeringInterests}
            selectedOptions={offeringInterests}
          />
        ) : (
          empty
        )}
      </div>
      <div>
        <h4 className="profile-page__interests_heading">I want to learn about:</h4>
        {seeksInterests ? (
          <OptionsBlock<string>
            availableOptions={seekingInterests}
            selectedOptions={seekingInterests}
            styling="secondary"
          />
        ) : (
          empty
        )}
      </div>
    </div>
  );
};

export default AttendeeInterests;
