import { OTSubscriber, OTSubscriberRef } from 'opentok-react';
import {
  Error as OTError,
  EventHandler as OTEventHandler,
  SignalEvent,
  Stream,
  SubscriberEventHandlers,
  SubscriberProperties,
} from 'opentok-react/types/opentok';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { usePublisher } from '~/lib/rooms';
import urlFor from '~/lib/urlFor';
import { Participant } from '~/types/rooms';

import { EventStreamContext } from './EventStreamContext';
import { OpenTokContext } from './OpenTokSession';
import { useAudioLevel } from './room_elements/AudioLevelIndicator';
import VideoStream from './VideoStream';

type WebcamVideoSubscriberProps = {
  className?: string;
  participant?: Participant;
  roomId?: string;
  stream: Stream;
  subscriberOptions: SubscriberProperties;
};

const STREAM_QUALITY_ISSUES = 'quality';

const WebcamVideoSubscriber = ({
  className,
  stream,
  participant,
  subscriberOptions,
  roomId,
}: WebcamVideoSubscriberProps): ReactElement<WebcamVideoSubscriberProps> => {
  const [isParticipantRaisingHand, setIsParticipantRaisingHand] = useState(false);
  const [hasConnectionIssues, setHasConnectionIssues] = useState(false);
  const { audioLevelUpdated, volumeLevel } = useAudioLevel();
  const { onEvent } = useContext(EventStreamContext);
  const { session, setSubscribeError } = useContext(OpenTokContext);

  const { setVideoConsent } = usePublisher();

  const targetRef = useRef<HTMLDivElement>(null);
  const subscriberRef = useRef<OTSubscriberRef>(null);

  let movementTimer: number | null = null;
  const RESET_TIMEOUT = 1000;
  const computeDimensions = () => {
    if (targetRef.current) {
      const currentDimensions = {
        height: targetRef.current.offsetHeight,
        width: targetRef.current.offsetWidth,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: setPreferredResolution typings incorrect
      subscriberRef?.current?.getSubscriber?.()?.setPreferredResolution?.(currentDimensions);
    }
  };
  useLayoutEffect(() => {
    computeDimensions();
    setTimeout(computeDimensions, RESET_TIMEOUT);
    setTimeout(computeDimensions, RESET_TIMEOUT * 10);
  }, [participant, stream]);
  window.addEventListener('resize', () => {
    if (movementTimer !== null) {
      window.clearTimeout(movementTimer);
    }
    movementTimer = window.setTimeout(computeDimensions, RESET_TIMEOUT);
  });

  const subscriberEventHandlers: SubscriberEventHandlers = {
    audioBlocked: onEvent,
    audioLevelUpdated,
    audioUnblocked: onEvent,
    connected: onEvent,
    destroyed: onEvent,
    disconnected: onEvent,
    videoDisableWarning: onEvent,
    videoDisableWarningLifted: onEvent,
    videoDisabled: (e) => {
      if (e.reason === STREAM_QUALITY_ISSUES) {
        setHasConnectionIssues(true);
      }
      onEvent(e);
    },
    videoElementCreated: (event) => onEvent(event, (state) => setVideoConsent?.(state)),
    videoEnabled: (e) => {
      if (e.reason === STREAM_QUALITY_ISSUES) {
        setHasConnectionIssues(false);
      }
      onEvent(e);
    },
  };

  const onSubscribe = () => {
    onEvent({ type: 'subscribeSuccess' });
  };

  const onSubscribeError = useCallback(
    (error: OTError) => {
      if (roomId && error.name === 'OT_STREAM_LIMIT_EXCEEDED') {
        window.location.href = urlFor(`/rooms/${roomId}/room_full`);
      }
      setSubscribeError(error);
    },
    [setSubscribeError, roomId],
  );

  useEffect(() => {
    if (!stream || !session?.connection) return () => {
      // No cleanup needed
    };

    const {
      connection: { connectionId },
    } = stream;

    const handleSignal = (e: SignalEvent, isRaisingHand: boolean) => {
      if (connectionId === e.from.connectionId) {
        setIsParticipantRaisingHand(isRaisingHand);
      }
    };

    const handleRaiseHand = ((e: SignalEvent) => handleSignal(e, true)) as OTEventHandler;
    const handleLowerHand = ((e: SignalEvent) => handleSignal(e, false)) as OTEventHandler;

    session.on('signal:raisedHand', handleRaiseHand);
    session.on('signal:loweredHand', handleLowerHand);

    return () => {
      session.off('signal:raisedHand', handleRaiseHand);
      session.off('signal:loweredHand', handleLowerHand);
    };
  }, [session, session?.connection, stream]);

  return (
    <VideoStream
      avatarUrl={participant ? participant.avatarUrl ?? undefined : ''}
      className={className}
      hasConnectionIssues={hasConnectionIssues}
      isPublishingAudio={stream.hasAudio}
      isPublishingVideo={stream.hasVideo}
      isRaisingHand={isParticipantRaisingHand}
      isScreenSharingStream={stream.videoType === 'screen'}
      name={stream.name}
      volumeLevel={volumeLevel}
      wrapperRef={targetRef}
    >
      <OTSubscriber
        ref={subscriberRef}
        //
        // Hoo boo, both the opentok docs and the js code in github (with prop-types!)
        // use the retry boolean prop, but somehow it's not in the typings.
        //
        // Whyyyyyyyyyyyyyyyyy
        //
        // so I'm ignoring the error here then - Ciarán
        //
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Opentok typings do not have retry option contray to docs
        retry
        eventHandlers={subscriberEventHandlers}
        properties={subscriberOptions}
        session={session}
        stream={stream}
        onError={onSubscribeError}
        onSubscribe={onSubscribe}
      />
    </VideoStream>
  );
};

export default WebcamVideoSubscriber;
