/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, useEffect, useRef } from 'react';

/**
 * Runs the effectful function fn only after initial mount. The parameters to this hook correspond
 * to the parameters of `useEffect`.
 */
const useEffectAfterMount = (fn: () => void, deps: DependencyList): void => {
  const mounted = useRef(false);

  if (mounted.current) {
    fn();
  }

  useEffect(() => {
    mounted.current = true;
  }, deps);
};

export default useEffectAfterMount;
