import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import MailIcon from '../icons/MailIcon';
import LoginMessage from './LoginMessage';

export type SuccessMessageProps = {
  children?: ReactNode;
};

const SuccessMessage = ({ children }: SuccessMessageProps): ReactElement<SuccessMessageProps> => {
  const { t } = useTranslation();

  return (
    <LoginMessage icon={<MailIcon />} title={t('login.successTitle')}>
      {children}
    </LoginMessage>
  );
};

export default SuccessMessage;
