import { useMutation, useQuery } from '@apollo/client';
import { ReactElement, ReactNode, useRef } from 'react';
import styled from 'styled-components';

import { useQuestions } from '~/lib/rooms/questions';
import { RoomSettingsDocument, UpdateRoomDocument } from '~/operations/catalyst';
import bodyMixin from '~/style-mixins/typography/bodyMixin';

import Button from '../button/Button';
import Modal, { ModalTriggerArguments } from '../others/Modal';
import ConfirmationPopover from '../popover/ConfirmationPopover';
import ToggleControl from '../shared/molecules/ToggleControl';

export type RoomSettingsModalProps = {
  channel?: boolean;
  modalTrigger?: ((arg: ModalTriggerArguments) => ReactNode) | undefined;
  roomId: string;
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const FieldWrapper = styled.div``;

const Field = styled.div`
  display: flex;
`;

const FieldTitle = styled.div`
  flex: 1;
  font-size: var(--fs-14);
  line-height: var(--fs-18);
  font-weight: 600;
`;

const FieldDescription = styled.div`
  width: 90%;
  ${bodyMixin}
  color: var(--c-darkgrey);
`;

const RoomSettingsModal = ({
  roomId,
  channel,
  modalTrigger,
}: RoomSettingsModalProps): ReactElement<RoomSettingsModalProps> => {
  const { data } = useQuery(RoomSettingsDocument, {
    variables: { id: roomId },
  });

  const [update] = useMutation(UpdateRoomDocument);
  const { clearHistory, setCurrentQuestion } = useQuestions();

  return (
    <Modal modalTrigger={modalTrigger}>
      {({ closeModal }): ReactElement => (
        <>
          <div className="preview__generic">
            <h2 className="preview__generic__heading">Settings</h2>
            <div className="preview__generic__text">
              {data?.room?.features && (
                <Fields>
                  <FieldDescription>
                    Settings will apply to new participants. Current participants must reload the
                    page.
                  </FieldDescription>
                  <FieldWrapper>
                    <Field>
                      <FieldTitle>Backstage mode</FieldTitle>
                      <ToggleControl>
                        <ToggleControl.Button
                          isDisabled={channel}
                          name="backstageEnabled"
                          selected={data.room.features.backstageEnabled}
                          setSelected={(selected): Promise<unknown> =>
                            update({
                              variables: { id: roomId, input: { backstageEnabled: selected } },
                            })
                          }
                        />
                      </ToggleControl>
                    </Field>
                    <FieldDescription>
                      When enabled moderators can control what content is visible to the audience.
                    </FieldDescription>
                  </FieldWrapper>

                  <FieldWrapper>
                    <Field>
                      <FieldTitle>Video call</FieldTitle>
                      <ToggleControl>
                        <ToggleControl.Button
                          isDisabled={channel}
                          name="videoCallEnabled"
                          selected={data.room.features.videoCallEnabled}
                          setSelected={(selected): Promise<unknown> =>
                            update({
                              variables: { id: roomId, input: { videoCallEnabled: selected } },
                            })
                          }
                        />
                      </ToggleControl>
                    </Field>
                    <FieldDescription>
                      When enabled participants can join a real-time video call.
                    </FieldDescription>
                  </FieldWrapper>

                  <FieldWrapper>
                    <Field>
                      <FieldTitle>Chat</FieldTitle>
                      <ToggleControl>
                        <ToggleControl.Button
                          name="chatEnabled"
                          selected={data.room.features.chatEnabled}
                          setSelected={(selected): Promise<unknown> =>
                            update({
                              variables: { id: roomId, input: { chatEnabled: selected } },
                            })
                          }
                        />
                      </ToggleControl>
                    </Field>
                    <FieldDescription>
                      When enabled participants can send chat messages.
                    </FieldDescription>
                  </FieldWrapper>

                  <FieldWrapper>
                    <Field>
                      <FieldTitle>Reactions</FieldTitle>
                      <ToggleControl>
                        <ToggleControl.Button
                          name="reactionsEnabled"
                          selected={data.room.features.reactionsEnabled}
                          setSelected={(selected): Promise<unknown> =>
                            update({
                              variables: { id: roomId, input: { reactionsEnabled: selected } },
                            })
                          }
                        />
                      </ToggleControl>
                    </Field>
                    <FieldDescription>
                      When enabled participants can send reactions.
                    </FieldDescription>
                  </FieldWrapper>

                  <FieldWrapper>
                    <Field>
                      <FieldTitle>Questions</FieldTitle>
                      <ToggleControl>
                        <ToggleControl.Button
                          name="questionsEnabled"
                          selected={data.room.features.questionsEnabled}
                          setSelected={(selected): Promise<unknown> =>
                            update({
                              variables: { id: roomId, input: { questionsEnabled: selected } },
                            })
                          }
                        />
                      </ToggleControl>
                    </Field>
                    <FieldDescription>
                      When enabled moderators can hold a Q&amp;A session.
                    </FieldDescription>
                  </FieldWrapper>

                  <FieldWrapper>
                    <Field>
                      <ConfirmationPopover
                        // Tippy js popover is appendeed to body by default
                        // In this case it needs to be appended to the field
                        // Otherwise the popover appears under the modal and in a 
                        // totally diffrent part of the dom tree
                        // 
                        // yay
                        // - Ciarán, April 2022
                        //
                        appendTo="parent"
                        confirmLabel="Clear"
                        text="Are you sure you want to clear questions?"
                        onConfirm={(): void => {
                          clearHistory();
                          setCurrentQuestion(null);
                        }}
                      >
                        <Button variant="secondary">Clear questions</Button>
                      </ConfirmationPopover>
                    </Field>
                  </FieldWrapper>
                </Fields>
              )}
            </div>
          </div>
          <nav className="preview__nav">
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </nav>
        </>
      )}
    </Modal>
  );
};

export default RoomSettingsModal;
