import { RoomRole } from '~/operations/catalyst';
import { RoomOptions } from '~/types/rooms';

const PERMISSIONS_MAP: Record<RoomRole, RoomOptions['occupant']> = {
  [RoomRole.Spectator]: {
    canRaiseHand: false,
    canReact: true,
    canReadMessages: true,
    canSendMessages: true,
    canShareScreen: false,
    canShareVideo: false,
    isAdmin: false,
    isModerator: false,
  },
  [RoomRole.Participant]: {
    canRaiseHand: true,
    canReact: true,
    canReadMessages: true,
    canSendMessages: true,
    canShareScreen: true,
    canShareVideo: true,
    isAdmin: false,
    isModerator: false,
  },
  [RoomRole.Moderator]: {
    canRaiseHand: true,
    canReact: true,
    canReadMessages: true,
    canSendMessages: true,
    canShareScreen: true,
    canShareVideo: true,
    isAdmin: false,
    isModerator: true,
  },
};

export default PERMISSIONS_MAP;
