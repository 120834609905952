import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

type ExhibitorLogoProps = {
  alt?: string;
  logoUrl?: string;
};

const ExhibitorLogo = ({ alt, logoUrl }: ExhibitorLogoProps): ReactElement<ExhibitorLogoProps> => (
  <div className="logo-container">
    {logoUrl && alt ? (
      <img alt={alt} className="exhibitor-logo" src={logoUrl} />
    ) : (
      <Skeleton className="exhibitor-logo" />
    )}
  </div>
);

export default ExhibitorLogo;
