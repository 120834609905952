import styled, { css } from 'styled-components';
import { ReactElement } from 'react';
import StreamingOnIcon from '../icons/StreamingOnIcon';

const Root = styled.div<{ poster?: string }>`
  background-color: #000;
  ${(props) =>
    props.poster &&
    css`
      background-image: url('${props.poster}');
    `}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const Label = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 0.75rem;
  display: flex;
  gap: 0.5rem;
`;

export type LiveStreamOfflineOverlayProps = {
  poster?: string;
};

// An image overlay placed above a live stream video player.
// It is displayed when the player is loaded but the live stream is offline.
const LiveStreamOfflineOverlay = ({ poster }: LiveStreamOfflineOverlayProps): ReactElement => {
  return (
    <Root poster={poster}>
      <Label>
        <StreamingOnIcon />
        <span>Live stream offline</span>
      </Label>
    </Root>
  );
};

export default LiveStreamOfflineOverlay;
