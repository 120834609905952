import clsx from 'clsx';

import { usePermissions, useRoom } from '~/lib/rooms';
import urlFor from '~/lib/urlFor';
import { Participant } from '~/types/rooms';
import AttendeeRoleTag from '~/components/attendees/AttendeeRoleTag';
import CloseIcon from '~/components/icons/CloseIcon';
import PinIcon from '~/components/icons/PinIcon';

import Linkify from 'linkify-react';
import Portrait from '../../attendees/Portrait';

const AUTHOR_DELETED_MESSAGE =
  'This message has been flagged in breach of our Content Guidelines and removed by a moderator. If continued, you will be restricted from chat.';

export type ChatMessageProps = {
  author: Participant;
  body: string;
  isDeleted?: boolean;
  isPinned?: boolean;
  onDelete?: () => void;
  onPin?: () => void;
};

const ChatMessage = ({
  author,
  body,
  isPinned = false,
  isDeleted = false,
  onDelete,
  onPin,
}: ChatMessageProps) => {
  const { user } = useRoom();
  const { isModerator } = usePermissions();

  if (isDeleted && author.identity !== user.identity) return null;

  return (
    <div className={clsx('chat-card', { '-deleted': isDeleted, '-pinned': isPinned })}>
      <div className="chat-card__avatar">
        <Portrait
          avatarUrl={author.avatarUrl}
          id={isPinned ? null : author.id}
          name={author.name}
          size="sm"
        />
      </div>

      {author.id ? (
        <a
          className="chat-card__title"
          href={author.id && urlFor(`/attendees/${author.id}`)}
          rel="noreferrer"
          target="_blank"
        >
          {author.name}
          <AttendeeRoleTag accreditation={author.accreditation} visibleAccreditations={['Staff']} />
        </a>
      ) : (
        <div className="chat-card__title">
          {author.name}
          <AttendeeRoleTag accreditation={author.accreditation} visibleAccreditations={['Staff']} />
        </div>
      )}

      {isModerator && !isDeleted && (
        <div className="chat-card__settings">
          {!isPinned && (
            <button className="chat-card__button" type="button" onClick={onDelete}>
              <CloseIcon className="chat-card__close-icon" />
            </button>
          )}
          <button className="chat-card__button" type="button" onClick={onPin}>
            <PinIcon className="chat-card__pin-icon" />
          </button>
        </div>
      )}

      <div className="chat-card__body">
        {isDeleted && AUTHOR_DELETED_MESSAGE}
        {!isDeleted && <Linkify options={{ target: '_blank' }}>{body}</Linkify>}
      </div>
    </div>
  );
};

export default ChatMessage;
