import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef } from 'react';

export type InteractiveButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isCentered?: boolean;
  isFlexible?: boolean;
  isStatic?: boolean;
  size?: 'sm';
  styling?: 'safe' | 'danger' | 'secondary' | 'connected' | 'requested';
};

/**
 * @deprecated use `components/button/Button`
 */
const InteractiveButton = forwardRef<HTMLButtonElement, InteractiveButtonProps>(
  (
    {
      children,
      className,
      styling,
      isStatic,
      isCentered,
      size,
      isFlexible,
      disabled,
      ...otherButtonProps
    }: InteractiveButtonProps,
    ref,
  ) => (
    <button
      ref={ref}
      className={clsx('interact-btn', className, {
        '-centered': isCentered,
        '-connected': styling === 'connected',
        '-danger': styling === 'danger',
        '-disabled': disabled,
        '-flexible': isFlexible,
        '-requested': styling === 'requested',
        '-safe': styling === 'safe',
        '-secondary': styling === 'secondary',
        '-sm': size === 'sm',
        '-static': isStatic,
      })}
      disabled={disabled}
      type="button"
      {...otherButtonProps}
    >
      {children}
    </button>
  ),
);

export default InteractiveButton;
