import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled, { css } from 'styled-components';
import { useMe } from '~/lib/avenger/api';

import urlFor from '~/lib/urlFor';
import { desktop } from '~/style-mixins/theme';

import Portrait from '../attendees/Portrait';
import CalendarMenu from './CalendarMenu';
import ChatMenu from './ChatMenu';

const Profile = styled.a`
  margin: 0 0.5rem;
`;

const Root = styled.div`
  display: flex;

  > div {
    display: none;
    ${desktop(css`
      display: block;
    `)}
  
`;

export type PersonalSectionProps = {
  chatNotifications: boolean;
};

const PersonalSection = ({ chatNotifications }: PersonalSectionProps): ReactElement => {
  const { data } = useMe();

  return (
    <Root>
      <ErrorBoundary fallbackRender={() => null}>
        <ChatMenu notifications={chatNotifications} />
      </ErrorBoundary>
      <ErrorBoundary fallbackRender={() => null}>
        <CalendarMenu />
      </ErrorBoundary>
      <Profile aria-label="My profile" href={urlFor('/my/profile')}>
        <Portrait avatarUrl={data?.person.avatar_url} />
      </Profile>
    </Root>
  );
};

export default PersonalSection;
