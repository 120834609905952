import clsx from 'clsx';
import { ReactElement, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import UpcomingTimeslots from '~/components/channel/UpcomingTimeslots';
import { RoomConfigContext } from '~/lib/rooms/config';
import { RoomReaction } from '~/operations/catalyst';
import ReactionsContent from '~/components/reactions/ReactionsContent';
import RoomProvider from '~/components/room/RoomProvider';
import { PUBLIC_CHAT_CONFIG } from '~/configs/chatConfig';
import { RoomOptions } from '~/types/rooms';

import IconButton from '../button/IconButton';
import GearIcon from '../icons/GearIcon';
import DiscussionPanel from '../room/discussion_panel/DiscussionPanel';
import RoomSettingsModal from '../room/RoomSettingsModal';
import Player from '../video/Player';

export type ChannelViewProps = {
  liveStreamPoster?: string;
  id?: string;
  reactionsLayout?: RoomReaction[];
  room?: RoomOptions;
};

const Sidebar = styled.aside``;

const ChannelView = ({
  id,
  room: roomOptions,
  liveStreamPoster,
  reactionsLayout,
}: ChannelViewProps): ReactElement<ChannelViewProps> => {
  const { contentLiveStream } = useContext(RoomConfigContext) || {};

  if (!id || !roomOptions) {
    return (
      <div className="channel-viewer">
        <div className="channel-viewer__content">
          <Skeleton height={600} />
        </div>
        <Sidebar>
          <Skeleton height={900} />
        </Sidebar>
      </div>
    );
  }

  const { room, reactions, occupant } = roomOptions;

  return (
    <RoomProvider {...roomOptions} chatConfig={PUBLIC_CHAT_CONFIG}>
      <div className="channel-viewer">
        <main className="channel-viewer__content">
          <div className={clsx('channel-viewer__player', id)}>
            <Player
              muted
              poster={liveStreamPoster}
              src={{
                gatepass: contentLiveStream?.gatepassUrl || undefined,
                hls: contentLiveStream?.hlsUrl || undefined,
                iframe: contentLiveStream?.iframeUrl || undefined,
              }}
              videoId={id}
              videoTitle={room.name}
            />
            <div className="channel-viewer__reactions">
              {reactions && <ReactionsContent reactionsLayout={reactionsLayout} />}
            </div>
          </div>
          <UpcomingTimeslots channelId={id} title={room.name}>
            {occupant.isModerator && (
              <RoomSettingsModal
                channel
                modalTrigger={({ openModal }): ReactElement => (
                  <IconButton onClick={openModal}>
                    <GearIcon />
                  </IconButton>
                )}
                roomId={room.id}
              />
            )}
          </UpcomingTimeslots>
        </main>
        <Sidebar>
          <DiscussionPanel />
        </Sidebar>
      </div>
    </RoomProvider>
  );
};

export default ChannelView;
