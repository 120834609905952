import { ReactElement } from 'react';
import styled from 'styled-components';

type AttendeeRoleTagProps = {
  accreditation?: string | null;
  role?: string;
  visibleAccreditations: string[];
  visibleRoles?: string[];
};

const RoleTagBase = styled.span`
  position: relative;
  color: var(--c-brand-primary);
  text-transform: uppercase;
  font-size: var(--fs-2xs);
  padding-left: 0.2rem;
  font-weight: bold;

  &:before {
    content: '·';
    color: var(--c-black);
    padding-right: 0.2rem;
  }
`;

const AttendeeRoleTag = ({
  accreditation,
  role,
  visibleAccreditations,
  visibleRoles,
}: AttendeeRoleTagProps): ReactElement<AttendeeRoleTagProps> | null => {
  const isRoleIncluded = role && visibleRoles?.includes(role);
  const isAccreditationIncluded = accreditation && visibleAccreditations.includes(accreditation);
  const isTagVisible = visibleRoles
    ? isRoleIncluded && isAccreditationIncluded
    : isAccreditationIncluded;

  return isTagVisible ? <RoleTagBase>{accreditation}</RoleTagBase> : null;
};

export default AttendeeRoleTag;
