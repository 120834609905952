import type { ReactElement } from 'react';

import type { AttendeeHitListType } from '~/types/search';
import AttendeeCard from '~/components/attendees/AttendeeCard';

type AttendeeHitsProps = { hits: AttendeeHitListType };

const AttendeeHits = ({ hits }: AttendeeHitsProps): ReactElement<AttendeeHitsProps> => {
  return (
    <div className="directory-list attendee-list">
      {hits.map((hit) => (
        <AttendeeCard
          key={hit.id}
          avatarUrl={hit.avatar_urls.thumb}
          cardRole={hit.role}
          companyName={hit.company_name}
          firstName={hit.first_name}
          id={hit.id}
          jobTitle={hit.job_title}
          lastName={hit.last_name}
        />
      ))}
    </div>
  );
};

export default AttendeeHits;
