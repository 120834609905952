import clsx from 'clsx';
import { ReactElement } from 'react';

import Portrait from '~/components/attendees/Portrait';
import ConnectionIssuesIcon from '~/components/icons/ConnectionIssuesIcon';

const getInitials = (name: string) =>
  name
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');

type VideoCoverProps = {
  avatarUrl?: string;
  hasConnectionIssues?: boolean;
  isCoverVisible?: boolean;
  name: string;
};

const VideoCover = ({
  avatarUrl,
  name,
  hasConnectionIssues = false,
  isCoverVisible = false,
}: VideoCoverProps): ReactElement<VideoCoverProps> | null => {
  if (!isCoverVisible) return null;

  return (
    <div className="stage__video-cover">
      {hasConnectionIssues && (
        <div className="stage__connection-issue">
          <ConnectionIssuesIcon />
        </div>
      )}
      <div className={clsx('stage__avatar', hasConnectionIssues && '-faded')}>
        {avatarUrl ? (
          <Portrait avatarUrl={avatarUrl} name={name} size="full" />
        ) : (
          <span>{getInitials(name)}</span>
        )}
      </div>
    </div>
  );
};

export default VideoCover;
