import { ReactElement, useMemo, useState } from 'react';

import { useAttendeeConnection } from '~/lib/connections';
import { Participant } from '~/types/rooms';
import AttendeeContext from '~/lib/attendees/AttendeeContext';
import ConfirmAttendeeConnectionModal from '~/components/connections/ConfirmAttendeeConnectionModal';

import AttendeeRoleTag from '../attendees/AttendeeRoleTag';
import AttendeeTooltip from '../attendees/AttendeeTooltip';

type AttendeeProps = {
  info: Participant;
};

const ConnectedConfirmAttendeeConnectionModal = ({ info }: AttendeeProps): ReactElement => {
  const { connect } = useAttendeeConnection(info.id);

  return <ConfirmAttendeeConnectionModal showOnInit name={info.name} onConfirm={connect} />;
};

const Attendee = ({ info }: AttendeeProps): ReactElement<AttendeeProps> => {
  const [showAttendeeConnectModal, setShowAttendeeConnectModal] = useState<boolean>(false);
  const tooltipInfo = useMemo(() => {
    // role property in the info object determines a role in a chat room not a role from Omnia
    // but accreditation property holds that information
    // more info can be found in AudienceProvider
    const { avatarUrl, name, jobTitle, accreditation, companyName, id } = info;
    return {
      avatarUrl,
      companyName,
      id,
      jobTitle,
      name,
      role: accreditation,
    };
  }, [info]);

  const attendeeValue = useMemo(
    () => ({
      showAttendeeConnectModal,
      setShowAttendeeConnectModal,
    }),
    [showAttendeeConnectModal],
  );

  return (
    <AttendeeContext.Provider value={attendeeValue}>
      <div>
        <AttendeeTooltip info={tooltipInfo} />
      </div>
      <div className="audience-item__content">
        <div className="audience-item__name">
          {info.name}
          {tooltipInfo.role && (
            <AttendeeRoleTag accreditation={tooltipInfo.role} visibleAccreditations={['Staff']} />
          )}
        </div>
        {info.companyName && <div className="audience-item__info">{info.companyName}</div>}
      </div>
      {showAttendeeConnectModal && (
        <ConnectedConfirmAttendeeConnectionModal info={info} />
      )}
    </AttendeeContext.Provider>
  );
};

export default Attendee;
