import { ReactElement, SVGProps } from 'react';

type LoadingIconProps = SVGProps<SVGSVGElement>;

const LoadingIcon = (props: LoadingIconProps): ReactElement<LoadingIconProps> => (
  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" {...props}>
    <g fill="none" fillRule="evenodd" id="Icon-/-Loading" stroke="none" strokeWidth="1">
      <path
        className="icon -rotating"
        d="M12.1323155,4 C7.63358779,4 4,7.58 4,12 C4,16.42 7.63358779,20 12.1323155,20 C15.9287532,20 19.0941476,17.45 20,14 L17.8829517,14 C17.0483461,16.33 14.7888041,18 12.1323155,18 C8.76335878,18 6.02544529,15.31 6.02544529,12 C6.02544529,8.69 8.76335878,6 12.1323155,6 C12.1323155,5.62097168 12.1323155,4.30554199 12.1323155,4 Z"
        fill="#333333"
        id="Path"
      />
    </g>
  </svg>
);

export default LoadingIcon;
