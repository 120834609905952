import { ApolloProvider } from '@apollo/client';
import { ReactElement, StrictMode, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Route, Router, Switch, useLocation } from 'wouter';

import BreakpointProvider from '~/components/util/BreakpointProvider';
import { updateProfile, useCountries, useIndustries, useMe, useTopics } from '~/lib/avenger/api';
import InterestsScreen, {
  InterestsScreenProps,
} from '~/components/attendees/onboarding/InterestsScreen';
import {
  OnboardingContextProvider,
  useOnboarding,
} from '~/components/attendees/onboarding/OnboardingContext';
import PublicProfileScreen from '~/components/attendees/onboarding/PublicProfileScreen';
import Stepper from '~/components/attendees/onboarding/Stepper';
import WelcomeScreen from '~/components/attendees/onboarding/WelcomeScreen';
import { init } from '~/lib/apollo';
import urlFor from '~/lib/urlFor';

const OnboardingLayout = styled.div`
  min-height: calc(100vh - var(--s-header-y) - var(--s-footer-y));
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 70ch;
`;

enum OnboardingPath {
  Interests = '/interests',
  PublicProfile = '/public-profile',
  Welcome = '/',
}

const Routes = (): ReactElement => {
  const { profileData, setProfileData, disableAvatarUploads } = useOnboarding();
  const [location, navigate] = useLocation();

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  const onProfileSubmit: InterestsScreenProps['onSubmit'] = useCallback(
    async (data) => {
      try {
        const newProfileData = { ...profileData, ...data };
        const response = await updateProfile({
          seeking_topic_ids: newProfileData.seekingTopicIds,
          offering_topic_ids: newProfileData.offeringTopicIds,
          conference_industry_id: newProfileData.industryId,
          person: {
            first_name: newProfileData.firstName,
            last_name: newProfileData.lastName,
            job_title: newProfileData.jobTitle,
            company_name: newProfileData.companyName,
            bio: newProfileData.bio,
            city: newProfileData.city,
            country_id: newProfileData.countryId,
          },
        });
        if (!response) throw new Error();
        setProfileData(newProfileData);
        window.location.href = urlFor('/onboarding/complete');
      } catch (e) {
        // TODO: Handle this in proper way
        console.error(e);
        navigate(OnboardingPath.PublicProfile);
      }
    },
    [navigate, profileData, setProfileData],
  );

  const activeStepIndex = useMemo(() => {
    return (
      {
        [OnboardingPath.Welcome]: -1,
        [OnboardingPath.PublicProfile]: 0,
        [OnboardingPath.Interests]: 1,
      }[location] ?? 0
    );
  }, [location]);

  return (
    <>
      {location !== OnboardingPath.Welcome && (
        <Stepper
          activeStepIndex={activeStepIndex}
          className="onboarding__stepper"
          steps={['Public profile', 'Interests']}
        />
      )}
      <Switch>
        <Route path={OnboardingPath.PublicProfile}>
          <PublicProfileScreen
            disableAvatarUploads={disableAvatarUploads}
            onSubmit={(data): void => {
              setProfileData((existingData) => ({ ...existingData, ...data }));
              navigate(OnboardingPath.Interests);
            }}
          />
        </Route>
        <Route path={OnboardingPath.Interests}>
          <InterestsScreen
            onBackButtonClick={(): void => navigate(OnboardingPath.PublicProfile)}
            onSubmit={onProfileSubmit}
          />
        </Route>
        <Route>
          <WelcomeScreen
            onContinueButtonClick={(): void =>
              navigate(OnboardingPath.PublicProfile, { replace: true })
            }
          />
        </Route>
      </Switch>
    </>
  );
};

type OnboardingProps = {
  apiUrl: string;
  conference: {
    coverPhotoUrl?: string;
    facebookAppKey?: string;
    githubAppKey?: string;
    name: string;
    slug: string;
    twitterAppKey?: string;
  };
  copy: {
    introMessage: string;
  };
  disableAvatarUploads?: boolean;
};

const Onboarding = ({
  apiUrl,
  conference,
  copy,
  disableAvatarUploads,
}: OnboardingProps): ReactElement<OnboardingProps> | null => {
  const apolloClient = init({ uri: apiUrl });

  const { data: me } = useMe();
  const { data: countries } = useCountries();
  const { data: industriesData } = useIndustries();
  const { data: topicsData } = useTopics();

  const industries = industriesData?.map((i) => ({ id: i.id, name: i.industry.name }));
  const topics = topicsData?.map((i) => ({ id: i.id, name: i.topic.name }));

  if (!me) {
    return null;
  }

  return (
    <StrictMode>
      <OnboardingLayout>
        <ApolloProvider client={apolloClient}>
          <BreakpointProvider>
            <OnboardingContextProvider
              conference={conference}
              copy={copy}
              countries={countries || []}
              currentAttendeeId={me.id}
              disableAvatarUploads={disableAvatarUploads}
              industries={industries || []}
              topics={topics || []}
              user={me}
            >
              <Router base={urlFor('/onboarding')}>
                <Routes />
              </Router>
            </OnboardingContextProvider>
          </BreakpointProvider>
        </ApolloProvider>
      </OnboardingLayout>
    </StrictMode>
  );
};

export default Onboarding;
