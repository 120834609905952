import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { uploadAvatar } from '~/lib/avenger/api';

import { desktop } from '~/style-mixins/theme';
import Portrait from '~/components/attendees/Portrait';
import AlertIcon from '~/components/icons/AlertIcon';
import CameraFrontIcon from '~/components/icons/CameraFrontIcon';
import GalleryIcon from '~/components/icons/GalleryIcon';
import { UpdateAttendeeProfileFormData } from '~/types/attendees';

import {
  acceptedTypes,
  checkFilesInvalidExtension,
  checkFilesMaxFileSize,
  MAX_AVATAR_SIZE,
} from './validation';

const AVATAR_INPUT_ID = 'avatar';

type AvatarUploadProps = {
  avatarUrl: string | undefined;
  form: UseFormReturn<UpdateAttendeeProfileFormData>;
  helperText?: ReactNode;
  mode?: 'default' | 'horizontal';
};

const UploadCta = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--c-brand-primary);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 1.5rem;

  box-shadow: var(--c-white) 0 0 0 0.75rem;
`;

const UploadCtaText = styled.p`
  font-size: var(--fs-sm);
  text-align: center;
  color: var(--c-white);
`;

const Instructions = styled.p``;

const UploadCtaIcon = styled(AlertIcon)`
  font-size: var(--is-xl);

  .alert-icon__path {
    fill: var(--c-white);
  }
`;

const ButtonLabelIcon = styled(CameraFrontIcon)`
  width: var(--is-md);
  height: var(--is-md);

  path {
    fill: var(--c-white);
  }
`;

const ButtonLabel = styled.label`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--c-brand-primary);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Note: still uses SCSS classes from other other components
const AvatarUploadBase = styled.div<{ mode?: 'default' | 'horizontal' }>`
  position: relative;
  width: 11rem;
  height: 11rem;

  ${({ mode = 'default' }) =>
    mode === 'horizontal' &&
    css`
      width: auto;
      height: auto;
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      align-items: center;
      position: relative;

      ${desktop(css`
        display: grid;
        grid-template-columns: 10rem auto;
      `)}

      ${Instructions} {
        color: var(--c-light-gray);
        font-size: var(--fs-sm);
        margin-top: 0.25rem;
      }

      ${UploadCta},
      .portrait {
        height: 10rem;
        width: 10rem;
      }

      .btn-as-label {
        gap: 0.5rem;
        appearance: button;
        display: inline-flex;
        align-items: center;
      }

      .form__field {
        margin: 0;
      }
    `}
`;

const AvatarUpload = ({
  avatarUrl,
  form,
  helperText,
  mode = 'default',
}: AvatarUploadProps): ReactElement<AvatarUploadProps> => {
  const updatedFile = useWatch({
    control: form.control,
    name: 'avatar',
  });

  const [previewUrl, setPreviewUrl] = useState(avatarUrl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const doUpload = async (f: File) => {
      setLoading(true);

      try {
        const res = await uploadAvatar(f);
      } catch {
        form.setError('avatar', {
          type: 'custom',
          message: 'Unable to save profile picture. Try again with a different image.',
        });
      }

      setLoading(false);
    };

    if (updatedFile && updatedFile[0]) {
      const file = updatedFile[0];

      // createObjectURL is not a pure function; it stores the
      // file in-memory and creates a local browser storage refrence,
      // which should be considered a side-effect.
      const objectURL = URL.createObjectURL(file);

      setPreviewUrl(objectURL);
      doUpload(file);

      // to clean up memory, we need to revoke our URL, when there no longer used.
      return () => {
        URL.revokeObjectURL(objectURL);
      };
    }

    return () => {
      // No cleanup needed
    };
  }, [updatedFile, form]);

  return (
    <AvatarUploadBase className="form__field" mode={mode}>
      {loading && <div className="form__spinner" />}
      {previewUrl && (
        <Portrait avatarUrl={previewUrl} size={mode === 'horizontal' ? 'expand' : 'profile'} />
      )}
      {!previewUrl && (
        <UploadCta>
          <UploadCtaIcon />
          <UploadCtaText>Select your profile picture.</UploadCtaText>
        </UploadCta>
      )}
      {mode === 'horizontal' && (
        <div>
          {/* its looks like a button but isn't */}
          <label className="btn btn-as-label -secondary" htmlFor={AVATAR_INPUT_ID}>
            <GalleryIcon />
            <span>Choose picture</span>
          </label>
          <Instructions>
            {helperText || <>Min recommended 400&times;400px (Up to {MAX_AVATAR_SIZE}MB)</>}
          </Instructions>
        </div>
      )}
      <div className="form__field">
        <input
          accept={acceptedTypes}
          className="file-input"
          id={AVATAR_INPUT_ID}
          type="file"
          {...form.register(AVATAR_INPUT_ID, {
            required: !avatarUrl,
            validate: {
              invalidExtension: checkFilesInvalidExtension,
              maxFileSize: checkFilesMaxFileSize,
            },
          })}
        />
        {mode === 'default' && (
          <ButtonLabel htmlFor={AVATAR_INPUT_ID}>
            <ButtonLabelIcon />
          </ButtonLabel>
        )}
      </div>
    </AvatarUploadBase>
  );
};

export default AvatarUpload;
