import { ReactElement } from 'react';

import { BreakoutRoom } from '~/components/breakout/types';
import RoomCard from '~/components/breakout/RoomCard';
import RoomList from '~/components/breakout/RoomList';

export type RoomsProps = {
  header: string;
  rooms: BreakoutRoom[];
};

const Rooms = ({ rooms, header }: RoomsProps): ReactElement<RoomsProps> | null => {
  if (!rooms.length) return null;

  return (
    <RoomList header={header}>
      {rooms.map((room) => (
        <RoomCard key={room.id} room={room} />
      ))}
    </RoomList>
  );
};

export default Rooms;
