import {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import Popover from '~/components/popover/Popover';

import Button from '../button/Button';

export type TriggerProps = {
  active: boolean;
};

export type ConfirmationPopoverProps = {
  appendTo?: "parent" | Element | ((ref: Element) => Element);
  cancelLabel?: string;
  children?: ReactNode | ((props: TriggerProps) => ReactNode);
  confirmLabel?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onOpen?: () => void;
  text: string;
};

const ConfirmationPopover = ({
  text,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  onOpen,
  onClose,
  onConfirm,
  children,
  appendTo,
}: ConfirmationPopoverProps): ReactElement<ConfirmationPopoverProps> => {
  const [visible, setVisible] = useState(false);

  const close = useCallback(() => {
    setVisible(false);
    onClose?.();
  }, [onClose]);

  const open = useCallback(() => {
    setVisible(true);
    onOpen?.();
  }, [onOpen]);

  const confirm = useCallback(() => {
    close();
    onConfirm?.();
  }, [close, onConfirm]);

  const trigger = children instanceof Function ? children({ active: visible }) : children;

  return (
    <Popover
      appendTo={appendTo}
      content={
        <>
          <p>{text}</p>
          <Popover.Actions>
            <Button variant="secondary" onClick={close}>
              {cancelLabel}
            </Button>
            <Button onClick={confirm}>{confirmLabel}</Button>
          </Popover.Actions>
        </>
      }
      trigger={undefined}
      visible={visible}
      onClickOutside={close}
    >
      {isValidElement(trigger) && cloneElement(trigger as ReactElement<any>, { onClick: open })}
    </Popover>
  );
};

export default ConfirmationPopover;
