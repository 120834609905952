import { ReactElement } from 'react';

import { useRoom } from '~/lib/rooms';

import AudienceTab from './AudienceTab';
import ChatTab from './ChatTab';
import { Tabs } from './discussionPanelTabsConfig';
import InfoTab from './InfoTab';
import QuestionsTab from './QuestionsTab';

type DiscussionPanelContentProps = {
  activeTab: Tabs;
  attendeeSettingsEnabled?: boolean;
};

const DiscussionPanelContent = ({
  activeTab,
}: DiscussionPanelContentProps): ReactElement<DiscussionPanelContentProps> | null => {
  const { chatConfig, chat, questions } = useRoom();

  const components: Record<Tabs, ReactElement | null> = {
    [Tabs.Audience]: <AudienceTab />,
    [Tabs.Chat]: chat ? (
      <ChatTab channel={chat.channel} messageMaxLength={chatConfig.messageMaxLength} />
    ) : null,
    [Tabs.QA]: questions ? <QuestionsTab /> : null,
    [Tabs.Info]: <InfoTab />,
  };

  return components[activeTab];
};

export default DiscussionPanelContent;
