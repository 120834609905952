import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import BuildingsIcon from '~/components/icons/BuildingsIcon';
import LocationIcon from '~/components/icons/LocationIcon';

const SkeletonProfileHead = (): ReactElement => (
  <article className="profile-head">
    <h1 className="profile-head__name">
      <Skeleton />
    </h1>
    <div className="profile-head__description">
      <Skeleton count={7} />
    </div>
    <div className="profile-head__summary-row">
      <span className="profile-head__summary-item">
        <LocationIcon />
        <Skeleton width={120} />
      </span>
      <span className="profile-head__summary-item">
        <BuildingsIcon />
        <Skeleton width={120} />
      </span>
    </div>
  </article>
);

export default SkeletonProfileHead;
