import clsx from 'clsx';
import { ReactElement, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import AttendeeProfileForm from '~/components/forms/attendee/AttendeeProfileForm';
import { UpdateAttendeeProfileFormData } from '~/types/attendees';
import { EntityType } from '~/types/shared';

import InterestsInput from './InterestsInput';
import { interestInputLength } from './validation';

export type MyProfileFormFieldsProps = {
  countries?: EntityType[];
  form: UseFormReturn<UpdateAttendeeProfileFormData>;
  industries?: EntityType[];
  topics?: EntityType[];
};

const MyProfileFormFields = ({
  form,
  topics = [],
  countries = [],
  industries = [],
}: MyProfileFormFieldsProps): ReactElement => {
  const { formState, control, trigger } = form;
  const disabled = form.formState.isSubmitting || !form.formState.isValid;

  useEffect(() => {
    void trigger();
  }, [trigger]);

  return (
    <div className="profile-page__content">
        <section className="profile-page__section">
          <h2 className="profile-page__form-heading">Profile</h2>
          {formState.errors.avatar && (
            <div className="form__field">
              <div className="form__field-error">{formState.errors.avatar.message}</div>
            </div>
          )}
          <AttendeeProfileForm countries={countries} form={form} industries={industries} />
          <h2 className="profile-page__form-heading">Interests</h2>
          <Controller
            control={control}
            name="seekingTopicIds"
            render={({ field: { name, onChange, value }, fieldState: { error } }): ReactElement => (
              <InterestsInput
                isRequired
                availableTopics={topics}
                error={error}
                label="I want to learn about"
                name={name}
                styling="secondary"
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              validate: interestInputLength,
            }}
          />
          <Controller
            control={control}
            name="offeringTopicIds"
            render={({ field: { name, onChange, value }, fieldState: { error } }): ReactElement => (
              <InterestsInput
                isRequired
                availableTopics={topics}
                error={error}
                label="I can share expertise in"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              validate: interestInputLength,
            }}
          />
        </section>
        <button
          className={clsx('btn', '-md', 'profile-page__save-button', disabled && '-disabled')}
          disabled={disabled}
          type="submit"
        >
          Save
        </button>
      </div>
  );
};

export default MyProfileFormFields;
