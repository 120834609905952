import { ApolloProvider, useQuery } from '@apollo/client';
import QRCode from 'qrcode.react';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

import Container from '~/components/util/Container';
import { init } from '~/lib/apollo';
import { useTitle } from '~/lib/dom';
import { RegistrationInfoDocument } from '~/operations/catalyst';
import { desktop } from '~/style-mixins/theme';

const Title = styled.h2`
  font-weight: 600;
  font-size: var(--fs-xl);
  margin: 1rem 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${desktop(css`
    flex-direction: row;
  `)}
`;

const QRCodeWrapper = styled.div`
  margin: 1rem auto;

  ${desktop(css`
    margin: 0;
  `)}
`;

const Info = styled.div``;

const Label = styled.div`
  font-weight: 600;
`;

const Field = styled.div`
  margin-bottom: 0.5rem;
`;

export const TicketPage = (): ReactElement => {
  useTitle('Ticket');
  const { t } = useTranslation();

  const { data } = useQuery(RegistrationInfoDocument);
  const addons = data?.me?.registrationAddOns || [];

  return (
    <Container>
      <Title>
        {data?.event?.name ? t('ticket.title', { conference: data.event.name }) : <Skeleton />}
      </Title>
      <Section>
        <QRCodeWrapper>
          {data?.me?.registrationCode ? (
            <QRCode size={256} value={data?.me?.registrationCode} />
          ) : (
            <Skeleton height={256} width={256} />
          )}
        </QRCodeWrapper>
        <Info>
          <Field>
            <Label>{t('ticket.register')}</Label>
            {data?.me?.registrationArea || <Skeleton width={128} />}
          </Field>
          <Field>
            <Label>{t('ticket.ticketOwner')}</Label>
            {data?.me?.profile?.name || <Skeleton width={128} />}
          </Field>
          {addons.length > 0 && (
            <Field>
              <Label>{t('ticket.addOns')}</Label>
              <ul>
                {addons.map((addon) => (
                  <li key={addon}>{addon}</li>
                ))}
              </ul>
            </Field>
          )}
        </Info>
      </Section>
    </Container>
  );
};

const TicketPageWrapper = (): ReactElement => {
  const client = useMemo(init, []);

  return (
    <ApolloProvider client={client}>
      <TicketPage />
    </ApolloProvider>
  );
};

export default TicketPageWrapper;
