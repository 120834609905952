import { ReactElement,SVGProps } from 'react';

const ScreenSharingIcon = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M21 5v11h-2v3a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1h3.4V5H21zm-4 4H5v9h12V9zm-6.541 1.287a.78.78 0 011.082 0l2.178 2.177a.75.75 0 11-1.061 1.06l-.908-.907V16a.75.75 0 11-1.5 0v-3.383l-.908.907a.75.75 0 01-1.061-1.06z"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default ScreenSharingIcon;
