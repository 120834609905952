import { ReactElement, SVGProps } from 'react';

type GroupChatProps = SVGProps<SVGSVGElement>;

const HalMingle = (props: GroupChatProps): ReactElement<GroupChatProps> => (
  <svg height={164} width={240} {...props}>
    <defs>
      <path d="M0 .302h208.667v128.09H0z" id="Group_svg__a" />
      <path d="M.551.039h31.664v21.968H.551z" id="Group_svg__c" />
      <path d="M.114.753H7.1V13.68H.114z" id="Group_svg__e" />
      <path d="M.74.005h9.872v10.547H.74z" id="Group_svg__g" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 34.739)">
        <mask fill="#fff" id="Group_svg__b">
          <use xlinkHref="#Group_svg__a" />
        </mask>
        <path
          d="M208.667 128.392H0V9.188C0 4.281 3.98.302 8.888.302h190.89c4.91 0 8.889 3.979 8.889 8.886v119.204z"
          fill="#F7F7F7"
          mask="url(#Group_svg__b)"
        />
      </g>
      <path
        d="M178.223 45.764a3.058 3.058 0 01-3.059 3.058 3.058 3.058 0 110-6.116 3.058 3.058 0 013.059 3.058M188.253 45.764a3.058 3.058 0 11-6.117-.001 3.058 3.058 0 016.117 0M198.282 45.764a3.058 3.058 0 11-6.117-.001 3.058 3.058 0 016.117 0"
        fill="#B7B7B1"
      />
      <path
        d="M207.886 54.083a4.696 4.696 0 014.691-4.69v9.38a4.696 4.696 0 01-4.691-4.69m25.872-14.147h-15.451c-3 0-5.48 2.337-5.711 5.279h-.019c-4.89 0-8.87 3.978-8.87 8.868 0 4.89 3.98 8.87 8.87 8.87v.434c0 3.15 2.578 5.728 5.73 5.728h15.451c3.152 0 5.73-2.578 5.73-5.728V45.664c0-3.15-2.578-5.728-5.73-5.728"
        fill="#47489E"
      />
      <path
        d="M218.163 34.878a.38.38 0 01-.38-.38c0-1.75.614-2.627 1.208-3.475.551-.789 1.072-1.532 1.072-3.041 0-1.51-.52-2.253-1.072-3.041-.594-.85-1.208-1.727-1.208-3.477 0-1.75.614-2.627 1.208-3.476.551-.787 1.072-1.532 1.072-3.04a.38.38 0 11.76 0c0 1.748-.616 2.627-1.209 3.475-.551.788-1.071 1.53-1.071 3.04s.52 2.256 1.071 3.042c.593.85 1.209 1.728 1.209 3.477 0 1.748-.616 2.626-1.209 3.476-.551.786-1.071 1.531-1.071 3.04 0 .21-.17.38-.38.38M225.712 34.878a.38.38 0 01-.38-.38c0-1.75.615-2.627 1.208-3.475.551-.789 1.072-1.532 1.072-3.041 0-1.51-.52-2.253-1.072-3.041-.593-.85-1.208-1.727-1.208-3.477 0-1.75.615-2.627 1.208-3.476.551-.787 1.072-1.532 1.072-3.04a.38.38 0 11.76 0c0 1.748-.615 2.627-1.209 3.475-.551.788-1.072 1.53-1.072 3.04s.52 2.256 1.072 3.042c.594.85 1.209 1.728 1.209 3.477 0 1.748-.615 2.626-1.209 3.476-.551.786-1.072 1.531-1.072 3.04 0 .21-.17.38-.38.38M233.26 34.878a.38.38 0 01-.378-.38c0-1.75.614-2.627 1.207-3.475.551-.789 1.072-1.532 1.072-3.041 0-1.51-.521-2.253-1.072-3.041-.593-.85-1.207-1.727-1.207-3.477 0-1.75.614-2.627 1.207-3.476.551-.787 1.072-1.532 1.072-3.04a.38.38 0 11.76 0c0 1.748-.615 2.627-1.21 3.475-.55.788-1.07 1.53-1.07 3.04s.52 2.256 1.07 3.042c.595.85 1.21 1.728 1.21 3.477 0 1.748-.615 2.626-1.21 3.476-.55.786-1.07 1.531-1.07 3.04 0 .21-.17.38-.38.38"
        fill="#CFCFCE"
      />
      <path
        d="M234.876 41.805c0 .555-3.931 1.005-8.78 1.005-4.85 0-8.78-.45-8.78-1.005 0-.555 3.93-1.006 8.78-1.006 4.849 0 8.78.45 8.78 1.006"
        fill="#6D504E"
      />
      <path
        d="M91.92 32.704l-.341.118a1781.62 1781.62 0 001.94 5.394c1.35-.512 2.743-1.186 3.96-2.075.698-.509.677-1.567-.066-2.008a24.772 24.772 0 01-4.729-3.69l.14.402a1.466 1.466 0 01-.904 1.86"
        fill="#DBAE86"
      />
      <path
        d="M31.558 142.51l.817-20.37c.013-1.184.063-2.352.141-3.509l.025-.604h.024c.487-6.339 1.937-12.243 4.184-17.584l.009-.062.022-.012c2.705-6.399 6.572-11.976 11.336-16.498-12.59 8.468-30.987 25.36-36.783 54.165a125.833 125.833 0 00-2.46 25.107h20.138l2.547-20.633z"
        fill="#028342"
      />
      <path
        d="M87.697 27.339l1.632 4.715a1.469 1.469 0 001.86.904l.39-.135c.677 1.892 1.326 3.697 1.939 5.393v-.001a1786.14 1786.14 0 003.728 10.223l.154.418c1.915 5.194-1.03 10.936-6.38 12.36-1.8.477-3.734.712-5.716.767.018-.233.039-.46.063-.68l.037-.325c-.04.32-.074.656-.1 1.006a25.388 25.388 0 00.05 4.24h-21l-.565-7.84.074-.493.167-1.129c.324-2.266.585-4.586.751-6.93l.045-.716-.045.716a13.09 13.09 0 01-1.242-.504l-.418-.201-.287-3.983 2.124.936c-.006.267-.013.533-.022.8l.022-.8c.032-1.402.02-2.804-.038-4.201l-.052-1.047-.057-.88a48.817 48.817 0 00-.154-1.752l21.8-10.277c.013.745.031 1.51.056 2.278l-.014-.46a164.47 164.47 0 01-.042-1.817l1.24-.585zM83.622 40.37a3.949 3.949 0 10-2.037 5.2 3.947 3.947 0 002.037-5.2zm.975-25.854c.743 1.143 1.4 2.48 1.944 4.044l.65 1.859-.306.106c-.09.03-.172.07-.25.115-.055-.167-.117-.325-.17-.496 0 0-.006.225-.011.625a1.462 1.462 0 00-.472 1.616l.443 1.284c-.002 1.233.004 2.7.032 4.254L64.657 38.2c-.332-3.097-.935-6.135-1.898-9.037l.608-.499A70.39 70.39 0 0082.67 16.438c.673-.6 1.344-1.23 1.926-1.922z"
        fill="#E9C29A"
      />
      <path
        d="M86.426 23.67c-.003 1.231.003 2.7.03 4.254l1.24-.585-1.27-3.67zM86.465 20.144l-.012.625c.058-.046.117-.092.182-.129-.055-.167-.117-.325-.17-.496"
        fill="#E9C29A"
      />
      <path
        d="M78.42 38.333a3.95 3.95 0 103.164 7.237 3.947 3.947 0 002.038-5.2 3.951 3.951 0 00-5.203-2.037"
        fill="#DBAE86"
      />
      <path
        d="M87.475 69.992c1.323 0 2.461.871 2.84 2.103l.013.048-.013-.048a14.446 14.446 0 015.248.37c8.03 2.091 15.972 5.47 22.525 10.818 12.379 10.107 28.196 24.128 39.939 35.566l.895.874c9.825 9.635 15.608 16.297 15.499 18.867-.047 1.095-.657 3.305-1.843 5.807 2.817 8.432 2.625 17.77-11.744 18.204a3.743 3.743 0 01-.738-.052l-.242-.048-.233-.053c-1.886-.296-3.955-.987-6.24-2.21-5.425-2.902-12.722-7.93-20.242-13.509l.917 14.322.134 2.092H28.01l2.547-20.633.817-20.37c.01-.888.04-1.767.088-2.639l.053-.87.025-.604h.024c.466-6.063 1.812-11.73 3.895-16.885l.289-.7.009-.062.022-.01c2.705-6.4 6.572-11.977 11.336-16.499a45.858 45.858 0 0112.51-8.46l.662-.298.258-2.453a2.966 2.966 0 012.6-2.635l.179-.016.09-.01.092-.008h23.968z"
        fill="#00904B"
      />
      <path
        d="M85.554 67.835c.102.692.235 1.412.397 2.156h1.02v-3.767h-1.616c.014.146.02.283.036.432.042.38.103.782.163 1.18M64.626 69.991l-.272-3.767h-1.03v3.784c.061-.003.12-.017.183-.017h1.119z"
        fill="#FFF"
      />
      <path
        d="M85.952 69.991a32.413 32.413 0 01-.398-2.156c-.06-.397-.122-.799-.162-1.18-.017-.147-.024-.285-.037-.43H64.354l.272 3.766h21.326z"
        fill="#CCC"
      />
      <path
        d="M60.396 40.209h-.002s-.223.155-.511.442c.17-.156.343-.306.511-.441l.002-.001"
        fill="#F05A2C"
      />
      <path
        d="M84.307 2.67C83.831 1.625 83.235.466 82.159.124c-.604-.194-1.248-.082-1.863.032-3.305.609-7.146 1.694-8.198 4.936-1.756-3.14-6.241-2.615-9.572-1.644-4.284 1.25-9.101 2.977-10.675 7.22-.308.834-.511 1.786-1.21 2.312-.585.439-1.372.46-2.105.515-3.455.257-6.929 1.768-8.835 4.668-1.908 2.9-1.805 7.288.76 9.762-1.813.76-3.039 2.73-2.96 4.751.08 2.023 1.456 3.972 3.326 4.704 1.55.607 3.347.436 4.768 1.326.903.564 1.528 1.484 2.149 2.362 4.68 6.616 9.274 12.988 16.044 17.318l-.038-.534.038.534c.027-.164.05-.33.075-.494.401-2.622.725-5.325.918-8.06a13.405 13.405 0 01-1.66-.705c-1.506-.756-2.97-1.805-3.88-2.93-1.93-2.388-.71-4.295.642-5.544.288-.288.511-.443.511-.443h.002a2.09 2.09 0 01.85-.4c1.015-.24 2.33.143 3.621 1.023-.05-.88-.116-1.759-.21-2.632-.332-3.096-.936-6.135-1.897-9.037l.607-.499a70.401 70.401 0 0019.304-12.226c.673-.6 1.344-1.228 1.925-1.922.57-.68 1.055-1.423 1.368-2.263 1.137-3.072-.272-6.535-1.657-9.582"
        fill="#FFE9BD"
      />
      <path d="M60.396 40.209l.85-.4a2.09 2.09 0 00-.85.4" fill="#E9C29A" />
      <path
        d="M92.685 30.443l-3.209-9.268a1.468 1.468 0 00-1.86-.903l-.426.147c.674 1.927 1.335 3.803 1.976 5.619 1.1 1.75 2.32 3.214 3.519 4.405"
        fill="#0D2833"
      />
      <path
        d="M89.167 26.038c.837 2.37 1.643 4.634 2.412 6.784l.34-.118c.761-.262 1.168-1.1.904-1.86l-.138-.401a24.822 24.822 0 01-3.518-4.405M86.635 20.64a24.6 24.6 0 002.532 5.398 1756.61 1756.61 0 01-1.977-5.619l-.305.105a1.48 1.48 0 00-.25.116M85.982 22.385l.444 1.284c.004-1.27.017-2.29.027-2.9a1.462 1.462 0 00-.471 1.616"
        fill="#0D2833"
      />
      <path
        d="M87.696 27.339l1.633 4.715c.264.76 1.101 1.166 1.86.903l.39-.134c-.768-2.15-1.576-4.416-2.413-6.785a24.562 24.562 0 01-2.53-5.398c-.067.038-.125.084-.183.129-.01.61-.023 1.63-.027 2.9l1.27 3.67"
        fill="#0D2833"
      />
      <path
        d="M64.958 46.08a64.53 64.53 0 00-.09-5.248c-1.292-.88-2.607-1.263-3.621-1.024l-.851.4-.001.001c-.17.136-.341.285-.512.442-.93.928-2.533 3.246.512 6.288-3.045-3.042-1.442-5.36-.512-6.288-1.352 1.25-2.572 3.156-.642 5.545.91 1.125 2.374 2.174 3.88 2.93l-.287-3.982 2.124.936zM210.887 50.491c-8.046 4.982-15.695 15.259-17.02 18.424l-.044-.014-15.928 29.062 20.964 10.24c7.263-18.182 13.268-34.001 13.268-34.001l-.012-.004c.116-.133.232-.265.323-.421 1.484-2.532 1.783-9.408 2.773-12.31.802-2.35.861-6.248-1.076-8.952-.866-1.209-1.985-2.807-3.248-2.024"
        fill="#E9C29A"
      />
      <path
        d="M178.306 155.565l.026-.078c4.88-9.068 13.224-29.12 20.457-47.19l9.704-24.22-19.198-7.314-10.932 19.947-15.408 28.115-6.331 11.167s-15.582 31.065 12.877 26.738c4.02-.612 7.48-3.32 8.805-7.165"
        fill="#00904B"
      />
      <path
        d="M198.624 70.302c.511-1.195-.142-8.148.842-9.424.126-.165 2.8-3.196 2.987-3.287 2.192-1.078 18.404-4.292 27.666-2.381 1.49.307 2.464 1.728 2.258 3.235-.463 3.373-1.566 9.09-3.816 10.275-2.779 1.46-13.173 4.526-16.059 5.364a2.82 2.82 0 01-1.422.043c-2.755-.618-12.95-2.665-12.456-3.825"
        fill="#E9C29A"
      />
      <g>
        <g transform="translate(57 25.444)">
          <mask fill="#fff" id="Group_svg__d">
            <use xlinkHref="#Group_svg__c" />
          </mask>
          <path
            d="M2.632 22.007C1.118 20.485.424 18.903.57 17.313c.208-2.296 2.127-3.65 2.21-3.708l.146-.085L31.321.039l.894 1.91L3.917 15.385c-.233.187-1.163.994-1.26 2.136-.077.934.413 1.94 1.458 2.994l-1.483 1.493z"
            fill="#0D2833"
            mask="url(#Group_svg__d)"
          />
        </g>
        <g transform="translate(85.8 19)">
          <mask fill="#fff" id="Group_svg__f">
            <use xlinkHref="#Group_svg__e" />
          </mask>
          <path
            d="M6.088 13.369l-.654.228a1.505 1.505 0 01-1.913-.938L.196 2.991a1.521 1.521 0 01.93-1.927l.653-.229a1.506 1.506 0 011.914.937l3.325 9.67a1.523 1.523 0 01-.93 1.927"
            fill="#0D2833"
            mask="url(#Group_svg__f)"
          />
        </g>
      </g>
      <g transform="translate(58 40)">
        <mask fill="#fff" id="Group_svg__h">
          <use xlinkHref="#Group_svg__g" />
        </mask>
        <path
          d="M9.17 3.543c1.761 2.77 1.933 5.815.38 6.802-1.55.988-6.346-1.733-8.11-4.503-1.762-2.77.177-4.54 1.729-5.527 1.55-.988 4.238.457 6 3.228"
          fill="#E9C29A"
          mask="url(#Group_svg__h)"
        />
      </g>
    </g>
  </svg>
);

export default HalMingle;
