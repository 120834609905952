import { ReactElement, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ConnectionState, useAttendeeConnection } from '~/lib/connections';
import AttendeeContext from '~/lib/attendees/AttendeeContext';

import ConnectedButton from './ConnectedButton';
import NotConnectedButton from './NotConnectedButton';
import RequestReceivedButton from './RequestReceivedButton';
import RequestSentButton from './RequestSentButton';

export type AttendeeConnectionButtonProps = {
  attendee: Attendee;
  isDisabled?: boolean;
};

type Attendee = {
  id: string;
  name: string;
};

/**
 * @deprecated use `AttendeeConnectionButton`
 */
const OldAttendeeConnectionButton = ({
  attendee: { id },
  isDisabled,
}: AttendeeConnectionButtonProps): ReactElement => {
  const { state, connect } = useAttendeeConnection(id);
  const { setShowAttendeeConnectModal } = useContext(AttendeeContext);

  const handleNotConnected = (): void => setShowAttendeeConnectModal(true);

  switch (state) {
    case ConnectionState.NotConnected:
      return <NotConnectedButton isDisabled={isDisabled} onClick={handleNotConnected} />;
    case ConnectionState.RequestReceived:
      return <RequestReceivedButton onClick={connect} />;
    case ConnectionState.RequestSent:
      return <RequestSentButton />;
    case ConnectionState.Connected:
      return <ConnectedButton />;
    default:
      return <Skeleton />;
  }
};

export default OldAttendeeConnectionButton;
