import uniqBy from 'lodash/uniqBy';
import { createSession, preloadScript, SessionHelper } from 'opentok-react';
import {
  Error as OTError,
  Session,
  SessionConnectEvent,
  SessionDisconnectEvent,
  SessionEventHandlers,
  SessionReconnectEvent,
  SessionReconnectingEvent,
  Stream,
  StreamCreatedEvent,
  StreamDestroyedEvent,
  StreamPropertyChangedEvent,
} from 'opentok-react/types/opentok';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import replaceArrayItem from '~/lib/replaceArrayItem';

import { EventStreamContext } from './EventStreamContext';
import OpentokErrorModal from './OpentokErrorModal';

type OpenTokSessionProps = {
  apiKey: string;
  children: ReactNode;
  fallback?: ReactNode;
  initialToken: string;
  onError?: (error: { name: string }, origin: string) => void;
  refreshAccess?: () => Promise<{ opentok: { token: string } } | null>;
  sessionId: string;
};

export const OpenTokContext = createContext<{
  activeStreams: Stream[];
  connection?: string;
  isConnected: boolean;
  publishError?: OTError;
  screenShareError?: OTError;
  session?: Session;
  setPublishError: (e: OTError) => void;
  setScreenShareError: (e: OTError) => void;
  setSubscribeError: (e: OTError) => void;
  streams: Stream[];
  streamsByParticipantId: Record<string, Stream>;
  subscribeError?: OTError;
}>({
  activeStreams: [],
  connection: undefined,
  isConnected: false,
  session: undefined,
  setPublishError: () => {
    // Default no op
  },
  setScreenShareError: () => {
    // Default no op
  },
  setSubscribeError: () => {
    // Default no op
  },
  streams: [],
  streamsByParticipantId: {},
});
export const isActiveStream = (stream: Stream): boolean =>
  stream.hasAudio === true || stream.hasVideo === true;

export const isCameraStream = (stream: Stream): boolean => stream.videoType === 'camera';

const USER_KICKED_OUT = 'forceDisconnected';
const CONNECTED = 'Connected';

export const participantFromStream = (stream: Stream): { id: string } =>
  JSON.parse(stream?.connection?.data);

const OpenTokSession = ({
  apiKey,
  initialToken,
  refreshAccess,
  sessionId,
  children,
  fallback = null,
  onError,
}: OpenTokSessionProps): ReactElement<OpenTokSessionProps> | null => {
  const [connection, setConnection] = useState('Connecting');
  const [sessionHelper, setSessionHelper] = useState<SessionHelper | null>(null);
  const { onEvent } = useContext(EventStreamContext);
  const [streams, setStreams] = useState<Stream[]>([]);

  const [token, setToken] = useState(initialToken);

  const refreshToken = useCallback(async () => {
    if (!refreshAccess) return;

    const response = await refreshAccess();
    if (!response) return;

    const {
      opentok: { token: t },
    } = response;
    setToken(t);
  }, [refreshAccess]);

  const sessionEventHandlers = useMemo(
    (): SessionEventHandlers => ({
      connectionCreated: onEvent,
      connectionDestroyed: onEvent,
      sessionConnected: (event: SessionConnectEvent) => {
        onEvent(event);
        setConnection(CONNECTED);
      },
      sessionDisconnected: (event: SessionDisconnectEvent) => {
        onEvent(event);
        if (event.reason === USER_KICKED_OUT) {
          window.history.back();
        }
        setConnection('Disconnected');
      },
      sessionReconnected: (event: SessionReconnectEvent) => {
        onEvent(event);
        setConnection(CONNECTED);
      },
      sessionReconnecting: (event: SessionReconnectingEvent) => {
        onEvent(event);
        setConnection('Connecting');
      },
      signal: onEvent,
      streamCreated: (event: StreamCreatedEvent) => {
        onEvent(event);
        setStreams((prevStreams) => uniqBy([...prevStreams, event.stream], 'streamId'));
      },
      streamDestroyed: (event: StreamDestroyedEvent) => {
        onEvent(event);
        setStreams((ss) => ss.filter((s: Stream) => s.streamId !== event.stream.streamId));
      },
      streamPropertyChanged: (event: StreamPropertyChangedEvent) => {
        onEvent(event);
        setStreams((prevStreams: Stream[]) =>
          replaceArrayItem(
            prevStreams,
            (stream: Stream) => event.stream.streamId === stream.streamId,
            event.stream,
          ),
        );
      },
    }),
    [onEvent],
  );

  const updateStreams = (ss: Stream[]) => {
    setStreams(ss);
  };

  useEffect(() => {
    if (!(apiKey && sessionId && token)) return () => {
      // No cleanup needed
    };

    const helper = createSession({
      apiKey,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: onConnect in actual JS but not the typings
      onConnect() {
        onEvent({ type: 'connectSuccess' });
      },
      onError(error: OTError) {
        window.newrelic?.noticeError(
          new Error(`Opentok: Session: ${error.code}: ${error.name}`),
          error,
        );
        if (error.name === 'OT_AUTHENTICATION_ERROR' && refreshToken) {
          void refreshToken();
        }
        onError?.(error, 'session');
      },
      onStreamsUpdated: updateStreams,
      // we aren't handling connectionCreated and connectionDestroyed events
      options: { connectionEventsSuppressed: true },
      sessionId,
      token,
    });
    helper.session.on(sessionEventHandlers);
    setSessionHelper(helper);

    return () => {
      helper.session.off(sessionEventHandlers);
      helper.disconnect();
    };
  }, [apiKey, sessionId, token, onEvent, onError, refreshToken, sessionEventHandlers]);

  const streamsByParticipantId = useMemo(
    () =>
      streams
        ? streams.reduce<Record<string, Stream>>((acc, stream) => {
            const { id } = participantFromStream(stream);
            acc[id] = stream;
            return acc;
          }, {})
        : {},
    [streams],
  );

  const activeStreams = useMemo(() => streams.filter(isActiveStream), [streams]);
  const [publishError, setPublishError] = useState<OTError>();
  const [subscribeError, setSubscribeError] = useState<OTError>();
  const [screenShareError, setScreenShareError] = useState<OTError>();

  useEffect(() => {
    if (publishError) {
      window.newrelic?.noticeError(
        new Error(`Opentok: Publish: ${publishError.code}: ${publishError.name}`),
        publishError,
      );
    }
  }, [publishError]);

  useEffect(() => {
    if (screenShareError) {
      window.newrelic?.noticeError(
        new Error(`Opentok: Publish: ${screenShareError.code}: ${screenShareError.name}`),
        screenShareError,
      );
    }
  }, [screenShareError]);

  useEffect(() => {
    if (subscribeError) {
      window.newrelic?.noticeError(
        new Error(`Opentok: Subscribe: ${subscribeError.code}: ${subscribeError.name}`),
        subscribeError,
      );
    }
  }, [subscribeError]);

  const openTokValue = useMemo(
    () => ({
      activeStreams,
      connection,
      isConnected: connection === CONNECTED,
      publishError,
      screenShareError,
      session: sessionHelper?.session,
      setPublishError,
      setScreenShareError,
      setSubscribeError,
      streams,
      streamsByParticipantId,
      subscribeError,
    }),
    [
      activeStreams,
      connection,
      publishError,
      screenShareError,
      sessionHelper?.session,
      streams,
      streamsByParticipantId,
      subscribeError
    ],
  );

  return sessionHelper ? (
    <OpenTokContext.Provider value={openTokValue}>
      {publishError && <OpentokErrorModal error={publishError} />}
      {screenShareError && <OpentokErrorModal error={screenShareError} />}
      {children}
    </OpenTokContext.Provider>
  ) : (
    <>{fallback}</>
  );
};

export default preloadScript(OpenTokSession);
