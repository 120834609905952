import { ReactElement, useCallback } from 'react';
import styled from 'styled-components';

import { usePublisher } from '~/lib/rooms';
import { useRoomConfig } from '~/lib/rooms/config';
import ConfirmationButton from '~/components/shared/molecules/ConfirmationButton';
import PlayIcon from '~/components/icons/PlayIcon';
import StreamingOffIcon from '~/components/icons/StreamingOffIcon';
import StreamingOnIcon from '~/components/icons/StreamingOnIcon';
import { useModeration } from '~/lib/rooms/moderation';
import { Mode } from '~/types/rooms-moderation';

import CountdownAwareConfirmationButton from './CountdownAwareConfirmationButton';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RoomModerationActions = (): ReactElement => {
  const { countdown } = usePublisher();
  const { mode, changeMode, changeModeWithDelay } = useModeration();

  const { contentLiveStream, videoCall } = useRoomConfig();

  const startContentStreaming = useCallback(() => {
    changeMode(Mode.content);
  }, [changeMode]);

  const stopStreaming = useCallback(() => {
    changeMode(Mode.offline);
  }, [changeMode]);

  const startDelayedBroadcasting = useCallback(() => {
    changeModeWithDelay(3000, Mode.live);
  }, [changeModeWithDelay]);

  const isCountdownActive = countdown !== null;

  return (
    <Actions>
      {mode === Mode.content && (
        <ConfirmationButton
            confirm="End"
            confirmation="Are you sure you want to end pre-recorded content?"
            disabled={isCountdownActive}
            icon={<StreamingOffIcon />}
            styling="danger"
            text="End pre-recorded content"
            onClick={stopStreaming}
          />
      )}
      {(mode === Mode.offline || mode === Mode.live) && Boolean(contentLiveStream) && (
        <ConfirmationButton
          confirm="Start"
          confirmation="Are you sure you want to start pre-recorded content?"
          disabled={isCountdownActive}
          icon={<PlayIcon />}
          styling="safe"
          text="Start pre-recorded content"
          onClick={startContentStreaming}
        />
      )}
      {(mode === Mode.offline || mode === Mode.content) && Boolean(videoCall) && (
        <CountdownAwareConfirmationButton
          confirm="Start"
          confirmation="Are you sure you want to start live session?"
          icon={<StreamingOnIcon />}
          isCountdownActive={isCountdownActive}
          styling="safe"
          text="Start live session"
          onClick={startDelayedBroadcasting}
        />
      )}
      {mode === Mode.live && (
        <ConfirmationButton
          confirm="End"
          confirmation="Are you sure you want to end live session?"
          disabled={isCountdownActive}
          icon={<StreamingOffIcon />}
          styling="danger"
          text="End live session"
          onClick={stopStreaming}
        />
      )}
    </Actions>
  );
};

export default RoomModerationActions;
