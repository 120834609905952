import urlFor from './lib/urlFor';
import config from './lib/config';
import currentSlug from './lib/currentSlug';

export const API_ENDPOINTS = {
  adminAttendee: (id: string): string => `${urlFor(`/api/attendees/${id}`)}`,
  adminAttendees: (): string => `${urlFor(`/api/attendees`)}`,
  blockAttendee: (id: string): string => `${urlFor(`/attendees/${id}/block`)}`,
  claimPerk: (exhibitorId: string, perkId: string): string =>
    `${urlFor(`/exhibitors/${exhibitorId}/perks/${perkId}/claim`)}`,
  joinMinglePool: (): string => `${urlFor('/mingle/join.json')}`,
  leaveMinglePool: (): string => `${urlFor('/mingle/leave.json')}`,
  mingleClock: (): string => `${urlFor('/mingle/clock.json')}`,
  mingleHistory: (): string => `${urlFor('/mingle/mingles.json')}`,
  myProfile: (): string => `${urlFor('/my/profile')}`,
  notificationsConfig: (): string => `${urlFor('/api/configs/notifications')}`,
  session: (): string => `${urlFor('/session')}`,
};

export const CALENDAR_API_ENDPOINTS = {
  calendar: (): string => `${config.calendarUrl}/calendar_events/upcoming`,
  pin: (id: string): string => `${config.calendarUrl}/calendar_events/${id}/pin`,
  pins: (): string => `${config.calendarUrl}/pins`,
};

export const RSVP_API_ENDPOINTS = {
  perks: (): string => `${config.rsvpUrl}/v1/perks`,
  perksByExhibitor: (id: string): string => `${config.rsvpUrl}/v1/events_by_appearance/${id}`,
};

export const AVENGER_API_ENDPOINTS = {
  me: {
    avatar: (): string => `${config.avengerUrl}/conferences/${currentSlug()}/me/avatar`,
    chatToken: (): string => `${config.avengerUrl}/conferences/${currentSlug()}/me/chat_token`,
    index: (): string => `${config.avengerUrl}/conferences/${currentSlug()}/me`,
    socialAuthentications: (id: string): string =>
      `${config.avengerUrl}/conferences/${currentSlug()}/me/social_authentications/${id}`,
  },
};
