import Linkify from 'linkify-react';
import { ReactElement } from 'react';

type LinkifyTextProps = {
  text: string;
};

const LinkifyText = ({ text }: LinkifyTextProps): ReactElement<LinkifyTextProps> => (
  <Linkify>{text}</Linkify>
);

export default LinkifyText;
