import { ReactElement } from 'react';

import OptionItem from './OptionItem';
import OptionsLayout, { OptionsLayoutProps } from './OptionsLayout';

type Option = string | OptionShape;

type OptionShape = {
  id: string;
  name: string;
};
const isOptionShaped = (option: Option): option is OptionShape =>
  typeof option === 'object' && option.id !== undefined && option.name !== undefined;

const defaultGetOptionId = (option: Option): string => {
  if (isOptionShaped(option)) {
    return option.id;
  }
  return option;
};

const defaultGetOptionLabel = (option: Option): string => {
  if (isOptionShaped(option)) {
    return option.name;
  }
  return option;
};

export type OptionsBlockProps<TOption = OptionShape> = {
  availableOptions: TOption[];
  getOptionId?: (option: TOption) => string;
  getOptionLabel?: (option: TOption) => string;
  id?: string;
  selectedOptions: string[];
  styling?: OptionsLayoutProps['styling'];
  toggle?: (value: string) => void;
};

const OptionsBlock = <TOption extends Option = OptionShape>({
  id,
  availableOptions,
  selectedOptions,
  toggle,
  styling,
  getOptionId = defaultGetOptionId,
  getOptionLabel = defaultGetOptionLabel,
}: OptionsBlockProps<TOption>): ReactElement<OptionsBlockProps<TOption>> => (
  <OptionsLayout id={id} styling={styling}>
    {availableOptions.map((option) => {
      const optionId = getOptionId(option);
      return (
        <OptionItem
          key={`option_${optionId}`}
          isSelected={selectedOptions.includes(optionId)}
          onClick={toggle ? (): void => toggle(optionId) : undefined}
        >
          {getOptionLabel(option)}
        </OptionItem>
      );
    })}
  </OptionsLayout>
);

export default OptionsBlock;
