import clsx from 'clsx';
import { ReactElement } from 'react';

import { usePin } from '~/lib/calendar/pins';
import PinTimeslotIcon from '~/components/icons/PinTimeslotIcon';

export type PinnableProps = {
  calendarEventId: string;
  className?: string;
};

const Pinnable = ({ calendarEventId, className }: PinnableProps): ReactElement<PinnableProps> => {
  const [pinned, toggle] = usePin(calendarEventId);

  return (
    <button
      className={clsx(className, 'pin', pinned ? '-on' : '-off')}
      type="button"
      onClick={toggle}
    >
      <PinTimeslotIcon isOn={pinned} />
    </button>
  );
};

export default Pinnable;
