import { DateTime } from 'luxon';
import { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo } from 'react';

import usePubnubMessages from '~/components/realtime/usePubnubMessages';
import { PubnubMessage } from '~/types/pubnub';
import { Participant } from '~/types/rooms';

import { useCurrentUser } from '../user';
import usePermissions from './usePermissions';
import useRoom from './useRoom';

export type Question = {
  answered: boolean;
  askedAt: DateTime;
  askedByMe: boolean;
  author: Participant & {
    uuid: string;
  };
  body: string;
  favourite: boolean;
  hidden: boolean;
  id: string;
};

export type Questions = {
  canModerateQuestions: boolean;
  clearHistory: () => void;
  currentQuestion: Question | null;
  hasMore: boolean;
  loadMore: () => void;
  markAnswered: (question: Question) => void;
  markFavourite: (question: Question) => void;
  markHidden: (question: Question) => void;
  markUnanswered: (question: Question) => void;
  markUnfavourite: (question: Question) => void;
  markUnhidden: (question: Question) => void;
  questions: Question[];
  setCurrentQuestion: (question: Question | null) => void;
  submitQuestion: (body: string) => void;
};

export const defaultContext: Questions = {
  canModerateQuestions: false,
  clearHistory() {
    // Default no op
  },
  currentQuestion: null,
  hasMore: false,
  loadMore() {
    // Default no op
  },
  markAnswered() {
    // Default no op
  },
  markFavourite() {
    // Default no op
  },
  markHidden() {
    // Default no op
  },
  markUnanswered() {
    // Default no op
  },
  markUnfavourite() {
    // Default no op
  },
  markUnhidden() {
    // Default no op
  },
  questions: [],
  setCurrentQuestion() {
    // Default no op
  },
  submitQuestion() {
    // Default no op
  },
};

export const QuestionsContext = createContext<Questions>(defaultContext);

const findMessageAction = (message: PubnubMessage, type: string, value: string) => {
  const values = message.actions?.[type] || {};
  const actions = values[value] || [];

  if (actions.length < 1) {
    return undefined;
  }

  return actions[0];
};

const hasMessageAction = (message: PubnubMessage, type: string, value: string) => {
  return !!findMessageAction(message, type, value);
};

export type QuestionsProviderProps = {
  children: ReactNode;
};

export const QuestionsProvider = ({
  children,
}: QuestionsProviderProps): ReactElement<QuestionsProviderProps> => {
  const user = useCurrentUser();
  const { isModerator } = usePermissions();
  const { questions: config } = useRoom();
  const channel = config?.channel;

  // The questions channel is the stream of questions submitted by the audience.
  const {
    messages,
    historyLoaded,
    sendMessage,
    fetchMessages,
    addMessageAction,
    removeMessageAction,
    clearHistory,
  } = usePubnubMessages<QuestionMessage>({
    channel,
    includeMessageActions: true,
    messagePerPage: 100,
    user,
  });

  // The current questions channel is a stream of current question messages. Moderators
  // publish to this channels to display a question on screen.
  const {
    messages: currentQuestionMessages,
    sendMessage: sendCurrentQuestionMessage,
    fetchMessages: fetchCurrentQuestionMessages,
  } = usePubnubMessages<CurrentQuestionMessage>({
    channel: channel && `${channel}-current`,
    user: undefined,
  });

  useEffect(() => fetchCurrentQuestionMessages(), [fetchCurrentQuestionMessages]);

  const questions: Question[] = useMemo(
    () =>
      messages
        .map((m) => {
          try {
            return {
              answered: hasMessageAction(m, 'state', 'answered'),
              askedAt: DateTime.fromISO(m.sent_at),
              askedByMe: m.publisher.id === user.id,
              author: m.publisher,
              body: (m.data as { body: string }).body,
              favourite: hasMessageAction(m, 'state', 'favourite'),
              hidden: hasMessageAction(m, 'state', 'hidden'),
              id: m.timetoken,
            };
          } catch (err) {
            if (err instanceof Error) {
              window.newrelic?.noticeError(err);
            }
            return null;
          }
        })
        .filter((m): m is Question => m !== null),
    [messages, user],
  );

  // The current question can be determined inspecting the *last* message on the current channel.
  // It will contain a question or null.
  const currentQuestion = useMemo(
    () =>
      currentQuestionMessages.reduce((previous, m) => {
        if (!m.question) {
          return null;
        }
        return {
          ...m.question,
          askedAt: DateTime.fromISO(m.question.askedAt),
        };
      }, null as Question | null),
    [currentQuestionMessages],
  );

  const submitQuestion = useMemo(() => {
    return (body: string) => {
      sendMessage({
        data: {
          body,
        },
      });
    };
  }, [sendMessage]);

  const setCurrentQuestion = useMemo(() => {
    return (question: Question | null) => {
      sendCurrentQuestionMessage({
        question,
      });
    };
  }, [sendCurrentQuestionMessage]);

  const removeState = useMemo(() => {
    return (question: Question, state: string) => {
      // Pubnub API requires an action timetoken in order to remove a message action.
      // We don't keep track of action timetokens in the Question, so look for it
      // in the original message.
      const message = messages.find((m) => m.timetoken === question.id);
      if (!message) {
        return;
      }

      const action = findMessageAction(message, 'state', state);
      if (!action) {
        return;
      }

      removeMessageAction({
        actionTimetoken: action.actionTimetoken,
        messageTimetoken: question.id,
      });
    };
  }, [messages, removeMessageAction]);

  const markAnswered = useMemo(() => {
    return (question: Question) => {
      if (question.answered) {
        return;
      }

      addMessageAction({
        action: {
          type: 'state',
          value: 'answered',
        },
        messageTimetoken: question.id,
      });
    };
  }, [addMessageAction]);

  const markUnanswered = useMemo(() => {
    return (question: Question) => {
      if (!question.answered) {
        return;
      }

      removeState(question, 'answered');
    };
  }, [removeState]);

  const markHidden = useMemo(() => {
    return (question: Question) => {
      if (question.hidden) {
        return;
      }

      addMessageAction({
        action: {
          type: 'state',
          value: 'hidden',
        },
        messageTimetoken: question.id,
      });
    };
  }, [addMessageAction]);

  const markUnhidden = useMemo(() => {
    return (question: Question) => {
      if (!question.hidden) {
        return;
      }

      removeState(question, 'hidden');
    };
  }, [removeState]);

  const markFavourite = useMemo(() => {
    return (question: Question) => {
      if (question.favourite) {
        return;
      }

      addMessageAction({
        action: {
          type: 'state',
          value: 'favourite',
        },
        messageTimetoken: question.id,
      });
    };
  }, [addMessageAction]);

  const markUnfavourite = useMemo(() => {
    return (question: Question) => {
      if (!question.favourite) {
        return;
      }

      removeState(question, 'favourite');
    };
  }, [removeState]);

  const loadMore = useMemo(() => () => fetchMessages(), [fetchMessages]);

  const questionsContextValue = useMemo(
    () => ({
      canModerateQuestions: isModerator,
      clearHistory,
      currentQuestion,
      hasMore: !historyLoaded,
      loadMore,
      markAnswered,
      markFavourite,
      markHidden,
      markUnanswered,
      markUnfavourite,
      markUnhidden,
      questions,
      setCurrentQuestion,
      submitQuestion,
    }),
    [
      clearHistory,
      currentQuestion,
      historyLoaded,
      isModerator,
      loadMore,
      markAnswered,
      markFavourite,
      markHidden,
      markUnanswered,
      markUnfavourite,
      markUnhidden,
      questions,
      setCurrentQuestion,
      submitQuestion,
    ],
  );

  return (
    <QuestionsContext.Provider
      value={questionsContextValue}
    >
      {children}
    </QuestionsContext.Provider>
  );
};

type QuestionMessage = PubnubMessage & {
  publisher: Participant;
};

type CurrentQuestionMessage = PubnubMessage & {
  question: SerializedQuestion | null;
};

type SerializedQuestion = Question & {
  askedAt: string;
};

export const useQuestions = (): Questions => {
  return useContext(QuestionsContext);
};
