import { ReactElement, useCallback } from 'react';

import { useTracking } from '~/lib/analytics';
import { useQuestions } from '~/lib/rooms/questions';

import RoomTextForm from '../RoomTextForm';

const QuestionsForm = (): ReactElement => {
  const { submitQuestion } = useQuestions();
  const track = useTracking();

  const submit = useCallback(
    (text: string) => {
      track('Submitted question');
      submitQuestion(text);
    },
    [track, submitQuestion],
  );

  return <RoomTextForm placeholder="Ask a question" onSubmit={submit} />;
};

export default QuestionsForm;
