import clsx from 'clsx';
import { ReactElement, ReactNode,Ref } from 'react';

import AudioIcon from '~/components/icons/AudioIcon';
import HandIcon from '~/components/icons/HandIcon';

import AudioLevelIndicator from './room_elements/AudioLevelIndicator';
import VideoCover from './room_elements/VideoCover';

type VideoStreamProps = {
  avatarUrl?: string;
  children?: ReactNode;
  className?: string;
  errorMessage?: string;
  hasConnectionIssues?: boolean;
  isPublishingAudio?: boolean;
  isPublishingVideo?: boolean;
  isRaisingHand?: boolean;
  isScreenSharingStream?: boolean;
  name?: string;
  volumeLevel?: number;
  wrapperRef?: Ref<HTMLDivElement>;
};

const VideoStream = ({
  className,
  isPublishingVideo = false,
  isPublishingAudio = false,
  isRaisingHand = false,
  volumeLevel = 0,
  children,
  isScreenSharingStream = false,
  avatarUrl,
  name = '',
  errorMessage = '',
  wrapperRef,
  hasConnectionIssues = false,
}: VideoStreamProps): ReactElement<VideoStreamProps> => {
  const isCoverVisible = (!isPublishingVideo && !isScreenSharingStream) || hasConnectionIssues;
  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'stage__stream',
        { '-publishing-video': isPublishingVideo },
        { '-screensharing': isScreenSharingStream },
        className,
      )}
    >
      {errorMessage && <div className="stage__error">{errorMessage}</div>}
      <div className={clsx('stage__video', volumeLevel > 0.1 && '-speaking')}>
        <VideoCover
          avatarUrl={avatarUrl}
          hasConnectionIssues={hasConnectionIssues}
          isCoverVisible={isCoverVisible}
          name={name}
        />
        {children}
      </div>
      <div className="stage__indicators">
        <div className="stage__name">
          {name}
          {isScreenSharingStream && ' - Presentation'}
        </div>
        <div className="stage__audio-level">
          {isPublishingAudio ? (
            <AudioLevelIndicator volumeLevel={volumeLevel} />
          ) : (
            <div className="indicator-icon -off">
              <AudioIcon isOn={false} />
            </div>
          )}
        </div>
        {isRaisingHand && (
          <div className="indicator-icon -hand">
            <HandIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoStream;
